import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import prepareKeywords from "./prepare_keywords";

// getSearchTask creates a task ready to be fetched
// it might return null in some circumstances

const getHash = (arr) => arr.map((item) => `${item.id}:${item.importance}`).sort();

const getSearchTaskModel = ({ term, searchTask, searchTaskId, choosenKeywords, deletedKeywords }) => {
  const { taskCustomFields } = prepareKeywords(choosenKeywords, deletedKeywords);
  let needsASave = true;
  const searchTaskModel = { state: "search", description: (term || "").trim(), communication_type: "group" };

  if (searchTaskId) {
    searchTaskModel.id = searchTaskId;
  }

  // fetch could be trigger not only by a race condition but also by an update of the
  // searchTask (that carries custom_fields or filters - TBD: should update of searchTask
  // update the term as well (description -> term)) basically if the most recent
  // searchTask props agree with local state then there is no need to fetch.
  if (searchTask) {
    const stateKeywordsHash = getHash(choosenKeywords);
    const taskKeywordsHash = getHash(flatten(searchTask.custom_fields.map(({ values }) => values)));

    const sameKeywords = isEqual(stateKeywordsHash, taskKeywordsHash);
    const sameTerm = searchTask.description.trim() === term.trim();

    if (sameKeywords && sameTerm) {
      needsASave = false;
    }

    // Only submit custom_fields to API if it was loaded before for removing
    searchTaskModel.custom_fields = taskCustomFields;
  } else if (!isEmpty(taskCustomFields)) {
    searchTaskModel.custom_fields = taskCustomFields;
  }

  return [searchTaskModel, needsASave];
};

export default getSearchTaskModel;
