import classNames from "classnames";
import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography";
import { useActivityVacancies } from "PFCore/hooks/queries/activity";
import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import css from "./activity_details_common.scss";

export const ActivityDetailsVacancies = ({ task }) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.details.vacancies" });

  const { isFetching, data: vacancies } = useActivityVacancies({
    activityId: task.id,
    perPage: 0,
    enabled: !task.id
  });

  return (
    <div className={classNames(css.infoItem, css.itemContainer)}>
      <Typography variant="h5" noMargin>
        {t("title")}
      </Typography>
      {isFetching ? (
        <LoadingDots />
      ) : (
        <div>
          <div className={css.vacanciesSection}>
            <Typography variant="bodyRegular" tag="span">
              <Trans
                t={t}
                i18nKey={"open"}
                values={{ count: vacancies?.meta.totalOpened }}
                components={[<Typography key={0} variant="bodyBold" tag="span" />]}
              />
            </Typography>
          </div>
          <div className={css.vacanciesSection}>
            <Typography variant="bodyRegular" tag="span">
              <Trans
                t={t}
                i18nKey={"total"}
                values={{ count: vacancies?.meta.total }}
                components={[<Typography key={0} variant="bodyBold" tag="span" />]}
              />
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

ActivityDetailsVacancies.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number
  })
};
