import isNil from "lodash/isNil";

import { convertToPercentage } from "../utilities/math";

type GetMatchDisplayValueOptions = {
  defaultValue?: string;
  showPercentage?: boolean;
  digits?: number;
  skipTrailingZeros?: boolean;
};

export const getMatchDisplayValue = (
  rawScore: number | undefined | null,
  options: GetMatchDisplayValueOptions = {
    defaultValue: "-",
    showPercentage: false,
    digits: 1,
    skipTrailingZeros: false
  }
) => {
  if (options.showPercentage) {
    return convertToPercentage(rawScore, {
      decimalPlaces: options.digits,
      placeholder: options.defaultValue,
      skipTrailingZeros: options.skipTrailingZeros
    });
  }

  if (isNil(rawScore)) {
    return options.defaultValue;
  }

  const scoreFixedDigits = (rawScore * 100).toFixed(options.digits);

  if (options.skipTrailingZeros) {
    return `${Number(scoreFixedDigits)}`;
  }

  return scoreFixedDigits;
};
