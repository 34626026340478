import classNames from "classnames";
import isNil from "lodash/isNil";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { getMatchDisplayValue } from "PFCore/helpers/match_value";
import { convertToPercentage } from "PFCore/utilities/math";

import css from "./match_card_scores.module.scss";

type MatchScoreProps = {
  title: string;
  score?: number | null;
  unit?: string;
  isBold?: boolean;
};

export const MatchScore = ({ title, score, unit, isBold }: MatchScoreProps) => {
  if (isNil(score)) {
    return null;
  }

  const formatScore = (score: number, digits: number) =>
    getMatchDisplayValue(score, { digits, skipTrailingZeros: true });

  const tooltipValue = convertToPercentage(score, { decimalPlaces: 3, skipTrailingZeros: true });

  return (
    <div className={css.matchScore}>
      <Typography variant="labelRegular" className={css.title} title={title} clipOverflow noMargin>
        {title}
      </Typography>
      <Tooltip content={tooltipValue}>
        <div className={css.value}>
          <Typography
            variant="h1"
            className={classNames({
              [css.bold]: isBold,
              [css.thin]: !isBold
            })}
            noMargin
          >
            {formatScore(score, 0)}
          </Typography>
          {unit && (
            <Typography variant="bodyRegular" tag="span" className={classNames(css.unit, css.bold)}>
              {unit}
            </Typography>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
