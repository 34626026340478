import camelCase from "lodash/camelCase";
import api from "PFCore/api";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useProfileInvalidate } from "PFCore/hooks/queries/profile/use_profile_invalidate";
import LightbulbIcon from "PFIcons/lightbulb.svg";
import { useTranslation } from "react-i18next";

import { NavDropdownItem, NavItemProps } from "../parts/nav_item";
import css from "./tips.module.scss";

const BASIC_INFO_HARDCODED_FIELDS = [
  "business_unit",
  "company",
  "talent_pool",
  "grade",
  "location",
  "day_rate"
];

const CONTACT_INFO_TYPES = [
  "twitter",
  "instagram",
  "facebook",
  "skype",
  "skype_business",
  "website",
  "phone",
  "ms_teams"
];

const dismissTip = ({ name }) =>
  api({
    url: "profile_progress_tips/dismiss",
    method: "POST",
    data: {
      name
    }
  });

type TipsProps = {
  closeSubMenu: NavItemProps["closeSubMenu"];
};

export const Tips = ({ closeSubMenu }: TipsProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "navBar.profileProgress.tips" });
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();
  const { invalidateCurrentProfile } = useProfileInvalidate();

  const tips = currentProfile.progress_tips || [];

  if (tips.length === 0) {
    return null;
  }

  return (
    <div className={css.root} data-qa-id="Navbar.Tips">
      <div className={css.header}>
        <LightbulbIcon width={13} height={13} />
        <span>
          {(currentProfile?.statistics?.progress_completion || 0) < 100
            ? t("headerNotCompleted")
            : t("header")}
        </span>
      </div>

      <p className={css.copy}>{t("hint", { name: currentProfile.first_name })}</p>

      <ul className={css.list}>
        {tips.map((tip, index) => {
          let sectionName = tip.name.replace("pcf_", "");
          const customType = (customTypes || []).find(({ name }) => name === sectionName);
          if (
            customType &&
            (customType?.sections?.filter(({ key }) => key === "profile_basic_info")?.length > 0 ||
              BASIC_INFO_HARDCODED_FIELDS.indexOf(customType?.name) >= 0)
          ) {
            sectionName = "basic_info";
          }

          if (CONTACT_INFO_TYPES.indexOf(sectionName) >= 0) {
            sectionName = "contact_info";
          }

          const displayName = customType ? customType.display_as : null;
          const name = tip.profile_section || tip.name;

          const tipMessage = displayName ? t("modify", { name: displayName }) : t(camelCase(name) as any);

          return (
            <NavDropdownItem
              key={`${index}:${tip.name}`}
              item={{
                id: tip.name,
                name: tipMessage,
                href: `/profiles/me/${sectionName}?edit=true&_t=${Date.now()}`,
                action: {
                  icon: "cross",
                  callback: () => dismissTip({ name: tip.name }).then(() => invalidateCurrentProfile()),
                  label: t("dismiss")
                }
              }}
              closeSubMenu={closeSubMenu}
            />
          );
        })}
      </ul>
    </div>
  );
};
