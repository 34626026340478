import { camelizeKeys } from "humps";
import isEmpty from "lodash/isEmpty";
import { ReopenActivityModal } from "PFApp/components/reopen_activity_modal";
import { useGrowl } from "PFApp/use_growl";
import { DropdownButton } from "PFComponents/dropdown_button";
import { isOpen } from "PFCore/helpers/activities";
import { isSubtemplate } from "PFCore/helpers/templates";
import { useActivityInvalidate, useActivityUpdate } from "PFCore/hooks/queries/activity";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import EllipsisIcon from "PFIcons/ellipsis.svg";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { useActivityPermissions } from "../../activities/hooks";
import { useActivityHistoryContext } from "../../activities/parts/history/activity_history_context";
import { useTemplate } from "../../hooks";
import useIsRoleRemovalPermitted from "../../use_is_role_removal_permitted";
import CloseConfirmModal from "./modals/close_confirm_modal";
import { ExpiryDateWarningModal } from "./modals/expiry_date_warning_modal";
import { PublishConfirmModal } from "./modals/publish_confirm_modal";
import { RemoveConfirmModal } from "./modals/remove_confirm_modal";

const allowedActions = [
  "publish",
  "edit",
  "clone",
  "close",
  "request_help",
  "remove-co-owner",
  "remove",
  "share",
  "show_history"
];

const auditAllowedActions = ["reopen", "clone", "show_history", "close"];

const ActivityActions = ({
  task,
  handlePublish,
  buttonSize,
  additionalOptions,
  dropDownStyle,
  handleEditPost,
  qaId,
  fromSearch,
  handleCloseActivitySuccess
}) => {
  const { t } = useTranslation("core");

  const history = useHistory();
  const { data: currentProfile } = useCurrentProfile();
  const { update: updateActivity } = useActivityUpdate(task.id);
  const { openHistory } = useActivityHistoryContext();
  const { invalidate: invalidateActivities } = useActivityInvalidate();

  const growl = useGrowl();
  const template = useTemplate(task.template_id);
  const isSubtemplateMissing = !!task.subtemplate_key && !isSubtemplate(template, task.subtemplate_key);

  const isAudit = template?.key.includes("audit");
  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [showCloseConfirm, setShowCloseConfirm] = useState(false);
  const [showExpiryDateModal, setShowExpiryDateModal] = useState(false);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [expiryDateWarningModalData, setExpiryDateWarningModalData] = useState({
    show: false,
    mode: "publish"
  });

  const removePermitted = useIsRoleRemovalPermitted();
  const { canSeeHistory } = useActivityPermissions(task);

  const publish = () => {
    updateActivity({ state: "new" })
      .then(() => {
        growl({
          kind: "success",
          title: t("activityActions.publishGrowlTitle"),
          message: t("activityActions.publishGrowl")
        });

        handlePublish();
      })
      .catch(() =>
        growl({
          message: t("activityActions.publishFailGrowl"),
          kind: "error",
          url: `/activities/${task.id}/edit`,
          handleClick: () => history.push(`/activities/${task.id}/edit`, { trigger: true })
        })
      );
  };

  const handleExpiryDate = (onNotExpiredCallback, mode) => {
    const { post_until } = task;
    const isExpired = new Date(post_until) < new Date();
    if (isExpired) {
      setExpiryDateWarningModalData({
        show: true,
        mode
      });
    } else {
      onNotExpiredCallback();
    }
  };

  const commonOptions = useMemo(() => {
    const items = [];

    const taskEmpty = isEmpty(task.metadata) && task.custom_fields.every((field) => isEmpty(field.values));

    if (task.coowned) {
      if (task.state === "draft" && handlePublish) {
        items.push({
          id: "publish",
          displayElement: t("activityActions.publish"),
          item: () => handleExpiryDate(() => (taskEmpty ? setShowPublishConfirm(true) : publish()), "publish")
        });
      }

      if (!isSubtemplateMissing) {
        items.push({
          id: "edit",
          displayElement: t("activityActions.edit"),
          item: () => {
            handleExpiryDate(
              () =>
                handleEditPost
                  ? handleEditPost(task.id)
                  : history.push(`/activities/${task.id}/edit`, { trigger: true }),
              "edit"
            );
          }
        });
      }

      if (isAudit && !isOpen(task)) {
        items.push({
          id: "reopen",
          displayElement: t("activityActions.reopen"),
          item: () => setShowExpiryDateModal(true)
        });
      }

      if (isOpen(task)) {
        // new or review
        items.push({
          id: "close",
          displayElement: t("activityActions.close"),
          item: () => setShowCloseConfirm(true)
        });
      }
    }

    if (
      template &&
      template.key !== "post" &&
      !template.hidden &&
      template.create_permitted &&
      !isSubtemplateMissing
    ) {
      items.push({
        id: "clone",
        displayElement: t("activityActions.clone"),
        item: () =>
          handleExpiryDate(
            () =>
              history.push(
                `/activities/${template.key}/new/${task.id}${fromSearch ? "?fromSearch=true" : ""}`,
                {
                  trigger: true
                }
              ),
            "clone"
          )
      });
    }

    if (canSeeHistory) {
      items.push({
        id: "show_history",
        displayElement: t("activityActions.showHistory"),
        item: () => openHistory(task)
      });
    }

    if (task.coowned && removePermitted) {
      items.push({
        id: "remove",
        displayElement: t("activityActions.remove"),
        item: () => setShowRemoveConfirm(true)
      });
    }

    return items;
  }, [task, currentProfile, isAudit, t]);

  const options = useMemo(() => {
    const allOptions = additionalOptions ? commonOptions.concat(additionalOptions) : commonOptions;
    const items = [];

    (isAudit ? auditAllowedActions : allowedActions).forEach((optionId) => {
      const option = allOptions.find(({ id }) => id === optionId);
      if (option) {
        items.push(option);
      }
    });
    return items;
  }, [commonOptions, additionalOptions, isAudit]);

  if (!options || options.length === 0) {
    return null;
  }

  return (
    <>
      <DropdownButton
        buttonKind="blank"
        size={buttonSize}
        options={options}
        handleChange={(fn) => fn()}
        dropDownStyle={{ ...dropDownStyle, overflow: "visible" }}
        qaId={qaId}
      >
        <EllipsisIcon width={20} height={20} style={{ fill: "var(--paletteBlue0)" }} />
      </DropdownButton>
      {showExpiryDateModal && (
        <ReopenActivityModal
          activity={camelizeKeys(task)}
          onClose={() => setShowExpiryDateModal(false)}
          onSuccess={() => invalidateActivities([task.id])}
        />
      )}
      {showCloseConfirm && (
        <CloseConfirmModal
          activity={task}
          handleClose={() => setShowCloseConfirm(false)}
          handleSuccess={() => handleCloseActivitySuccess?.()}
        />
      )}
      {showRemoveConfirm && (
        <RemoveConfirmModal activity={task} isAudit={isAudit} onClose={() => setShowRemoveConfirm(false)} />
      )}
      {showPublishConfirm && (
        <PublishConfirmModal
          templateName={template.name}
          onOk={publish}
          onClose={() => {
            setShowPublishConfirm(false);
          }}
        />
      )}
      {expiryDateWarningModalData.show && (
        <ExpiryDateWarningModal
          activityId={task.id}
          templateKey={template.key}
          mode={expiryDateWarningModalData.mode}
          onClose={() => setExpiryDateWarningModalData(false)}
        />
      )}
    </>
  );
};

ActivityActions.propTypes = {
  task: PropTypes.shape({
    profile: PropTypes.shape({
      id: PropTypes.number
    }),
    id: PropTypes.number,
    parent_activity_id: PropTypes.number,
    coowned: PropTypes.bool,
    private: PropTypes.bool,
    template_id: PropTypes.number,
    metadata: PropTypes.object,
    custom_fields: PropTypes.array,
    owners: PropTypes.array,
    state: PropTypes.string.isRequired,
    subtemplate_key: PropTypes.string,
    post_until: PropTypes.string,
    post_until_change_reason: PropTypes.object
  }).isRequired,
  handlePublish: PropTypes.func,
  buttonSize: PropTypes.string,
  additionalOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOf(allowedActions).isRequired,
      displayElement: PropTypes.node.isRequired,
      item: PropTypes.func.isRequired
    })
  ),
  dropDownStyle: PropTypes.object,
  handleEditPost: PropTypes.func,
  handleCloseActivitySuccess: PropTypes.func,
  qaId: PropTypes.string,
  fromSearch: PropTypes.bool
};

ActivityActions.defaultProps = {
  buttonSize: "sm",
  fromSearch: false
};

export default ActivityActions;
