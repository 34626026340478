import { Query, QueryKey } from "@tanstack/react-query";
import { CalendarRange, DateRange, Id } from "PFTypes";

import { FetchProfileMatchingRolesParams } from "../../../services/profile";
import { CalendarGranularity } from "./use_profiles_availabilities";

type Availability = {
  id: number;
  utilization: number;
  availableMinutes: number;
  billableUtilization: number;
  nominalWorkingMinutes: number;
  bookedMinutes: number;
};

export type AvailabilityDaily = {
  date: string;
} & Availability;

export type AvailabilityWeekly = {
  startDate: string;
  endDate: string;
} & Availability;

const normalizeParams = (params) =>
  ((params && (Array.isArray(params) ? params : [params])) as Array<unknown>) || [];

export const profileKeys = {
  all: () => ["profile"],
  profile: (profileId: Id) => [...profileKeys.all(), { profile: Number(profileId) }],
  me: () => ["me"],
  bookings: (profileId: Id, params?: unknown) => [
    ...profileKeys.profile(profileId),
    "bookings",
    ...normalizeParams(params)
  ],
  warnings: (profileId: Id, calendarRange?: CalendarRange, activityId?: Id) => [
    ...profileKeys.profile(profileId),
    "warnings",
    calendarRange,
    activityId
  ],
  availabilities: (
    profileId: Id,
    calendarRange: DateRange,
    calendarGranularity: CalendarGranularity,
    params?: unknown
  ) => [
    ...profileKeys.profile(profileId),
    "availabilities",
    calendarRange,
    calendarGranularity,
    ...normalizeParams(params)
  ],
  matchingRoles: ({ profileId, ...params }: FetchProfileMatchingRolesParams) => [
    ...profileKeys.profile(profileId),
    "matchingRoles",
    params
  ],
  isBookingQuery: (query: Query) => query.queryKey[0] === "profile" && query.queryKey.includes("bookings"),
  isWarningsQuery: (query: Query) => query.queryKey[0] === "profile" && query.queryKey.includes("warnings"),
  isAvailabilitiesQuery: (query: Query) =>
    query.queryKey[0] === "profile" && query.queryKey.includes("availabilities"),
  getProfileId: (keys: QueryKey): number | undefined =>
    // @ts-ignore
    (keys.find((key) => !!(key || {}).profile) || {}).profile
};

export default profileKeys;
