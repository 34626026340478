import last from "lodash/last";
import { Source } from "PFTypes/source";
import { useTranslation } from "react-i18next";

export const useSourceLabel = () => {
  const { t } = useTranslation();

  const getSourceLabel = (source: Source | null) => {
    if (!source) {
      return null;
    }

    const translationKey = last(source.split("."));

    return t(`source.${translationKey}` as unknown as TemplateStringsArray);
  };

  return {
    getSourceLabel
  };
};
