import isNil from "lodash/isNil";
import { RequirementType } from "PFTypes";
import { useRef } from "react";
import { useFormContext } from "react-hook-form";

import { BookingFormValues } from "../booking_form_provider";
import { Requirement } from "./requirement.utils";

export const DEFAULT_LOAD_REQUIREMENT = 100;

export const mapBookingDataToRequirement = ({ type, value }: Partial<Requirement>): Requirement =>
  isNil(type)
    ? {
        type: RequirementType.Load,
        value: DEFAULT_LOAD_REQUIREMENT
      }
    : {
        type,
        value
      };

export const useInitialRequirementValues = (itemIndex: number) => {
  const { getValues } = useFormContext<BookingFormValues>();

  const getInitialValues = () => {
    const requirement = getValues()?.bookings?.[itemIndex]?.requirement;
    const defaultLoad = {
      [RequirementType.Load]: DEFAULT_LOAD_REQUIREMENT
    };
    if (!requirement) {
      return defaultLoad;
    }
    const { type: defaultType, value: defaultValue } = mapBookingDataToRequirement(requirement);
    return {
      ...defaultLoad,
      [defaultType]: defaultValue
    };
  };

  return useRef<Partial<Record<RequirementType, number>>>(getInitialValues());
};
