import { useQueryClient } from "@tanstack/react-query";

import { potentialWarningsKeys } from "./query_keys";

export const usePotentialWarningsQueryInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidateAll: () => {
      queryClient.invalidateQueries({ queryKey: potentialWarningsKeys.all() });
    }
  };
};
