import isEqual from "lodash/isEqual";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { LoadingDots } from "PFComponents/loading_dots";
import { getProfileName } from "PFCore/helpers/profile";
import { Id, Profile } from "PFTypes";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ProfileMisalignments } from "../../../../../parts/hooks/use_get_misalignment_bars/get_misalignment_items_by_row";
import { WarningsDetail } from "../../warnings_detail/warnings_detail";
import { WarningsFilter } from "../../warnings_detail/warnings_filter";
import { MisalignmentsList } from "./misalignments_list";

const ALL_OPTION_ID = "all";

type MisalignmentsDetailProps = {
  profileId: Id | undefined;
  profiles: Profile[];
  areProfilesLoading: boolean;
  misalignments: ProfileMisalignments[];
  goToDate?: (date: Date) => void;
};

export const MisalignmentsDetail = ({
  profileId,
  profiles,
  areProfilesLoading,
  misalignments,
  goToDate
}: MisalignmentsDetailProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "details.misalignments" });

  const isSingleProfile = profiles.length === 1;

  const allOption: DropdownOption = {
    id: ALL_OPTION_ID,
    displayElement: t("allVacancies", { count: misalignments.length }),
    item: ALL_OPTION_ID
  };

  const workforceMemberOptions: DropdownOption[] = profiles.map((profile) => ({
    id: profile.id,
    displayElement: getProfileName(profile),
    item: profile.id
  }));

  const options = isSingleProfile ? workforceMemberOptions : [allOption, ...workforceMemberOptions];

  const defaultSelectedVacancy = profileId ? profileId : options[0].item;
  const [selectedVacancy, setSelectedVacancy] = useState<Id>(defaultSelectedVacancy);

  useEffect(() => {
    setSelectedVacancy(profileId ? profileId : options[0].item);
  }, [profileId]);

  if (areProfilesLoading) {
    return <LoadingDots />;
  }

  const filteredMisalignments =
    selectedVacancy === ALL_OPTION_ID
      ? misalignments
      : misalignments.filter(({ profileId }) => isEqual(String(profileId), String(selectedVacancy)));

  const misalignmentsCount = filteredMisalignments.flatMap(({ misalignments }) => misalignments).length;

  return (
    <WarningsDetail
      customHeader={
        <WarningsFilter
          isSingleOption={isSingleProfile}
          label={t("workforceMemberLabel")}
          singleOptionValue={getProfileName(
            profileId !== undefined ? profiles.find(({ id }) => profileId === id) : profiles[0]
          )}
          onChangeSelectedOption={setSelectedVacancy}
          selectedOption={selectedVacancy}
          options={options}
        />
      }
      summary={t("counter", { count: misalignmentsCount })}
    >
      {(selectedOrder) => (
        <MisalignmentsList
          misalignments={filteredMisalignments}
          goToDate={goToDate}
          selectedOrder={selectedOrder}
        />
      )}
    </WarningsDetail>
  );
};
