import { useQueryClient } from "@tanstack/react-query";

import { bookingApprovalsKeys } from "./query_keys";

export const useBookingApprovalsInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: (id?: number) => {
      if (id === undefined) {
        return queryClient.invalidateQueries({ queryKey: bookingApprovalsKeys.all() });
      }

      return queryClient.invalidateQueries({ queryKey: bookingApprovalsKeys.single(id) });
    }
  };
};
