import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import isObject from "lodash/isObject";
import moment from "moment";
import {
  filterValueIsInteger,
  isAvailabilityRule,
  isDateWithRangesValue,
  isDateWithSingleRangeValue
} from "PFCore/helpers/filters/type_checks";
import { Filter, NumberFilter, Value } from "PFCore/types/filters";

type TextFromFilterValue = {
  filter: Filter;
  formatDate: (rawDate: any) => string;
};

type GetDisplayValueFromFilterValueReturn =
  | { id: Value; text: string }
  | { id: Value; text: string }[]
  | null;

export const getDisplayValueFromFilterValue = ({
  filter,
  formatDate
}: TextFromFilterValue): GetDisplayValueFromFilterValueReturn => {
  const { title, value } = filter;

  if (isNil(value)) {
    return null;
  }

  if (filterValueIsInteger(value)) {
    const min = (filter as NumberFilter).statistics.min ?? 0;
    const max = (filter as NumberFilter).statistics.max ?? 0;
    const valueMin = Math.max(value.gte ?? min, min);
    const valueMax = Math.min(value.lte ?? max, max);
    if (valueMin === valueMax) {
      return { id: value, text: `${title}: ${valueMin}` };
    }
    return { id: value, text: `${title}: ${valueMin} - ${valueMax}` };
  }

  if (isDateWithRangesValue(value) || isAvailabilityRule(value)) {
    const minDate = formatDate(moment.min(value.ranges.map((range) => moment(range.start))));
    const maxDate = formatDate(moment.max(value.ranges.map((range) => moment(range.end))));

    return { id: value, text: `${minDate} - ${maxDate}` };
  }

  if (isDateWithSingleRangeValue(value)) {
    return { id: value, text: `${formatDate(value.start)} - ${formatDate(value.end)}` };
  }

  if (Array.isArray(value)) {
    const displayValue = value
      .map((valueItem) => ({
        id: valueItem,
        text: (isObject(valueItem) ? valueItem?.text : valueItem.toString()) ?? ""
      }))
      .filter(({ text }) => !!text);
    return !isEmpty(displayValue) ? displayValue : null;
  }

  if (isObject(value)) {
    return value.text ? { id: value, text: value.text } : null;
  }

  if (value === true || value === false || typeof value === "number") {
    return value.toString() ? { id: value, text: value.toString() } : null;
  }

  return { id: value, text: value };
};

export type FilterWithChildrenFlag = Filter & { children?: boolean };
