import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Id, MutationOptions } from "PFTypes";

import { PostReviewDeadlineResponse, putReviewDeadline } from "../../../services/activity/reviews";
import activityKeys from "./query_keys";
import { useActivityInvalidate } from "./use_activity_invalidate";

export const useActivityReviewDeadlineUpdate = (
  options: MutationOptions<{ id: Id; date: string }, PostReviewDeadlineResponse> = {}
) => {
  const cache = useQueryClient();
  const { invalidate: invalidateActivities } = useActivityInvalidate();

  return useMutation<PostReviewDeadlineResponse, unknown, { id: Id; date: string }>({
    mutationFn: ({ id, date }) => putReviewDeadline(id, date),
    ...options,
    onSuccess: (response, vars, context) => {
      const cacheKey = activityKeys.activity(vars.id);

      Promise.all([cache.invalidateQueries({ queryKey: cacheKey }), invalidateActivities([Number(vars.id)])]);
      options.onSuccess?.(response, vars, context);
    }
  });
};
