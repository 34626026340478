import { useActivityPermissions } from "PFApp/activities/hooks";
import { useActivityVacancies } from "PFCore/hooks/queries/activity";
import { Activity } from "PFTypes";
import { useCallback, useState } from "react";

const DEFAULT_PARAMS = { page: 1, perPage: 5 };

export const useActivityPageVacancies = (activity: Activity) => {
  const [params, setParams] = useState(DEFAULT_PARAMS);

  const { canSeeVacancies } = useActivityPermissions(activity);
  const { data: vacancies } = useActivityVacancies(
    {
      ...params,
      activityId: activity.id
    },
    {
      enabled: canSeeVacancies
    }
  );

  const changePage = useCallback(
    (page: number = DEFAULT_PARAMS.page, perPage: number = DEFAULT_PARAMS.perPage) =>
      setParams({
        ...DEFAULT_PARAMS,
        page,
        perPage
      }),
    [setParams]
  );

  return {
    vacancies,
    changePage
  };
};
