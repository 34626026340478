import { Id } from "PFTypes";

import {
  fetchActivityEconomicsScenario,
  FetchActivityEconomicsScenarioResponse
} from "../../../services/activity";
import { QueryOptionsWithCallbacks, useQueryWithCallbacks } from "../use_query_with_callbacks";
import activityKeys from "./query_keys";

export const useActivityEconomicsScenario = (
  scenarioId: Id,
  options?: QueryOptionsWithCallbacks<FetchActivityEconomicsScenarioResponse, { status: number }>
) =>
  useQueryWithCallbacks({
    queryKey: activityKeys.economicsScenario(scenarioId),
    queryFn: () => fetchActivityEconomicsScenario(scenarioId),
    retry: false,
    staleTime: 0,
    ...options
  });
