import classNames from "classnames";
import { forwardRef } from "react";

import { BorderShadow } from "./border_shadow";
import css from "./side_panel_footer.module.scss";

type SidePanelFooterProps = React.PropsWithChildren<{
  hasBorderShadow: boolean;
  hasScroll: boolean;
}>;

export const SidePanelFooter = forwardRef<HTMLDivElement, SidePanelFooterProps>(
  ({ hasBorderShadow, hasScroll, children }, ref) => (
    <div className={classNames(css.footer)}>
      <BorderShadow enabled={hasBorderShadow} />
      <div
        ref={ref}
        className={classNames(css.footerContent, css.customFooterContent, {
          [css.footerBorder]: hasScroll
        })}
      >
        {children}
      </div>
    </div>
  )
);

SidePanelFooter.displayName = "SidePanelFooter";
