import difference from "lodash/difference";
import range from "lodash/range";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BookingDetailItem } from "../../../booking_detail_item";
import { BookingFormValues } from "../../booking_form_provider";
import { WeekDaysSelect } from "../../week_days_select";

type RepeatsProps = {
  itemIndex: number;
};

export const Repeats = ({ itemIndex }: RepeatsProps) => {
  const { t } = useTranslation(["bookingModule", "translation"]);
  const { control } = useFormContext<BookingFormValues>();

  return (
    <BookingDetailItem
      label={t("bookingModule:bookings.create.repeats")}
      value={
        <Controller
          name={`bookings.${itemIndex}.repeatDays`}
          control={control}
          render={({ field }) => {
            const disabledDays = difference(range(0, 7), field.value?.allowedWeekDays ?? []);
            return (
              <WeekDaysSelect
                disabledDays={disabledDays}
                selectedDays={field.value?.selectedWeekDays ?? []}
                onChange={(selectedWeekDays) => field.onChange({ ...(field.value ?? {}), selectedWeekDays })}
              />
            );
          }}
        />
      }
    />
  );
};
