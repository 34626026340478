import { useTranslation } from "react-i18next";

import { ClosedVacancy, ClosedVacancyProps } from "./closed_vacancy";
import { OpenVacancy, OpenVacancyProps } from "./open_vacancy";
import css from "./vacancy.module.scss";

type VacancyProps = {
  vacancyNumber: number;
};

export const Vacancy = ({
  candidateValue,
  closeOptions,
  closureReasons,
  selectedProfileIds,
  onCandidateChange,
  onReasonChange,
  onReopenVacancy,
  reasonValue,
  vacancy,
  vacancyNumber,
  profile,
  filledRole
}: VacancyProps & OpenVacancyProps & ClosedVacancyProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.vacancies" });

  return (
    <div className={css.vacancy}>
      <div className={css.header}>{t("vacancyNumber", { number: vacancyNumber })}</div>
      {vacancy.state === "opened" ? (
        <OpenVacancy
          closeOptions={closeOptions}
          closureReasons={closureReasons}
          reasonValue={reasonValue}
          candidateValue={candidateValue}
          selectedProfileIds={selectedProfileIds}
          onReasonChange={onReasonChange}
          onCandidateChange={onCandidateChange}
        />
      ) : (
        <ClosedVacancy
          vacancy={vacancy}
          closureReasons={closureReasons}
          onReopenVacancy={onReopenVacancy}
          profile={profile}
          filledRole={filledRole}
        />
      )}
    </div>
  );
};
