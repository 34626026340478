import {
  QueryOptionsWithCallbacks,
  useQueryWithCallbacks
} from "PFCore/hooks/queries/use_query_with_callbacks";

import { PotentialWarningsResponse } from "../../../services/api";
import { BookingFormValues } from "../booking_form_provider";
import { BookingFormMode } from "../use_booking_form";
import { fetchPotentialWarnings } from "./fetch_potential_warnings";
import { potentialWarningsKeys, QueryKeyParams } from "./query_keys";

export type FetchPotentialWarningsData = {
  values: BookingFormValues;
  mode: BookingFormMode;
  queryKeyParams: QueryKeyParams;
};

export const usePotentialWarningsQuery = (
  params: FetchPotentialWarningsData,
  options?: QueryOptionsWithCallbacks<
    PotentialWarningsResponse,
    { response: any; statusText: string; code: string }
  >
) =>
  useQueryWithCallbacks<PotentialWarningsResponse, { response: any; statusText: string; code: string }>({
    queryKey: potentialWarningsKeys.single(params.queryKeyParams),
    queryFn: () =>
      fetchPotentialWarnings({
        values: params.values,
        mode: params.mode
      }),
    ...options
  });
