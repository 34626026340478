import reduce from "lodash/reduce";
import { Experience, GenericActivityEvent, Importance } from "PFTypes";
import { useTranslation } from "react-i18next";

export const useSanitizedAttributes = (
  diff: GenericActivityEvent["diff"],
  label?: string
): GenericActivityEvent["diff"] => {
  const { t } = useTranslation("activities", { keyPrefix: "show.history.entry.updatedAttributes" });

  return reduce(
    diff,
    (acc, value: object, key) => {
      // Hide metadata key from the UI
      if (key === "metadata") {
        return { ...acc, ...value! };
      }

      // Hide id key from the UI
      if (key === "id") {
        return acc;
      }

      // Flatten the Availability Ranges object with Index
      if (key === "ranges") {
        const flattenedRanges = reduce(
          value,
          (acc, value, key) => ({
            ...acc,
            [t("range", { index: key })]: value
          }),
          {}
        );

        return { ...acc, ...flattenedRanges };
      }

      if (key === "private") {
        const privacyValues = (value as boolean[]).map((val) => t(val ? "private.true" : "private.false"));
        return { ...acc, [key]: privacyValues };
      }

      // Translate the experience number values to readable labels
      if (label === "requiredExperience") {
        const experienceLabelMap = {
          [Experience.None]: t("experience.none"),
          [Experience.Basic]: t("experience.basic"),
          [Experience.Intermediate]: t("experience.intermediate"),
          [Experience.Advanced]: t("experience.advanced")
        };

        return [experienceLabelMap[diff[0]], experienceLabelMap[diff[1]]];
      }

      // Translate the importance number values to readable labels
      if (label === "importance") {
        const experienceLabelMap = {
          [Importance.Essential]: t("importance.essential"),
          [Importance.Required]: t("importance.required"),
          [Importance.Supporting]: t("importance.supporting")
        };

        return [experienceLabelMap[diff[0]], experienceLabelMap[diff[1]]];
      }

      return { ...acc, [key]: value };
    },
    {}
  );
};
