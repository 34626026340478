import { useQuery } from "@tanstack/react-query";
import isBoolean from "lodash/isBoolean";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { fetchSuspendedShortlists, ShortlistCollection } from "PFCore/services/shortlist";
import { FeatureFlag } from "PFTypes";
import { Id, QueryOptions, QueryParams } from "PFTypes";

import { shortlistsKeys } from "./query_keys";

export const useActivitySuspendedShortlists = (
  activityId: Id,
  params: QueryParams = {},
  options: QueryOptions<ShortlistCollection> = {}
) => {
  const isEnabled = useIsFeatureEnabled();
  const enabled = isBoolean(options.enabled) ? options.enabled : true;

  return useQuery<ShortlistCollection>({
    queryKey: shortlistsKeys.activitySuspendedShortlists(activityId, params),
    queryFn: () => fetchSuspendedShortlists(activityId, params.page, params.perPage),
    retry: false,
    ...options,
    enabled: isEnabled(FeatureFlag.SuspendedProfiles) && enabled
  });
};
