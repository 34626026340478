import { useGrowl } from "PFApp/use_growl";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  DiscoveredSkillsResponse,
  fetchDiscoveredSkills
} from "../../../services/skills/fetch_discovered_skills";
import { QueryOptionsWithCallbacks, useQueryWithCallbacks } from "../use_query_with_callbacks";
import { skillsKeys } from "./query_keys";

export const useDiscoveredSkills = (options?: QueryOptionsWithCallbacks<DiscoveredSkillsResponse>) => {
  const growl = useGrowl();
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });

  const { isError, ...query } = useQueryWithCallbacks({
    queryKey: skillsKeys.discovered(),
    queryFn: () => fetchDiscoveredSkills(),
    ...options
  });

  useEffect(() => {
    if (!isError) {
      return;
    }

    growl({
      message: t("skillsModal.errors.fetchDiscoveredSkills"),
      kind: "error"
    });
  }, [growl, isError, t]);

  return query;
};
