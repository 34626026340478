import truncate from "lodash/truncate";
import { ECOption } from "PFCore/helpers/echarts_wrapper";
import EchartsWrapper from "PFCore/helpers/echarts_wrapper";
import colors from "PFTheme/tokens/colors";
import { useMemo } from "react";

import { Header, HeaderProps } from "./header";

const getOptions = (
  color: string[],
  data: RadarChartProps["data"],
  indicator: { text: string }[]
): ECOption => ({
  color,
  radar: [
    {
      indicator: indicator?.map((item) => ({ ...item, text: truncate(item.text, { length: 14 }) })),
      center: ["50%", "50%"],
      radius: "70%",
      splitNumber: 3,
      shape: "circle",
      axisName: {
        color: colors.paletteBlue2
      },
      axisLine: {
        lineStyle: {
          color: colors.paletteWhite3,
          width: 1.5
        }
      },
      splitLine: {
        lineStyle: {
          color: colors.paletteWhite3,
          width: 1.5
        }
      }
    }
  ],
  series: [
    {
      type: "radar",
      symbol: "none",
      label: {
        fontSize: 14,
        color: "black"
      },
      lineStyle: {
        width: 3
      },
      areaStyle: {
        opacity: 1
      },
      emphasis: {
        lineStyle: {
          width: 4
        }
      },
      data
    }
  ]
});

type RadarChartProps = {
  color: string[];
  indicator: { text: string }[];
  title?: string;
  data: {
    name: string;
    value: number[];
    areaStyle?: { color: string };
  }[];
} & Pick<HeaderProps, "coverage" | "headerIconName" | "onHeaderIconClick">;

export const RadarChart = ({
  color,
  coverage,
  data,
  indicator,
  title,
  headerIconName,
  onHeaderIconClick
}: RadarChartProps) => {
  const options: ECOption = useMemo(() => getOptions(color, data, indicator), [color, data, indicator]);
  const series = data.map((item) => ({ ...item, data: item.value.map((value) => ({ value })) }));

  return (
    <>
      {title && (
        <Header
          title={title}
          series={series}
          coverage={coverage}
          headerIconName={headerIconName}
          onHeaderIconClick={onHeaderIconClick}
        />
      )}
      <EchartsWrapper options={options} height={200} minWidth={300} />
    </>
  );
};
