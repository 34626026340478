import { useTemplate } from "PFApp/hooks";
import { getVisibleProperties } from "PFCore/helpers/templates";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { ActivityPureCustomValue, CustomType, ProfileCustomValue } from "PFTypes";

import { MatchCustomFieldSection } from "../../components/match_attribute_section/match_custom_field_section";
import { useCustomFieldValuesOfImportance } from "../../hooks/use_custom_field_values_of_importance";
import { useMatchCardContext } from "../../match_card_context";
import css from "./activity_match_custom_fields.module.scss";

type MatchCustomFieldsData = CustomType & {
  roleCustomValues: ActivityPureCustomValue[];
  matchCustomValues: ProfileCustomValue[];
};

export const ActivityMatchCustomFields = () => {
  const { role } = useMatchCardContext();
  const { customTypes } = useCustomTypes();
  const template = useTemplate(role?.template_id);

  const wrappedGetCustomFieldValuesOfImportance = useCustomFieldValuesOfImportance();

  const matchProperties = getVisibleProperties(template).filter(
    ({ match, type, name }) => match && type === "custom_field" && name !== "skills"
  );
  const matchCustomFields: MatchCustomFieldsData[] = matchProperties
    .map(({ name: propertyName }) =>
      customTypes.find(({ name: customFieldName }) => customFieldName === propertyName)
    )
    .filter(Boolean)
    .map((customType) => {
      const { roleCustomValues, matchCustomValues } = wrappedGetCustomFieldValuesOfImportance(
        customType!.name
      );

      return {
        ...(customType as CustomType),
        roleCustomValues,
        matchCustomValues
      };
    })
    .filter(({ roleCustomValues }) => roleCustomValues.length > 0);

  if (matchCustomFields.length === 0) {
    return null;
  }

  return (
    <div className={css.activityMatchCustomFields}>
      {matchCustomFields.map(({ id, name, display_as: displayAs, roleCustomValues, matchCustomValues }) => (
        <MatchCustomFieldSection
          key={id}
          customFieldId={id}
          customFieldLabel={displayAs ?? name}
          customFieldName={name}
          i18nKey="customFieldCount"
          matchCustomValues={matchCustomValues}
          roleCustomValues={roleCustomValues}
          maxNameLength={18}
        />
      ))}
    </div>
  );
};
