import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/uniqBy";
import { Activity, CustomField, Metadata, Subtemplate } from "PFTypes";
import { Dispatch, SetStateAction } from "react";

import { FormFieldData } from "../../activity_edit_form.types";
import { useGetCustomFieldsToLink } from "../../hooks/use_get_custom_fields_to_link";
import { useUpdateCustomFieldValue } from "./use_update_custom_field_value";

type PopulateFromAllSourcesAttributes = {
  sourceValues: FormFieldData[];
  source: "engagement" | "subtemplate" | "skillsFrameworks";
};

type UseFieldsAutoPopulation = {
  selectedParentActivity?: Activity;
  cachedSubtemplateValues: FormFieldData[];
  cachedFrameworksValues: FormFieldData[];
  setMetadata: Dispatch<SetStateAction<Metadata>>;
  setCustomFields: Dispatch<SetStateAction<CustomField[]>>;
  subtemplate: Subtemplate | null;
};

export const useFieldsAutoPopulation = ({
  selectedParentActivity,
  cachedSubtemplateValues,
  cachedFrameworksValues,
  setMetadata,
  setCustomFields,
  subtemplate
}: UseFieldsAutoPopulation) => {
  const getCustomFieldsToLink = useGetCustomFieldsToLink();
  const handleFieldPopulate = useUpdateCustomFieldValue({ setMetadata, setCustomFields, subtemplate });

  const populateFromAllSources = ({ sourceValues, source }: PopulateFromAllSourcesAttributes) => {
    let engagementDataToPopulate: FormFieldData[] = [];
    if (source === "engagement") {
      engagementDataToPopulate = sourceValues;
    } else if (selectedParentActivity) {
      engagementDataToPopulate = getCustomFieldsToLink(selectedParentActivity).map<FormFieldData>(
        ({ type, values }) => ({
          values,
          fieldName: type.name
        })
      );
    }

    const subtemplateDataToPopulate: FormFieldData[] =
      source === "subtemplate" ? sourceValues : cachedSubtemplateValues;
    const skillsFrameworksDataToPopulate: FormFieldData[] =
      source === "skillsFrameworks" ? sourceValues : cachedFrameworksValues;

    // Source importance: Engagement > Subtemplate > Skills Framework
    const mergedCustomValues = cloneDeep(
      uniqBy(
        [...engagementDataToPopulate, ...subtemplateDataToPopulate, ...skillsFrameworksDataToPopulate],
        (data) => data.fieldName
      )
    );

    mergedCustomValues.forEach(({ fieldName, values }) => {
      handleFieldPopulate(fieldName, values);
    });
  };

  return {
    populateFromAllSources
  };
};
