import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useFirstRender } from "../../../hooks";

type UseSidePanelClose = {
  isOpen?: boolean;
  onClose: VoidFunction;
};

export const useCloseOnLocationChange = ({ isOpen, onClose }: UseSidePanelClose) => {
  const { pathname } = useLocation();
  const isFirstRender = useFirstRender();

  // Close sidepanel when page is changed
  useEffect(() => {
    if (isFirstRender || !isOpen) {
      return;
    }

    onClose?.();
  }, [pathname]);
};
