import { useIsMutating, useMutation } from "@tanstack/react-query";
import { editBookingNote } from "PFApp/booking/services/booking_notes_api";

import { MutationOptions } from "../../../../types";
import { noteKeys } from "./query_keys";
import { useBookingNoteInvalidate } from "./use_booking_note_invalidate";

export const useBookingNoteUpdate = (
  bookingId: number,
  noteId: number,
  options?: MutationOptions<string>
) => {
  const mutationKey = noteKeys.update(noteId);

  const { invalidate } = useBookingNoteInvalidate();

  const updateMutation = useMutation({
    mutationFn: async (body: string) => editBookingNote({ bookingId, id: noteId, body }),
    mutationKey,
    ...options,
    onSuccess: async (...args) => {
      await invalidate(bookingId);
      options?.onSuccess?.(...args);
    }
  });

  const isMutating = useIsMutating({ mutationKey });

  return {
    mutation: updateMutation,
    update: updateMutation.mutateAsync,
    isMutating
  };
};
