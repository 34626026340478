import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import LazyLocalExportTrigger from "PFApp/components/document_buttons/lazy_local_export_trigger";
import FiltersButton from "PFApp/components/filters/filters_button";
import { SearchEntity } from "PFApp/components/search_autocomplete/search_autocomplete.types";
import { SearchInput } from "PFApp/components/search_input";
import { getAPIFilters } from "PFApp/use_filtered_collection";
import { TablePropertiesButton } from "PFApp/workflow/parts/table_properties";
import { Button } from "PFComponents/button";
import PageTitle from "PFComponents/page/page_title";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { fetchActivityWatchers } from "PFCore/services/activities";
import { getExportProfiles } from "PFCore/services/export_profiles";
import { RecentSearchesPageKey } from "PFTypes/recent_searches";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import RefreshAction from "../../../../parts/refresh_action";
import useFormatWatchersToCsv from "./hooks/use_format_watchers_to_csv";
import { useInterestedContext } from "./interested_context_provider";
import css from "./interested_page_header.module.scss";

const InterestedPageHeader = () => {
  const {
    setTablePropertiesVisibility,
    toggleShowFilters,
    filtersStorage,
    selectedProfiles,
    watchers,
    columns,
    watchersMetadata,
    searchTerm,
    setSearchTerm
  } = useInterestedContext();
  const { t } = useTranslation("activities", { keyPrefix: "show.interested" });
  const { task } = useActivityPageContext() as {
    task: { id: number };
  };
  const formatWatchers = useFormatWatchersToCsv(columns);
  const { invalidateWatchers } = useActivityInvalidate();
  const history = useHistory();
  const { selectedFilters } = filtersStorage;
  const profilesForExport = selectedProfiles.length > 0 ? selectedProfiles : watchers;

  const fetchProfilesForExport = () =>
    getExportProfiles({
      profileIds: profilesForExport.map(({ profile }) => profile.id),
      activityId: task.id
    }).then((resp) => ({
      entries: resp.map((profile) => ({
        profile
      })),
      meta: { total: resp.length, page: 1 }
    }));

  const compareSelectedProfiles = () => {
    history.push({
      pathname: `/activities/${task.id}/compare/details/${selectedProfiles
        .map(({ profile: { id } }) => id)
        .join(",")}/interested`,
      state: { prevPath: location.pathname }
    });
  };

  const buttons = (
    <div className={css.buttonContainer}>
      <RefreshAction
        onUpdate={() => invalidateWatchers(task.id)}
        activityId={task.id}
        qaId="ActivityInterestedTabRefreshMatchesButton"
      />
      <Button
        text={t("buttons.compare")}
        kind="secondary"
        onClick={compareSelectedProfiles}
        disabled={selectedProfiles.length < 2}
        qaId="ActivityInterestedTabCompareButton"
      />
      <LazyLocalExportTrigger
        buttonKind="secondary"
        quantity={profilesForExport.length}
        getProfilesCollection={fetchProfilesForExport}
        getRawCollection={() =>
          fetchActivityWatchers(task.id, 1, 1000, getAPIFilters(selectedFilters)).then((res) => ({
            ...res,
            entries: formatWatchers(res.entries)
          }))
        }
        qaId="ActivityInterestedTabExportButton"
      />
      <TablePropertiesButton onClick={() => setTablePropertiesVisibility(true)} />
      <FiltersButton
        onClick={toggleShowFilters}
        filtersAreClear={isEmpty(omit(selectedFilters || {}, "utmSource"))}
        style={{ paddingBottom: "0" }}
      />
    </div>
  );

  return (
    <>
      <PageTitle actions={buttons}>
        <div className={css.titleContainer}>
          {t("title")}
          {watchersMetadata && watchersMetadata.total > 0 && <span>{`(${watchersMetadata.total})`}</span>}
        </div>
      </PageTitle>
      <SearchInput
        classes={{ root: css.searchInput }}
        term={searchTerm}
        placeholder={t("table.termSearch.placeholder")}
        pageKey={RecentSearchesPageKey.ActivityInterested}
        autocompleteConfig={[
          {
            entity: SearchEntity.Profile,
            redirect: true
          }
        ]}
        onTermChange={setSearchTerm}
        qaId="ActivityInterestedTabSearchTermInput"
      />
    </>
  );
};

export default InterestedPageHeader;
