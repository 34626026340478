import { verticalListSortingStrategy } from "@dnd-kit/sortable";
import filter from "lodash/filter";
import find from "lodash/find";
import { Stack } from "PFComponents/containers/flex/stack";
import Hr from "PFComponents/hr";
import SidePanel from "PFComponents/side_panel/side_panel";
import { ListAction, SortableList, SortableListProps } from "PFComponents/sortable_list";
import { Typography } from "PFComponents/typography";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useOptionalTranslation } from "PFCore/hooks/use_optional_translation";
import { Meta, Template } from "PFTypes";
import { useEffect, useMemo, useState } from "react";

import { TableColumn } from "../../../components/table/table_types";
import { getTemplateVisibleProperties } from "../../helpers";
import { GroupBy } from "./group_by";
import css from "./table_properties.module.scss";

type TablePropertiesProps = {
  isVisible: boolean;
  onCloseClick: () => void;
  onColumnsChange: (columns: TableColumn[]) => void;
  selectedColumns: TableColumn[];
  defaultColumns?: TableColumn[];
  selectedGroupBy?: string;
  onGroupByChange?: (group: string) => void;
  blockedProperties?: string[];
  template?: Template;
  meta?: Meta;
};

// TODO: [SP-1947] Refactor TableProperties logic
export const TableProperties = ({
  isVisible,
  onCloseClick,
  onColumnsChange,
  selectedColumns,
  defaultColumns = [],
  selectedGroupBy,
  onGroupByChange,
  blockedProperties,
  template,
  meta
}: TablePropertiesProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();
  const { t, optionalT } = useOptionalTranslation("workflow");

  // Optimistic update for immediate DnD feedback
  const [localSelectedColumns, setLocalSelectedColumns] = useState(selectedColumns);
  useEffect(() => {
    setLocalSelectedColumns(selectedColumns);
  }, [selectedColumns]);

  const visibleTemplateProperties = useMemo(
    () => getTemplateVisibleProperties(customTypes, currentProfile, blockedProperties!, template),
    [customTypes, currentProfile, blockedProperties, template]
  );

  const [availableColumns, visibleColumns, unselectedColumns] = useMemo(() => {
    const availableColumns = defaultColumns.concat(
      visibleTemplateProperties.map(({ name, type, title, kind }) => ({
        name,
        type,
        title,
        ...(type === "custom_field" && kind === "single" ? { orderKey: `custom_fields.${name}` } : {})
      })) as TableColumn[]
    );

    const visibleColumns = filter(
      localSelectedColumns,
      (column) => column.locked || find(availableColumns, ["name", column.name])
    ) as TableColumn[];

    const unselectedColumns = filter(
      availableColumns,
      (column) => !find(localSelectedColumns, ["name", column.name])
    );

    return [availableColumns, visibleColumns, unselectedColumns];
  }, [localSelectedColumns, visibleTemplateProperties, defaultColumns]);

  const handleChange = (newColumns: TableColumn[]) => {
    setLocalSelectedColumns(newColumns);
    onColumnsChange(newColumns);
  };

  const handleOrderChange: SortableListProps["onChange"] = (sortedItems, _, listAction) => {
    let newColumns: TableColumn[] = [];
    if (listAction === ListAction.Sort) {
      newColumns = sortedItems.map(({ id }) => availableColumns.find(({ name }) => id === name)!);
    }

    handleChange(newColumns);
  };

  const handleToggleChange = (itemId: string, toggled: boolean) => {
    const column = availableColumns.find(({ name }) => itemId === name)!;
    let newColumns: TableColumn[] = [];

    if (toggled) {
      newColumns = [...localSelectedColumns, column];
    } else {
      newColumns = localSelectedColumns.filter(({ name }) => name !== itemId);
    }

    handleChange(newColumns);
  };

  const selectedItems: SortableListProps["items"] = useMemo(
    () =>
      visibleColumns.map((column) => ({
        id: column.name,
        label: optionalT(column.title),
        toggled: !!localSelectedColumns.find(({ name }) => name === column.name),
        actionDisabled: column.locked
      })),
    [localSelectedColumns, visibleColumns, optionalT]
  );

  const unselectedItems: SortableListProps["items"] = useMemo(
    () =>
      unselectedColumns.map((column) => ({
        id: column.name,
        label: optionalT(column.title)
      })),
    [unselectedColumns, optionalT]
  );

  return (
    <SidePanel show={isVisible} fullHeight onClose={onCloseClick} title={t("parts.tableProperties")}>
      <Stack gap="spacingXl">
        <GroupBy meta={meta} selectedGroupBy={selectedGroupBy} onGroupByChange={onGroupByChange} />
        <Stack
          gap="spacingMd"
          className={css.columnButtonsContainer}
          data-qa-id="ActivityInterestedTabTableProperties"
        >
          {selectedItems.length > 0 && (
            <Typography variant="h5" noMargin>
              {t("parts.visibleColumns")}
            </Typography>
          )}
          <SortableList
            onChange={handleOrderChange}
            onToggle={handleToggleChange}
            items={selectedItems}
            strategy={verticalListSortingStrategy}
            action={ListAction.Toggle}
          />
          {unselectedItems.length > 0 && (
            <>
              <Hr className={css.hr} />
              <Typography variant="h5" noMargin>
                {t("parts.hiddenColumns")}
              </Typography>
            </>
          )}
          <SortableList
            onChange={() => {}}
            onToggle={handleToggleChange}
            items={unselectedItems}
            strategy={verticalListSortingStrategy}
            action={ListAction.Toggle}
            disabled
          />
        </Stack>
      </Stack>
    </SidePanel>
  );
};
