import type { DefaultError, InfiniteData, QueryClient, QueryKey } from "@tanstack/query-core";
import { useInfiniteQuery as useInfiniteTanstackQuery, UseInfiniteQueryOptions } from "@tanstack/react-query";

export const useInfiniteQuery = <
  TQueryFnData,
  TError = DefaultError,
  TData = InfiniteData<TQueryFnData>,
  TQueryKey extends QueryKey = QueryKey
>(
  options: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey, number>,
  queryClient?: QueryClient
) => useInfiniteTanstackQuery(options, queryClient);
