import { Modal } from "PFComponents/modal";
import { useTranslation } from "react-i18next";

type DeleteNoteModalProps = {
  closeModal: VoidFunction;
  onOK?: VoidFunction;
};

export const DeleteNoteModal = ({ closeModal, onOK }: DeleteNoteModalProps) => {
  const { t } = useTranslation(["translation", "bookingModule"]);
  return (
    <Modal
      title={t("bookingModule:details.notes.deleteNote")}
      labelOK={t("translation:delete")}
      onOK={onOK}
      onClose={closeModal}
      kindOK="danger"
    >
      {t("bookingModule:details.notes.areYouSureToDeleteNote")}
    </Modal>
  );
};
