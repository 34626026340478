import classNames from "classnames";
import isNil from "lodash/isNil";
import isNumber from "lodash/isNumber";
import pick from "lodash/pick";
import some from "lodash/some";
import { ActionIcon } from "PFComponents/action_icon";
import Toggle from "PFComponents/toggle/toggle";
import { AccountAvailability, Id } from "PFTypes";
import { AvailabilityMode, AvailabilityRule } from "PFTypes/booking";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { RANGES_ERROR_KEY, ROOT_ERROR_KEY } from "../constants";
import { RulesCarousel } from "../rules_carousel";
import { ThresholdSlider } from "../threshold_slider";
import { getDefaultAvailabilityRule } from "../utils";
import css from "./availability_input.module.scss";
import { isAvailabilityClear } from "./availability_input.utils";

type AvailabilityInputProps = {
  accountAvailability: AccountAvailability;
  availability: Partial<AvailabilityRule>;
  allowedModes: string[];
  defaultAvailabilityMode?: AvailabilityMode;
  defaultAvailabilityThreshold?: number;
  errors?: Record<string, string>;
  isFilter: boolean;
  minDate?: { start: string; end?: string };
  activityId?: Id;
  allowClearRestore?: boolean;
  onChange: (availability: AvailabilityRule | null, timeRuleModeChecked?: boolean) => void;
  onRestore?: () => void;
};

export const AvailabilityInput = ({
  onChange,
  accountAvailability,
  availability,
  allowedModes,
  defaultAvailabilityMode = AvailabilityMode.Within,
  defaultAvailabilityThreshold = 70,
  errors,
  isFilter,
  minDate,
  activityId,
  allowClearRestore = true,
  onRestore
}: AvailabilityInputProps) => {
  const { t } = useTranslation();
  const portalRef = useRef<HTMLDivElement>(null);

  const [isTimeRule, setIsTimeRule] = useState<boolean>(
    (availability?.mode || defaultAvailabilityMode) === AvailabilityMode.TimeRule ||
      (allowedModes.includes("time_rule") && allowedModes.length === 1)
  );

  useEffect(() => {
    if (availability.mode) {
      setIsTimeRule(availability.mode === AvailabilityMode.TimeRule);
    }
  }, [availability.mode]);

  const rootError = errors?.[ROOT_ERROR_KEY] || errors?.[RANGES_ERROR_KEY];

  const defaultAvailabilityRule = getDefaultAvailabilityRule(
    isTimeRule,
    accountAvailability,
    defaultAvailabilityThreshold
  );

  const handleChange = (change: Partial<AvailabilityRule>) => {
    const isLastRuleRemoval = !isNil(change.ranges) && !change.ranges.length;
    onChange({
      ...defaultAvailabilityRule,
      ...availability,
      ...change,
      ...(isLastRuleRemoval ? pick(defaultAvailabilityRule, "availability_threshold") : {})
    });
  };

  const handleThresholdChange = (value: number) => {
    handleChange({ availability_threshold: value });
  };

  const handleToggleMode = (value: boolean) => {
    setIsTimeRule(value);
    onChange(null, value);
  };

  const handleClear = () => onChange(null);
  const isTimeRuleToggleVisible = allowedModes.includes("time_rule") && allowedModes.length > 1;

  const threshold = isNumber(availability.availability_threshold)
    ? availability.availability_threshold
    : defaultAvailabilityThreshold;

  const noRangeSet = !some(
    availability.ranges || defaultAvailabilityRule.ranges,
    ({ start, end }) => !isNil(start) && !isNil(end)
  );

  return (
    <div className={css.root}>
      <div ref={portalRef} />
      <div className={classNames(css.content, { [css.errorBox]: !!rootError })}>
        <ThresholdSlider
          threshold={threshold}
          actions={
            allowClearRestore && (
              <div className={css.iconButtons}>
                {onRestore && (
                  <ActionIcon name="history" size="sm" onClick={onRestore} color="paletteBlue0" />
                )}
                {!isAvailabilityClear(availability, defaultAvailabilityRule) && (
                  <ActionIcon name="filter-clean" size="sm" onClick={handleClear} color="paletteBlue0" />
                )}
              </div>
            )
          }
          onChange={handleThresholdChange}
          disabled={noRangeSet}
          tooltip={
            noRangeSet ? t("availabilityRequirement.tooltips.addRequirementsBeforeThreshold") : undefined
          }
        />
        {isTimeRuleToggleVisible && (
          <Toggle
            inline
            label={t("availabilityRequirement.timeRules")}
            checked={isTimeRule}
            onChange={handleToggleMode}
          />
        )}
        <RulesCarousel
          accountAvailability={accountAvailability}
          defaultAvailabilityRule={defaultAvailabilityRule}
          isFilter={isFilter}
          isTimeRule={isTimeRule}
          allowedModes={allowedModes}
          availability={availability}
          minDate={minDate}
          onChange={handleChange}
          errors={errors}
          activityId={activityId}
          portalRef={portalRef}
        />
      </div>
      {rootError && <div className={css.errorMessage}>{rootError}</div>}
    </div>
  );
};
