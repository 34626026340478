import { Button } from "PFComponents/button";
import ButtonGroup from "PFComponents/button_group/button_group";
import { useShortlistDelete } from "PFCore/hooks/queries/shortlists/use_shortlist_delete";
import { Shortlist } from "PFTypes";
import { useTranslation } from "react-i18next";

type UndoButtonProps = {
  activityId: number;
  shortlist: Shortlist;
  isLoadingShortlists: boolean;
};

export const UndoButton = ({ activityId, shortlist, isLoadingShortlists }: UndoButtonProps) => {
  const { t } = useTranslation(["profiles", "translation"]);
  const { isPending: isDeleting, mutate: deleteShortlist } = useShortlistDelete({ activityId });

  const isButtonDisabled = isDeleting || isLoadingShortlists;

  return (
    <ButtonGroup autoWidth>
      <Button kind="secondary" disabled>
        {t("profiles:shortlistForProfile.shortlisted")}
      </Button>
      <Button
        kind="primary"
        onClick={() => {
          deleteShortlist(shortlist.id);
        }}
        disabled={isButtonDisabled}
      >
        {t("translation:undo")}
      </Button>
    </ButtonGroup>
  );
};
