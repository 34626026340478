import compact from "lodash/compact";
import map from "lodash/map";
import { CustomField, CustomType, Keyword } from "PFTypes";

type NormalizedKeyword = Omit<Keyword, "type"> & {
  type: CustomField["type"];
  match: boolean;
  filterKey?: string;
};

const findCustomType = (
  item: Keyword | NormalizedKeyword,
  customTypes: CustomType[]
): CustomField["type"] | null => {
  const type = customTypes.find(({ name, id }) =>
    typeof item.type === "string"
      ? name === item.type
      : (item.type && id === item.type.id) || (item.type && name === item.type.name) || false
  );

  if (!type) {
    return null;
  }

  return {
    id: type.id as number,
    name: type.name
  };
};

export const normalize = (
  item: Keyword | NormalizedKeyword,
  customTypes: CustomType[],
  matchCustomTypes: CustomType[],
  filterCustomTypes: CustomType[]
): NormalizedKeyword | null => {
  const customType = findCustomType(item, customTypes);

  if (!customType) {
    return null;
  }

  return {
    ...item,
    type: customType,
    match: !!findCustomType(item, matchCustomTypes),
    filterKey: findCustomType(item, filterCustomTypes)?.name
  };
};

export const normalizeList = (
  keywords: (Keyword | NormalizedKeyword)[],
  customTypes: CustomType[],
  matchCustomTypes: CustomType[],
  filterCustomTypes: CustomType[]
) => compact(map(keywords, (item) => normalize(item, customTypes, matchCustomTypes, filterCustomTypes)));

export const isFilter = (item: NormalizedKeyword) => !!item.filterKey;
export const isMatch = (item: NormalizedKeyword) => item.match;
