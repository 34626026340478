import isArray from "lodash/isArray";
import mapValues from "lodash/mapValues";
import uniqueId from "lodash/uniqueId";
import without from "lodash/without";
import { useAppContext } from "PFApp/app_context";
import ContactInformationEditField from "PFApp/profiles/edit/fields/contact_information_field";
import { Button } from "PFComponents/button";
import NotFoundComponent from "PFComponents/not_found/not_found";
import { isProfileFieldPermitted } from "PFCore/helpers/profile_permissions";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useCurrentProfile, useCurrentProfileSet } from "PFCore/hooks/queries/profile";
import { ApiRoute } from "PFCore/utilities/routes";
import { GROWL_ACTIONS } from "PFReducers/growl_reducer";
import { AccessLevel } from "PFTypes";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./contact_info_edit_form.less";

const ContactInfoEditForm = forwardRef(({ profile, adminPage, onLoading, onChanged }, ref) => {
  const { dispatch } = useAppContext();
  const { data: currentProfile } = useCurrentProfile();
  const setCurrentProfile = useCurrentProfileSet();

  const { t } = useTranslation("profiles", { keyPrefix: "common.contactInfo" });
  const { getFormattedErrors } = useResponseErrors();

  const [errors, setErrors] = useState({});
  const [hasRemoved, setHasRemoved] = useState(false);
  const [contacts, setContacts] = useState(
    () =>
      profile?.contact_information?.map((item) => ({
        ...item,
        // eslint-disable-next-line camelcase
        input_id: parseInt(uniqueId(), 10)
      })) || []
  );
  const [isEmpty, setIsEmpty] = useState(profile?.contact_information?.length === 0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsEmpty(contacts?.length === 0);
  }, [contacts]);

  const handleChange = (contact) => {
    const index = contacts.findIndex((cont) => cont.input_id === contact.input_id);
    contacts[index] = contact;
    setContacts(contacts);
    onChanged && onChanged(true);
  };

  const handleAddNew = () => {
    setIsEmpty(false);
    setContacts((prev) => [
      ...prev,
      {
        // eslint-disable-next-line camelcase
        input_id: parseInt(uniqueId(), 10),
        type: "phone",
        value: ""
      }
    ]);
  };

  const handleUpdate = () => {
    const url = ApiRoute(adminPage ? `/api/admin/profiles/${profile.id}` : "/api/me");

    onLoading(true);
    setIsLoading(true);
    $.ajax({
      url,
      method: "PUT",
      // eslint-disable-next-line camelcase
      api_version: 2,
      contentType: "application/json; charset=utf-8",
      // eslint-disable-next-line camelcase
      data: JSON.stringify({ contact_information: contacts })
    })
      .then((res) => {
        setErrors({});
        onChanged(false);
        setHasRemoved(false);
        onLoading(false);
        setIsLoading(false);
        dispatch({
          type: GROWL_ACTIONS.GROWL_RENDER,
          payload: {
            message: t("infoUpdated"),
            kind: "success"
          }
        });
        !adminPage && setCurrentProfile(res);
      })
      .catch((resp) => {
        const errors = mapValues(getFormattedErrors(resp), (val) => (isArray(val) ? val.join(", ") : val));
        setErrors(errors);
        onLoading(false);
        setIsLoading(false);
      });
  };

  const handleRemove = (contact) => {
    setContacts((prev) => without(prev, contact));
    setErrors({});
    setHasRemoved(true);
    onChanged && onChanged(true);
  };

  useImperativeHandle(ref, () => ({
    onAddNew: handleAddNew
  }));

  const isPermitted = isProfileFieldPermitted(
    currentProfile.permissions_group,
    profile?.permissions_group?.id,
    "contact_info",
    AccessLevel.ReadWrite
  );

  return (
    <div ref={ref}>
      {isEmpty && !hasRemoved && (
        <NotFoundComponent
          message={t("noInfoAdded")}
          buttonMessage={t("addNew")}
          buttonDisabled={isLoading || !isPermitted}
          handleClick={handleAddNew}
        />
      )}
      {(!isEmpty || hasRemoved) && (
        <div>
          <div
            style={{ marginTop: 30 }}
            className={css.wrap}
            data-qa-id="profile-edit-contact-info-links-section"
          >
            {contacts?.map((contact, index) => (
              <ContactInformationEditField
                key={contact.input_id}
                contact={contact}
                error={errors[`contact_information[${index}][value]`]}
                locked={!isPermitted}
                lockedTip={t("noPermissionsTip")}
                onChange={handleChange}
                onRemove={handleRemove}
              />
            ))}
            {hasRemoved && (
              <div style={{ display: "block", opacity: 0.7, textAlign: "center", margin: 40 }}>
                {t("someRemoved")}
              </div>
            )}
          </div>
          <div className={css.wrapper}>
            <Button onClick={handleUpdate} disabled={isLoading}>
              {t("translation:update")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
});

ContactInfoEditForm.propTypes = {
  profile: PropTypes.any.isRequired,
  adminPage: PropTypes.bool,
  onLoading: PropTypes.func,
  onChanged: PropTypes.func
};

ContactInfoEditForm.defaultProps = {
  adminPage: false
};

ContactInfoEditForm.displayName = "ContactInfoEditForm";

export default ContactInfoEditForm;
