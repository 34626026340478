import { useQuery } from "@tanstack/react-query";
import { fetchVacancies, FetchVacanciesParams } from "PFCore/services/vacancies";
import { VacanciesCollection } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { queryKeys } from "./query_keys";

export const useActivityVacancies = <TData = VacanciesCollection>(
  params: FetchVacanciesParams,
  options?: QueryOptions<VacanciesCollection, any, TData>
) =>
  useQuery({
    queryKey: queryKeys.vacancies(params.activityId, params),
    queryFn: () => fetchVacancies(params),
    retry: false,
    ...options
  });

export const useHasOpenVacancy = (
  params: FetchVacanciesParams,
  options?: QueryOptions<VacanciesCollection, any, boolean>
) =>
  useActivityVacancies<boolean>(params, {
    select: (data) => (data?.meta.totalOpened || 0) > 0,
    ...options
  });
