import flatMap from "lodash/flatMap";
import flatten from "lodash/flatten";
import groupBy from "lodash/groupBy";
import some from "lodash/some";
import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import without from "lodash/without";
import { getProfileName } from "PFCore/helpers/profile";
import { PermissionRule, Profile } from "PFTypes";

export const getOrderedActivityProfiles = (profiles: Profile[]): Profile[] => {
  // if permisisons group property does not exist on profiles then return unordered profiles
  if (some(flatMap(profiles, "permissions_group"), (permissionGroup) => !permissionGroup)) {
    return profiles;
  }

  const projectManagersPermissionsGroupsNames = uniqBy(
    profiles.map((profile) => profile.permissions_group),
    "name"
  )
    .filter(({ permissions }) =>
      permissions.find(({ name, permitted }) => name === PermissionRule.ProjectManager && permitted)
    )
    .map(({ display_as }) => display_as)
    .sort();

  const groupedByPermissionsGroup = groupBy(profiles, "permissions_group.display_as");
  const orderedGroupNames = projectManagersPermissionsGroupsNames.concat(
    without(Object.keys(groupedByPermissionsGroup), ...projectManagersPermissionsGroupsNames).sort()
  );

  return flatten(
    orderedGroupNames.map((groupName) =>
      sortBy(groupedByPermissionsGroup[groupName], (profile) => getProfileName(profile))
    )
  );
};
