import { keepPreviousData } from "@tanstack/react-query";
import { useActivityRankedShortlists } from "PFCore/hooks/queries/shortlists/use_activity_ranked_shortlists";
import { useActivityShortlists } from "PFCore/hooks/queries/shortlists/use_activity_shortlists";
import { useActivitySuspendedShortlists } from "PFCore/hooks/queries/shortlists/use_activity_suspended_shortlists";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import { Activity, QueryParams, Shortlist } from "PFTypes";
import { useCallback, useEffect, useState } from "react";

import { useActivityPermissions } from "../../hooks";

const useActivityPageShortlists = (activity: Activity) => {
  const { id } = activity;
  const { canSeeShortlist } = useActivityPermissions(activity);

  const [params, setParams] = useState<QueryParams>({});

  const shortlistsQueryOptions = {
    enabled: canSeeShortlist,
    placeholderData: keepPreviousData
  };

  const { data: shortlistsData, isFetching: isLoadingShortlists } = useActivityShortlists(
    id,
    params,
    shortlistsQueryOptions
  );

  const { data: suspendedShortlistsData, isFetching: isLoadingSuspendedShortlists } =
    useActivitySuspendedShortlists(id, params, shortlistsQueryOptions);

  const isAtLeastOneShortlist =
    !!shortlistsData?.entries?.length || !!suspendedShortlistsData?.entries?.length;

  const { data: rankedShortlistsData, refetch: refetchRankedShortlists } = useActivityRankedShortlists({
    activityId: id,
    options: {
      enabled: canSeeShortlist && isAtLeastOneShortlist
    }
  });

  const { invalidateShortlists } = useShortlistsInvalidate();

  useEffect(() => {
    if (canSeeShortlist) {
      invalidateShortlists(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSeeShortlist, params]);

  useEffect(() => {
    if (canSeeShortlist && isAtLeastOneShortlist) {
      refetchRankedShortlists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity, shortlistsData, suspendedShortlistsData, isAtLeastOneShortlist]);

  const updateShortlistsParams = useCallback((params: QueryParams) => {
    setParams((prev) => ({ ...prev, ...params }));
  }, []);

  const rankedShortlistsReady = rankedShortlistsData?.meta?.status === "ready";
  const shortlists = (shortlistsData?.entries as Shortlist[]) || [];
  const suspendedShortlists = (suspendedShortlistsData?.entries as Shortlist[]) || [];
  const allShortlists = [...shortlists, ...suspendedShortlists];

  return {
    shortlists,
    shortlistsMeta: shortlistsData?.meta || { total: 0 },
    suspendedShortlists,
    suspendedShortlistsMeta: suspendedShortlistsData?.meta || { total: 0 },
    allShortlists,
    isLoadingShortlists: isLoadingShortlists || isLoadingSuspendedShortlists,
    rankedShortlists: rankedShortlistsReady ? rankedShortlistsData.entries : [],
    updateShortlistsParams
  };
};

export default useActivityPageShortlists;
