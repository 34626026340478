/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import classNames from "classnames";
import find from "lodash/find";
import DisplayDate from "PFComponents/display/display_date";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { getTargetType } from "PFCore/helpers/conversation";
import { getProfileName } from "PFCore/helpers/profile";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import css from "./chat_list.module.scss";

const ChatListItem = ({ conversation, participants, isOpen, handleOpenConversation }) => {
  const { t } = useTranslation();
  const { data: currentProfile } = useCurrentProfile();

  const isUnread = conversation.unread_count > 0;

  const handleClick = () => handleOpenConversation(conversation.id);

  const renderParticipants = (owner) => {
    const profiles = (participants || [])
      .map((participant) => participant.profile)
      .filter((participant) => participant.id !== owner?.id) // already shown on side
      .slice(0, 9);

    return (
      <div className={css.profiles}>
        {profiles.map((profile, zIndex) => (
          <div className={css.avatarWrap} key={profile.id} style={{ zIndex }}>
            <ProfileAvatar size={20} profile={profile} />
          </div>
        ))}
      </div>
    );
  };

  const { title, owner } = useMemo(() => {
    const { title, owner = {} } = conversation;

    if (getTargetType(conversation) === "Profile") {
      const otherParticipant = find(
        participants,
        (participant) => participant.profile.id !== currentProfile.id
      )?.profile;

      return {
        title: otherParticipant ? getProfileName(otherParticipant) : "",
        owner: otherParticipant ? otherParticipant : owner
      };
    }

    return { title, owner };
  }, [conversation, participants, currentProfile]);

  const desc = (
    <>
      <div className={css.title}>{title}</div>
      <div className={css.info}>
        {isUnread ? t("chat.unreadMessage") : <DisplayDate date={conversation.last_activity_at} smartAgo />}
      </div>
    </>
  );

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <li
      onClick={handleClick}
      className={classNames(css.item, { [css.itemOpen]: isOpen, [css.itemUnread]: isUnread })}
    >
      {getTargetType(conversation) === "Profile" ? (
        <>
          <div className={css.ownerAvatar}>
            <ProfileAvatar profile={owner} size={30} />
          </div>
          <div className={css.description}>{desc}</div>
        </>
      ) : (
        <>
          <div className={css.activityDescription}>{desc}</div>
          <div className={css.avatarStack}>{renderParticipants(owner)}</div>
        </>
      )}
    </li>
  );
};

ChatListItem.propTypes = {
  conversation: PropTypes.any.isRequired,
  participants: PropTypes.any.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleOpenConversation: PropTypes.func.isRequired
};

export default ChatListItem;
