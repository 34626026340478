import classNames from "classnames";
import { useActivityParent } from "PFApp/activities/show/hooks/use_activity_parent";
import { useTemplate } from "PFApp/hooks";
import { scoresShapePropType } from "PFCore/base/prop_types/common_prop_types";
import PropTypes from "prop-types";

import { useActivityCurrentUserRole } from "../../hooks";
import { useActivityPageContext } from "../activity_page_context";
import AcceptedButtons from "./buttons/accepted_buttons";
import BookedOrConfirmedButtons from "./buttons/booked_or_confirmed_buttons";
import DeclinedButtons from "./buttons/declined_buttons";
import PendingButtons from "./buttons/pending_buttons";
import RespondedTag from "./buttons/responded_tag";
import css from "./buttons/shortlist_item_buttons.module.scss";

export const ShortlistItemButtons = ({ shortlistedProfile, isBlindModeOn, className }) => {
  const { task, isAudit } = useActivityPageContext();

  const parentActivity = useActivityParent(task);
  const template = useTemplate(task.template_id);
  const isRole = template?.key.includes("role");
  const qaPrefix = "shortlist";
  const isDeclined = shortlistedProfile.state === "declined";
  const { isReviewer, isResourcer } = useActivityCurrentUserRole(task);

  const hasException = task.workflow_state === "exception";

  const buttonsProps = {
    item: shortlistedProfile,
    task,
    parentActivity,
    isAudit,
    isResourcer,
    isReviewer,
    isRole,
    qaPrefix,
    hasException,
    isBlindModeOn,
    template
  };

  const Buttons = {
    pending: PendingButtons,
    accepted: AcceptedButtons,
    declined: DeclinedButtons,
    assigned: AcceptedButtons,
    filled: RespondedTag,
    rejected: isAudit ? PendingButtons : RespondedTag,
    booked: BookedOrConfirmedButtons,
    confirmed: BookedOrConfirmedButtons
  }[shortlistedProfile.state];

  return (
    <div className={classNames(css.actionButtons, { [css.declinedState]: isDeclined }, className)}>
      <Buttons {...buttonsProps} />
    </div>
  );
};

ShortlistItemButtons.propTypes = {
  shortlistedProfile: PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.oneOf([
      "pending",
      "accepted",
      "declined",
      "assigned",
      "filled",
      "rejected",
      "confirmed"
    ]),
    reason: PropTypes.string,
    profile: PropTypes.object,
    activity_id: PropTypes.number,
    scores: scoresShapePropType
  }).isRequired,
  isBlindModeOn: PropTypes.bool,
  className: PropTypes.string
};
