import reverse from "lodash/reverse";
import {
  fetchMessages,
  FetchMessagesPayload,
  FetchMessagesResponse
} from "PFCore/services/chat/fetch_messages";
import { InfiniteQueryOptions } from "PFTypes";

import { useInfiniteQuery } from "../use_infinite_query";
import { chatKeys } from "./query_keys";

export const useChatMessages = (
  payload: FetchMessagesPayload,
  options: InfiniteQueryOptions<
    FetchMessagesResponse,
    Error,
    {
      pages: FetchMessagesResponse[];
      pageParams: number[];
    }
  > & {
    placeholderData?: () => undefined;
  } = {}
) => {
  const queryKey = chatKeys.messages(payload.conversationId || "");

  return useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }) => {
      const { entries, meta } = await fetchMessages({
        ...payload,
        page: pageParam
      });

      return {
        entries: reverse(entries),
        meta
      };
    },
    getNextPageParam: (_, pages) => pages.length + 1,
    select: (data): { pages: FetchMessagesResponse[]; pageParams: number[] } => ({
      pages: [...data.pages].reverse(),
      pageParams: [...data.pageParams].reverse()
    }),
    placeholderData: { pages: [{ entries: [], meta: { total: 0 } }], pageParams: [] } as any,
    initialPageParam: 1,
    ...options
  });
};
