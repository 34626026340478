import { useCustomValuesCreate } from "PFCore/hooks/queries/custom_values/use_custom_values_create";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { useSkillsType } from "PFCore/hooks/use_skills_type";
import React, { useState } from "react";

import { NewSkill } from "./use_skills_modal";

type UseSkillChange = {
  newSkills: NewSkill[];
  setNewSkills: React.Dispatch<React.SetStateAction<NewSkill[]>>;
};

type UseSkillChangeReturn = {
  handleSkillChange: (newSkills: NewSkill[]) => void;
  blockedSkills: string[];
};

export const useSkillChange = ({ newSkills, setNewSkills }: UseSkillChange): UseSkillChangeReturn => {
  const growlError = useErrorsGrowl();
  const skillsCustomType = useSkillsType();

  const [blockedSkills, setBlockedSkills] = useState<string[]>([]);

  const { mutate: createCustomValue } = useCustomValuesCreate({
    onSuccess: (data) => {
      if (data.state === "blocked") {
        setBlockedSkills((prev) => [...prev, data.value as string]);
      }
      setNewSkills((prev) => [...prev, data as any]);
    },
    onError: (error) => {
      growlError(error.response);
    },
    gcTime: 0
  });

  const handleSkillChange = (changedSkills: NewSkill[]) => {
    const skillWasRemoved = changedSkills.length < newSkills.length;
    const filteredBlockedSkills =
      changedSkills.length > 0
        ? blockedSkills.filter((blockedSkill) =>
            changedSkills.find(
              ({ text, value }) => (text || value.toString()).toLowerCase() === blockedSkill.toLowerCase()
            )
          )
        : [];

    if (skillWasRemoved) {
      const isBlockedSkillsRemoved = filteredBlockedSkills.length < blockedSkills.length;

      isBlockedSkillsRemoved && setBlockedSkills(filteredBlockedSkills);
      setNewSkills(changedSkills);
    } else {
      const skillsToCreate = changedSkills
        .filter(({ created }) => created)
        .filter((skill) => !filteredBlockedSkills.includes(skill.text));

      if (skillsToCreate.length > 0 && skillsCustomType?.id) {
        skillsToCreate.forEach(({ value }) => {
          createCustomValue({ payload: { value, customTypeId: skillsCustomType?.id } });
        });
      } else {
        setNewSkills(changedSkills);
      }
    }
  };

  return {
    handleSkillChange,
    blockedSkills
  };
};
