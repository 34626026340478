import { useQuery } from "@tanstack/react-query";
import {
  ActivitiesOptionsParams,
  ActivityOptionsResponse,
  fetchActivitiesOptions
} from "PFCore/services/activities";
import { QueryOptions } from "PFTypes/request_options";

import { activitiesKeys } from "./query_keys";

export const useActivitiesOptions = (
  params: ActivitiesOptionsParams,
  options: QueryOptions<ActivityOptionsResponse> = {}
) =>
  useQuery<ActivityOptionsResponse>({
    queryKey: activitiesKeys.options(params),
    queryFn: () => fetchActivitiesOptions(params),
    ...options
  });
