import { Button } from "PFComponents/button";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useShortlistFill } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { useTranslation } from "react-i18next";

import { useRoleProfileActionButtonsContext } from "../context/role_profile_action_buttons_context";
import { ProfileActionButtonsProps } from "./profile_action_buttons_props";
import css from "./shortlist_buttons.module.scss";

export const FillButton = ({ onError, onSuccess }: ProfileActionButtonsProps) => {
  const { t } = useTranslation("activities");
  const { role, shortlist, isFillDisabled, areButtonsDisabled, small } = useRoleProfileActionButtonsContext();
  const { invalidateVacancies } = useActivityInvalidate();
  const { mutate: fillShortlist, isPending } = useShortlistFill();

  const handleClick = () => {
    fillShortlist(
      { id: shortlist!.id },
      {
        onSuccess: () => {
          invalidateVacancies(role.id);
          onSuccess?.("fill");
        },
        onError: () => onError?.()
      }
    );
  };

  const disabled = areButtonsDisabled || isFillDisabled || isPending;

  return (
    <Button
      onClick={(event) => {
        event.stopPropagation();
        handleClick();
      }}
      disabled={disabled}
      small={small}
      className={css.shortlistButton}
    >
      {!isPending ? t("show.shortlist.fill") : "..."}
    </Button>
  );
};
