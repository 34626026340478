import every from "lodash/every";
import isEmpty from "lodash/isEmpty";
import { AvailabilityRule } from "PFTypes";

export const isAvailabilityClear = (
  availability: Partial<AvailabilityRule>,
  defaultAvailability: Partial<AvailabilityRule>
) => {
  if (isEmpty(availability)) {
    return true;
  }

  if (availability.availability_threshold !== defaultAvailability.availability_threshold) {
    return false;
  }

  const isEveryDateRangeEmpty = every(availability.ranges || [], ({ start, end }) => !start && !end);

  if (isEveryDateRangeEmpty) {
    return true;
  }

  return false;
};
