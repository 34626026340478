import { ProfileCustomValuesList } from "PFApp/components/custom_values_list";
import { useCustomValuePreviewActions } from "PFApp/hooks/use_custom_value_preview_actions";
import { generateMatchCustomValues } from "PFCore/helpers/custom_value";
import { ActivityPureCustomValue, CustomValueScope, Id, MatchCustomValue } from "PFTypes";
import { Trans, useTranslation } from "react-i18next";

import { useMatchCardContext } from "../../match_card_context";
import { InfoSection } from "../info_section";

type MatchCustomFieldSectionProps = {
  customFieldId: Id;
  customFieldLabel?: string;
  customFieldName: string;
  i18nKey: string;
  matchCustomValues: MatchCustomValue[];
  roleCustomValues: ActivityPureCustomValue[];
  maxNameLength?: number;
};

export const MatchCustomFieldSection = ({
  customFieldId,
  customFieldLabel,
  customFieldName,
  i18nKey,
  matchCustomValues,
  roleCustomValues,
  maxNameLength
}: MatchCustomFieldSectionProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.matches.card" });
  const { id: profileId, animate, role, isBlindModeOn } = useMatchCardContext();
  const { showPreview } = useCustomValuePreviewActions();
  const matchAndActivityCustomValues = generateMatchCustomValues(matchCustomValues, roleCustomValues);

  return (
    <InfoSection
      title={
        <Trans
          t={t}
          i18nKey={i18nKey as any}
          values={{
            name: customFieldLabel,
            count: matchCustomValues.length,
            total: roleCustomValues.length
          }}
          components={[<strong key="0" />]}
        />
      }
    >
      <ProfileCustomValuesList
        customFieldName={customFieldName}
        customValues={matchAndActivityCustomValues}
        profileId={profileId}
        isBlindModeOn={isBlindModeOn}
        maxNameLength={maxNameLength}
        onMoreLessButtonClick={animate}
        onMissingClick={(customValueId) => {
          showPreview({
            scope: CustomValueScope.Activity,
            customTypeId: customFieldId,
            customValueId,
            activityId: role.id
          });
        }}
      />
    </InfoSection>
  );
};
