import capitalize from "lodash/capitalize";
import { MisalignmentItem } from "PFApp/booking/types";
import { Typography } from "PFComponents/typography";

import { useErrorTypeLabels } from "../use_error_type_labels";

type MisalignmentSubHeaderProps = Pick<MisalignmentItem, "errors">;

export const MisalignmentSubHeader = ({ errors }: MisalignmentSubHeaderProps) => {
  const errorTypeLabels = useErrorTypeLabels();

  const label = Object.keys(errors)
    .map((errorKey) => errorTypeLabels[errorKey] ?? errorKey)
    .join(", ");

  return (
    <Typography variant="bodyRegular" tag="span">
      {capitalize(label)}
    </Typography>
  );
};
