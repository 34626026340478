import { ProfileCustomValuesList } from "PFApp/components/custom_values_list";
import { Id, ProfileCustomValue } from "PFTypes";

type VerticalSkillsListProps = {
  skills: ProfileCustomValue[];
  profileId: Id;
  showLeftIcon?: boolean;
};

export const VerticalSkillsList = ({ skills, profileId, showLeftIcon = true }: VerticalSkillsListProps) => (
  <ProfileCustomValuesList
    customFieldName="skills"
    profileId={profileId}
    customValues={skills}
    limit={Infinity}
    showLeftIcon={showLeftIcon}
    canEdit
    vertical
  />
);
