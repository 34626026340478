import { useQueryClient } from "@tanstack/react-query";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import isMatch from "lodash/isMatch";
import isPlainObject from "lodash/isPlainObject";

import { FetchRolesParams } from "../../../services/roles";
import { auditRolesKeys, rolesKeys } from "./query_keys";

const isFiltersQueryKey = (queryKeyPart: FetchRolesParams | unknown[]): queryKeyPart is FetchRolesParams =>
  isPlainObject(queryKeyPart) &&
  ["page", "perPage", "filters", "order"].some((fetchRoleParamsProperty) =>
    has(queryKeyPart, fetchRoleParamsProperty)
  );

export const useRolesInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidateRoles: () => queryClient.invalidateQueries({ queryKey: rolesKeys.all() }),
    invalidateAuditRoles: () => queryClient.invalidateQueries({ queryKey: auditRolesKeys.all() }),
    invalidateFilteredRoles: (filters: FetchRolesParams | undefined) =>
      queryClient.invalidateQueries({
        predicate: (query) => {
          if (!["roles", "auditRoles"].includes(query.queryKey[0] as string)) {
            return false;
          }

          if (!filters || isEmpty(filters)) {
            return true;
          }

          const queriedFilters = query.queryKey.find(isFiltersQueryKey);

          return !!queriedFilters && isMatch(queriedFilters, filters);
        }
      })
  };
};
