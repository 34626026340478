import { useQuery } from "@tanstack/react-query";
import { ActivitiesResponse, fetchActivities } from "PFCore/services/activities";
import { QueryOptions, QueryParams } from "PFTypes";

import { activitiesKeys } from "./query_keys";

export const useActivities = (params: QueryParams, options: QueryOptions<ActivitiesResponse> = {}) =>
  useQuery<ActivitiesResponse>({
    queryKey: activitiesKeys.all(params),
    queryFn: () => fetchActivities(params),
    ...options
  });
