import classNames from "classnames";
import range from "lodash/range";
import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import useWindowSize from "PFCore/helpers/use_window_size";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import css from "./simple_pagination.module.scss";

const getSpan = (windowWidth: number) => {
  let span = 3; // 3 before 3 after: < ... 1 2 3 (4) 5 6 7 ... >
  if (windowWidth < 360) {
    span = 1; // < ... 3 (4) 5 .. >
  } else if (windowWidth < 460) {
    span = 2; // < ... 2 3 (4) 5 6 .. >
  }
  return span;
};

const Gap = ({ onClick }: { onClick: VoidFunction }) => (
  <Button className={css.gap} onClick={onClick}>
    &hellip;
  </Button>
);

type PageProps = {
  page: number;
  isCurrentPage?: boolean;
  onClick: (page: number) => void;
  className?: string;
};

const Page = ({ page, isCurrentPage = false, onClick, className }: PageProps) => {
  const { t } = useTranslation("core");
  return (
    <Button
      className={classNames(css.page, className, {
        [css.current]: isCurrentPage
      })}
      onClick={() => onClick(page)}
      title={t("page", { page })}
    >
      <Typography className={css.pageText} noMargin variant={isCurrentPage ? "bodyBold" : "bodyRegular"}>
        {page}
      </Typography>
    </Button>
  );
};

type SimplePaginationProps = {
  currentPage?: number;
  totalPages?: number;
  handleClick: (page: number) => void;
  disabled?: boolean;
  scrollOnClick?: boolean;
  style?: CSSProperties;
  className?: string;
  customSpan?: number;
};

const EDGE_PAGE_AND_GAP_OFFSET = 2;
const LOWEST_NON_EDGE_PAGE = 2;
const FIRST_PAGE = 1;

export const SimplePagination = ({
  currentPage = 1,
  totalPages = 1,
  handleClick,
  disabled,
  scrollOnClick = true,
  style,
  className,
  customSpan
}: SimplePaginationProps) => {
  const { windowWidth } = useWindowSize();

  if (totalPages <= 1) {
    return null;
  }

  const highestNonEdgePage = totalPages - 1;

  const span = customSpan || getSpan(windowWidth);

  const showRightWindow = currentPage + span + EDGE_PAGE_AND_GAP_OFFSET < totalPages;
  const showLeftWindow = currentPage - span - EDGE_PAGE_AND_GAP_OFFSET > FIRST_PAGE;

  const low = showLeftWindow ? Math.max(currentPage - span, LOWEST_NON_EDGE_PAGE) : LOWEST_NON_EDGE_PAGE;
  const high = showRightWindow ? Math.min(currentPage + span, highestNonEdgePage) : highestNonEdgePage;

  const showLeftGap = showLeftWindow && low > LOWEST_NON_EDGE_PAGE;
  const showRightGap = showRightWindow && high < highestNonEdgePage;

  const goToPage = (newPage) => {
    handleClick && currentPage !== newPage && handleClick(newPage);
    if (scrollOnClick) {
      $("html, body").animate({ scrollTop: 0 }, 100, "swing");
    }
  };

  return (
    <div className={classNames(css.root, className, { [css.disabled]: disabled })} style={style}>
      <Page page={1} isCurrentPage={currentPage === FIRST_PAGE} onClick={goToPage} className={css.low} />
      {showLeftGap && <Gap onClick={() => goToPage(low - 1)} />}
      {range(low, high + 1).map((i) => (
        <Page key={i} isCurrentPage={i === currentPage} page={i} onClick={goToPage} />
      ))}
      {showRightGap && <Gap onClick={() => goToPage(high + 1)} />}
      <Page
        page={totalPages}
        isCurrentPage={currentPage === totalPages}
        onClick={goToPage}
        className={css.high}
      />
    </div>
  );
};
