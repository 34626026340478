import snakeCase from "lodash/snakeCase";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { ProfilePositionHighlightAttribute } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { getCustomTypeName } from "./helpers";

export const useGetPositionAttributeName = () => {
  const { customTypes } = useCustomTypes();

  const { t } = useTranslation("translation", { keyPrefix: "searchHighlights" });
  return useCallback(
    (attribute: ProfilePositionHighlightAttribute) => {
      if (attribute === ProfilePositionHighlightAttribute.Summary) {
        return t(`position.${attribute}` as unknown as TemplateStringsArray);
      }

      return getCustomTypeName(customTypes.find(({ name }) => name === snakeCase(attribute))) || attribute;
    },
    [customTypes, t]
  );
};
