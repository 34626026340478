import omit from "lodash/omit";
import AppContext from "PFApp/app_context";
import { CustomType, CustomValuePreview, Id } from "PFTypes";
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react";

type CustomValuePreviewContextType = Omit<CustomValuePreview, "customValueId"> & {
  customType: CustomType;
  allowSearch: boolean;
  customValueId: Id | null;
  setSelectedCustomValueId: Dispatch<SetStateAction<Id | null>>;
};

const CustomValuePreviewContext = createContext<CustomValuePreviewContextType>(
  {} as CustomValuePreviewContextType
);

export const CustomValuePreviewContextProvider = ({
  children,
  customType
}: PropsWithChildren<Pick<CustomValuePreviewContextType, "customType">>) => {
  const {
    store: { customValuePreview }
  } = useContext(AppContext);

  const {
    customValueId: initialCustomValueId = null,
    allowSearch = false,
    canRemove = true
  } = customValuePreview;

  const [selectedCustomValueId, setSelectedCustomValueId] = useState<Id | null>(null);

  useEffect(() => {
    setSelectedCustomValueId(null);
  }, [initialCustomValueId]);

  return (
    <CustomValuePreviewContext.Provider
      value={{
        ...omit(customValuePreview, "customValueId"),
        customType,
        allowSearch,
        canRemove,
        customValueId: allowSearch ? selectedCustomValueId : initialCustomValueId,
        setSelectedCustomValueId
      }}
    >
      {children}
    </CustomValuePreviewContext.Provider>
  );
};

export const useCustomValuePreviewContext = (): CustomValuePreviewContextType => {
  const customValuePreviewContext = useContext<CustomValuePreviewContextType>(CustomValuePreviewContext);

  if (!customValuePreviewContext) {
    throw new Error("useCustomValuePreviewContext must be used within a CustomValuePreviewContextProvider");
  }

  return (customValuePreviewContext || {}) as CustomValuePreviewContextType;
};
