import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { SelectV2, SelectV2Props } from "PFComponents/select_v2";
import { FilterWithDefaults, Id } from "PFTypes";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { areFilterValuesEqual, letFilterRestore } from "../../use_filtered_collection";
import { createDropdownId, numberizeValue, stringifyValue } from "./filters.utils";
import { OptionOriginal, useFiltersOptions } from "./use_filters_options";

type SelectManyItemProps = SelectV2Props<OptionOriginal> & {
  filter: FilterWithDefaults;
  onChange: (ids: Id[] | null, values?: OptionOriginal[] | null) => void;
  searchId?: number;
  disabled?: boolean;
};

export const SelectManyItem = ({
  filter,
  onChange,
  disabled,
  labelTooltip,
  searchId
}: SelectManyItemProps) => {
  const { t } = useTranslation();

  const optionsProps = useFiltersOptions({ filter });

  const letRestore = letFilterRestore(filter);

  const value = useMemo(() => {
    if (isNil(filter.value)) {
      return [];
    }

    const values = ((Array.isArray(filter.value) ? filter.value : [filter.value]) || []) as Id[];

    return values.map((item) => numberizeValue(item));
  }, [filter.value]);

  const [tempMultiValues, setTempMultiValues] = useState<Id[]>(value);
  const [tempMultiObjects, setTempMultiObjects] = useState<OptionOriginal[]>(value);

  useEffect(() => setTempMultiValues(value), [value]);

  const renderDisplayValue = (value) => {
    if (!value || value.length === 0) {
      return "";
    }
    const isOR = filter.operator === "any";
    const label = isOR
      ? t("filters.filtersSelectedOR", { count: value.length })
      : t("filters.filtersSelected", { count: value.length });
    return label;
  };

  const handleChange = (value) => {
    setTempMultiValues(value.map(({ id }) => id));
    setTempMultiObjects(value.map(({ original }) => original));
  };
  const handleClear = () => onChange(null);
  const handleDropdownClose = () =>
    !areFilterValuesEqual(value, tempMultiValues) &&
    onChange(tempMultiValues.map(stringifyValue), tempMultiObjects);

  const noValue = !value || isEmpty(value);

  return (
    <SelectV2<OptionOriginal>
      disabled={filter.disabled || disabled}
      label={filter.title}
      labelTooltip={labelTooltip}
      value={tempMultiValues}
      onChange={handleChange}
      onRestore={letRestore ? () => onChange(filter.defaultValue as Id[]) : undefined}
      onClear={noValue ? undefined : handleClear}
      onDropdownClose={handleDropdownClose}
      renderDisplayValue={renderDisplayValue}
      dropdownId={createDropdownId(filter, searchId)}
      multiple
      {...optionsProps}
    />
  );
};
