import { useIsMutating } from "@tanstack/react-query";
import { useBookingFormContext } from "PFApp/booking/components/booking_form";
import { fetchBooking } from "PFApp/booking/services/api";
import { Booking } from "PFCore/types";
import { useEffect, useState } from "react";

import { QueryOptionsWithCallbacks, useQueryWithCallbacks } from "../use_query_with_callbacks";
import { bookingKeys } from "./query_keys";
import { getBookingDeleteMutationKey } from "./use_booking_delete";

export const useBooking = (bookingId?: number, options: QueryOptionsWithCallbacks<Booking> = {}) => {
  const { profileId } = useBookingFormContext();

  const [disabled, setDisabled] = useState(false);

  const isDeleting = !!useIsMutating({ mutationKey: getBookingDeleteMutationKey(bookingId) });

  useEffect(() => {
    if (isDeleting) {
      setDisabled(true);
    }
  }, [isDeleting]);

  return useQueryWithCallbacks<Booking>({
    queryKey: bookingKeys.single(bookingId, profileId),
    queryFn: () => fetchBooking(bookingId),
    retry: false,
    ...options,
    enabled: !disabled && (options.enabled ?? true)
  });
};
