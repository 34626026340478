import map from "lodash/map";
import mapValues from "lodash/mapValues";
import { getValueFilters } from "PFApp/use_filtered_collection";
import { Filters, Value } from "PFTypes";

export const removeBlacklistedFilters = (valueFilters: Filters<Value>, blacklist: string[]) =>
  mapValues(valueFilters, (entry, familyName) => {
    if (familyName === "availability") {
      return blacklist.includes(familyName) ? undefined : entry;
    }

    return mapValues(entry as Record<string, Value>, (filterValue, name) =>
      blacklist.includes(name) ? null : filterValue
    );
  }) as Filters<Value>;

export const getFilterValuesForClear = (meta, filterElements) =>
  removeBlacklistedFilters(getValueFilters(meta?.filters), map(filterElements, "name"));
