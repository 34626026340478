import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { ActionIcon } from "PFComponents/action_icon";
import { Typography } from "PFComponents/typography";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useShortlistReset } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { useShortlistDelete } from "PFCore/hooks/queries/shortlists/use_shortlist_delete";
import { useTranslation } from "react-i18next";

import { useActiveTab } from "../../../activities/show/hooks/use_active_tab";
import { useRoleProfileActionsPermissions } from "../../../hooks/use_role_profile_actions_permissions";
import { useRoleProfileActionButtonsContext } from "../context/role_profile_action_buttons_context";
import css from "./shortlist_buttons.module.scss";

type ShortlistStateLabelProps = {
  isUndoDisabled?: boolean;
  onUndoClick?: () => void;
};

export const ShortlistStateLabel = ({ isUndoDisabled, onUndoClick }: ShortlistStateLabelProps) => {
  const { t } = useTranslation("activities");
  const { removeProfileFromComparison } = useActivityPageContext();
  const { role, shortlist, profile, areButtonsDisabled } = useRoleProfileActionButtonsContext();

  const { checkCanUndo } = useRoleProfileActionsPermissions();
  const { isOnMatchesTab } = useActiveTab();

  const isPending = shortlist?.state === "pending";
  const isUndoAllowedOnCurrentPage = isPending || !isOnMatchesTab;
  const displayUndoButton = checkCanUndo(role, profile, shortlist) && isUndoAllowedOnCurrentPage;

  const { invalidateVacancies } = useActivityInvalidate();

  const { mutateAsync: deleteShortlist, isPending: isDeleting } = useShortlistDelete({
    activityId: role.id,
    options: {
      onSuccess: () => {
        onUndoClick?.();
      }
    }
  });
  const { mutate: resetShortlist, isPending: isResetting } = useShortlistReset({
    onSuccess: () => {
      if (["filled", "rejected", "booked", "confirmed"].includes(shortlist!.state || "")) {
        invalidateVacancies(role.id);
      }
      onUndoClick?.();
    }
  });

  const handleUndoClick = () => {
    if (shortlist!.state === "pending") {
      deleteShortlist(shortlist!.id).then(() => {
        removeProfileFromComparison(profile.id);
      });
    } else {
      resetShortlist(shortlist!.id);
    }
  };

  const labelText = t(`show.shortlist.state.${shortlist!.state}`);

  const isDisabled = isUndoDisabled || areButtonsDisabled || isDeleting || isResetting;

  return (
    <div className={css.labelWrapper}>
      <Typography variant="labelRegular">{labelText}</Typography>
      {displayUndoButton && (
        <ActionIcon
          name="history"
          size="sm"
          disabled={isDisabled}
          onClick={(event) => {
            event.stopPropagation();
            handleUndoClick();
          }}
        />
      )}
    </div>
  );
};
