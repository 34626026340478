import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { ProfileSearchHighlightAttribute, SearchHighlightAttribute } from "PFTypes";
import { useTranslation } from "react-i18next";

import { getCustomTypeName } from "./helpers";

export const useAttributeName = (attribute: SearchHighlightAttribute) => {
  const { t } = useTranslation();
  const { customTypes } = useCustomTypes();

  if (
    [
      ProfileSearchHighlightAttribute.MatchInfo1,
      ProfileSearchHighlightAttribute.MatchInfo2,
      ProfileSearchHighlightAttribute.MatchInfo3
    ].includes(camelCase(attribute) as ProfileSearchHighlightAttribute)
  ) {
    return getCustomTypeName(
      customTypes.find(({ sections }) => (sections || []).find(({ key }) => key === snakeCase(attribute)))
    );
  }

  return t(`translation:searchHighlights.${camelCase(attribute)}` as unknown as TemplateStringsArray);
};
