import { Ref } from "react";

import { BookingNote } from "./booking_note";
import css from "./booking_notes_list.module.scss";
import type { BookingNote as BookingNoteType, BookingNoteDeleteModalState } from "./types";

type BookingNotesListProps = {
  readonly: boolean;
  bookingId: number;
  notes: BookingNoteType[];
  openDeleteModal: (state: Omit<BookingNoteDeleteModalState, "show">) => void;
  lastItemRef: Ref<HTMLDivElement>;
};

export const BookingNotesList = ({
  readonly,
  bookingId,
  notes,
  openDeleteModal,
  lastItemRef
}: BookingNotesListProps) => (
  <div className={css.notesContainer}>
    {notes.map((note, i) => (
      <BookingNote
        key={note.id}
        ref={i === notes.length - 1 ? lastItemRef : null}
        readonly={readonly}
        bookingId={bookingId}
        note={note}
        openDeleteModal={openDeleteModal}
      />
    ))}
  </div>
);
