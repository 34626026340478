import { ReactElement } from "react";

import { Stack } from "../containers";
import { Typography } from "../typography";

type ReadOnlyFieldProps = {
  label: string;
  value: string | number | ReactElement;
  className?: string;
};

export const ReadOnlyField = ({ label, value, className }: ReadOnlyFieldProps) => (
  <Stack as="span" className={className}>
    <Typography variant="labelRegular" tag="span">
      {label}
    </Typography>
    <Typography variant="bodyBold" tag="span">
      {value}
    </Typography>
  </Stack>
);
