import { TemplateKey } from "PFApp/constants/templates";
import { useTemplateFind } from "PFApp/hooks";
import { Activity } from "PFTypes";

export const useShowAsInternalExperience = (activity: Activity) => {
  const findTemplate = useTemplateFind();

  const { shortlist_assignment } = activity || {};

  const workTemplate = findTemplate({ key: TemplateKey.Work, create_permitted: true });
  const roleTemplate = findTemplate({ key: TemplateKey.Role });
  const isRole = Boolean(roleTemplate) && activity?.template_id === roleTemplate?.id;
  const hasAssignment = !!shortlist_assignment;

  return isRole && workTemplate && hasAssignment;
};
