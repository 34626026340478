import { fetchActivityFeedbackParticipants } from "PFCore/services/activities";
import { Collection } from "PFTypes";
import { ActivityParticipant } from "PFTypes/activity_participant";

import { QueryOptionsWithCallbacks, useQueryWithCallbacks } from "../use_query_with_callbacks";
import activityKeys from "./query_keys";

export type FeedbackParticipantsResponseType = Collection<ActivityParticipant[]>;

export const useActivityFeedbackParticipants = (
  activityId?: number,
  options?: QueryOptionsWithCallbacks<FeedbackParticipantsResponseType>
) =>
  useQueryWithCallbacks({
    queryKey: activityKeys.feedbackParticipants(activityId),
    queryFn: () => fetchActivityFeedbackParticipants(activityId!),
    retry: false,
    staleTime: 0,
    enabled: !!activityId,
    ...options
  });
