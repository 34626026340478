import isArray from "lodash/isArray";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CustomValuesEditFieldProps } from "./custom_values_edit_field";
import { getCanEditCustomFieldData } from "./custom_values_edit_field.utils";

type UseCommonProps = Pick<
  CustomValuesEditFieldProps,
  | "adminPage"
  | "customType"
  | "profile"
  | "position"
  | "isActivity"
  | "forceUnlocked"
  | "label"
  | "errors"
  | "placeholder"
  | "tip"
  | "lockedTipPosition"
  | "filterOptions"
  | "required"
  | "kind"
>;

export const useCommonProps = ({
  adminPage,
  customType,
  profile,
  position,
  isActivity,
  forceUnlocked,
  label,
  errors,
  placeholder,
  tip,
  lockedTipPosition,
  required,
  kind,
  filterOptions
}: UseCommonProps) => {
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation();

  const { locked, lockedTip } = useMemo(
    () =>
      getCanEditCustomFieldData({
        customType,
        adminPage,
        forceUnlocked,
        isActivity,
        position,
        profile,
        currentProfile,
        t
      }),
    [customType, adminPage, forceUnlocked, isActivity, position, profile, currentProfile, t]
  );

  return useMemo(() => {
    const baseLabel = label ?? customType?.display_as;
    const decoratedLabel = required ? `${baseLabel} *` : baseLabel;

    return {
      kind,
      error: isArray(errors) ? errors[0] : errors,
      label: decoratedLabel,
      placeholder,
      tip: tip ?? customType?.description,
      locked,
      lockedTip,
      lockedTipPosition,
      filterOptions
    };
  }, [
    customType?.description,
    customType?.display_as,
    errors,
    filterOptions,
    kind,
    label,
    locked,
    lockedTip,
    lockedTipPosition,
    placeholder,
    required,
    tip
  ]);
};
