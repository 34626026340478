import { decamelizeKeys } from "humps";
import some from "lodash/some";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import { useActivityVacancies } from "PFCore/hooks/queries/activity";
import { useShortlistFill } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import { Activity, ShortlistMinimized } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useSyncRows } from "../../../use_sync_rows";
import css from "../shortlist_actions.modules.scss";

type FillButtonProps = {
  shortlistId: number;
  activity: Activity;
};

export const FillButton = ({ shortlistId, activity }: FillButtonProps): JSX.Element => {
  const { t } = useTranslation(["translation", "activities"]);

  const { task } = useActivityPageContext();
  const { data: vacancies } = useActivityVacancies({ activityId: task.id });

  const syncRows = useSyncRows();

  const { mutate: fillShortlist } = useShortlistFill({
    onSuccess: (...args) => {
      // TODO: [PROF-3732] Fix ShortlistMinimized type to be camelized
      const shortlist = decamelizeKeys(args[0]) as ShortlistMinimized;
      syncRows({
        activityId: shortlist.activity_id,
        profileId: shortlist.profile_id,
        parentActivityIds: activity.parent_activity_id ? [activity.parent_activity_id] : []
      });
    }
  });

  const growl = useGrowl();

  const handleClick = useCallback(() => {
    const hasOpenVacancy = some(vacancies?.entries, (vacancy) => vacancy.state !== "closed");

    if (hasOpenVacancy) {
      fillShortlist({ id: shortlistId });
    } else {
      growl({
        message: t("activities:show.parts.fillRoleWarning"),
        kind: "alert"
      });
    }
  }, [vacancies?.entries, shortlistId]);
  return <Button text={t("translation:fill")} className={css.button} kind="primary" onClick={handleClick} />;
};
