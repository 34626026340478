import { keepPreviousData } from "@tanstack/react-query";
import { useQueries, UseQueryResult } from "@tanstack/react-query";
import { fetchProfileAvailabilities } from "PFApp/booking/services/api";
import { CalendarRange } from "PFTypes";
import { useMemo } from "react";

import profileKeys, { AvailabilityDaily, AvailabilityWeekly } from "./query_keys";
import { Profile } from "./use_profiles_bookings";

export type ProfileAvailabilitiesIndex<T = AvailabilityDaily | AvailabilityWeekly> = Record<number, Array<T>>;

export enum CalendarGranularity {
  Daily = "daily",
  Weekly = "weekly"
}

const groupLoadingByRowKey = (
  queries: UseQueryResult<any>[],
  profiles: Omit<Profile, "activityId">[]
): Record<string, boolean> =>
  queries.reduce((result, { isLoading, isFetching }, index) => {
    const { rowKey } = profiles[index];
    return {
      ...result,
      [rowKey]: isLoading || isFetching
    };
  }, {});

interface UseProfilesAvailabilities {
  profiles: Omit<Profile, "activityId">[];
  dateRange: CalendarRange;
  calendarGranularity: CalendarGranularity;
  enabled?: boolean;
}

interface UseProfilesAvailabilitiesReturn<T = AvailabilityDaily | AvailabilityWeekly> {
  availabilitiesByProfile: ProfileAvailabilitiesIndex<T>;
  availabilitiesLoadingByRowKey: Record<string, boolean>;
}

export const useProfilesAvailabilities = <T = AvailabilityDaily | AvailabilityWeekly>({
  profiles,
  dateRange,
  calendarGranularity,
  enabled = true
}: UseProfilesAvailabilities): UseProfilesAvailabilitiesReturn<T> => {
  const stringifiedTriggers = JSON.stringify({ profiles, dateRange, calendarGranularity });
  const queriesConfig = useMemo(
    () =>
      profiles.map((profile) => ({
        queryKey: profileKeys.availabilities(profile.id, dateRange, calendarGranularity),
        queryFn: async (): Promise<ProfileAvailabilitiesIndex<T>> => ({
          [profile.id]: (
            await fetchProfileAvailabilities({
              profileId: profile.id,
              dateRange,
              calendarGranularity
            })
          ).entries
        }),
        placeholderData: keepPreviousData,
        enabled
      })),
    [stringifiedTriggers, enabled]
  );
  const queries = useQueries({
    queries: queriesConfig
  });

  const isLoading = useMemo(
    () => !!queries.find(({ isLoading, isFetching }) => isLoading || isFetching),
    [queries]
  );

  const availabilitiesLoadingByRowKey = useMemo(
    () => groupLoadingByRowKey(queries, profiles),
    [isLoading, stringifiedTriggers]
  );

  const availabilitiesByProfile = useMemo<ProfileAvailabilitiesIndex<T>>(
    () => Object.assign({}, ...queries.map(({ data }) => data)),
    [isLoading, stringifiedTriggers]
  );

  return {
    availabilitiesByProfile,
    availabilitiesLoadingByRowKey
  };
};
