import { useQuery } from "@tanstack/react-query";
import {
  fetchBookedProfiles,
  FetchBookedProfilesPayload
} from "PFCore/services/booked_profiles/fetch_booked_profiles";
import { BookedProfile, Collection } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { bookedProfilesKeys } from "./query_keys";

export const useBookedProfiles = (
  payload: FetchBookedProfilesPayload,
  options: QueryOptions<Collection<BookedProfile>> = {}
) =>
  useQuery<Collection<BookedProfile>>({
    queryKey: bookedProfilesKeys.byPage(payload),
    queryFn: () => fetchBookedProfiles(payload),
    retry: false,
    ...options
  });
