import { useSourceLabel } from "PFApp/hooks/use_source_label";
import { ReadOnlyField } from "PFComponents/read_only_field";
import { Source } from "PFTypes/source";
import { useTranslation } from "react-i18next";

import css from "../custom_value_preview.module.scss";

type SourceDataProps = {
  source: Source | null;
};

export const SourceData = ({ source }: SourceDataProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel" });
  const { getSourceLabel } = useSourceLabel();

  const translatedSource = getSourceLabel(source);

  if (!translatedSource) {
    return null;
  }

  return <ReadOnlyField className={css.halfWidth} label={t("source")} value={translatedSource} />;
};
