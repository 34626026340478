import classNames from "classnames";
import findIndex from "lodash/findIndex";
import isEmpty from "lodash/isEmpty";
import { Typography } from "PFComponents/typography";

import { GroupOption, Option, OptionOriginal, SelectV2Props } from "../../select_v2.types";
import { flattenOptions, getIds } from "../../select_v2.utils";
import { HighlightedIndex } from "../use_highlighted_index";
import css from "./list.module.scss";
import { ListItem } from "./list_item";

type ListProps<T> = {
  id: string;
  optionsGroups: GroupOption<T>[];
  onChange: (option: Option<T>, selected: boolean) => void;
  highlightedIndex: HighlightedIndex;
  highlightOption: (option: Option<T>, options: Option<T>[]) => void;
} & Pick<SelectV2Props<T>, "value" | "multiple">;

export const ListGrouped = <T extends OptionOriginal = OptionOriginal>({
  id,
  value,
  optionsGroups,
  multiple,
  onChange,
  highlightedIndex,
  highlightOption
}: ListProps<T>) => {
  const selectedIds = getIds(value);
  const flatOptions = flattenOptions(optionsGroups);

  if (isEmpty(optionsGroups)) {
    return null;
  }

  return (
    <ul id={id} role="listbox" className={css.list} aria-multiselectable={multiple} tabIndex={-1}>
      {(optionsGroups ?? []).map(({ id: groupId, label, options, disabled }) => (
        <div key={groupId} aria-label="group" className={classNames(css.group, { [css.disabled]: disabled })}>
          {!!label && options.length > 0 && (
            <Typography variant="labelRegular" tag="span" className={css.groupLabel}>
              {label}
            </Typography>
          )}
          {options.map((option) => {
            const selected = selectedIds.includes(option.id);
            const flattenOptionsIndex = findIndex(flatOptions, ({ id }) => option.id === id);
            return (
              <ListItem
                id={`${id}-${option.id}`}
                key={`${id}-${groupId}-${option.id}`}
                option={option}
                selected={selected}
                highlighted={highlightedIndex === flattenOptionsIndex}
                onClick={() => {
                  onChange(option, !selected);
                  highlightOption(option, flatOptions);
                }}
                multiple={multiple}
              />
            );
          })}
        </div>
      ))}
    </ul>
  );
};
