import compact from "lodash/compact";
import { useIsUnbiasedEnabled } from "PFApp/activities/show/hooks/use_is_unbiased_enabled";
import { Modal } from "PFComponents/modal";
import { Tabs } from "PFComponents/tabs";
import { Activity } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAssignee } from "../hooks/use_assignee";
import { useOwners } from "../hooks/use_owners";
import { useReviewers } from "../hooks/use_reviewers";
import css from "./add_activity_profiles_modal.module.scss";
import { useAddAssigneeModal } from "./use_add_assignee_modal";
import { useAddOwnersModal } from "./use_add_owners_modal";
import { useAddReviewersModal } from "./use_add_reviewers_modal";

type AddActivityProfilesModalProps = {
  onClose: VoidFunction;
  activity: Activity;
  tabId?: AddProfilesTabs;
};

export enum AddProfilesTabs {
  Owners = "owners",
  Assignee = "assignee",
  Reviewers = "reviewers"
}

export const AddActivityProfilesModal = ({ onClose, activity, tabId }: AddActivityProfilesModalProps) => {
  const { t } = useTranslation("activities");

  const { canWrite: canWriteOwners } = useOwners(activity);
  const { canWrite: canWriteAssignee } = useAssignee(activity);
  const { canWrite: canWriteReviewers } = useReviewers(activity);

  const isUnbiasedEnabled = useIsUnbiasedEnabled(activity);

  const ownersModalData = useAddOwnersModal({ activity, handleClose: onClose });
  const reviewersModalData = useAddReviewersModal({ activity, isUnbiasedEnabled });
  const assigneeModalData = useAddAssigneeModal({ activity });

  const tabs = compact([
    canWriteOwners && {
      id: AddProfilesTabs.Owners,
      title: t("show.parts.owner_other")
    },
    canWriteAssignee && {
      id: AddProfilesTabs.Assignee,
      title: t("show.parts.assignee_one")
    },
    canWriteReviewers && {
      id: AddProfilesTabs.Reviewers,
      title: t("show.parts.reviewer_other")
    }
  ]);

  const [tab, setTab] = useState<AddProfilesTabs>(tabId || tabs[0].id);
  const showTabs = tabs.length > 1;

  const { getModalContent, handleSubmit, enableSubmit, handleReset } = {
    [AddProfilesTabs.Owners]: ownersModalData,
    [AddProfilesTabs.Reviewers]: reviewersModalData,
    [AddProfilesTabs.Assignee]: assigneeModalData
  }[tab];

  const handleTabChange = (newTab: AddProfilesTabs) => {
    if (newTab === tab) {
      return;
    }

    handleReset();
    setTab(newTab);
  };

  const title =
    tabs.length === 1
      ? t("show.addProfiles.title_one", { participantType: tabs[0].title.toLowerCase() })
      : t("show.addProfiles.title_other");

  return (
    <Modal
      title={title}
      onClose={onClose}
      onOK={handleSubmit}
      disableOKButton={!enableSubmit}
      labelOK={t("show.addProfiles.apply")}
    >
      {showTabs && (
        <Tabs<AddProfilesTabs> tabs={tabs} onChange={handleTabChange} activeTab={tab} className={css.tabs} />
      )}
      {getModalContent()}
    </Modal>
  );
};
