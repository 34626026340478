import cloneDeep from "lodash/cloneDeep";
import slice from "lodash/slice";
import { fetchCustomValuesOptions } from "PFCore/services/custom_values";
import { Activity, CheckboxesFieldValue, CustomField, Metadata, Subtemplate } from "PFTypes";
import { Dispatch, SetStateAction, useEffect } from "react";

import { FormFieldData } from "../../activity_edit_form.types";
import { useFieldsAutoPopulation } from "./use_fields_auto_population";
import { useVisiblePermittedProperties } from "./use_visible_permitted_properties";

type UseDynamicSubtemplateFields = {
  subtemplate: Subtemplate | null;
  enabled: boolean;
  selectedParentActivity?: Activity;
  cachedFrameworksValues: FormFieldData[];
  cachedSubtemplateValues: FormFieldData[];
  saveSubtemplateValuesInCache: (values: FormFieldData[]) => void;
  setCustomFields: Dispatch<SetStateAction<CustomField[]>>;
  setMetadata: Dispatch<SetStateAction<Metadata>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

export const useDynamicSubtemplateFields = ({
  subtemplate,
  enabled,
  selectedParentActivity,
  cachedFrameworksValues,
  cachedSubtemplateValues,
  saveSubtemplateValuesInCache,
  setCustomFields,
  setMetadata,
  setIsLoading
}: UseDynamicSubtemplateFields) => {
  const { populateFromAllSources } = useFieldsAutoPopulation({
    setCustomFields,
    setMetadata,
    subtemplate,
    selectedParentActivity,
    cachedFrameworksValues,
    cachedSubtemplateValues
  });

  const subtemplateKey = subtemplate?.subtemplate_key;
  const { getVisiblePermittedProperties } = useVisiblePermittedProperties();

  useEffect(() => {
    if (!enabled || !subtemplate) {
      return;
    }

    const dataToPopulate: FormFieldData[] = [];

    const visibleProperties = getVisiblePermittedProperties({ subtemplate });
    const visiblePropertiesWithValues = visibleProperties.filter((property) => !!property.values?.length);

    const metadataTextFields = visiblePropertiesWithValues.filter(
      (property) => property.type !== "custom_field"
    );

    metadataTextFields.forEach((property) => {
      const defaultValue =
        property.type === "checkboxes"
          ? (cloneDeep(property.values || []) as CheckboxesFieldValue)
          : (property.values![0] as string);

      dataToPopulate.push({
        values: defaultValue,
        fieldName: property.name
      });
    });

    const customValuesToFetch = visiblePropertiesWithValues
      .filter((property) => property.type === "custom_field")
      .map((property) => {
        const isSingle = property.kind === "single";

        return {
          type: property.name,
          values: isSingle ? slice(property.values as string[], 0, 1) : property.values
        };
      }) as { type: string; values: string[] }[];

    const queries = customValuesToFetch.map(({ type, values }) =>
      fetchCustomValuesOptions({ type, value: values })
    );

    Promise.all(queries)
      .then((responses) => {
        responses.forEach((response) => {
          const customValues = response.entries;
          const type = customValues[0]?.type;
          const property = type
            ? visiblePropertiesWithValues.find((property) => property.name === type)
            : null;

          if (!!property && !!customValues?.length) {
            dataToPopulate.push({ values: customValues, fieldName: property.name });
          }
        });

        populateFromAllSources({ sourceValues: dataToPopulate, source: "subtemplate" });
        saveSubtemplateValuesInCache(dataToPopulate);
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [subtemplateKey, enabled]);
};
