import { useQueryClient } from "@tanstack/react-query";

import { workforceMetricsKeys } from "./query_keys";

export const useWorkforceMetricsInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: () => {
      queryClient.invalidateQueries({
        queryKey: workforceMetricsKeys.all()
      });
    }
  };
};
