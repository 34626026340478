import noop from "lodash/noop";
import useWindowSize from "PFCore/helpers/use_window_size";
import MenuIcon from "PFIcons/menu.svg";
import { useEffect } from "react";

import css from "./menu_trigger.module.scss";

type MenuTriggerProps = {
  disabled: boolean;
  onClick: VoidFunction;
};

export const MenuTrigger = ({ disabled, onClick }: MenuTriggerProps) => {
  const showMenu = disabled ? noop : onClick;

  const transform = disabled ? "translate(-50px, 0)" : "translate(0, 0)";

  const { windowWidth } = useWindowSize();

  useEffect(() => {
    let startEvent: {
      changedTouches: { screenX: number; screenY: number }[];
      touches: unknown[];
      timeStamp: number;
    } | null = null;

    if (windowWidth <= 500) {
      const onTouchStart = (ev) => (startEvent = ev);
      const onTouchEnd = (endEvent) => {
        const docWidth = window.innerWidth;
        const startTouch = startEvent?.changedTouches[0];
        const endTouch = endEvent.changedTouches[0];
        const startX = startTouch?.screenX || 0;
        const endX = endTouch.screenX;

        if (startEvent?.touches?.length !== 1) {
          return; // user clearly is trying to do something else
        }

        const dx = Math.abs(endX - startX);
        const dy = Math.abs(endTouch.screenY - (startTouch?.screenY || 0));
        if (dy * 2 > dx) {
          return; // moving finger too much vertically - 22.5deg (probably scrolling)
        }

        if (endEvent.timeStamp - startEvent.timeStamp > 2000) {
          return; // probably scrolling
        }

        if (endX - startX > docWidth * 0.05) {
          showMenu();
        }
      };

      window.addEventListener("touchstart", onTouchStart);
      window.addEventListener("touchend", onTouchEnd);

      return () => {
        window.removeEventListener("touchstart", onTouchStart);
        window.removeEventListener("touchend", onTouchEnd);
      };
    }
  }, [showMenu, windowWidth]);

  return (
    <div className={css.root}>
      <button className={css.menu} onClick={showMenu} style={{ transform }}>
        <MenuIcon width={32} height={32} />
        <span>Menu</span>
      </button>
    </div>
  );
};
