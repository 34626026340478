import get from "lodash/get";
import { ActionIcon } from "PFComponents/action_icon";
import { Select } from "PFComponents/select/select";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useProfileInformationEditField } from "../../../../../common/use_contact_information_edit_field";
import { MyInformationFormData } from "../../my_information_edit_modal.types";
import css from "./contact_section.module.scss";

type ContactRowProps = {
  index: number;
  locked: boolean;
  onRemove: VoidFunction;
};

export const ContactRow = ({ index, locked, onRemove }: ContactRowProps) => {
  const { t: tTranslation } = useTranslation();
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.myInformationModal" });
  const {
    control,
    formState: { errors },
    watch
  } = useFormContext<MyInformationFormData>();

  const values = watch();
  const selectedType = values.contactInformation[index].type;

  const { typeOptions, getValueLabel } = useProfileInformationEditField();

  const lockedTip = tTranslation("customValues.tips.noPermissionsTip");
  const allowLabel = ["phone", "website"].includes(selectedType);
  const id = index + 1;

  return (
    <fieldset className={css.row} role="group" aria-label={t("contactTitle", { id })}>
      <Controller
        name={`contactInformation.${index}.type`}
        control={control}
        render={({ field: { name, onChange, value, ref } }) => {
          const selectedTypeOption = typeOptions.find(({ item }) => item === value);

          return (
            <Select
              ref={ref}
              name={name}
              label={t("type")}
              readOnly
              locked={locked}
              lockedTip={lockedTip}
              // ariaLabel in value to make screen reader read the value
              value={selectedTypeOption?.ariaLabel}
              displayValues={selectedTypeOption?.displayElement}
              controlledValue
              options={typeOptions}
              onChange={onChange}
            />
          );
        }}
      />
      <Controller
        name={`contactInformation.${index}.value`}
        control={control}
        render={({ field: { name, onChange, value } }) => (
          <InputFieldSet
            label={getValueLabel(selectedType)}
            value={value}
            error={get(errors, name)?.message}
            onChange={onChange}
            locked={locked}
            lockedTip={lockedTip}
            className={css.input}
          />
        )}
      />
      {allowLabel && (
        <Controller
          name={`contactInformation.${index}.label`}
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <InputFieldSet
              label={t("label")}
              value={value}
              error={get(errors, name)?.message}
              onChange={onChange}
              locked={locked}
              lockedTip={lockedTip}
              className={css.input}
            />
          )}
        />
      )}
      <ActionIcon
        name="remove"
        size="sm"
        classes={{ root: css.removeIcon }}
        onClick={onRemove}
        title={t("removeContact", { id })}
      />
    </fieldset>
  );
};
