import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import { getPostUntilChangeReasonData } from "PFApp/components/expiry_date_modal";
import { Metadata } from "PFTypes";

import { useTemplateConfiguration } from "../../../hooks";
import { useActivityEditFormContext } from "../activity_edit_form_context";
import { useActivityEditPageContext, useActivityEditPageFlags } from "../activity_edit_page_context";
import { getPayloadCustomFields } from "../utils/activity_edit_form.utils";

export const getMetadataToSave = (metadata: Metadata) => {
  const availability = {
    ...metadata.availability,
    ranges: (metadata.availability?.ranges || []).filter(({ start, end }) => start || end)
  };

  return {
    ...omit(metadata, ["interest_questions", "interest_statement_enabled"]),
    availability: isEmpty(availability?.ranges) ? {} : availability
  };
};

export const useGenerateActivityEditFormData = () => {
  const { template } = useActivityEditPageContext();

  const { isNew } = useActivityEditPageFlags();

  const templateConfig = useTemplateConfiguration(template);

  const {
    isDevelopmental,
    name,
    description,
    postUntil,
    postUntilChangeReason,
    isPrivate,
    bookingCategory,
    communicationType,
    selectedParentActivity,
    metadata,
    customFields,
    attachments,
    minRequiredCount,
    audiences,
    subtemplateKey,
    questionsToggleValue,
    questions,
    selectedSkillsFrameworks
  } = useActivityEditFormContext();

  return () => {
    const interestQuestionnaireData: {
      interest_statement_enabled?: boolean;
      interest_questions?: string[];
    } = {};
    if (isNew) {
      interestQuestionnaireData.interest_statement_enabled = !!templateConfig?.show_statement;
      if (questionsToggleValue) {
        interestQuestionnaireData.interest_questions = questions
          .map(({ value }) => value.trim())
          .filter((value) => !!value);
      }
    }

    const metadataToSave = getMetadataToSave(metadata);

    return {
      template_id: template.id,
      ...(subtemplateKey ? { subtemplate_key: subtemplateKey } : {}),
      post_until: postUntil,
      post_until_change_reason: getPostUntilChangeReasonData(postUntilChangeReason),
      custom_fields: getPayloadCustomFields(customFields),
      skills_framework_ids: selectedSkillsFrameworks.map(({ id }) => id),
      booking_category_id: bookingCategory?.id ?? null,
      attachment_ids: attachments.map((attachment) => attachment.id),
      communication_type: communicationType,
      private: isPrivate,
      metadata: metadataToSave,
      name,
      description,
      parent_activity_id: selectedParentActivity?.id ?? null,
      min_required_count: minRequiredCount,
      audiences: audiences.map((audience) => omit(audience, "unconfirmed")),
      developmental: isDevelopmental,
      ...interestQuestionnaireData
    };
  };
};
