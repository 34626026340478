import { useQueryClient } from "@tanstack/react-query";

import { bookingTemplateKeys } from "../query_keys";

export const useBookingTemplateInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: (id?: number) => {
      if (id === undefined) {
        return queryClient.invalidateQueries({ queryKey: bookingTemplateKeys.all() });
      }

      return queryClient.invalidateQueries({ queryKey: bookingTemplateKeys.single(id) });
    },
    invalidateBookingsTemplatesForBookingGroup: (bookingGroupId: number | string) => {
      const keys = queryClient
        .getQueryCache()
        .findAll({ queryKey: bookingTemplateKeys.all() })
        // @ts-ignore
        .filter(({ state }) => state.data?.booking_group_id === bookingGroupId)
        .map(({ queryKey }) => queryKey);

      keys.forEach((key) =>
        queryClient.invalidateQueries({
          queryKey: key
        })
      );
    }
  };
};
