import keys from "lodash/keys";

const STORAGE_KEY = "profinda_custom_configuration";

// customizeConfig let you overwrite default PF.config with localStorage.
// usage (enable):
//    PF.Initializers.customizeConfig({api_endpoint: "https://profinda-staging-pr-99999.multi.profinda-staging.com"})
// usage (disable):
//    PF.Initializers.customizeConfig()
// and then refresh
export function customizeConfig(custom_config) {
  // avoid a common issue of trailing "/" when copy-pasting api_endpoint
  if (custom_config?.api_endpoint) {
    custom_config.api_endpoint = custom_config.api_endpoint.replace(/\/$/, "");
  }

  if (keys(custom_config).length === 0) {
    return storage.removeItem(STORAGE_KEY);
  } else {
    return storage.setItem(STORAGE_KEY, custom_config);
  }
}
