import { decamelizeKeys } from "humps";
import cloneDeep from "lodash/cloneDeep";
import { useTemplateFind } from "PFApp/hooks";
import { useActivity } from "PFCore/hooks/queries/activity";
import { useAttachments } from "PFCore/hooks/queries/attachments/use_attachments";
import { useSubtemplates } from "PFCore/hooks/queries/subtemplates/use_subtemplates";
import { Activity, Attachment, CustomValueState, GIdTargetType, Id, Subtemplate, Template } from "PFTypes";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useActivityEditPageDefaultAttributes } from "./hooks/use_activity_edit_page_default_attributes";

const getActivityWithoutBlockedSkills = (activity?: Partial<Activity>) => {
  if (!activity) {
    return activity;
  }

  const activityCopy: Partial<Activity> = cloneDeep(activity);

  // eslint-disable-next-line camelcase
  activityCopy.custom_fields = activityCopy.custom_fields?.map(({ values, ...customField }) => ({
    ...customField,
    values: values.filter(({ state }) => state !== CustomValueState.Blocked)
  }));

  return activityCopy;
};

type ActivityEditPageProviderProps = {
  id?: Id;
  type?: Template["key"];
  attributes?: Partial<Activity>;
  isClone: boolean | undefined;
};

export type ActivityEditPageContextType = Omit<ActivityEditPageProviderProps, "isClone" | "attributes"> & {
  loaded: boolean;
  activity: Activity;
  parentActivity?: Activity;
  attachments: Attachment[];
  template: Template;
  subtemplates: Subtemplate[];
  errors: string[] | null;
  isClone: boolean;
};

export const ActivityEditPageContext = createContext<ActivityEditPageContextType>(
  {} as ActivityEditPageContextType
);

export const ActivityEditPageProvider = ({
  attributes: rawAttributes,
  isClone: isCloneRaw,
  children,
  ...props
}: PropsWithChildren<ActivityEditPageProviderProps>) => {
  const location = useLocation<{ attributes: Partial<Activity> }>();

  const attributes: Partial<Activity> = location.state?.attributes ?? rawAttributes;

  const isClone = !!isCloneRaw;

  const findTemplate = useTemplateFind();
  const { t } = useTranslation();

  const { id, type } = props;

  const isNew = !id && !!type;

  const {
    data: activity,
    isError: isActivityError,
    isFetched: isActivityFetched
  } = useActivity(id!, { enabled: !!id });

  const { data: attachments } = useAttachments(
    { targetType: GIdTargetType.Task, targetId: id! },
    { enabled: !isNew }
  );

  const parentId = activity?.parent_activity_id || attributes?.parent_activity_id;

  const { data: parentActivity, isFetched: isParentActivityFetched } = useActivity(parentId!, {
    enabled: !!parentId,
    onError: () => {
      if (attributes?.parent_activity_id) {
        attributes.parent_activity_id = null;
      }
    }
  });

  const template = findTemplate({
    read_permitted: true,
    hidden: false,
    ...(isNew ? { key: type } : { id: activity?.template_id })
  });

  const { data: subtemplates, isFetched: isSubtemplatesFetched } = useSubtemplates(
    { templateKey: template?.key as string },
    { enabled: !!template?.key }
  );

  const defaultAttributes = useActivityEditPageDefaultAttributes({
    isClone,
    isNew,
    template,
    parentActivity,
    subtemplates,
    attributes
  });

  const activityToEdit = useMemo(
    () => (isNew ? getActivityWithoutBlockedSkills(defaultAttributes) : activity),
    [activity, defaultAttributes, isNew]
  );

  const context = useMemo(
    () => ({
      ...props,
      isClone,
      template: template!, // If template is null controller will take care of it
      activity: activityToEdit as Activity,
      parentActivity,
      attachments: attachments?.entries || [],
      subtemplates: (subtemplates ? decamelizeKeys(subtemplates) : []) as Subtemplate[],
      errors: isActivityError ? [t("thereWasAnError")] : null,
      loaded:
        (isNew || isActivityFetched) &&
        (parentId ? isParentActivityFetched : true) &&
        ((isNew && !template) || isSubtemplatesFetched)
    }),
    [
      activityToEdit,
      isClone,
      props,
      attachments,
      parentActivity,
      subtemplates,
      template,
      parentId,
      isSubtemplatesFetched,
      isParentActivityFetched,
      isActivityError,
      isActivityFetched,
      isNew,
      t
    ]
  );

  return <ActivityEditPageContext.Provider value={context}>{children}</ActivityEditPageContext.Provider>;
};

export const useActivityEditPageContext = () => useContext(ActivityEditPageContext);
