import differenceWith from "lodash/differenceWith";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { Filters, FilterWithDefaults, Value } from "PFTypes";

import { getAPIFilters } from "./filters_api";

export const letFilterRestore = (filter: FilterWithDefaults) =>
  !isEmpty(filter.defaultValue) && !areFilterValuesEqual(filter.value, filter.defaultValue);

export const areFilterValuesEqual = (filterValue1: Value, filterValue2: Value) => {
  if (isArray(filterValue1) && isArray(filterValue2)) {
    // has same values regardless of order
    return (
      isEmpty(differenceWith(filterValue1, filterValue2, isEqual)) &&
      isEmpty(differenceWith(filterValue2, filterValue1, isEqual))
    );
  }

  return isEqual(filterValue1, filterValue2);
};

/**
 * checks if all source filters have the same values as target filters
 */
const checkFiltersEquality = (
  sourceFilters: Filters<Value> | undefined,
  targetFilters: Filters<Value> | undefined
) =>
  Object.entries(sourceFilters || {}).every(([filterFamily, filtersSet]) =>
    Object.entries(filtersSet || {}).every(([key, filterValue]) => {
      const defaultFilterValueForKey = targetFilters?.[filterFamily]?.[key];

      if (!defaultFilterValueForKey) {
        return false;
      }

      return areFilterValuesEqual(filterValue, defaultFilterValueForKey);
    })
  );

export const checkFiltersAreDefault = (valueFilters: Filters<Value>, defaultFilters: Filters<Value>) => {
  const { children: childrenValues, ...values } = valueFilters;
  const { children: defaultChildrenValue, ...defaultValues } = defaultFilters;

  const filtersAPI = getAPIFilters(values);
  const defaultFiltersAPI = getAPIFilters(defaultValues);
  const childrenFiltersAPI = getAPIFilters(childrenValues);
  const defaultChildrenFiltersAPI = getAPIFilters(defaultChildrenValue);

  return (
    checkFiltersEquality(filtersAPI, defaultFiltersAPI) &&
    checkFiltersEquality(defaultFiltersAPI, filtersAPI) &&
    checkFiltersEquality(childrenFiltersAPI, defaultChildrenFiltersAPI) &&
    checkFiltersEquality(defaultChildrenFiltersAPI, childrenFiltersAPI)
  );
};
