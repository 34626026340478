import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import { ActionIcon } from "PFComponents/action_icon";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography";
import { uploadAttachment } from "PFCore/services/attachments/upload_attachment";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./attachments_section.module.scss";

const AttachmentItem = ({ attachment, onRemove }) => (
  <li>
    <div className={css.activitiesAttachmentsItemName}>
      <Typography variant="bodyRegular" tag="span">
        {attachment.file_name}
      </Typography>
      <span className={css.iconSection}>
        {attachment.pending ? (
          <LoadingDots />
        ) : (
          <ActionIcon
            classes={{
              root: css.activitiesAttachmentsItemRemove
            }}
            onClick={() => onRemove(attachment)}
            size="sm"
            name="remove"
          />
        )}
      </span>
    </div>
  </li>
);

const AttachmentsSection = ({
  attachments,
  handleUploadAdded,
  handleUploadSuccessful,
  handleUploadFailed,
  handleUploadRemove,
  qaIdPrefix
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("activities");

  const handleChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    const pendingAttachment = {
      id: uniqueId("fake"),
      pending: true,
      file_name: file.name
    };

    handleUploadAdded(pendingAttachment);
    setLoading(true);

    uploadAttachment(file)
      .always(() => setLoading(false))
      .then(handleUploadSuccessful, () => {
        handleUploadFailed();
        handleUploadRemove(pendingAttachment);
      });
  };

  const handleClearInputValue = (event) => {
    event.target.value = null;
  };

  return (
    <div className={css.wrapper} data-qa-id={`${qaIdPrefix}-attachments`}>
      <label
        className={classNames(css.activitiesAttachmentsLabel, {
          [css.activitiesAttachmentsLabelPending]: loading
        })}
      >
        <Button tag="a" icon="add" text={t("edit.sections.add")} disabled={loading} kind="secondary" />
        <span>{t("edit.sections.activityAttachmentLabel")}</span>
        <input
          disabled={loading}
          type="file"
          name="attachment"
          onChange={handleChange}
          onClick={handleClearInputValue}
        />
      </label>
      <ul className={css.activitiesAttachments}>
        {attachments.map((attachment) => (
          <AttachmentItem key={attachment.id} attachment={attachment} onRemove={handleUploadRemove} />
        ))}
      </ul>
    </div>
  );
};

export default AttachmentsSection;

AttachmentItem.propTypes = {
  attachment: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired
};

AttachmentsSection.propTypes = {
  attachments: PropTypes.array.isRequired,
  handleUploadAdded: PropTypes.func.isRequired,
  handleUploadFailed: PropTypes.func.isRequired,
  handleUploadSuccessful: PropTypes.func.isRequired,
  handleUploadRemove: PropTypes.func.isRequired,
  qaIdPrefix: PropTypes.string,
  className: PropTypes.string
};
