import { useMutation } from "@tanstack/react-query";
import { reopenVacancy, ReopenVacancyParams } from "PFCore/services/vacancies";
import { MutationOptions } from "PFTypes/request_options";

import { useShortlistsInvalidate } from "../../shortlists/use_shortlists_invalidate";
import { useActivityInvalidate } from "../use_activity_invalidate";

export const useReopenVacancy = (options?: MutationOptions<ReopenVacancyParams>) => {
  const { invalidateShortlists, invalidateRankedShortlists } = useShortlistsInvalidate();
  const { invalidate: invalidateActivities, invalidateVacancies } = useActivityInvalidate();

  const { onSuccess, ...rest } = options || {};

  return useMutation({
    mutationFn: (payload: ReopenVacancyParams) => reopenVacancy(payload),
    onSuccess: (...args) => {
      const { activityId, vacancyId } = args[1];

      invalidateShortlists(activityId);
      invalidateRankedShortlists(activityId);
      invalidateVacancies(vacancyId);
      invalidateActivities([activityId]);

      onSuccess?.(...args);
    },
    ...rest
  });
};
