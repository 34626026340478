import orderBy from "lodash/orderBy";
import { ECOption } from "PFCore/helpers/echarts_wrapper";
import { EconomicsScenarioDetails, Experience } from "PFTypes";
import { useTranslation } from "react-i18next";

const getExperienceChartValue = (experience: Experience | null) => {
  if (!experience) {
    return 0;
  }

  return {
    [Experience.Basic]: 1,
    [Experience.Intermediate]: 2,
    [Experience.Advanced]: 3
  }[experience];
};

export const useSkillsChartModal = (skillsRadarData: EconomicsScenarioDetails["skillsRadarData"]) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.charts.skills" });
  const { t: tCore } = useTranslation("core");

  const sortedSkills = orderBy(skillsRadarData, "skill.value", "desc");
  const skillsNames = sortedSkills.map(({ skill }) => skill.value);
  const requiredExperienceValues = sortedSkills.map(({ requiredExperience }) => ({
    value: getExperienceChartValue(requiredExperience)
  }));
  const possessedExperienceValues = sortedSkills.map(({ possessedExperience }) => ({
    value: getExperienceChartValue(possessedExperience)
  }));

  const series = [
    { name: "required", data: requiredExperienceValues },
    { name: "filled", data: possessedExperienceValues }
  ];

  const formatExperienceLabel = (value: number) =>
    ({
      [getExperienceChartValue(null)]: t("no"),
      [getExperienceChartValue(Experience.Basic)]: tCore("skillRatings.basic"),
      [getExperienceChartValue(Experience.Intermediate)]: tCore("skillRatings.intermediate"),
      [getExperienceChartValue(Experience.Advanced)]: tCore("skillRatings.advanced")
    }[value] ?? "");

  const customOptions: ECOption = {
    xAxis: {
      type: "value",
      axisLabel: {
        formatter: formatExperienceLabel
      },
      splitLine: {
        lineStyle: {
          type: "dashed"
        }
      },
      max: 3,
      interval: 1
    }
  };

  return {
    skillsNames,
    series,
    customOptions
  };
};
