import { useMutation } from "@tanstack/react-query";
import { forgotPassword, ForgotPasswordPayload } from "PFCore/services/signup/forgot_password";

import { authKeys } from "./query_keys";

export const useForgotPassword = () => {
  const mutationKey = authKeys.forgotPassword();

  return useMutation({
    mutationFn: async (data: ForgotPasswordPayload) => forgotPassword(data),
    mutationKey
  });
};
