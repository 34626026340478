import { Flex } from "PFComponents/containers";
import { PropsWithChildren } from "react";

import { CustomValuesListCommonProps } from "../custom_values_list.consts";
import { ShowMoreButton } from "./show_more_button";

type CustomValuesWrapperProps = Pick<CustomValuesListCommonProps, "limit" | "dark" | "vertical"> & {
  displayShowMoreButton: boolean;
  showAll: boolean;
  onMoreLessButtonClick: VoidFunction;
};

export const CustomValuesWrapper = ({
  displayShowMoreButton,
  showAll,
  dark,
  vertical,
  onMoreLessButtonClick,
  children
}: PropsWithChildren<CustomValuesWrapperProps>) => (
  <Flex
    wrap="wrap"
    rowGap="spacingLg"
    columnGap="spacingMd"
    alignItems={vertical ? "start" : "center"}
    direction={vertical ? "column" : "row"}
  >
    {children}
    {displayShowMoreButton && (
      <ShowMoreButton dark={dark} showAll={showAll} onClick={onMoreLessButtonClick} />
    )}
  </Flex>
);
