import { BookingNotesResponse, fetchBookingNotes } from "PFApp/booking/services/booking_notes_api";
import { getNextPageParam } from "PFCore/helpers/get_next_page_param";
import { InfiniteQueryOptions } from "PFTypes";

import { useInfiniteQuery } from "../../use_infinite_query";
import { noteKeys } from "./query_keys";

export const useBookingNotesInfinite = (
  bookingId: number,
  perPage?: number,
  options: InfiniteQueryOptions<BookingNotesResponse> = {}
) =>
  useInfiniteQuery({
    queryKey: noteKeys.ofBooking(bookingId, perPage),
    queryFn: ({ pageParam }) => {
      const params = {
        bookingId,
        page: pageParam,
        perPage
      };
      return fetchBookingNotes(params) as unknown as Promise<BookingNotesResponse>;
    },
    initialPageParam: 1,
    getNextPageParam,
    ...options
  });
