import { QueryKey, useQueryClient } from "@tanstack/react-query";
import { FetchBookings } from "PFApp/booking/services/api";

import { profileKeys } from "../profile";
import { bookingKeys } from "./query_keys";

export const useBookingInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: (id?: number) => {
      if (id === undefined) {
        return queryClient.invalidateQueries({ queryKey: bookingKeys.all() });
      }
      return queryClient.invalidateQueries({ queryKey: bookingKeys.single(id) });
    },
    invalidateBookingsForBookingGroup: (bookingGroupId: number | string) => {
      const allStoredBookingsCache = queryClient.getQueryCache().findAll({ queryKey: bookingKeys.all() });

      const keys = allStoredBookingsCache
        .filter(
          (query) => (query.state.data as { booking_group_id?: number })?.booking_group_id === bookingGroupId
        )
        .map((query) => query.queryKey as QueryKey);

      keys.forEach((key) =>
        queryClient.invalidateQueries({
          queryKey: key
        })
      );
    },
    invalidateProfileBookingsList: (profileId: number) => {
      // TODO: [SP-1742] Improve invalidating cache
      const queryKey = profileKeys.bookings(profileId);
      return queryClient.invalidateQueries({ queryKey });
    },
    invalidateList: (params?: FetchBookings) => {
      // TODO: [SP-1742] Improve invalidating cache
      if (!params) {
        return queryClient.invalidateQueries({ queryKey: profileKeys.all() });
      }
      return queryClient.invalidateQueries({
        queryKey: profileKeys.bookings(params.profileId)
      });
    }
  };
};
