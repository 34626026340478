import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Id, MutationOptions } from "PFTypes";

import { deleteActivityEconomicsScenarioAssignment } from "../../../services/activity";
import activityKeys from "./query_keys";

export const useActivityEconomicsScenarioUnassign = (scenarioId: Id, options?: MutationOptions<Id>) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...queryOptions } = options || {};

  return useMutation<never, unknown, Id>({
    mutationFn: async (id) => await deleteActivityEconomicsScenarioAssignment(id),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.economicsScenario(scenarioId) });
      onSuccess?.(...args);
    },
    ...queryOptions
  });
};
