import { useToggle } from "react-use";

type UseCustomValuesVisibility = {
  availableCustomValuesCount: number;
  missingCustomValuesCount: number;
  limit: number;
};

type UseCustomValuesVisibilityReturn = {
  visibleAvailableCustomValuesCount: number;
  visibleMissingCustomValuesCount: number;
  showAll: boolean;
  displayShowMoreButton: boolean;
  toggleShowAll: VoidFunction;
};

export const useCustomValuesVisibility = ({
  availableCustomValuesCount,
  missingCustomValuesCount,
  limit
}: UseCustomValuesVisibility): UseCustomValuesVisibilityReturn => {
  const [showAll, toggleShowAll] = useToggle(false);

  const limitedAvailableCustomValuesCount = Math.min(availableCustomValuesCount, limit);
  const limitedMissingCustomValuesCount = Math.min(
    missingCustomValuesCount,
    Math.max(limit - availableCustomValuesCount, 0)
  );
  const displayShowMoreButton = limit < availableCustomValuesCount + missingCustomValuesCount;

  return {
    visibleAvailableCustomValuesCount: showAll
      ? availableCustomValuesCount
      : limitedAvailableCustomValuesCount,
    visibleMissingCustomValuesCount: showAll ? missingCustomValuesCount : limitedMissingCustomValuesCount,
    showAll,
    displayShowMoreButton,
    toggleShowAll
  };
};
