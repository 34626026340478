import { useCallback, useEffect, useState } from "react";

const CLOSE_DURATION_MS = 150;

type UseSidePanelClose = {
  onClose: VoidFunction | undefined;
  isSidePanelClosing?: boolean;
};

type UseSidePanelCloseReturn = {
  onSidePanelClose: VoidFunction;
  isClosing: boolean;
};

export const useSidePanelClose = ({
  isSidePanelClosing = false,
  onClose
}: UseSidePanelClose): UseSidePanelCloseReturn => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = useCallback(() => {
    setIsClosing(true);
    setTimeout(() => {
      onClose && onClose();
      setIsClosing(false);
    }, CLOSE_DURATION_MS);
  }, [onClose]);

  useEffect(() => {
    setIsClosing(isSidePanelClosing);
  }, [isSidePanelClosing]);

  return {
    onSidePanelClose: handleClose,
    isClosing
  };
};
