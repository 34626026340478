import classNames from "classnames";
import chunk from "lodash/chunk";
import debounce from "lodash/debounce";
import { useAppContext } from "PFApp/app_context";
import LazyLocalExportTrigger from "PFApp/components/document_buttons/lazy_local_export_trigger";
import { TemplateKey } from "PFApp/constants/templates";
import Tooltip from "PFComponents/tooltip/tooltip";
import useDebounce from "PFCore/helpers/use_debounce";
import { useDeterministicStringify } from "PFCore/helpers/use_deterministic_stringify";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { getExportProfiles } from "PFCore/services/export_profiles";
import InfoIcon from "PFIcons/icon_info.svg";
import { SEARCH_ACTIONS, SEARCH_ASYNC_ACTIONS } from "PFReducers/search_reducer/search_reducer";
import { PermissionRule } from "PFTypes";
import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { ActivityHistoryProvider } from "../activities/parts/history/activity_history_context";
import SearchFilters from "./parts/search_filters";
import SearchKeywordSliders from "./parts/search_keyword_sliders";
import ResultsActivities from "./results/results_activities";
import ResultsAll from "./results/results_all";
import ResultsMatches from "./results/results_matches";
import ResultsProfiles from "./results/results_profiles";
import ChoosenKeywords from "./search_box/choosen_keywords";
import SearchBox from "./search_box/search_box";
import css from "./search_page.less";
import SearchTabs from "./search_tabs";
import SmartSuggestions from "./smart_suggestions/smart_suggestions";
import { activitiesListTab, activitiesTab, allTab, allTabs, matchesTab, profilesTab } from "./tabs_constants";

const Title = ({ tab, count }) => {
  const { t } = useTranslation("search");
  if (count === undefined || count === null) {
    return "";
  }

  return (
    <span className={css.resultsFound}>
      <span>
        {tab === activitiesTab ? t("countActivitiesFound", { count }) : t("countProfilesFound", { count })}
      </span>
      <Tooltip content={t("resultsDependOnPerms")}>
        <span>
          <InfoIcon height={24} width={24} />
        </span>
      </Tooltip>
    </span>
  );
};

Title.propTypes = {
  tab: PropTypes.string,
  count: PropTypes.number
};

const SearchPage = () => {
  const history = useHistory();
  const location = useLocation();
  const stringify = useDeterministicStringify();
  const { data: currentProfile } = useCurrentProfile();

  const {
    dispatch,
    store: {
      search,
      search: {
        profiles,
        matches,
        triggerCounter,
        term,
        tab,
        searchTaskId,
        searchTaskFetchCounter,

        choosenKeywords,

        activitiesRequestFilters,
        profilesRequestFilters,
        matchesRequestFilters,

        activitiesRequestOrder,
        profilesRequestOrder,
        matchesRequestOrder,

        activitiesPending,
        profilesPending,
        matchesPending,
        filtersShown,

        profilesMeta,
        matchesMeta,
        availableTemplates,

        activitiesPagination,
        profilesPage,
        matchesPage,

        allActivities,
        allActivitiesMeta,

        matchTypes,
        filterTypes
      }
    }
  } = useAppContext();

  const isPermittedTo = useIsPermittedTo(currentProfile);

  const trimmedTerm = term.trim(); // avoid triggering effects on no-changes (use in the deps)

  useEffect(() => {
    if (!isPermittedTo(PermissionRule.ProfileList)) {
      history.push("/");
    }
  }, [isPermittedTo, history]);

  useEffect(() => {
    if (filtersShown) {
      dispatch({ type: SEARCH_ACTIONS.SEARCH_TOGGLE_FILTERS });
    }
  }, [tab]);

  useEffect(() => {
    const availableTemplates = currentProfile.templates.filter(
      (template) => !template.hidden && template.read_permitted
    );
    dispatch({ type: SEARCH_ACTIONS.SET_AVAILABLE_TEMPLATES, payload: { availableTemplates } });
    dispatch({ type: SEARCH_ACTIONS.SET_ACTIVITIES_PAGINATION, payload: { availableTemplates } });
    dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_TYPES, payload: { currentProfile } });

    return () => dispatch({ type: SEARCH_ACTIONS.SEARCH_RESET_STATE });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // keywords
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    useDebounce(() => {
      if (!matchTypes.length && !filterTypes.length) {
        return;
      }
      const type = SEARCH_ASYNC_ACTIONS.SEARCH_FETCH_SUGGESTED_KEYWORDS;
      dispatch({
        type,
        payload: { choosenKeywords, term, matchTypes, filterTypes }
      });
    }, 500),
    [
      trimmedTerm,
      tab,
      choosenKeywords.map(({ id, global_id, globalId }) => `${global_id || globalId},${id}`).join(":"),
      matchTypes,
      filterTypes
    ]
  );

  const debouncedGoToPage1Dispatch = useCallback(
    () =>
      debounce((payload) => {
        dispatch(SEARCH_ACTIONS.SEARCH_MERGE_STATE, payload);
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // activities
  const activitiesParamsHash = stringify([activitiesRequestFilters, activitiesRequestOrder]);

  useEffect(() => {
    debouncedGoToPage1Dispatch({ activitiesPage: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitiesParamsHash]);

  const fetchAllActivitiesDebounced = useDebounce(
    () => dispatch({ type: SEARCH_ASYNC_ACTIONS.SEARCH_FETCH_ALL_ACTIVITIES, payload: search }),
    500
  );

  useEffect(() => {
    const page = activitiesPagination && activitiesPagination[tab];
    if (page > 1) {
      dispatch({
        type: SEARCH_ACTIONS.SEARCH_MORE_ACTIVITIES,
        payload: {
          allActivities,
          tab,
          activitiesPagination,
          availableTemplates,
          term,
          activitiesRequestFilters,
          activitiesRequestOrder
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitiesPagination]);

  useEffect(() => {
    dispatch({
      type: SEARCH_ACTIONS.SEARCH_FILTER_ACTIVITIES,
      payload: {
        allActivities,
        tab,
        activitiesPagination,
        availableTemplates,
        term,
        activitiesRequestFilters,
        activitiesRequestOrder
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitiesRequestFilters?.[tab], activitiesParamsHash]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(debouncedGoToPage1Dispatch({ activitiesPage: 1 }), [activitiesParamsHash]);

  useEffect(() => {
    dispatch({ type: SEARCH_ACTIONS.SEARCH_ABORT_ACTIVITIES });
    fetchAllActivitiesDebounced();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trimmedTerm, triggerCounter]);

  // profiles
  const profilesParamsHash = stringify([profilesRequestFilters, profilesRequestOrder]);
  const fetchProfilesType = SEARCH_ASYNC_ACTIONS.SEARCH_FETCH_PROFILES;
  const fetchProfilesDebounced = useDebounce(
    () => dispatch({ type: fetchProfilesType, payload: search }),
    500
  );

  useEffect(() => {
    debouncedGoToPage1Dispatch({ profilesPage: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilesParamsHash]);

  useEffect(() => {
    fetchProfilesDebounced();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trimmedTerm, profilesPage, profilesParamsHash, triggerCounter]);

  // matches
  const matchesParamsHash = stringify([matchesRequestFilters, matchesRequestOrder]);
  const choosenKeywordsHash = choosenKeywords
    .map(({ id, importance }) => `${id}:${importance}`)
    .sort()
    .join(",");
  const taskTemplateId = currentProfile.templates?.find(({ key }) => key === TemplateKey.Task)?.id;
  const triggerFetchType = SEARCH_ASYNC_ACTIONS.SEARCH_TRIGGER_MATCHES_FETCH;
  const fetchMatchesDebounced = useDebounce(
    () => dispatch({ type: triggerFetchType, payload: { ...search, taskTemplateId } }),
    500
  );

  useEffect(() => {
    debouncedGoToPage1Dispatch({ matchesPage: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenKeywordsHash, trimmedTerm, matchesParamsHash]);
  useEffect(() => {
    dispatch({ type: SEARCH_ACTIONS.SEARCH_ABORT_MATCHES });
    fetchMatchesDebounced();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosenKeywordsHash, matchesPage, matchesParamsHash, triggerCounter]);

  // update url
  const updateUrl = useCallback(() => {
    if (searchTaskFetchCounter === 0) {
      return;
    }
    const encodedTerm = window.encodeURI(term || "").replace(/\//g, "%2f");
    const safeTab = allTabs.includes(tab) ? tab : "all";
    // If there is no choosenKeywords then the searchTask would not be reloadable.
    // You cannot save a task without custom_fields - we dont even atempt (see fetchMatches)
    // We still keep the id in memory so we can reuse the task but if user reloads the page
    // the id is lost.
    //
    //const safeId = choosenKeywords.length > 0 && searchTaskId ? searchTaskId : "new";
    const safeId = searchTaskId || "new";

    if (safeTab === matchesTab && !searchTaskId && matchesPending) {
      // when you load the task for the first time and no choosenKeywords then
      // we don't want to update the url (it'd update to "new" and then back to
      // the proper id after the fetch
      return;
    }

    if (window.encodeURI(location.pathname) !== `/search/${safeId}/${safeTab}/${encodedTerm}`) {
      history.push(`/search/${safeId}/${safeTab}/${encodedTerm}`);
    }
  }, [searchTaskFetchCounter, history, location.pathname, tab, searchTaskId, matchesPending, term]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(useDebounce(updateUrl, 10), [
    tab,
    trimmedTerm,
    searchTaskId,
    choosenKeywords,
    matchesPending,
    searchTaskFetchCounter
  ]);

  const filtersAllowed = !(tab === matchesTab && matches.length === 0);
  const canExportDocs = isPermittedTo(PermissionRule.ExportDocuments);

  const isExportable = matches && tab === matchesTab;

  return (
    <ActivityHistoryProvider>
      <div className={css.root}>
        <SmartSuggestions />

        {canExportDocs && (
          <div className={css.export}>
            {isExportable && (
              <LazyLocalExportTrigger
                quantity={matches?.length || 0}
                getProfilesCollection={(paginationPage = 1, paginationSize = 50) => {
                  const items = matches.map(({ profile, scores }) => ({
                    id: profile.id,
                    _score: scores?.normalized_score
                  }));
                  const paginatedArray = chunk(items, paginationSize);
                  return getExportProfiles({
                    profileIds: paginatedArray[paginationPage - 1].map(({ id }) => id)
                  }).then((resp) => ({
                    entries: resp.map((profile, i) => ({
                      profile: { ...profile, score: items[i]._score }
                    })),
                    meta: { total: resp.length, page: paginationPage }
                  }));
                }}
              />
            )}

            {profiles && tab === profilesTab && (
              <LazyLocalExportTrigger
                quantity={profiles?.length || 0}
                getProfilesCollection={(paginationPage = 1, paginationSize = 50) =>
                  getExportProfiles({
                    profileIds: profiles.map(({ id }) => id),
                    page: paginationPage,
                    perPage: paginationSize
                  }).then((resp) => ({
                    entries: resp.map((profile) => ({
                      profile
                    })),
                    meta: { total: resp.length, page: paginationPage }
                  }))
                }
              />
            )}
          </div>
        )}

        <div className={classNames(css.wrap, { [css.wrapWithNoTerm]: !filtersAllowed })}>
          <div className={css.content}>
            <SearchBox />
            <ChoosenKeywords />

            {tab === matchesTab && (
              <SearchFilters
                blockedList={["term", "skills"]}
                pending={matchesPending}
                filtersShown={filtersShown}
                meta={matchesMeta}
                requestFilters={matchesRequestFilters}
                toggleShown={() => dispatch({ type: SEARCH_ACTIONS.SEARCH_TOGGLE_FILTERS })}
                clearFilters={() => {
                  dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_MATCHES_REQUEST_FILTERS, payload: {} });
                }}
                applyRequestFilters={(payload) => {
                  dispatch({ type: SEARCH_ACTIONS.SEARCH_MERGE_MATCHES_REQUEST_FILTERS, payload });
                }}
                applyOrder={(payload) => {
                  dispatch({ type: SEARCH_ACTIONS.SEARCH_MERGE_MATCHES_REQUEST_ORDER, payload });
                }}
              >
                <SearchKeywordSliders />
              </SearchFilters>
            )}

            {activitiesListTab.includes(tab) && (
              <SearchFilters
                key={tab}
                blockedList={["term"]}
                pending={activitiesPending}
                filtersShown={filtersShown}
                meta={allActivitiesMeta?.[tab]}
                requestFilters={activitiesRequestFilters[tab]}
                toggleShown={() => dispatch({ type: SEARCH_ACTIONS.SEARCH_TOGGLE_FILTERS })}
                clearFilters={() => {
                  dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_ACTIVITIES_REQUEST_FILTERS, payload: {} });
                }}
                applyRequestFilters={(payload) => {
                  dispatch({
                    type: SEARCH_ACTIONS.SEARCH_MERGE_ACTIVITIES_REQUEST_FILTERS,
                    payload
                  });
                }}
                applyOrder={(payload) => {
                  dispatch({
                    type: SEARCH_ACTIONS.SEARCH_MERGE_ACTIVITIES_REQUEST_ORDER,
                    payload
                  });
                }}
              />
            )}

            {tab === profilesTab && (
              <SearchFilters
                pending={profilesPending}
                filtersShown={filtersShown}
                meta={profilesMeta}
                blockedList={["term"]}
                requestFilters={profilesRequestFilters}
                toggleShown={() => dispatch({ type: SEARCH_ACTIONS.SEARCH_TOGGLE_FILTERS })}
                clearFilters={() => {
                  dispatch({ type: SEARCH_ACTIONS.SEARCH_SET_PROFILES_REQUEST_FILTERS, payload: {} });
                }}
                applyRequestFilters={(payload) => {
                  dispatch({ type: SEARCH_ACTIONS.SEARCH_MERGE_PROFILES_REQUEST_FILTERS, payload });
                }}
                applyOrder={(payload) => {
                  dispatch({ type: SEARCH_ACTIONS.SEARCH_MERGE_PROFILES_REQUEST_ORDER, payload });
                }}
              />
            )}

            <div className={css.header}>
              <h1>
                <Title
                  tab={tab}
                  count={
                    {
                      [matchesTab]: matchesMeta?.total,
                      [profilesTab]: profilesMeta?.total
                    }[tab]
                  }
                />
              </h1>
            </div>

            <SearchTabs />
            {tab === allTab && <ResultsAll />}
            {activitiesListTab.includes(tab) && <ResultsActivities />}
            {tab === profilesTab && <ResultsProfiles />}
            {tab === matchesTab && <ResultsMatches />}
          </div>
        </div>
      </div>
    </ActivityHistoryProvider>
  );
};

SearchPage.propTypes = {};

export default SearchPage;
