import get from "lodash/get";
import { InputFieldSet } from "PFComponents/text/input_field_set";
import { AccessLevel } from "PFTypes";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { IsFieldPermittedFunction, MyInformationFormData } from "../my_information_edit_modal.types";
import { FormSection } from "./form_section";

type DetailsSectionProps = {
  isFieldPermitted: IsFieldPermittedFunction;
};

export const DetailsSection = ({ isFieldPermitted }: DetailsSectionProps) => {
  const { t: tTranslation } = useTranslation();
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.myInformationModal" });

  const lockedTip = tTranslation("customValues.tips.noPermissionsTip");

  const {
    control,
    formState: { errors }
  } = useFormContext<MyInformationFormData>();

  return (
    <FormSection title={t("details")}>
      {isFieldPermitted("first_and_last_name", AccessLevel.ReadOnly) && (
        <>
          <Controller
            name="firstName"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <InputFieldSet
                label={t("firstName")}
                value={value}
                error={get(errors, name)?.message}
                onChange={onChange}
                locked={!isFieldPermitted("first_and_last_name", AccessLevel.ReadWrite)}
                lockedTip={lockedTip}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <InputFieldSet
                label={t("lastName")}
                value={value}
                error={get(errors, name)?.message}
                onChange={onChange}
                locked={!isFieldPermitted("first_and_last_name", AccessLevel.ReadWrite)}
                lockedTip={lockedTip}
              />
            )}
          />
        </>
      )}
      {isFieldPermitted("summary", AccessLevel.ReadOnly) && (
        <Controller
          name="summary"
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <InputFieldSet
              inputType="textarea"
              label={t("bio")}
              value={value}
              error={get(errors, name)?.message}
              onChange={onChange}
              locked={!isFieldPermitted("summary", AccessLevel.ReadWrite)}
              lockedTip={lockedTip}
            />
          )}
        />
      )}
    </FormSection>
  );
};
