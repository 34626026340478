import { Inline } from "PFComponents/containers/flex/inline";
import { Stack } from "PFComponents/containers/flex/stack";
import { Divider } from "PFComponents/divider";
import { ReadOnlyField } from "PFComponents/read_only_field";
import { Typography } from "PFComponents/typography";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { PureCustomValueCamelCased } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "../custom_value_preview.module.scss";

type DetailsProps = {
  customValue: PureCustomValueCamelCased;
};

export const Details = ({ customValue }: DetailsProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel" });
  const { formatDate } = useDateFormatter();

  return (
    <>
      {customValue.description && (
        <>
          <Divider />
          <Stack gap="spacingMd">
            <Typography variant="h5" noMargin>
              {t("description")}
            </Typography>
            <Typography variant="bodyRegular" noMargin>
              {customValue.description}
            </Typography>
          </Stack>
        </>
      )}
      <Divider />
      <Stack gap="spacingMd">
        <Typography variant="h5" noMargin>
          {t("insights")}
        </Typography>
        <Inline spread>
          <ReadOnlyField
            className={css.halfWidth}
            label={t("membersWithThis")}
            value={customValue.profilesCount || 0}
          />
          <ReadOnlyField
            className={css.halfWidth}
            label={t("requiredInCurrentActivities")}
            value={customValue.activitiesCount || 0}
          />
        </Inline>
        <Inline spread>
          <ReadOnlyField
            className={css.halfWidth}
            label={t("addedToAccount")}
            value={formatDate(customValue.createdAt)}
          />
          <ReadOnlyField
            className={css.halfWidth}
            label={t("lastEditedInAccount")}
            value={formatDate(customValue.updatedAt)}
          />
        </Inline>
      </Stack>
    </>
  );
};
