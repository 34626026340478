import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EconomicsScenario, Id, MutationOptions } from "PFTypes";

import { createActivityEconomicsScenario } from "../../../services/activity";
import activityKeys from "./query_keys";

export const useActivityEconomicsScenarioCreate = (
  activityId: Id,
  options?: MutationOptions<string, EconomicsScenario>
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...queryOptions } = options || {};

  return useMutation<EconomicsScenario, unknown, string>({
    mutationFn: (name: string) => createActivityEconomicsScenario(activityId, name),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.economics(activityId) });
      onSuccess?.(...args);
    },
    ...queryOptions
  });
};
