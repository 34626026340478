import { Stack } from "PFComponents/containers";
import { Icon } from "PFComponents/icon";
import { Typography } from "PFComponents/typography";
import { useTranslation } from "react-i18next";

export const NoItemsToShow = () => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts" });

  return (
    <Stack gap="spacingSm" alignItems="center">
      <Icon color="paletteWhite0" name="missing" size="md" />
      <Typography variant="labelRegular">{t("noItemsToShow")}</Typography>
    </Stack>
  );
};
