import head from "lodash/head";
import moment from "moment";
import EditPositionForm from "PFApp/profiles/common/experience/edit_position_form";
import ProfilePositionHeadline from "PFApp/profiles/profile_position_headline";
import PROFILE_SECTIONS from "PFApp/profiles/profile_sections";
import { fromFormat } from "PFCore/helpers/date";
import { isProfileFieldPermitted } from "PFCore/helpers/profile_permissions";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useReadableDuration } from "PFCore/helpers/use_readable_duration";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { AccessLevel, PermissionsGroup, Position, Profile } from "PFTypes";
import { useMemo, useState } from "react";

import { ItemWrapper } from "./item_wrapper";

const getPrefixForPermissions = ({ main }) => (main ? "main_position" : "other_positions");

export type PositionSection = {
  position: Position;
  profile?: Profile;
  permissionsGroup?: PermissionsGroup;
  onSuccess: () => void;
  onRemove: () => void;
  isAdmin: boolean;
};

export const PositionItem = ({
  position,
  profile,
  permissionsGroup,
  onSuccess,
  onRemove,
  isAdmin
}: PositionSection) => {
  const { data: currentProfile } = useCurrentProfile();

  const [display, setDisplay] = useState(false);
  const { loadForSections } = useCustomTypes();

  const showDuration = (duration) => {
    // always show filled field on own profile
    if (onOwnProfile() && !!duration) {
      return true;
    }

    return isProfileFieldPermitted(
      permissionsGroup,
      profile?.permissions_group?.id,
      `${getPrefixForPermissions(position)}/dates`,
      AccessLevel.ReadOnly
    );
  };

  const onOwnProfile = () => currentProfile.id === profile?.id;
  const handleSuccessFunction = () => {
    setDisplay(false);
    onSuccess && onSuccess();
  };

  const handleClick = () => setDisplay((prev) => !prev);

  const readableDuration = useReadableDuration({
    // eslint-disable-next-line camelcase
    duration: { start_date: position?.start_date, end_date: position?.end_date }
  });

  const isMain = position?.main;

  const jobTitleType = head(
    loadForSections(
      isMain ? PROFILE_SECTIONS.current_experience_title_1 : PROFILE_SECTIONS.past_experience_title_1
    )
  );

  const companyType = head(
    loadForSections(
      isMain ? PROFILE_SECTIONS.current_experience_title_2 : PROFILE_SECTIONS.past_experience_title_2
    )
  );

  const jobTitle = (jobTitleType?.name && position?.[jobTitleType?.name]?.[0]?.value) || null;
  const companyName = (companyType?.name && position?.[companyType?.name]?.[0]?.value) || null;

  const isCurrent = position?.current;

  const details = useMemo(() => {
    const duration = position?.duration;
    const start = position?.start_date;
    if (!duration && !start) {
      return;
    }
    if (duration && !start) {
      return moment.duration(duration, "months").humanize();
    } else {
      return isCurrent ? fromFormat(start) : readableDuration;
    }
  }, [isCurrent, position, readableDuration]);

  return (
    <ItemWrapper
      headLine={<ProfilePositionHeadline jobTitle={jobTitle} companyName={companyName} />}
      details={showDuration(details) ? details : undefined}
      isCurrent={isCurrent}
      isMain={isMain}
      display={display}
      onClick={handleClick}
      collapsible
    >
      <EditPositionForm
        position={position}
        profile={profile}
        adminPage={isAdmin}
        permissionsGroup={permissionsGroup}
        handleSuccess={handleSuccessFunction}
        handleRemove={onRemove}
        onClose={handleClick}
      />
    </ItemWrapper>
  );
};
