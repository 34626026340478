import escape from "lodash/escape";
// softBreakable adds soft hypen (&shy;) to every word that is longer then
// fragLen characters (default 14). This will prevent horizontal overflow
// in case of long words - such an edge case should not happen too often.
// It does not employ any grammar rules for breaking words.
//
// Example:
// softBreakable("quitelongtitlethatdoesntfitintocontainer") returns
// "quitelongtitle&shy;thatdoesntfiti&shy;ntocontainer"

export const softBreakable = (text, fragLen = 14, doEscape = true) => {
  const re = new RegExp(`([^ -]{${fragLen}})`, "g");
  if (doEscape) {
    text = escape(text);
  }
  return text
    .replace(re, "$1&shy;")
    .replace("&shy; ", " ")
    .replace(/&shy;$/, "");
};

// Cleans the given text from Hypen chars
export const cleanSoftBreakable = (text) => {
  // eslint-disable-next-line eqeqeq
  if (text == null) {
    return undefined;
  }
  return text.replace(/[\u00AD\u2011]+/g, "");
};

// a version of softBreakable that fixes bugs in safari webkit causing displaying
// weird characters instead or &shy; and/or not breaking the words
// this might not work for some urls (like ones made mostly of strings like
// /a/a/... or a.a.a...) but it's unlikely.
// PT: https://www.pivotaltracker.com/story/show/113158587
const softBreakableLinkRe = new RegExp("([^ -]{21,}?[^/.])([^/.])", "g");
export const softBreakableLink = (text) => {
  if (text.indexOf("<img") >= 0) {
    return text;
  }
  return escape(text).replace(softBreakableLinkRe, "$1<span>&shy;</span>$2");
};
