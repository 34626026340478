import flatten from "lodash/flatten";
import range from "lodash/range";
import times from "lodash/times";
import { Moment } from "moment";
import { useCallback } from "react";
import { useMemo } from "react";

import { useDateFormatter } from "../../../hooks/use_date_formatter";

const DEFAULT_HOUR = 12;
const DEFAULT_MINUTE = 0;

export type CalendarDay = {
  date: string;
  config: {
    working: boolean;
    disabled: boolean;
    isCurrentMonth: boolean;
  };
};

type UseCalendarDays = {
  minDate: string;
  maxDate: string;
  displayMonth: Moment;
  isWorkingCheck?: (date: Moment) => boolean;
};

export const useCalendarDays = ({
  minDate,
  maxDate,
  displayMonth,
  isWorkingCheck
}: UseCalendarDays): CalendarDay[] => {
  const { formatISODate } = useDateFormatter();
  const getDayProps = useCallback(
    (momentDate: Moment): CalendarDay => {
      const date = momentDate
        .clone()
        .date(momentDate.get("date"))
        .hour(DEFAULT_HOUR)
        .minute(DEFAULT_MINUTE)
        .second(0);

      const dateFormatted = formatISODate(date);
      const isBeforeMin = dateFormatted < formatISODate(minDate);
      const isAfterMax = dateFormatted > formatISODate(maxDate);

      return {
        date: dateFormatted,
        config: {
          working: isWorkingCheck ? isWorkingCheck(date) : true,
          disabled: isBeforeMin || isAfterMax,
          isCurrentMonth: displayMonth.isSame(dateFormatted, "month")
        }
      };
    },
    [displayMonth, minDate, maxDate]
  );

  const currentMonthStartDate = displayMonth.clone().startOf("month");
  const currentMonthEndDate = displayMonth.clone().endOf("month");
  const previousMonthDaysCount = currentMonthStartDate.isoWeekday() - 1;
  const nextMonthDaysCount = 7 - currentMonthEndDate.isoWeekday();

  const daysProps: CalendarDay[] = useMemo(
    () =>
      flatten([
        times(previousMonthDaysCount, (index) =>
          getDayProps(currentMonthStartDate.clone().add(index - previousMonthDaysCount, "days"))
        ),
        range(1, currentMonthEndDate.get("date") + 1).map((index) =>
          getDayProps(currentMonthStartDate.clone().date(index))
        ),
        times(nextMonthDaysCount, (index) => getDayProps(currentMonthEndDate.clone().add(index + 1, "days")))
      ]),
    [previousMonthDaysCount, nextMonthDaysCount, currentMonthStartDate, currentMonthEndDate, getDayProps]
  );

  return daysProps;
};
