import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EconomicsScenario, Id, MutationOptions } from "PFTypes";

import { shareActivityEconomicsScenario } from "../../../services/activity";
import activityKeys from "./query_keys";

export const useActivityEconomicsScenarioShare = (
  scenarioId: Id,
  options?: MutationOptions<Id[], EconomicsScenario>
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...queryOptions } = options || {};

  return useMutation<EconomicsScenario, unknown, Id[]>({
    mutationFn: (profileIds: Id[]) => shareActivityEconomicsScenario(scenarioId, profileIds),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.economicsScenario(scenarioId) });
      onSuccess?.(...args);
    },
    ...queryOptions
  });
};
