import { fetchBookingHistory } from "PFApp/booking/services/api";
import { IBookingHistoryItem } from "PFApp/booking/types";
import { getNextPageParam } from "PFCore/helpers/get_next_page_param";
import { InfiniteQueryOptions } from "PFTypes";

import { useInfiniteQuery } from "../../use_infinite_query";
import { historyKeys } from "./query_keys";

export type IBookingHistoryResponse = {
  entries: IBookingHistoryItem[];
  meta: {
    total: number;
    totalPages: number;
    page: number;
  };
};

export const useBookingHistoryInfinite = (
  bookingId: number,
  perPage?: number,
  options: InfiniteQueryOptions<IBookingHistoryResponse> = {}
) =>
  useInfiniteQuery({
    queryKey: historyKeys.ofBooking(bookingId),
    queryFn: ({ pageParam }) => {
      const params = {
        bookingId,
        page: pageParam,
        perPage
      };
      return fetchBookingHistory(params) as unknown as Promise<IBookingHistoryResponse>;
    },
    initialPageParam: 1,
    getNextPageParam,
    ...options
  });
