import classNames from "classnames";
import { Card, CardProps } from "PFComponents/card";
import { Animate } from "PFComponents/containers";
import { forwardRef, ReactNode } from "react";
import { useToggle } from "react-use";

import css from "./expandable_card.module.scss";
import { ExpandButton } from "./parts/expand_button";

type ExpandableCardProps = {
  header: ReactNode;
  footer?: ReactNode;
  animationTrigger?: any;
  className?: string;
} & CardProps;

export const ExpandableCard = forwardRef<HTMLDivElement, ExpandableCardProps>(
  ({ header, children, footer, animationTrigger, ...cardProps }, ref) => {
    const [isOpen, toggleIsOpen] = useToggle(false);

    return (
      <Card ref={ref} {...cardProps}>
        <span className={css.headerWrapper}>
          <div className={css.expandButtonColumn}>
            <ExpandButton
              isOpen={isOpen}
              onClick={(event) => {
                event.stopPropagation();
                toggleIsOpen();
              }}
            />
          </div>
          <div className={css.header}>
            <Animate trigger={animationTrigger}>{header}</Animate>
          </div>
        </span>
        <Animate trigger={animationTrigger}>
          <div className={classNames({ [css.closed]: !isOpen })}>{children}</div>
        </Animate>
        {footer && <Animate trigger={animationTrigger}>{footer}</Animate>}
      </Card>
    );
  }
);

ExpandableCard.displayName = "ExpandableCard";
