import moment from "moment";
import { getProfileName } from "PFCore/helpers/profile";
import { getVisibleProperties } from "PFCore/helpers/templates";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { Activity, PermissionRule, Subtemplate, Template } from "PFTypes";
import { useMemo } from "react";

import { shouldDisplayProperty } from "../../utils/should_display_property";
import { useIsAssigneeRequired } from "./use_is_assignee_required";

type UseActivityEditPageDefaultAttributesProps = {
  isClone: boolean;
  isNew: boolean;
  template?: Template;
  parentActivity?: Activity;
  subtemplates?: Subtemplate[];
  attributes: Partial<Activity>;
};

export const useActivityEditPageDefaultAttributes = ({
  isClone,
  isNew,
  template,
  parentActivity,
  subtemplates,
  attributes
}: UseActivityEditPageDefaultAttributesProps): Partial<Activity> => {
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();
  const isPermittedTo = useIsPermittedTo(currentProfile);
  const isResourcer = isPermittedTo(PermissionRule.Resourcer);

  const isAssigneeRequiredFnc = useIsAssigneeRequired();

  const defaultAttributes = useMemo<Partial<Activity>>(() => {
    if (!isNew || !template) {
      return {};
    }

    const result = {
      custom_fields: [],
      metadata: {},
      ...attributes
    };

    if (!isClone && parentActivity?.assignee) {
      result.assignee = parentActivity.assignee;
    }

    if (!result.assignee && isAssigneeRequiredFnc(template) && isResourcer) {
      result.assignee = { profile: currentProfile, id: -1 };
    }

    result.post_until = moment().add(template.default_post_until_days, "days").toISOString();

    const getParentCustomFieldValues = () => {
      if (!parentActivity) {
        return {};
      }

      return parentActivity.custom_fields.reduce((obj, customField) => {
        if (template.schema.properties[customField.type.name]) {
          obj[customField.type.name] = customField.values;
        }
        return obj;
      }, {});
    };

    // custom defaults not from schema
    const customDefaultValues =
      {
        engagement: {
          requested_by: getProfileName(currentProfile),
          service_line: currentProfile.service_line
        },
        role: {
          ...getParentCustomFieldValues(),
          requested_by: getProfileName(currentProfile),
          talent_pool: currentProfile.talent_pool
        }
      }[template.key] || {};

    getVisibleProperties(template).forEach((property) => {
      const hasDefault = "default" in property || property.name in customDefaultValues;
      const isInAttributesSection =
        !property.match && shouldDisplayProperty(property, customTypes, currentProfile);
      const hasSubtemplates = Number(subtemplates?.length) > 0;
      const allowFillingDefault = !(hasSubtemplates && isInAttributesSection);

      if (!hasDefault || !allowFillingDefault) {
        return;
      }

      const defaultValue = property.default || customDefaultValues[property.name];

      if (property.type !== "custom_field") {
        const valueBlank = result.metadata[property.name] === undefined;
        if (valueBlank) {
          result.metadata[property.name] = defaultValue;
        }

        return;
      }

      const customType = customTypes.filter((type) => type.name === property.name)[0];
      const valueBlank = !result.custom_fields.find((field) => field.type.id === customType.id);
      if (customType && defaultValue && valueBlank) {
        result.custom_fields = [
          ...result.custom_fields,
          {
            type: { id: customType.id as number, name: customType.name },
            values: defaultValue
          }
        ];
      }
    });
    return result;
  }, [currentProfile, customTypes, parentActivity, attributes, template, isClone, isNew, subtemplates]);

  return defaultAttributes;
};
