import set from "lodash/set";
import { useIsUnbiasedEnabled } from "PFApp/activities/show/hooks/use_is_unbiased_enabled";
import ActivityProfilesList from "PFApp/activities/show/parts/activity_profiles/activity_profiles_list";
import { ProfileItem } from "PFApp/components/profile_item";
import AutoSelect from "PFComponents/select/autoselect";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { Activity, ActivityProfileType, Id, Profile } from "PFTypes";
import { PropsWithChildren, useRef } from "react";
import { useTranslation } from "react-i18next";

import { ActivityProfileProp, NewProfile } from "./activity_profiles.types";
import css from "./add_activity_profiles_modal_content.module.scss";

export type AddActivityProfilesModalContentProps = PropsWithChildren<{
  activity?: Activity;
  onRemove?: (activityProfile: ActivityProfileProp) => void;
  showUnbiasedToggle?: boolean;
  description?: React.ReactNode;
  newProfiles: NewProfile[];
  onNewProfilesChange: (profiles: NewProfile[]) => void;
  profiles: ActivityProfileProp[];
  getRemoveModalContent?: (profile: Profile) => string | undefined;
  activityProfileType?: ActivityProfileType;
  disabledProfileIds?: Id[];
}>;

export const AddActivityProfilesModalContent = ({
  activity,
  profiles,
  onRemove,
  showUnbiasedToggle,
  description,
  newProfiles,
  onNewProfilesChange,
  children,
  getRemoveModalContent,
  activityProfileType,
  disabledProfileIds = []
}: AddActivityProfilesModalContentProps) => {
  const { t } = useTranslation(["activities", "translation"]);

  const { data: currentProfile } = useCurrentProfile();

  const isUnbiasedEnabled = useIsUnbiasedEnabled(activity);

  const bodyRef = useRef<HTMLDivElement>(null);

  const handleSelection = (profiles: Profile[]) => {
    onNewProfilesChange(profiles);
    set(bodyRef, "current.scrollTop", 0);
  };

  const handleRemoveSelected = (selected: ActivityProfileProp) => {
    const updatedProfiles = newProfiles.filter(({ id }) => id !== selected.profile_id);
    onNewProfilesChange(updatedProfiles);
  };

  const handleAddUnbiasedMode = (selected: ActivityProfileProp) => {
    const updatedProfiles = newProfiles.filter(({ id }) => id !== selected.profile_id);
    onNewProfilesChange([...updatedProfiles, { ...selected.profile, unbiased: selected.unbiased }]);
  };

  const selectedIds = profiles
    .map(({ profile }) => profile)
    .concat(newProfiles)
    .map(({ id }) => id);

  return (
    <>
      <AutoSelect
        closeOnChange
        multi
        values={newProfiles}
        query={(term: string) => fetchProfilesOptions({ term })}
        filterOptions={(response) => response.filter((item) => currentProfile.id !== item.id)}
        formatOption={(item: Profile) => ({
          id: item.id,
          displayElement: <ProfileItem profile={item} />,
          disabled: selectedIds.includes(item.id) || disabledProfileIds.includes(item.id),
          item
        })}
        handleChange={handleSelection}
        showValues={false}
        placeholder={t("translation:search")}
      />
      {(!!profiles.length || !!newProfiles.length) && (
        <div className={css.lists} ref={bodyRef}>
          <ActivityProfilesList
            activityProfiles={newProfiles.map((profile) => ({
              profile,
              profile_id: profile.id,
              activity_id: activity?.id
            }))}
            title={t("activities:show.parts.aboutToAdd")}
            isUnbiasedEnabled={isUnbiasedEnabled}
            onRemove={handleRemoveSelected}
            handleAddUnbiasedMode={handleAddUnbiasedMode}
            showUnbiasedToggle={showUnbiasedToggle}
            activityProfileType={activityProfileType}
          />
          <ActivityProfilesList
            activityProfiles={profiles}
            isUnbiasedEnabled={isUnbiasedEnabled}
            title={t("activities:show.parts.alreadyAdded")}
            onRemove={onRemove}
            showUnbiasedToggle={showUnbiasedToggle}
            getRemoveModalContent={getRemoveModalContent}
            activityProfileType={activityProfileType}
          />
        </div>
      )}
      {description && (
        <div className={css.subtitle}>
          <strong>{t("translation:pleaseNote")}</strong>
          <br />
          <div className={css.description}>{description}</div>
        </div>
      )}
      {children}
    </>
  );
};
