import forEach from "lodash/forEach";
import isArray from "lodash/isArray";
import omit from "lodash/omit";
import { useGrowl } from "PFApp/use_growl";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useResponseErrors } from "../helpers/use_response_errors";

type ErrorsGrowlOptions = {
  omit?: string | string[];
  display_all?: boolean;
  version?: string;
};

export const useErrorsGrowl = () => {
  const growl = useGrowl();
  const { t } = useTranslation();
  const { getFirstError, getFormattedErrors } = useResponseErrors();

  const growlAll = useCallback(
    (errors, options: ErrorsGrowlOptions) => {
      const allErrors = getFormattedErrors(errors);
      const keys = omit(allErrors, options.omit || "");

      if (!options.display_all) {
        const result: unknown[] = [];
        for (const key in keys) {
          let message = getFirstError(errors, {}, key);
          if (isArray(message)) {
            message = message.join(", ");
          }
          result.push(growl({ message, kind: "error" }));
        }
        return result;
      } else {
        return forEach(keys, (errorCategory) =>
          forEach(isArray(errorCategory) ? errorCategory : [errorCategory], (errorKey) => {
            const message = t(errorKey, { defaultValue: errorKey });

            return growl({ message, kind: "error" });
          })
        );
      }
    },
    [getFirstError, getFormattedErrors, growl, t]
  );

  return useCallback(
    // eslint-disable-next-line camelcase
    (response, options: ErrorsGrowlOptions = { display_all: false }) => growlAll(response, options),
    [growlAll]
  );
};
