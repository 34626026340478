import { getNextPageParam } from "PFCore/helpers/get_next_page_param";
import { fetchMatches } from "PFCore/services/matches";
import { Match, PaginatedCollection } from "PFTypes";
import { QueryParams } from "PFTypes/query_params";
import { InfiniteQueryOptions } from "PFTypes/request_options";

import { useInfiniteQuery } from "../use_infinite_query";
import { QueryOptionsWithCallbacks, useQueryWithCallbacks } from "../use_query_with_callbacks";
import { matchesKeys } from "./query_keys";

export const useMatches = (
  activityId: number | string,
  params: QueryParams,
  options: QueryOptionsWithCallbacks<PaginatedCollection<Match[]>, { status: number }> = {}
) =>
  useQueryWithCallbacks({
    queryKey: matchesKeys.activity(activityId, params),
    queryFn: () => fetchMatches(activityId, params),
    ...options
  });

export const useMatchesInfinite = (
  activityId: number | string,
  params: QueryParams,
  options: InfiniteQueryOptions<PaginatedCollection<Match[]>> = {}
) =>
  useInfiniteQuery({
    queryKey: matchesKeys.activity(activityId, params),
    getNextPageParam,
    queryFn: ({ pageParam }) => fetchMatches(activityId, { ...params, page: pageParam }),
    initialPageParam: 1,
    ...options
  });
