import { fetchShortlists, ShortlistCollection } from "PFCore/services/shortlist";
import { QueryParams } from "PFTypes";

import { QueryOptionsWithCallbacks, useQueryWithCallbacks } from "../use_query_with_callbacks";
import { shortlistsKeys } from "./query_keys";

export const useActivityShortlists = (
  activityId: number,
  params: QueryParams = {},
  options: QueryOptionsWithCallbacks<ShortlistCollection> = {}
) =>
  useQueryWithCallbacks<ShortlistCollection>({
    queryKey: shortlistsKeys.activityShortlists(activityId, params),
    queryFn: () => fetchShortlists(activityId, params.page, params.perPage, params.filters, params.order),
    retry: false,
    staleTime: 0,
    ...options
  });
