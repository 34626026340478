import { useQuery } from "@tanstack/react-query";
import { fetchProfile } from "PFCore/services/profile";
import { Profile } from "PFTypes";
import { Id } from "PFTypes/id";
import { QueryOptions } from "PFTypes/request_options";

import { profileKeys } from "./query_keys";

export const useProfile = (id: Id | undefined, options: QueryOptions<Profile> = {}) =>
  useQuery<Profile>({
    queryKey: profileKeys.profile(id ?? -1),
    queryFn: () => fetchProfile(id) as unknown as Profile,
    ...options
  });
