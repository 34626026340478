import uniqueId from "lodash/uniqueId";
import { Button } from "PFComponents/button";
import { AccessLevel } from "PFTypes";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { IsFieldPermittedFunction, MyInformationFormData } from "../../my_information_edit_modal.types";
import { FormSection } from "../form_section";
import { ContactRow } from "./contact_row";
import css from "./contact_section.module.scss";

type ContactSectionProps = {
  isLoading: boolean;
  isFieldPermitted: IsFieldPermittedFunction;
};

export const ContactSection = ({ isLoading, isFieldPermitted }: ContactSectionProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.myInformationModal" });

  const { control, setFocus } = useFormContext<MyInformationFormData>();

  const { fields, prepend, remove } = useFieldArray({
    control,
    name: "contactInformation"
  });

  if (!isFieldPermitted("contact_info", AccessLevel.ReadOnly)) {
    return null;
  }

  const isContactRWPermitted = isFieldPermitted("contact_info", AccessLevel.ReadWrite);

  const handleAddNewContact = () => {
    prepend({
      inputId: uniqueId(),
      type: "phone",
      value: "",
      label: ""
    });

    setTimeout(() => {
      setFocus(`contactInformation.0.type`);
    }, 100);
  };

  return (
    <FormSection title={t("contactInformation")}>
      <Button
        kind="secondary"
        type="button"
        disabled={isLoading || !isContactRWPermitted}
        onClick={handleAddNewContact}
        text={t("addContact")}
        className={css.addContactButton}
      />
      <div className={css.contactsList} data-qa-id="profile-edit-contact-info-links-section">
        {fields.map((contact, index) => (
          <ContactRow
            key={contact.inputId}
            index={index}
            locked={!isContactRWPermitted}
            onRemove={() => remove(index)}
          />
        ))}
      </div>
    </FormSection>
  );
};
