import isFunction from "lodash/isFunction";
import isNil from "lodash/isNil";
import { ForwardedRef, forwardRef, InputHTMLAttributes, useCallback, useEffect, useRef } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

type HTMLProps = Omit<InputHTMLAttributes<HTMLInputElement>, "onChange" | "autoComplete" | "autoFocus">;
export type InputProps = HTMLProps & {
  autoComplete?: boolean;
  autofocus?: boolean;
} & (
    | {
        register?: never;
        onChange?: (value: string, event: Event) => void;
      }
    | {
        name?: never;
        onChange?: never;
        register: UseFormRegisterReturn;
      }
  );

export const Input = forwardRef(
  (
    {
      autoComplete,
      autofocus,
      register,
      type = "text",
      name,
      onChange,
      value,
      defaultValue,
      ...props
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    // Hotfix for clearing input when value is not defined
    useEffect(() => {
      if (inputRef.current && !value) {
        inputRef.current.value = isNil(defaultValue) ? "" : String(defaultValue);
      }
    }, [value, defaultValue]);

    const handleChange = useCallback(
      (event) => {
        const { value } = event.target;

        onChange?.(value, event);
      },
      [onChange]
    );

    useEffect(() => {
      if (inputRef.current && autofocus) {
        inputRef.current.focus();
      }
      if (isFunction(ref)) {
        ref(inputRef.current);
      } else if (ref) {
        ref.current = inputRef.current;
      }
    }, []);

    const autoCompleteState = autoComplete ? "on" : "off";

    return (
      <input
        ref={inputRef}
        type={type}
        name={name}
        autoComplete={autoCompleteState}
        value={type !== "password" ? value : undefined}
        onChange={handleChange}
        {...props}
        {...register}
      />
    );
  }
);

Input.displayName = "Input";
