import extend from "lodash/extend";
import { ParsedSchemaProperty, PermissionRule, Template } from "PFTypes";

type Permission = "ro" | "rw";

type PermissionGroup = {
  id: number;
  name: string;
  permitted: boolean;
};

type CrossGroupPermissions = {
  activities_access_levels: Record<string, Permission>;
};

type ActivityPermissions = {
  cross_group_permissions: Record<number, CrossGroupPermissions>;
  permissions: PermissionGroup[];
};

const activityPermissions = (
  activityPermissions: ActivityPermissions,
  templates: Template[],
  templateName: string
): Permission | null => {
  const activityId = templates?.find((item) => item?.key === templateName)?.id;

  if (!activityId) {
    return null;
  }

  const activitiesAccessLevelsGroups = Object.entries(activityPermissions?.cross_group_permissions).map(
    ([, { activities_access_levels }]) => activities_access_levels // eslint-disable-line camelcase
  );
  const permissionGroupWithActivityId = activitiesAccessLevelsGroups.find((group) => group[activityId]);
  if (!permissionGroupWithActivityId) {
    return null;
  }
  return permissionGroupWithActivityId[activityId];
};

const isUserSeniorPartner = (permissionsGroup: ActivityPermissions) =>
  permissionsGroup?.permissions?.find((permission) => permission.name === PermissionRule.SeniorPartner)
    ?.permitted;

const isUserResourcer = (permissionsGroup: ActivityPermissions) =>
  permissionsGroup?.permissions?.find((permission) => permission.name === PermissionRule.Resourcer)
    ?.permitted;

const isUserSeniorPartnerOrResourcer = (permissionsGroup: ActivityPermissions) =>
  isUserSeniorPartner(permissionsGroup) || isUserResourcer(permissionsGroup);

const getProperties = (template): ParsedSchemaProperty[] => {
  const { schema } = template;

  const properties: ParsedSchemaProperty[] = [];

  for (const key in schema.properties) {
    const prop = schema.properties[key];
    const ref = prop.$ref;
    const type = ref && ref.replace("#/definitions/", "");

    const elem = extend({}, schema.definitions[type] || {}, prop, { type, name: key });

    properties.push(elem);
  }

  properties.sort((first, second) => (first.order || 0) - (second.order || 0));

  return properties;
};

const getProperty = (template, propertyName) =>
  getProperties(template).find(({ name }) => name === propertyName);

const getVisibleProperties = (template) => getProperties(template).filter(({ visible }) => visible !== false);

const getConnectedProperties = (template) => getProperties(template).filter((prop) => prop.connected_options);

const getCustomFieldNames = (template) =>
  getProperties(template)
    .filter(({ type }) => type === "custom_field")
    .map(({ name }) => name);

const propertyToTitle = (template, name, customFields) => {
  const prop = getProperties(template).find((prop) => prop.name === name);

  if (prop?.type === "custom_field") {
    const customField = customFields && customFields.find((field) => field.name === name);
    return (customField && customField.display_as) || prop.title;
  }

  return prop?.title;
};

const isSubtemplate = (template: Template, subtemplateKey: string): boolean =>
  !!template.schema.subtemplates?.[subtemplateKey];

export {
  activityPermissions,
  getCustomFieldNames,
  getProperties,
  getProperty,
  getConnectedProperties,
  getVisibleProperties,
  isUserSeniorPartner,
  isUserResourcer,
  isUserSeniorPartnerOrResourcer,
  propertyToTitle,
  isSubtemplate
};
