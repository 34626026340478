import compact from "lodash/compact";
import partition from "lodash/partition";
import { filterElements } from "PFApp/use_filtered_collection";
import { MutableRefObject } from "react";

import { useFiltersContext } from "../context/filters_context";
import { FiltersAccordion } from "../filters_accordion";
import { FiltersElement } from "./filters_element";
import { FiltersPanelProps } from "./filters_panel";

export type FiltersContentProps = { lastFocusedFilterNameRef: MutableRefObject<any> } & Omit<
  React.PropsWithChildren<FiltersPanelProps>,
  | "onFiltersChange"
  | "clearFilters"
  | "closeFilters"
  | "filtersAreClear"
  | "allowToClose"
  | "style"
  | "withTitle"
  | "letRestoreAll"
  | "restoreAllTooltipContent"
  | "isRelevantToMeDisabled"
  | "keyPrefix"
>;

export const FiltersContent = (props: FiltersContentProps) => {
  const {
    lastFocusedFilterNameRef,
    clearRefValue,
    blockedList = [],
    blockedChildrenList = [],
    children,
    narrow,
    isLoading,
    viewsKey = null,
    classes = {},
    fetchOptions,
    portalRef,
    accordionProps,
    disabled,
    meta,
    onFilterChange,
    renderExternalFilters,
    childrenPosition = "top"
  } = props;
  const metaFilters = meta?.filters || {};
  const allFilters = filterElements(metaFilters, blockedList, blockedChildrenList);
  const [childrenFilters, filters] = partition(allFilters, "children");
  const isChildrenFiltersInMeta = !!metaFilters.children;

  const { isChildrenFiltersEnabled } = useFiltersContext();

  const handleFocus = (filter) => {
    if (lastFocusedFilterNameRef.current !== filter.name) {
      lastFocusedFilterNameRef.current = filter.name;
    }
  };

  const handleBlur = () => {
    if (lastFocusedFilterNameRef.current) {
      lastFocusedFilterNameRef.current = null;
    }
  };

  const getFiltersElementProps = (filters, children: boolean) => ({
    filters,
    renderExternalFilters,
    viewsKey,
    clearRefValue,
    fetchOptions,
    lastFocusedFilterNameRef,
    meta,
    narrow,
    onFilterChange: (filter, valueId, valueObject) =>
      onFilterChange?.(filter, valueId, { children, valueObject }),
    onFocus: handleFocus,
    onBlur: handleBlur,
    disabled: isLoading || disabled,
    shouldUseChildrenFilters: children
  });

  const childrenElement = !!children && <div key={0}>{children}</div>; // children prop element

  const content = compact([
    childrenPosition === "top" && childrenElement,
    <FiltersElement // standard filters from meta element
      key={1}
      {...getFiltersElementProps(filters, false)}
      classes={{ list: classes.list }}
      portalRef={portalRef}
    />,
    isChildrenFiltersInMeta && ( // filters children element
      <FiltersElement
        key={2}
        {...getFiltersElementProps(childrenFilters, true)}
        classes={{ list: classes.list }}
        portalRef={portalRef}
      />
    ),
    childrenPosition === "bottom" && childrenElement
  ]);

  const childrenFiltersIndex = isChildrenFiltersInMeta ? content.length - 1 : -1;

  const activeState = {
    ...(isChildrenFiltersInMeta ? { [childrenFiltersIndex]: isChildrenFiltersEnabled } : {}),
    ...(accordionProps?.activeState || {})
  };

  return accordionProps ? (
    <FiltersAccordion
      titlesList={[accordionProps.childrenTitle, accordionProps.filtersTitle]}
      disabledList={[false, !isChildrenFiltersEnabled || !!accordionProps.filtersDisabled]}
      activeState={activeState}
      allDisabled={accordionProps.allDisabled}
      onActiveIndexChange={accordionProps.onActiveIndexChange}
      allowMultipleActive={accordionProps.allowMultipleActive}
      style={{ marginTop: 20 }}
    >
      {content}
    </FiltersAccordion>
  ) : (
    <>{content}</>
  );
};
