import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import camelCase from "lodash/camelCase";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { CreateCustomValuePayload } from "PFCore/services/admin/custom_fields";
import { CustomValue } from "PFTypes";
import { Id } from "PFTypes/id";
import { MutationOptions } from "PFTypes/request_options";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useCustomValuesInvalidate } from "../use_custom_values_invalidate";

export type MutationFnParams = {
  customTypeId: Id;
  payload: CreateCustomValuePayload;
};

export const useCustomValueCreate = (
  { onSuccess, ...queryParams }: MutationOptions<MutationFnParams> = {},
  onFetch: (customTypeId: Id, payload: CreateCustomValuePayload) => Promise<CustomValue>
) => {
  const { invalidate } = useCustomValuesInvalidate();

  return useMutation({
    mutationFn: ({ customTypeId, payload }: MutationFnParams): Promise<CustomValue> =>
      onFetch(customTypeId, payload),
    onSuccess: (...args) => {
      invalidate();
      onSuccess?.(...args);
    },
    ...queryParams
  });
};

export const useCustomValueCreateError = () => {
  const { t } = useTranslation("core", { keyPrefix: "errors" });
  const { getFormattedErrors } = useResponseErrors();

  const parseCustomValueCreateError = useCallback(
    (err: AxiosError, handleError: (error: string) => void) => {
      const formattedErrors = getFormattedErrors(err.response);
      const errors = Object.keys(formattedErrors).map(
        //@ts-ignore
        (fieldName) => `${t(`customValues.fields.${camelCase(fieldName)}`, "")} ${formattedErrors[fieldName]}`
      );

      handleError(errors[0] || t("general"));
    },
    [getFormattedErrors, t]
  );

  return { parseCustomValueCreateError };
};
