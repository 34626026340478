import { useQuery } from "@tanstack/react-query";
import { Id } from "PFTypes";

import { fetchActivityEconomics, FetchActivityEconomicsResponse } from "../../../services/activity";
import activityKeys from "./query_keys";

export const useActivityEconomics = (activityId: Id, options?: FetchActivityEconomicsResponse) =>
  useQuery({
    queryKey: activityKeys.economics(activityId),
    queryFn: () => fetchActivityEconomics(activityId),
    retry: false,
    staleTime: 0,
    ...options
  });
