import { Button } from "PFComponents/button";
import { useShortlistCreate } from "PFCore/hooks/queries/shortlists/use_shortlist_create";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ShortlistAction } from "../action_buttons/role_profile_action_buttons";
import { useRoleProfileActionButtonsContext } from "../context/role_profile_action_buttons_context";
import css from "./shortlist_buttons.module.scss";

type ShortlistButtonProps = {
  onSuccess: (action: ShortlistAction) => void;
  onError: () => void;
};

export const ShortlistButton = ({ onError, onSuccess }: ShortlistButtonProps) => {
  const { t } = useTranslation("activities");
  const { mutate: createShortlist, isPending: isCreating } = useShortlistCreate();
  const { role, profile, areButtonsDisabled, small } = useRoleProfileActionButtonsContext();

  const handleClick = () =>
    createShortlist(
      { activityId: role.id, profileId: profile.id },
      { onSuccess: () => onSuccess("shortlist"), onError: () => onError() }
    );

  const buttonText = useMemo(() => (!isCreating ? t("show.matches.shortlist") : "..."), [t, isCreating]);
  const isDisabled = areButtonsDisabled || isCreating;

  return (
    <Button
      className={css.shortlistButton}
      onClick={(event) => {
        event.stopPropagation();
        handleClick();
      }}
      disabled={isDisabled}
      small={small}
    >
      {buttonText}
    </Button>
  );
};
