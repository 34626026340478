import every from "lodash/every";
import { TemplateKey } from "PFApp/constants/templates";
import { ONE_MINUTE_MS } from "PFCore/utilities/time_consts";
import { Activity, Template } from "PFTypes";

export const isEngagement = (templateOrKey?: Pick<Template, "key"> | string) =>
  Boolean(
    templateOrKey &&
      [TemplateKey.Engagement, TemplateKey.AuditEngagement].includes(
        (templateOrKey as Template).key || templateOrKey
      )
  );

export const isRole = (templateOrKey?: Pick<Template, "key"> | string) =>
  Boolean(
    templateOrKey &&
      [TemplateKey.Role, TemplateKey.AuditRole].includes((templateOrKey as Template).key || templateOrKey)
  );

export const isAudit = (templateOrKey?: Pick<Template, "key"> | string) =>
  Boolean(((templateOrKey as Template)?.key || templateOrKey || "").includes("audit"));

export const checkIfIsDefaultPrivate = (template: Template, parentActivity?: Activity) => {
  if (parentActivity && template.default_visibility === "inherit_from_parent") {
    return parentActivity.private;
  } else {
    return template.default_visibility === "private";
  }
};

export const isActivityPending = (activity: Activity, checkRecentlyUpdated?: boolean) => {
  const { custom_fields, source, updated_at } = activity;

  if (checkRecentlyUpdated) {
    const updatedAtTime = new Date(updated_at).getTime();
    const currentTime = new Date().getTime();
    if (currentTime - updatedAtTime > ONE_MINUTE_MS) {
      return false;
    }
  }

  const isFromJML = source === "jml";
  const skills = custom_fields.find(({ type }) => type?.name === "skills")?.values || [];
  const allSkillsNotCurated = every(skills, (skill) => skill.curated_at === null);

  return isFromJML && skills.length > 0 && allSkillsNotCurated;
};
