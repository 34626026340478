export const DEFAULT_LIMIT = 8;

export type CustomValuesListCommonProps = {
  customFieldName: string;
  limit?: number;
  canEdit?: boolean;
  dark?: boolean;
  vertical?: boolean;
  maxNameLength?: number;
  onMoreLessButtonClick?: VoidFunction;
};
