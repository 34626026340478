import last from "lodash/last";
import sortBy from "lodash/sortBy";
import { useProfileFeedback } from "PFCore/hooks/queries/activity/use_profile_feedback";
import { FeedbackForProfile } from "PFTypes/activity_feedback";

type LastFeedback = {
  lastFeedback: FeedbackForProfile | null;
  isLoading: boolean;
};

export const useLastFeedback = (roleId: number, profileId: number): LastFeedback => {
  const { data: managerFeedback, isLoading } = useProfileFeedback(profileId, [roleId]);

  const lastFeedback = last(sortBy(managerFeedback?.entries, "submitted_at")) || null;

  return { lastFeedback, isLoading };
};
