import lowerCase from "lodash/lowerCase";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useCustomValueInvalidate } from "PFCore/hooks/queries/custom_fields/use_custom_value_invalidate";
import { useErrorsGrowl } from "PFCore/hooks/use_errors_growl";
import { Activity, ActivityData, Experience, Importance, PureCustomValueCamelCased } from "PFTypes";
import { useTranslation } from "react-i18next";

import { useCustomValuePreviewContext } from "../../context/custom_value_preview_context";
import { addCustomField, editCustomField, removeCustomField } from "./activity_custom_value_footer.utils";
import { Footer, FooterProps } from "./footer";

type ActivityCustomValueFooterProps = {
  activity: Activity | undefined;
  customValue: PureCustomValueCamelCased | undefined;
  importance: Importance | null;
  requiredExperience: Experience | null;
  templateName: string | null;
  updateActivity: (activityData: ActivityData) => Promise<Activity | undefined>;
  closeWithAnimation: VoidFunction;
} & Omit<FooterProps, "saveNewLabel" | "onRemove" | "onSave">;

export const ActivityCustomValueFooter = ({
  activity,
  customValue,
  importance,
  requiredExperience,
  templateName,
  updateActivity,
  closeWithAnimation,
  ...props
}: ActivityCustomValueFooterProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel" });
  const growlError = useErrorsGrowl();
  const { invalidate: invalidateActivities } = useActivityInvalidate();
  const {
    invalidateCustomValueActivityConnection,
    removeCustomValueActivityConnection,
    invalidateCustomValueInsights
  } = useCustomValueInvalidate();
  const { isNew, customValueId, customType, activityId } = useCustomValuePreviewContext();

  const invalidate = (removeFromCache: boolean = false) => {
    invalidateActivities([activityId!]);
    invalidateCustomValueInsights(customValueId!);
    if (removeFromCache) {
      removeCustomValueActivityConnection(customValueId!, activityId!);
    } else {
      invalidateCustomValueActivityConnection(customValueId!, activityId!);
    }
  };

  const handleRemove = () =>
    updateActivity({
      custom_fields: removeCustomField(activity!.custom_fields, customValueId!, customType.id)
    })
      .then(() => {
        closeWithAnimation();
        setTimeout(() => {
          // Invalidate after isClosing is true, to avoid incorrect query call
          invalidate(true);
        }, 0);
      })
      .catch(() => {
        growlError(t("removeError", { value: customValue?.text }));
      });

  const handleSave = () => {
    const taskCustomFields = isNew
      ? addCustomField(activity!.custom_fields, customType!, customValue!, importance!, requiredExperience!)
      : editCustomField(
          activity!.custom_fields,
          customValueId!,
          customType.id,
          importance!,
          requiredExperience!
        );

    updateActivity({ custom_fields: taskCustomFields })
      .then(() => {
        closeWithAnimation();
        setTimeout(() => {
          // Invalidate after isClosing is true, to avoid incorrect query call
          invalidate();
        }, 0);
      })
      .catch(() => {
        growlError(t("saveError", { value: customValue?.text }));
      });
  };

  return (
    <Footer
      {...props}
      saveNewLabel={t("addToActivity", {
        templateName: lowerCase(templateName || "")
      })}
      onRemove={handleRemove}
      onSave={handleSave}
    />
  );
};
