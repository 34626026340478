// Opens a popup and does something on callback
//
// WARNING: this needs to be triggered form a click event flow
//          or the browser will stop this popup
//
// usage:
//    name: 'Linkedin authorization process'
//    path: Routes.linkedin_connect_path()
//    loading: [Object] # if present a custom loading screen will be shown, format: {title: '', message:''}
//    callback: ->
//      alert('well done. You are now connected')
//
import defaults from "lodash/defaults";
import defer from "lodash/defer";

export default function popup(options = {}, onError) {
  options = defaults(options, {
    name: "",
    window_options: "location=0,status=0,width=800,height=500,scrollbars=1"
  });

  const _popupWindow = window.open(options.path, options.name || "_new", options.window_options);
  if (!_popupWindow) {
    return onError();
  }

  _popupWindow.focus();

  if (options.loading) {
    const { message, title } = options.loading;
    let isLoaded = false;
    try {
      _popupWindow.onload = () => (isLoaded = true);
    } catch {}

    defer(function () {
      if (isLoaded) {
        return;
      }
      try {
        /* eslint-disable max-len */
        return _popupWindow.document.body !== null
          ? (_popupWindow.document.body.innerHTML = `<html>
              <head>
                <title>Profinda</title>
                <meta charset='utf-8'>
                <meta content='width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no' name='viewport'/>
              </head>
              <body style="background: #f3f3f3;padding: 5%;font-family: Helvetica, Arial, sans-serif;text-align: center;font-weight: lighter;">
                <h1>${title || "Loadidng..."}</h1>
                <strong>${message || ""}</strong>
              </body>
            </html>`)
          : undefined;
      } catch {}
    });
  }

  if (options.callback) {
    var _popupInterval = window.setInterval(function () {
      if (_popupWindow.closed) {
        window.clearInterval(_popupInterval);
        return typeof options.callback === "function" ? options.callback() : undefined;
      }
    }, 50);
  }

  return _popupWindow;
}
