import { useActivityCurrentUserRole } from "PFApp/activities/hooks";
import commonCss from "PFApp/activities/show/activity_page/activity_page.module.scss";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { MatchCard } from "PFApp/components/activity_match_card";
import { LoadingDots } from "PFComponents/loading_dots";
import { SimplePagination } from "PFComponents/pagination/simple_pagination";

import { MatchTableEntry } from "../../../activity_tabs.utils";

type MatchesCardsViewProps = {
  matchesEntries: MatchTableEntry[];
  isLoading: boolean;
};

export const MatchesCardsView = ({ matchesEntries, isLoading }: MatchesCardsViewProps) => {
  const {
    task: activity,
    matchesMeta,
    updateMatchesParams,
    matchesParams,
    isFillDisabled
  } = useActivityPageContext();
  const { isReviewerUnbiased } = useActivityCurrentUserRole(activity);

  return (
    <div className={commonCss.pageCardEntriesList} data-qa-id="ActivityMatchesTabCardsContainer">
      {isLoading && <LoadingDots circlesEnabled circleSize="xs" className={commonCss.loading} />}

      {matchesEntries.map(({ match }) => (
        <MatchCard
          role={activity}
          key={match.id}
          match={match}
          isBlindModeOn={isReviewerUnbiased}
          isFillDisabled={isFillDisabled}
          areButtonsDisabled={isLoading}
        />
      ))}
      <SimplePagination
        currentPage={matchesParams?.page || 1}
        handleClick={(num) => updateMatchesParams({ page: num })}
        totalPages={matchesMeta.totalPages}
      />
    </div>
  );
};
