import classNames from "classnames";
import flatten from "lodash/flatten";
import moment from "moment";
import { useAppContext } from "PFApp/app_context";
import { Modal } from "PFComponents/modal";
import { ProfileAvatar } from "PFComponents/profile_avatar/profile_avatar";
import { TextArea } from "PFComponents/text/text_area";
import useDebounce from "PFCore/helpers/use_debounce";
import useWindowSize from "PFCore/helpers/use_window_size";
import { useActivity } from "PFCore/hooks/queries/activity";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { GROWL_ACTIONS } from "PFCore/reducers/growl_reducer";
import { createActivity, updateActivity } from "PFCore/services/activities";
import { fetchUrlMetadata } from "PFCore/services/common";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./create_post.less";
import extractUrl from "./extract_url";
import PreviewPostLink from "./preview_post_link";
import TagsSuggestions from "./tags_suggestions";

const perPage = 10;
const maxLength = 300;

const CreatePostForm = ({ postTask, isEdit, tab, fetchActivities, handleClose }) => {
  const { dispatch } = useAppContext();
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("feed");

  const { windowWidth } = useWindowSize();

  const isMobile = windowWidth < 530;

  const postTemplate = currentProfile.templates.find(({ key }) => key === "post");

  const dummy = isEdit ? postTask : {};
  const [postText, setPostText] = useState(isEdit ? dummy.description : "");
  const attributes = isEdit ? flatten(dummy.custom_fields.map((cf) => cf.values)) : [];

  const [selectedAttributes, setSelectedAttributes] = useState(
    attributes.map((attribute) => ({
      id: attribute.id,
      text: attribute.text,
      value: attribute.value
    }))
  );

  const [textLength, setTextLength] = useState(postText.length || 0);
  const [validUrl, setValidUrl] = useState("");
  const [isMaxCharacters, setIsMaxCharacters] = useState(false);
  const [postResp, setPostResp] = useState(
    isEdit ? dummy.attributes && dummy.attributes.metadata && dummy.attributes.metadata.og : null
  );
  const retrieveUrlMetadata = useDebounce(
    (validUrl) =>
      fetchUrlMetadata(validUrl).then((response) =>
        setPostResp({ ...response, image: response.image || "" })
      ),
    500
  );

  useEffect(() => {
    // set response to null if there is no keys in the object, preventing render empty responses
    if (postResp && Object.keys(postResp).length === 0) {
      setPostResp(null);
    }
  }, [postResp]);

  const handleClick = () => {
    const payload = {
      template_id: postTemplate.id,
      post_until: moment().add(postTemplate.default_post_until_days, "days").toISOString(),
      custom_fields: [{ values: selectedAttributes }],
      private: false,
      state: "new",
      name: postResp ? postResp.title : postText.substr(0, 20),
      description: postText,
      ...(postResp ? { metadata: { og: { ...postResp } } } : null)
    };
    const apiFunction = isEdit ? updateActivity(postTask.id, payload) : createActivity(payload);

    // this timeout has been added because the API needs some time to reindex the new activity
    // This is a temp solution until a fix is implemented on the API.
    apiFunction
      .then((resp) =>
        setTimeout(() => {
          const newTab = tab === "my_feed" ? "my_activities" : tab;
          fetchActivities(1, perPage, newTab, resp);

          const type = GROWL_ACTIONS.GROWL_RENDER;
          dispatch({ type, payload: { message: t("post.create.success") } });
        }, 2000)
      )
      .catch(() => {
        const type = GROWL_ACTIONS.GROWL_RENDER;
        dispatch({
          type,
          payload: {
            message: t("post.create.error"),
            kind: "error"
          }
        });
      });
  };

  useEffect(() => {
    if (extractUrl(postText)) {
      setValidUrl(extractUrl(postText)[0]);
    } else {
      setValidUrl("");
    }
    setTextLength(postText.length);
    setIsMaxCharacters(textLength >= maxLength ? true : false);
  }, [postText]);

  useEffect(() => {
    if (validUrl) {
      retrieveUrlMetadata(validUrl);
    }
  }, [validUrl]);

  return (
    <Modal
      title={t("post.createPost")}
      labelOK={t("post.post")}
      onOK={textLength > 0 ? handleClick : null}
      onClose={handleClose}
      classes={{ modal: css.modal }}
    >
      <div className={css.postArea}>
        <ProfileAvatar size={40} profile={isEdit ? postTask.profile : currentProfile} />
        <div className={css.textArea}>
          <TextArea
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            placeholder={t("post.shareThoughtsAndLinks")}
            value={postText}
            onChange={setPostText}
            maxLength={maxLength}
            maxHeight={isMobile ? 100 : 200}
            style={{
              width: "85%",
              border: "none",
              marginRight: "5%"
            }}
          />
          <div
            className={classNames(css.counter, { [css.maxLimit]: isMaxCharacters })}
          >{`${textLength}/${maxLength}`}</div>
        </div>
      </div>
      {postResp && (
        <PreviewPostLink postResp={postResp} removePost={() => setPostResp(null)} isMobile={isMobile} />
      )}
      <TagsSuggestions
        text={postText}
        setSuggestedAttributes={setSelectedAttributes}
        attributes={attributes}
        activityTemplate={postTemplate}
      />
    </Modal>
  );
};

const CreatePost = ({ postId, isEdit, ...props }) => {
  const queryEnabled = postId !== null && isEdit;
  const { data: postTask } = useActivity(postId, { enabled: queryEnabled });

  if (queryEnabled && !postTask) {
    return null;
  }

  return <CreatePostForm {...props} postTask={postTask} isEdit={isEdit} />;
};

CreatePostForm.propTypes = {
  handleClose: PropTypes.func,
  profile: PropTypes.shape({
    avatar: PropTypes.shape({
      thumbnail_url: PropTypes.string
    })
  }),
  fetchActivities: PropTypes.func,
  tab: PropTypes.string
};

CreatePost.propTypes = {
  ...CreatePostForm.propTypes,
  postId: PropTypes.number,
  isEdit: PropTypes.bool
};

export default CreatePost;
