import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import useChat from "PFApp/use_chat";
import { gidFor } from "PFCore/helpers/gid";
import { openChatInMsTeams } from "PFCore/helpers/ms_teams";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { saveConversation } from "PFCore/services/chat/save_conversation";
import { saveParticipant } from "PFCore/services/chat/save_participant";
import { GIdTargetType, ProfileMinimized } from "PFTypes";

import { useActivityPageConversations } from "../../hooks/use_activity_page_conversations";

export const useActivityChat = () => {
  const { data: currentProfile } = useCurrentProfile();
  const { task } = useActivityPageContext();

  const { renderChat } = useChat();
  const { otherConversations, participantsOfConversations } = useActivityPageConversations(task);

  const createChatConversation = async (profileId: number) => {
    if (!task) {
      return;
    }
    const targetGid = gidFor(GIdTargetType.Task, task.id);
    const activeConversation = otherConversations?.find((conversationId) =>
      participantsOfConversations[conversationId.id].find((item) => item.profile.id === profileId)
    );

    if (activeConversation && activeConversation.id) {
      renderChat(activeConversation.id, {
        expanded: true
      });
    } else {
      const conversation = await saveConversation({
        target: targetGid,
        ownerId: currentProfile.id
      });
      await saveParticipant({ conversationId: conversation.id, profileId });
      renderChat(conversation.id, {
        expanded: true
      });
    }
  };

  return {
    startChat: (profile: ProfileMinimized) =>
      openChatInMsTeams(profile.email) || createChatConversation(profile.id)
  };
};
