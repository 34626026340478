import { useTranslation } from "react-i18next";

export const useErrorTypeLabels = () => {
  const { t } = useTranslation(["bookingModule", "translation"]);

  return {
    duration: t("translation:duration"),
    startDate: t("translation:startDate"),
    endDate: t("translation:endDate")
  };
};
