import isEmpty from "lodash/isEmpty";
import FiltersButton from "PFApp/components/filters/filters_button";
import { FiltersPanel, PagePrefix } from "PFApp/components/filters/filters_panel";
import FiltersPreview from "PFApp/components/filters/filters_preview";
import SortBy from "PFApp/components/sort_by";
import PropTypes from "prop-types";
import { useEffect } from "react";

import {
  checkFiltersAreClear,
  getAPIFilters,
  getValueFilters,
  handleRequestFiltersChange
} from "../../use_filtered_collection";
import css from "./search_filters.less";

const SearchFilters = ({
  pending,
  filtersShown,
  meta,
  toggleShown,
  applyRequestFilters,
  applyOrder,
  clearFilters,
  blockedList,
  children,
  initialFilters
}) => {
  useEffect(() => {
    if (initialFilters) {
      applyRequestFilters(initialFilters);
    }
  }, []);

  if (!meta?.filters) {
    return null;
  }

  const handleFilterChange = (filter, value) => {
    // API expects different format from the req and the response on the checkboxes filter
    // && value && value[0]?.id checks if it isn't the correct format (Array of id strings) already
    if (filter.type === "checkboxes" && value && value[0]?.id) {
      value = value.map(({ id }) => id);
    }

    // Clear sorting, so order by "availability match" is not left after availability is cleared
    if (filter.type === "availability" && isEmpty(value)) {
      applyOrder({});
    }
    const valueFilters = getValueFilters(meta.filters);
    const newFilters = handleRequestFiltersChange(valueFilters, filter, value);
    const apiFilters = getAPIFilters(newFilters);
    applyRequestFilters(apiFilters);
  };

  const handleFiltersChange = (filters) => {
    const apiFilters = getAPIFilters(filters);
    applyRequestFilters(apiFilters);
  };

  const areClear = checkFiltersAreClear(meta?.filters, blockedList);
  const toggle = () => meta.filters && toggleShown();

  return (
    <>
      <div className={css.filtersWrap}>
        <div className={css.filtersPreviewWrap}>
          <FiltersPreview
            meta={meta}
            filtersAreClear={areClear}
            clearFilters={clearFilters}
            onFilterChange={handleFilterChange}
            onMoreClicked={toggle}
            blockedList={["term"]}
          />
        </div>
        <div className={css.sortByWrap}>
          <SortBy
            sort={meta.order.value?.text}
            options={meta.order.options.map((option) => ({
              id: option.text,
              displayElement: option.text,
              item: option
            }))}
            onSortChange={(value) => {
              applyOrder(value);
            }}
          />
          <FiltersButton filtersAreClear={areClear} onClick={toggle} withLabel />
        </div>
      </div>
      {filtersShown && (
        <FiltersPanel
          keyPrefix={PagePrefix.Search}
          isLoading={pending}
          closeFilters={toggle}
          filtersAreClear={areClear}
          meta={meta}
          clearFilters={clearFilters}
          onFilterChange={handleFilterChange}
          onFiltersChange={handleFiltersChange}
          blockedList={blockedList}
        >
          {children}
        </FiltersPanel>
      )}
    </>
  );
};

SearchFilters.propTypes = {
  pending: PropTypes.bool,
  filtersShown: PropTypes.bool,
  meta: PropTypes.shape({ filters: PropTypes.array, order: PropTypes.object }),
  requestFilters: PropTypes.object,
  toggleShown: PropTypes.func,
  applyRequestFilters: PropTypes.func,
  applyOrder: PropTypes.func,
  clearFilters: PropTypes.func,
  children: PropTypes.node,
  blockedList: PropTypes.arrayOf(PropTypes.string),
  initialFilters: PropTypes.object
};

export default SearchFilters;
