import compact from "lodash/compact";
import { useTranslation } from "react-i18next";

import { useSliderContext } from "../context/slider_context";

export const useAriaLabels = () => {
  const { t } = useTranslation("core", { keyPrefix: "slider" });
  const { title, unit } = useSliderContext();

  const unitLabel = unit ? t("unit", { unit }) : "";
  const singleAriaLabel = compact([title, unitLabel]).join(" ");
  const minAriaLabel = compact([t("rangeStartLabel", { title }), unitLabel]).join(" ");
  const maxAriaLabel = compact([t("rangeEndLabel", { title }), unitLabel]).join(" ");

  return {
    singleAriaLabel,
    minAriaLabel,
    maxAriaLabel
  };
};
