import isEmpty from "lodash/isEmpty";
import { createContext, useContext } from "react";

export type CoreContextType = {
  dateFormat?: string;
  isAM?: boolean;
};

export const CoreContext = createContext({} as CoreContextType);

export const useCoreContext = () => {
  const context = useContext<CoreContextType>(CoreContext);
  if (isEmpty(context)) {
    throw new Error("Usage of CoreContext outside of CoreContext provider!");
  }
  return context;
};
