import {
  bookingsForUpdate,
  BookingsForUpdatePayload,
  BookingsForUpdateResponse
} from "PFCore/services/bookings_bulk_operation";

import { QueryOptionsWithCallbacks, useQueryWithCallbacks } from "../../use_query_with_callbacks";
import { bulkOperationKeys } from "./query_keys";

export type SelectionType =
  | "total_selection"
  | "filtered_selection"
  | "wall_planner"
  | "split_mode"
  | "profile_bookings";

export const useBookingsForUpdate = (
  selectionType: SelectionType,
  payload: BookingsForUpdatePayload,
  options: QueryOptionsWithCallbacks<BookingsForUpdateResponse>
) =>
  useQueryWithCallbacks<BookingsForUpdateResponse>({
    queryKey: bulkOperationKeys.forUpdate(selectionType, payload),
    queryFn: () => bookingsForUpdate(payload),
    ...options
  });
