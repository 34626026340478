import isEmpty from "lodash/isEmpty";
import css from "PFApp/activities/show/parts/activity_profiles/add_activity_profiles_modal_content.module.scss";
import { ProfileItem } from "PFApp/components/profile_item";
import { useGrowl } from "PFApp/use_growl";
import AutoSelect from "PFComponents/select/autoselect";
import api from "PFCore/api";
import { getProfileName } from "PFCore/helpers/profile";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useActivityInvalidate } from "PFCore/hooks/queries/activity";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { Activity, FeatureFlag } from "PFTypes";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { UseAddActivityProfilesReturn } from "./activity_profiles.types";

export const useAddAssigneeModal = ({ activity }: { activity: Activity }): UseAddActivityProfilesReturn => {
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("activities");
  const { t: tTranslation } = useTranslation();
  const { invalidate: invalidateActivities } = useActivityInvalidate();

  const growl = useGrowl();
  const isEnabled = useIsFeatureEnabled();
  const { getFirstError } = useResponseErrors();

  const taskAssignee = !isEmpty(activity.assignee?.profile) ? activity.assignee : null;
  const [assignee, setAssignee] = useState(taskAssignee?.profile);

  const description = (
    <div>
      <Trans i18nKey={"show.parts.changeAssigneeDescription"} t={t} components={[<strong key="0" />]} />
    </div>
  );

  useEffect(() => {
    setAssignee(taskAssignee?.profile);
  }, [taskAssignee?.profile]);

  const handleReset = () => {
    setAssignee(taskAssignee?.profile);
  };

  const handleSubmit = () => {
    if (!assignee) {
      if (taskAssignee) {
        return api({
          url: `assignees/${taskAssignee.id}`,
          method: "DELETE"
        })
          .then(() => {
            invalidateActivities([activity.id]).then(() => {
              growl({
                kind: "success",
                message: t("show.parts.assigneeRemoved")
              });
            });
          })
          .catch(() => {
            growl({
              kind: "error",
              message: t("show.parts.unableToRemoveAssignee")
            });
          });
      }

      return Promise.resolve();
    }

    return api({
      url: "assignees",
      method: "POST",
      data: {
        profileId: assignee.id,
        activityId: activity.id
      }
    })
      .then(() =>
        invalidateActivities([activity.id]).then(() => {
          growl({
            message: t("show.parts.profileAssigned", { profileName: getProfileName(assignee) }),
            kind: "success"
          });
        })
      )
      .catch((response) => {
        const error = getFirstError(response);
        growl({
          kind: "error",
          message: error || t("show.parts.profileAssigned", { profileName: getProfileName(assignee) })
        });
      });
  };

  const getModalContent = () => (
    <>
      <AutoSelect
        closeOnChange
        multi={false}
        displayValues={assignee ? <ProfileItem profile={assignee} /> : null}
        query={(term) => fetchProfilesOptions({ term, policyName: "resourcer" })}
        parseResponse={(response) => response.entries.filter((item) => !assignee || assignee.id !== item.id)}
        filterOptions={(response) =>
          response.filter((item) => currentProfile.id !== item.id || activity.coowned)
        }
        formatOption={(item) => ({
          id: item.id,
          displayElement: <ProfileItem profile={item} />,
          item: item
        })}
        handleChange={(profiles) => {
          setAssignee(profiles[0]);
        }}
        letClear={!isEnabled(FeatureFlag.RequireAssignee)}
        placeholder={tTranslation("search")}
      />
      {description && (
        <div className={css.subtitle}>
          <strong>{tTranslation("pleaseNote")}</strong>
          <br />
          <div className={css.description}>{description}</div>
        </div>
      )}
    </>
  );

  return { getModalContent, handleSubmit, enableSubmit: true, handleReset };
};
