import compact from "lodash/compact";
import LazyLocalExportTrigger from "PFApp/components/document_buttons/lazy_local_export_trigger";
import { useGrowl } from "PFApp/use_growl";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import { DropdownButton } from "PFComponents/dropdown_button";
import { Icon } from "PFComponents/icon";
import copyTextToClipboard from "PFCore/helpers/copyTextToClipboard";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useAttachments } from "PFCore/hooks/queries/attachments/use_attachments";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { downloadAttachment } from "PFCore/services/attachments/download_attachment";
import { getExportProfiles } from "PFCore/services/export_profiles";
import { UiRoute } from "PFCore/utilities/routes";
import { Attachment, FeatureFlag, GIdTargetType, PermissionRule, Profile } from "PFTypes";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import UploadCvModal from "../../upload_cv_modal";
import css from "./profile_actions_menu.module.scss";

type ProfileActionsMenuProps = {
  profile: Profile;
  isMe: boolean;
};

export const ProfileActionsMenu = ({ profile, isMe }: ProfileActionsMenuProps) => {
  const { t } = useTranslation("profiles");
  const triggerButtonRef = useRef<HTMLButtonElement>(null);
  const [renderUploadModal, setRenderUploadModal] = useState(false);
  const { data: currentProfile } = useCurrentProfile();

  const growl = useGrowl();
  const isEnabled = useIsFeatureEnabled();
  const isPermittedTo = useIsPermittedTo(currentProfile);
  const { data: currentAccount } = useCurrentAccount();

  const isProfileExportEnabled = isEnabled(FeatureFlag.ProfileExporter);
  const isDocumentExportPermitted = isPermittedTo(PermissionRule.ExportDocuments);
  const isCvParsingEnabled = currentAccount?.cv_parser_config?.enabled;

  const { data } = useAttachments({ targetType: GIdTargetType.Profile, targetId: profile.id });

  const handleCopyProfileLink = () => {
    const profileUrl = UiRoute(`/profiles/${profile.id}`);
    copyTextToClipboard(profileUrl)
      .then(() => growl({ message: t("show.parts.shareProfileSuccess"), kind: "success", ttl: 2500 }))
      .catch(() => growl({ message: t("unknownError"), kind: "error", ttl: 2500 }));
  };

  const handleDownloadAttachment = (attachment: Attachment) => {
    downloadAttachment({ id: attachment.id }).then(({ file_url: fileUrl }) => {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", attachment.file_name);

      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    });
  };

  const allowExport = profile && isProfileExportEnabled && isDocumentExportPermitted;
  const allowImportCV = isMe && isCvParsingEnabled;
  const attachments = data?.entries || [];

  const options: DropdownOption[] = compact([
    allowExport && {
      id: "export",
      displayElement: "Export",
      item: {
        func: () => triggerButtonRef.current?.click(),
        keepOpen: true
      }
    },
    allowImportCV && {
      id: "import",
      displayElement: t("show.parts.importCV"),
      item: { func: () => setRenderUploadModal(true) }
    },
    {
      id: "import",
      displayElement: (
        <div className={css.iconWithText}>
          {t("show.parts.shareProfile")}
          <Icon name="copy" size="sm" />
        </div>
      ),
      item: { func: handleCopyProfileLink },
      separator: attachments.length > 0
    },
    ...attachments.map((attachment) => ({
      id: attachment.id,
      displayElement: (
        <div className={css.iconWithText}>
          <Icon name="note" size="sm" />
          {attachment.file_name}
        </div>
      ),
      item: { func: () => handleDownloadAttachment(attachment) }
    }))
  ]);

  const handleChange = (item) => {
    item.func();
  };

  const handleClick = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <DropdownButton
        options={options}
        icon="menu-vertical"
        buttonKind="secondary"
        small
        aria-label={t("show.parts.profileActions")}
        handleChange={handleChange}
        handleClick={handleClick}
      />
      {renderUploadModal && <UploadCvModal handleModalClose={setRenderUploadModal} />}
      {allowExport && (
        <div className={css.hidden}>
          <LazyLocalExportTrigger
            triggerButtonRef={triggerButtonRef}
            buttonKind="blank"
            quantity={1}
            getProfilesCollection={() =>
              getExportProfiles({
                profileIds: [profile.id]
              }).then((resp) => ({
                entries: resp.map((profile) => ({
                  profile
                })),
                meta: { total: resp.length, page: 1 }
              }))
            }
          />
        </div>
      )}
    </>
  );
};
