import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EconomicsScenarioDetails, Id, MutationOptions } from "PFTypes";

import { deleteActivityEconomicsScenario } from "../../../services/activity";
import activityKeys from "./query_keys";

export const useActivityEconomicsScenarioDelete = (
  activityId: Id,
  options?: MutationOptions<EconomicsScenarioDetails>
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...queryOptions } = options || {};

  return useMutation<EconomicsScenarioDetails, unknown, any>({
    mutationFn: deleteActivityEconomicsScenario,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.economics(activityId) });
      onSuccess?.(...args);
    },
    ...queryOptions
  });
};
