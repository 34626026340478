import classNames from "classnames";
import inRange from "lodash/inRange";
import range from "lodash/range";
import { useRef } from "react";

import { useSliderContext } from "../../context/slider_context";
import { MultiRangeValue } from "../../multirange_slider";
import { getLeftOffset } from "../../sliders.common";
import css from "./steps.module.scss";

type StepsProps<Value> = {
  value: Value;
  inputWidth: number;
};

type GetStepIndexFromValue = {
  value: number;
  step: number;
  min: number;
};

const getStepIndexFromValue = ({ value, step, min }: GetStepIndexFromValue) =>
  Math.round((value - min) / step);

export const Steps = <Value extends number | MultiRangeValue>({ value, inputWidth }: StepsProps<Value>) => {
  const { min, max, step, multiRange, small } = useSliderContext();
  const ref = useRef<HTMLDivElement>(null);
  const selectionStartStepIndex = getStepIndexFromValue({
    value: multiRange ? (value as MultiRangeValue).min : min,
    step,
    min
  });
  const selectionEndStepIndex = getStepIndexFromValue({
    value: multiRange ? (value as MultiRangeValue).max : (value as number),
    step,
    min
  });
  const stepsCount = Math.floor((max - min) / step) + 1;
  const parentWidth = ref.current?.parentElement?.clientWidth ?? 0;

  return (
    <div className={css.root} style={{ width: parentWidth }} ref={ref}>
      {range(stepsCount).map((index) => {
        const leftOffset = getLeftOffset({ value: min + step * index, min, max, inputWidth, small });

        return (
          <div
            className={classNames(css.step, {
              [css.light]: inRange(index, selectionStartStepIndex, selectionEndStepIndex)
            })}
            style={{ left: leftOffset }}
            key={`${index}-${min}-${max}-${step}`}
          />
        );
      })}
    </div>
  );
};
