import { useIsMutating, useMutation, useQueryClient } from "@tanstack/react-query";
import { updateActivity } from "PFCore/services/activities";
import { ActivityData } from "PFTypes";

import activityKeys from "./query_keys";
import { useActivityInvalidate } from "./use_activity_invalidate";

const activityMutationsKeys = {
  update: (id: number) => [...activityKeys.activity(id), "update"]
};

export const useActivityUpdate = (id?: number) => {
  const cache = useQueryClient();
  const { invalidate: invalidateActivities } = useActivityInvalidate();

  const cacheKey = activityKeys.activity(id || -1);
  const mutationKey = id ? activityMutationsKeys.update(id) : ["disabled-activity-update-mutation"];

  const updateMutation = useMutation({
    mutationFn: async (activityData: ActivityData = {}) => {
      if (!id) {
        return;
      }

      await cache.cancelQueries({ queryKey: cacheKey });
      return updateActivity(id, activityData);
    },
    mutationKey,
    onSuccess: () => {
      if (!id) {
        return;
      }

      return Promise.all([cache.invalidateQueries({ queryKey: cacheKey }), invalidateActivities([id])]);
    }
  });

  const isUpdating = useIsMutating({
    mutationKey
  });

  return {
    mutation: updateMutation,
    update: updateMutation.mutateAsync,
    isUpdating
  };
};
