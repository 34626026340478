import { MutationFilters, useMutation, useQueryClient } from "@tanstack/react-query";
import isEmpty from "lodash/isEmpty";
import xor from "lodash/xor";
import { BookingResponse } from "PFApp/booking/components/booking_form/use_handle_submit/use_handle_submit";
import { UpdateBookingsPayload, updateManyBookings } from "PFApp/booking/services/api";
import { Collection, Id, MutationOptions } from "PFTypes";
import { useCallback } from "react";

import { useProfileInvalidate } from "../profile";
import { bookingMutationsKeys } from "./mutation_keys";
import { bookingKeys } from "./query_keys";

const mutationKey = bookingMutationsKeys.multiple();

export const useBookingsUpdateMany = (options?: MutationOptions<UpdateBookingsPayload>) => {
  const cache = useQueryClient();
  const { invalidateProfileWithBooking } = useProfileInvalidate();

  const updateMutation = useMutation<Collection<BookingResponse[]>, any, UpdateBookingsPayload>({
    mutationKey,
    mutationFn: async (payload: UpdateBookingsPayload) => {
      const bookingCacheKeys = payload.bookings.map(({ id }) => bookingKeys.single(id));
      await Promise.all(bookingCacheKeys.map((queryKey) => cache.cancelQueries({ queryKey })));
      return updateManyBookings(payload);
    },
    onSuccess: (result, payload) => {
      const bookingCacheKeys = payload.bookings.map(({ id }) => bookingKeys.single(id));

      return Promise.all([
        ...bookingCacheKeys.map((queryKey) => cache.invalidateQueries({ queryKey })),
        ...payload.bookings.map(({ id }) => invalidateProfileWithBooking(id))
      ]);
    },
    ...options
  });

  const checkIsUpdating = useCallback(
    (bookingId: number) =>
      cache.isMutating<MutationFilters<any, any, { id: Id }>>({
        predicate: (mutation) => {
          const isUpdateMutation = isEmpty(xor(mutationKey, mutation.options.mutationKey));
          return isUpdateMutation && mutation.state.variables?.id === bookingId;
        }
      }),
    [cache]
  );

  return {
    mutation: updateMutation,
    update: updateMutation.mutateAsync,
    checkIsUpdating
  };
};
