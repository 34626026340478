import flatten from "lodash/flatten";
import uniqBy from "lodash/uniqBy";
import { Accordion } from "PFComponents/accordion/accordion";
import { Button } from "PFComponents/button";
import { Icon } from "PFComponents/icon";
import { LoadingDots } from "PFComponents/loading_dots";
import AnalyticsPush from "PFCore/helpers/analytics_push";
import { getId } from "PFCore/helpers/get_id";
import useDebounce from "PFCore/helpers/use_debounce";
import { useKeywords } from "PFCore/hooks/queries/bookings/keywords/use_keywords";
import { KeywordsParams } from "PFCore/services/keywords";
import { Id, Keyword, PureCustomValue } from "PFTypes";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "../sections/sections.module.scss";

type MatchAttributesProps = {
  attributes: ((Keyword | PureCustomValue) & { created?: boolean })[];
  onMatchAttributeChange: (attr: Keyword[]) => void;
  name?: string;
  description?: string;
  usedOn: string;
  userId: Id;
  type: string;
};

const SUGGESTIONS_MAX = 5;

export const MatchAttributes = ({
  attributes,
  onMatchAttributeChange,
  type,
  name,
  description,
  usedOn,
  userId
}: MatchAttributesProps) => {
  const { t } = useTranslation("activities");
  const [showAllSuggestions, setShowAllSuggestions] = useState(false);
  const text = `${name} ${description}`;
  const attributesIds = useMemo(() => attributes.filter(({ created }) => !created).map(getId), [attributes]);

  const getParams = () => {
    const params: KeywordsParams = {
      text,
      matchTypes: [type]
    };
    if (attributesIds.length > 0) {
      params.selectedIds = attributesIds;
    }
    return params;
  };

  const [params, setParams] = useState(getParams());
  const debounceSetMatchAttributes = useDebounce(() => {
    setParams(getParams());
  }, 500);

  useEffect(() => {
    debounceSetMatchAttributes();
  }, [name, description, attributes]);

  const { data, isFetching } = useKeywords(params, {
    enabled: Boolean(Number(params.selectedIds?.length) > 0 || params.text?.trim())
  });

  const suggestions = useMemo(
    () =>
      ((data?.entries || []) as Keyword[])
        .filter((item) => !(attributesIds || []).includes(getId(item)))
        .slice(0, showAllSuggestions ? undefined : SUGGESTIONS_MAX),
    [attributesIds, data?.entries, showAllSuggestions]
  );

  const hasMoreSuggestions = (data?.entries?.length || 0) > suggestions.length;

  const handleMatchAttributeChange = (selectedAttribute) => {
    const dedupedAttributes = uniqBy(
      flatten([...attributes, selectedAttribute]),
      (attribute: Keyword | PureCustomValue) => ("value" in attribute ? attribute.value : attribute.text)
    );
    onMatchAttributeChange(dedupedAttributes);
  };

  if (suggestions.length === 0) {
    return null;
  }

  return (
    <div
      className={css.matchAttributes}
      style={{ opacity: isFetching ? 0.5 : 1 }}
      data-qa-id="attribute-suggestions"
    >
      <Accordion
        header={t("edit.sections.suggestedAttributes")}
        headerVariant="h5"
        actions={isFetching && <LoadingDots />}
        onStateChange={() => setShowAllSuggestions(false)}
      >
        <div className={css.subHeader}>{t("edit.sections.identifiedRelatedAttributes")}</div>
        <div className={css.suggestions}>
          {suggestions.map((suggestion) => (
            <Button
              className={css.matchAttributesButton}
              key={`${suggestion.id}+${suggestion.globalId}`}
              kind="autosuggest"
              onClick={() => {
                handleMatchAttributeChange(suggestion);
                AnalyticsPush(
                  `${usedOn}_recomendation`,
                  "click in activity suggested attributes",
                  JSON.stringify({
                    eventCategory: `${usedOn}_recomendation`,
                    eventAction: "click in activity suggested attributes",
                    userId: userId,
                    searchText: `${name} ${description}`,
                    recomendations: suggestions,
                    selectedRecomendation: suggestion.value,
                    index: suggestions.findIndex((item) => item.value === suggestion.value),
                    timestamp: new Date().getTime()
                  })
                );
              }}
            >
              <Icon name="add" size="xs" />
              {suggestion.value}
            </Button>
          ))}
          {hasMoreSuggestions && (
            <Button kind="text" onClick={() => setShowAllSuggestions(true)}>
              ...
            </Button>
          )}
        </div>
      </Accordion>
    </div>
  );
};
