import every from "lodash/every";
import { useTemplateFind } from "PFApp/hooks";
import { useFillAndBookAction } from "PFApp/hooks/use_fill_and_book_action";
import { useRoleProfileActionsPermissions } from "PFApp/hooks/use_role_profile_actions_permissions";
import { useShortlistAndFillAction } from "PFApp/hooks/use_shortlist_and_fill_action";
import { useGrowl } from "PFApp/use_growl";
import { useActivityEconomicsScenarioInvalidate } from "PFCore/hooks/queries/activity/use_activity_economics_scenario_invalidate";
import { useActivitiesShortlists } from "PFCore/hooks/queries/shortlists/use_activities_shortlists";
import { useShortlistBook } from "PFCore/hooks/queries/shortlists/use_shortlist_actions";
import {
  Activity,
  CamelizedShortlistMinimized,
  EconomicsScenarioDetails,
  EconomicsVacancyProfile,
  Id,
  ShortlistMinimized
} from "PFTypes";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getActivityFillAndBookData } from "./utils";

type UseScenarioFillAndBookProps = {
  activities: Activity[];
  selectedVacancies: Id[];
  setSelectedVacancies: Dispatch<SetStateAction<Id[]>>;
  scenario?: EconomicsScenarioDetails;
};

export const useScenarioFillAndBook = ({
  activities,
  scenario,
  selectedVacancies,
  setSelectedVacancies
}: UseScenarioFillAndBookProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario" });
  const { invalidate: invalidateScenario } = useActivityEconomicsScenarioInvalidate();
  const { handleFillAndBook: handleFillAndBookAction } = useFillAndBookAction();
  const { handleFill: handleFillAction } = useShortlistAndFillAction();
  const { mutateAsync: bookShortlist } = useShortlistBook();
  const growl = useGrowl();
  const findTemplate = useTemplateFind();
  const { results: shortlistsData, isLoading: isLoadingShortlists } = useActivitiesShortlists(
    activities.map(({ id }) => id)
  );
  const { checkCanFill, checkCanFillAndBook } = useRoleProfileActionsPermissions();

  const allVacancies = useMemo(() => scenario?.vacancies || [], [scenario?.vacancies]);
  const template = findTemplate({ name: "Role" });
  const isAutoBookingCreationEnabled = !!template?.automations?.auto_create_bookings?.enabled;
  const hasFillAndBookButton =
    isAutoBookingCreationEnabled &&
    !!activities.length &&
    every(activities, (activity) => !!activity.metadata.availability);

  const handleFillAndBook = async () => {
    const promises: Promise<CamelizedShortlistMinimized | ShortlistMinimized | undefined>[] = [];

    activities.forEach((activity) => {
      const {
        shortlists,
        openVacancies,
        selectedAssignees: assigneesToFillAndBook,
        assigneesToBook
      } = getActivityFillAndBookData(activity.id, shortlistsData, allVacancies, selectedVacancies);

      assigneesToFillAndBook.forEach((profile, index) => {
        const openVacancy = openVacancies[index];
        const shortlist = shortlists?.find((shortlist) => profile.id === shortlist.profile.id);

        const promise = handleFillAndBookAction({
          activity,
          profileId: profile.id,
          vacancyId: openVacancy.id,
          shortlistId: shortlist?.id
        });
        promises.push(promise);
      });

      assigneesToBook.forEach((profile) => {
        const shortlist = shortlists?.find((shortlist) => profile?.id === shortlist.profile.id);
        const promise = bookShortlist({ id: shortlist!.id });
        promises.push(promise);
      });
    });

    Promise.allSettled(promises).then((responses) => {
      const successfulResponsesCount = responses.filter((response) => response.status === "fulfilled").length;

      scenario && invalidateScenario(scenario?.id);
      setSelectedVacancies([]);

      if (successfulResponsesCount > 0) {
        growl({
          message: t("vacancyClosedAndBookingsCreated", { count: successfulResponsesCount })
        });
      }
    });
  };

  const handleFill = () => {
    const promises: Promise<CamelizedShortlistMinimized | undefined>[] = [];

    activities.forEach((activity) => {
      const {
        shortlists,
        selectedAssignees: assigneesToFill,
        openVacancies
      } = getActivityFillAndBookData(activity.id, shortlistsData, allVacancies, selectedVacancies);

      assigneesToFill.forEach((profile, index) => {
        const openVacancy = openVacancies[index];
        const shortlist = shortlists?.find((shortlist) => profile.id === shortlist.profile.id);
        const promise = handleFillAction({
          activity,
          profileId: profile.id,
          vacancyId: openVacancy.id,
          shortlistId: shortlist?.id
        });
        promises.push(promise);
      });
    });

    Promise.allSettled(promises).then((responses) => {
      const successfulResponsesCount = responses.filter((response) => response.status === "fulfilled").length;

      scenario && invalidateScenario(scenario?.id);
      setSelectedVacancies([]);

      if (successfulResponsesCount > 0) {
        growl({
          message: t("vacancyFilled", { count: successfulResponsesCount })
        });
      }
    });
  };

  const checkCanSelectToFillAndBook = useCallback(
    (roleId: number, profile: EconomicsVacancyProfile) => {
      const { shortlists } = getActivityFillAndBookData(
        roleId,
        shortlistsData,
        allVacancies,
        selectedVacancies
      );

      const shortlist = shortlists.find((shortlist) => shortlist.profile.id === profile.id);

      const role = activities.find(({ id }) => id === roleId);
      const canFillAndBook = !!role && checkCanFillAndBook(role, profile, shortlist);

      return canFillAndBook;
    },
    [activities, allVacancies, checkCanFillAndBook, selectedVacancies, shortlistsData]
  );

  const checkCanSelectToFill = useCallback(
    (roleId: number, profile: EconomicsVacancyProfile) => {
      const { shortlists } = getActivityFillAndBookData(
        roleId,
        shortlistsData,
        allVacancies,
        selectedVacancies
      );

      const shortlist = shortlists.find((shortlist) => shortlist.profile.id === profile.id);
      const role = activities.find(({ id }) => id === roleId);
      const canFill = !!role && checkCanFill(role, profile, shortlist);

      return canFill;
    },
    [activities, allVacancies, checkCanFill, selectedVacancies, shortlistsData]
  );

  const fillAndBookCounter = activities
    .map(({ id }) => {
      const { assigneesToBook } = getActivityFillAndBookData(
        id,
        shortlistsData,
        allVacancies,
        selectedVacancies
      );

      return assigneesToBook.length;
    })
    .reduce((sum, count) => sum + count, selectedVacancies.length);

  return {
    hasFillAndBookButton,
    fillAndBookCounter,
    isLoadingShortlists,
    handleFillAndBook,
    handleFill,
    checkCanSelectVacancy: hasFillAndBookButton ? checkCanSelectToFillAndBook : checkCanSelectToFill
  };
};
