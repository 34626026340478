import { ColumnHelper } from "@tanstack/react-table";
import includes from "lodash/includes";
import { Checkbox } from "PFComponents/checkbox/checkbox";
import Tooltip from "PFComponents/tooltip/tooltip";
import { EconomicsVacancy, EconomicsVacancyProfile, Id, Shortlist, ShortlistState } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const FILLED_SHORTLIST_STATES: ShortlistState[] = ["filled", "booked", "confirmed"];

type UseCheckboxColumn = {
  selectedVacancies: Id[];
  hasUnassignedVacancies: boolean;
  hasOpenVacancies: boolean;
  shortlists: Shortlist[];
  isEditable: boolean;
  onSelectVacancy: (vacancyId: Id, select: boolean) => void;
  checkCanSelectVacancy: (roleId: number, profile: EconomicsVacancyProfile) => boolean;
};

export const useCheckboxColumn = ({
  selectedVacancies,
  hasUnassignedVacancies,
  hasOpenVacancies,
  shortlists,
  isEditable,
  onSelectVacancy,
  checkCanSelectVacancy
}: UseCheckboxColumn) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.scenario.assignmentTable" });

  const getCheckboxDisabledState = useCallback(
    (
      isAssigneeFilled: boolean,
      noVacancyToAssign: boolean,
      noOpenVacancy: boolean,
      isSelectPermitted: boolean
    ) => {
      if (isAssigneeFilled) {
        return { disabled: true, message: t("workforceMemberAlreadyFilled") };
      }

      if (noOpenVacancy) {
        return { disabled: true, message: t("noOpenVacancies") };
      }

      if (noVacancyToAssign) {
        return { disabled: true, message: t("deselectSomeoneToChooseThisPerson") };
      }

      if (!isSelectPermitted) {
        return { disabled: true, message: t("operationNotPermitted") };
      }

      return { disabled: false, message: null };
    },
    [t]
  );

  return useCallback(
    (columnHelper: ColumnHelper<EconomicsVacancy>) =>
      columnHelper.accessor((row) => row, {
        id: "checkbox",
        header: "",
        cell: (info) => {
          const vacancy = info.getValue();
          const isSelected = includes(selectedVacancies, vacancy.id);
          const isAssigneeFilled = !!shortlists.find(
            ({ profile, state }) =>
              profile.id === vacancy.assignment!.profile!.id && FILLED_SHORTLIST_STATES.includes(state)
          );
          const checked = isAssigneeFilled || isSelected;
          const noVacancyToAssign = !checked && !hasUnassignedVacancies;
          const noOpenVacancy = !checked && !hasOpenVacancies;
          const isSelectPermitted =
            checkCanSelectVacancy(vacancy.activity.id, vacancy.assignment!.profile!) && isEditable;

          const { disabled, message: tooltipMessage } = getCheckboxDisabledState(
            isAssigneeFilled,
            noVacancyToAssign,
            noOpenVacancy,
            isSelectPermitted
          );

          return (
            <Tooltip content={tooltipMessage} disabled={!tooltipMessage}>
              <div>
                <Checkbox
                  checked={checked}
                  disabled={disabled}
                  onChange={(value) => {
                    onSelectVacancy(vacancy.id, value);
                  }}
                />
              </div>
            </Tooltip>
          );
        },
        enableSorting: false,
        size: 30
      }),
    [
      checkCanSelectVacancy,
      getCheckboxDisabledState,
      hasOpenVacancies,
      hasUnassignedVacancies,
      isEditable,
      onSelectVacancy,
      selectedVacancies,
      shortlists
    ]
  );
};
