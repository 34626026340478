import { keepPreviousData } from "@tanstack/react-query";
import ShareBar from "PFApp/components/activity_share/share_bar";
import FiltersButton from "PFApp/components/filters/filters_button";
import { FiltersPanel, PagePrefix } from "PFApp/components/filters/filters_panel";
import { ProfileAvatarConnected } from "PFApp/components/profile_avatar_connected";
import { RolesTogglesControls } from "PFApp/components/roles/roles_toggles_controls";
import SortBy from "PFApp/components/sort_by";
import { MatchingRelationType } from "PFApp/constants/matching_relation_type";
import { AvailabilityContent, AvailabilityContext } from "PFApp/profiles/common/availability";
import { getAPIFilters, getValueFilters, useMetaFilters } from "PFApp/use_filtered_collection";
import { useGrowl } from "PFApp/use_growl";
import useIsAvailabilityPermitted from "PFApp/use_is_availability_permitted";
import { LoadingDots } from "PFComponents/loading_dots";
import PageTitle from "PFComponents/page/page_title";
import { SimplePagination } from "PFComponents/pagination/simple_pagination";
import { getProfileName } from "PFCore/helpers/profile";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useProfileMatchingRoles } from "PFCore/hooks/queries/profile/use_profile_matching_roles";
import { Id } from "PFTypes";
import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useRolesStatistics } from "../../marketplace/hooks/use_roles_statistics";
import { PageSection } from "../show/parts/page_section";
import BookingList from "./booking_list";
import { useProfileMatchingRolesQueryParams } from "./hooks/use_profile_matching_roles_query_params";
import { MatchingRole } from "./matching_role";
import css from "./profile_matching_roles_page.module.scss";

const MatchingRolesPage = ({ profile }) => {
  const { data: currentProfile } = useCurrentProfile();
  const { t } = useTranslation("profiles");

  const isAvailabilityPermitted = useIsAvailabilityPermitted(profile);
  const growl = useGrowl();

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [rolesToShare, setRolesToShare] = useState<Id[]>([]);
  const [requirementsOn, setRequirementsOn] = useState(true);
  const [availabilityOn, setAvailabilityOn] = useState(true);

  const { updateOrder, updatePage, updateFilters, updateRelation, relationType, order, ...params } =
    useProfileMatchingRolesQueryParams();

  const { data: matchingRoles, isLoading } = useProfileMatchingRoles(
    { profileId: profile.id, order, relationType, ...params },
    {
      placeholderData: keepPreviousData,
      onError: () => {
        growl({ message: t("matchingRoles.errorRetrievingMatches"), kind: "error" });
      }
    }
  );

  const rolesStatistics = useRolesStatistics(matchingRoles?.entries, ["shares"]);

  const meta = useMemo(
    () => matchingRoles?.meta ?? { total: 0, page: 0, perPage: 0, totalPages: 0 },
    [matchingRoles?.meta]
  );

  const defaultFilters = useMemo(
    () => getValueFilters(meta.filters, (key, filter) => filter?.hidden),
    [meta.filters]
  );

  const setFilters = useCallback(
    (updatedFilters) => {
      updateFilters(getAPIFilters(updatedFilters));
      updatePage(1);
    },
    [updateFilters, updatePage]
  );

  const { clearFilters, updateFilter, noFiltersSelected, selectedFilters } = useMetaFilters(
    meta,
    setFilters,
    defaultFilters
  );

  const onInvite = useCallback(() => {
    growl({
      message: t("matchingRoles.roleAssignedSuccess", { name: getProfileName(profile) }),
      kind: "success"
    });
  }, [growl, profile, t]);

  const handleRelationTypeChange = useCallback(
    (relation: MatchingRelationType) => {
      updatePage(1);
      clearFilters();
      setFiltersOpen(false);
      updateRelation(relation);
    },
    [clearFilters, updatePage, updateRelation]
  );

  const handleShare = (id: Id) =>
    rolesToShare.includes(id)
      ? setRolesToShare(rolesToShare.filter((roleId) => roleId !== id))
      : setRolesToShare(rolesToShare.concat(id));

  return (
    <>
      <ShareBar activityIds={rolesToShare} onCancel={() => setRolesToShare([])} activityType="role" />
      {profile && isAvailabilityPermitted && (
        <PageSection
          title={
            <div className={css.summary}>
              <ProfileAvatarConnected profile={profile} size={50} />
              <h3 className={css.summaryName}>{getProfileName(profile)}</h3>
            </div>
          }
        >
          <AvailabilityContext profile={profile} isAdmin={false}>
            <div className={css.availabilitySection}>
              <div className={css.availabilityCalendar}>
                <AvailabilityContent activeViews={["calendar"]} profile={profile} readOnly={true} />
              </div>
              <div className={css.bookingsContainer}>
                <BookingList />
              </div>
            </div>
          </AvailabilityContext>
        </PageSection>
      )}
      <div className={isLoading ? css.loading : ""}>
        <PageSection className={css.section} contentStyle={{ overflow: "visible" }}>
          <PageTitle
            actions={
              <div className={css.matchesActions}>
                {meta.order && (
                  <SortBy
                    options={meta.order.options.map((option) => ({
                      item: option,
                      displayElement: option.text,
                      id: option.text
                    }))}
                    onSortChange={updateOrder}
                    sort={meta.order.value?.text}
                  />
                )}
                <FiltersButton
                  filtersAreClear={noFiltersSelected}
                  onClick={() => setFiltersOpen(!filtersOpen)}
                />
                <RolesTogglesControls
                  relationType={relationType}
                  onRelationTypeChange={handleRelationTypeChange}
                  disabled={isLoading}
                  requirementsOn={requirementsOn}
                  setRequirementsOn={setRequirementsOn}
                  availabilityOn={availabilityOn}
                  setAvailabilityOn={setAvailabilityOn}
                />
              </div>
            }
          >
            {t("matchingRoles.title")}
          </PageTitle>
          {filtersOpen && selectedFilters && (
            <div className={css.matchesFilters}>
              <FiltersPanel
                keyPrefix={PagePrefix.ProfileMatchingRoles}
                isLoading={isLoading}
                closeFilters={() => setFiltersOpen(false)}
                filtersAreClear={noFiltersSelected}
                meta={meta}
                clearFilters={clearFilters}
                onFilterChange={updateFilter}
                onFiltersChange={setFilters}
              />
            </div>
          )}
          {!isLoading && matchingRoles?.entries.length === 0 && t("matchingRoles.noMatchingRoles")}
          {matchingRoles?.entries?.length === 0 && isLoading && (
            <LoadingDots circlesEnabled className={css.loadingDots} />
          )}
          {matchingRoles?.entries.map((match) => (
            <MatchingRole
              key={match?.activity?.id}
              profile={profile}
              currentProfile={currentProfile}
              match={match}
              statistics={rolesStatistics?.[match?.activity?.id]}
              onInvite={onInvite}
              isBlindModeOn
              handleShare={handleShare}
              rolesToShare={rolesToShare}
            />
          ))}
        </PageSection>
      </div>
      {meta.totalPages > 1 && (
        <SimplePagination
          currentPage={params.page}
          handleClick={updatePage}
          totalPages={meta.totalPages}
          disabled={isLoading}
        />
      )}
      <div className={css.space}></div>
    </>
  );
};

MatchingRolesPage.propTypes = {
  profile: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.object)
};

export default MatchingRolesPage;
