import {
  fetchProfileMatchingRoles,
  FetchProfileMatchingRolesParams,
  FetchProfileMatchingRolesResponse
} from "../../../services/profile";
import { QueryOptionsWithCallbacks, useQueryWithCallbacks } from "../use_query_with_callbacks";
import profileKeys from "./query_keys";

export const useProfileMatchingRoles = (
  params: FetchProfileMatchingRolesParams,
  options: QueryOptionsWithCallbacks<FetchProfileMatchingRolesResponse> = {}
) =>
  useQueryWithCallbacks<FetchProfileMatchingRolesResponse>({
    queryKey: profileKeys.matchingRoles(params),
    queryFn: () => fetchProfileMatchingRoles(params),
    retry: false,
    ...options
  });
