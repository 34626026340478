import { useQueryClient } from "@tanstack/react-query";

import { historyKeys } from "./query_keys";

export const useBookingHistoryInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidate: (bookingId) => queryClient.invalidateQueries({ queryKey: historyKeys.ofBooking(bookingId) })
  };
};
