import map from "lodash/map";
import reduce from "lodash/reduce";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCustomValues } from "PFCore/hooks/queries/custom_values/use_custom_values";
import { ActivityConnectionsEvent } from "PFTypes";

export const useHumanizedAcitivityConnectionEventDiff = (diff: ActivityConnectionsEvent["diff"]) => {
  const { getCustomTypeById } = useCustomTypes();
  const { data: skillsCustomValues } = useCustomValues({
    ids: map(diff, ({ customValueId }) => customValueId)
  });

  const customValuesLabels = reduce(
    skillsCustomValues?.entries,
    (acc, { id, text, value }) => ({ ...acc, [id]: text || value }),
    {}
  );

  const humanizedDiff = reduce(
    diff,
    (acc, { customTypeId, customValueId, diff }) => {
      const customType = getCustomTypeById(customTypeId);
      const customTypeName = customType?.display_as || customType?.name;
      const customValueName = customValuesLabels[customValueId];

      if (!customTypeName || !customValueName) {
        return acc;
      }

      return { ...acc, [customTypeName]: { ...(acc[customTypeName] || {}), [customValueName]: diff } };
    },
    {}
  );

  return humanizedDiff;
};
