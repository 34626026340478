import isNil from "lodash/isNil";
import api from "PFCore/api";
import { Id, VacanciesCollection, Vacancy } from "PFTypes";

type CloseVacancyParams = {
  activityId: Id;
  vacancyId: Id;
  closureReason: string;
  closureDetails?: string;
  profileId?: Id;
};

export type ReopenVacancyParams = {
  activityId: Id;
  vacancyId: Id;
};

export type FetchVacanciesParams = {
  activityId: Id;
  page?: number;
  perPage?: number;
};

export const closeVacancy = ({
  vacancyId,
  activityId,
  closureReason,
  profileId,
  closureDetails
}: CloseVacancyParams): Promise<Vacancy> =>
  api({
    url: `vacancies/${vacancyId}/close`,
    method: "PUT",
    data: {
      activityId,
      closureReason,
      ...(!isNil(profileId) ? { profileId } : {}),
      ...(!isNil(closureDetails) ? { closureDetails } : {})
    }
  });

export const reopenVacancy = ({ vacancyId, activityId }: ReopenVacancyParams): Promise<Vacancy> =>
  api({
    url: `vacancies/${vacancyId}/reopen`,
    method: "PUT",
    data: { activityId }
  });

export const fetchVacancies = ({
  activityId,
  page = 1,
  perPage = 5
}: FetchVacanciesParams): Promise<VacanciesCollection> =>
  api({
    url: "vacancies",
    params: { activityId, page, perPage }
  });
