import { keepPreviousData } from "@tanstack/react-query";
import { useAuditRoles } from "PFCore/hooks/queries/roles/use_audit_roles";
import { useRoles } from "PFCore/hooks/queries/roles/use_roles";
import { FetchRolesParams, FetchRolesResponse } from "PFCore/services/roles";
import { useMemo, useState } from "react";

import { useActivityPageContext } from "../activity_page_context";

const DEFAULT_ROLES = {
  entries: [],
  meta: { total: 0, page: 1, perPage: 25, totalPages: 1 }
};

type UseActivityPageRolesReturn = {
  roles: FetchRolesResponse;
  changePage: (params: FetchRolesParams) => void;
};

export const useActivityPageRoles = (): UseActivityPageRolesReturn => {
  const { task, isEngagement, roleKey } = useActivityPageContext();

  const [params, setParams] = useState<FetchRolesParams>({
    page: 1,
    perPage: 25
  });

  const payload = useMemo(
    () => ({
      ...params,
      filters: {
        fields: {
          parentActivityId: task.id,
          state: ["draft", "new", "complete"]
        }
      }
    }),
    [params, task.id]
  );

  const isKindRole = roleKey === "role";

  const { data: roles } = useRoles(payload, {
    placeholderData: keepPreviousData,
    enabled: isEngagement && task.coowned && isKindRole
  });

  const { data: auditRoles } = useAuditRoles(payload, {
    placeholderData: keepPreviousData,
    enabled: isEngagement && task.coowned && !isKindRole
  });

  const changePage = (params: FetchRolesParams) => {
    setParams(params);
  };

  return {
    roles: (isKindRole ? roles : auditRoles) || DEFAULT_ROLES,
    changePage
  };
};
