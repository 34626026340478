import { useIsMutating, useMutation, useQueryClient } from "@tanstack/react-query";
import { updateBookingTemplate, UpdateBookingTemplatePayload } from "PFApp/booking/services/api";
import { MutationOptions } from "PFTypes/request_options";

import { bookingTemplateKeys } from "../query_keys";
import { useBookingTemplateInvalidate } from "./use_booking_template_invalidate";

const mutationKey = ["booking-template-update"];

export const useBookingTemplateUpdate = (options?: MutationOptions<UpdateBookingTemplatePayload>) => {
  const cache = useQueryClient();
  const { invalidate: invalidateBookingTemplates } = useBookingTemplateInvalidate();

  const updateMutation = useMutation({
    mutationFn: async (data: UpdateBookingTemplatePayload) => {
      const bookingTemplateCacheKey = bookingTemplateKeys.single(data.id);
      await cache.cancelQueries({ queryKey: bookingTemplateCacheKey });
      return updateBookingTemplate(data);
    },
    mutationKey,
    onSuccess: (response, payload) => {
      const { id } = payload;
      if (id === undefined) {
        return;
      }

      return invalidateBookingTemplates(id);
    },
    ...options
  });

  const isUpdating = useIsMutating({
    mutationKey
  });

  return {
    mutation: updateMutation,
    update: updateMutation.mutateAsync,
    isUpdating
  };
};
