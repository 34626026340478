import { useRef } from "react";

import css from "../../../activity_page.module.scss";
import { MatchesPanel, MatchesSidePanel } from "./parts";

export const ActivityMatches = () => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={scrollableContainerRef} className={css.pageCardContainer}>
      <MatchesSidePanel scrollableContainerRef={scrollableContainerRef} />
      <MatchesPanel />
    </div>
  );
};
