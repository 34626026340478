import isEmpty from "lodash/isEmpty";
import memoize from "lodash/memoize";
import { CalendarMode } from "PFApp/booking/types";
import { minutesToHours } from "PFCore/helpers/date";
import { ProjectItem } from "PFCore/hooks/queries/bookings/projects/use_projects_entries";
import { WorkforceItem } from "PFCore/hooks/queries/bookings/workforce/use_workforce_entries";
import { CurrentProfile } from "PFTypes";

import { Row } from "../../types";
import { ProfileBasic, RowOriginal, RowResource, RowType } from "../../types/row";

const getTypeFromTemplateKey = memoize(
  (templateId: number, currentProfile: CurrentProfile): RowType => {
    const templateKey = currentProfile.templates.find(({ id }) => id === templateId)?.key;
    if (templateKey?.includes("role")) {
      return RowType.Role;
    }
    if (templateKey?.includes("engagement")) {
      return RowType.Engagement;
    }
    throw new Error(`Unexpected activity template! [templateId=${templateId}]`);
  },
  (id) => id
);

export const getRowType = (
  item: RowOriginal,
  currentProfile: CurrentProfile,
  mode: CalendarMode
): RowType => {
  if ("bookingCategoryIds" in item) {
    return RowType.Category;
  }
  if (mode === CalendarMode.Projects) {
    if ("first_name" in item || "last_name" in item) {
      return RowType.Profile;
    } else {
      return getTypeFromTemplateKey((item as ProjectItem).activity.template_id, currentProfile);
    }
  } else {
    if ("profile" in item && !("activity" in item)) {
      return RowType.Profile;
    } else {
      // can be Role or Engagement
      return getTypeFromTemplateKey((item as ProjectItem).activity.template_id, currentProfile);
    }
  }
};

export const getStateFromChildren = ({
  total,
  booked
}: {
  total: number;
  booked: number;
}): "booked" | "partially_booked" | "not_booked" =>
  total - booked === 0 ? "booked" : booked > 0 ? "partially_booked" : "not_booked";

const getRowTotalChildren = (item: ProjectItem, type: RowType): number => {
  if (type === RowType.Role) {
    return item.activity.vacancies?.total || 0;
  }
  if (type === RowType.Engagement) {
    return Object.values(item.activity?.children_workflow_states_tally || {}).reduce(
      (curr, acc) => curr + acc,
      0
    );
  }
  return 0;
};

export const sumBookedChildren = (data: Record<string, number>) =>
  (data.booked || 0) + (data.confirmed || 0) + (data.exception || 0);

const getChildrenBooked = (item: ProjectItem, type: RowType): number => {
  const stats =
    (type === RowType.Engagement ? item.activity.children_workflow_states_tally : item.activity.vacancies) ||
    {};
  return sumBookedChildren(stats);
};

export const getChildrenCount = ({
  original,
  type
}: Row): {
  total: number;
  booked: number;
} => {
  if (!("activity" in original)) {
    return { booked: 0, total: 0 };
  }
  return {
    booked: getChildrenBooked(original, type),
    total: getRowTotalChildren(original, type)
  };
};

const getKey = (item: RowOriginal, type: RowType, parents?: string[]): string => {
  if (!isEmpty(parents)) {
    return [...(parents || []), `${type}_${item.id}`].join("_");
  }
  return `${type}_${item.id}`;
};

export const getRowFromDataItem = (
  item: RowOriginal,
  currentProfile: CurrentProfile,
  mode: CalendarMode,
  parents?: string[]
): Row => {
  const type = getRowType(item, currentProfile, mode);
  const key = getKey(item, type, parents);
  return {
    key,
    type,
    original: item,
    parents: parents || []
  };
};

export const calculateHoursBooked = (items: { duration: number }[]): number =>
  minutesToHours((items || []).reduce((acc, booking) => acc + booking.duration || 0, 0));

export const getOriginalResource = ({ type, original }: Row, mode: CalendarMode): RowResource => {
  switch (type) {
    case RowType.Profile:
      return mode === CalendarMode.Workforce
        ? (original as WorkforceItem).profile
        : (original as ProfileBasic);
    case RowType.Role:
    case RowType.Engagement:
      return (original as ProjectItem).activity;
    case RowType.Category:
    default:
      return null;
  }
};
