import { useQuery } from "@tanstack/react-query";
import { CustomValuesQueryParams, fetchCustomValues } from "PFCore/services/custom_values";
import { Collection, PureCustomValue } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { customValuesKeys } from "./query_keys";

export const useCustomValues = (
  params: CustomValuesQueryParams,
  options: QueryOptions<Collection<PureCustomValue[]>> = {}
) =>
  useQuery<Collection<PureCustomValue[]>>({
    queryKey: customValuesKeys.list(params),
    queryFn: () => fetchCustomValues(params),
    ...options
  });
