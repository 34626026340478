import { GetNextPageParamFunction } from "@tanstack/react-query";
import { PaginatedCollection } from "PFTypes";

export const getNextPageParam: GetNextPageParamFunction<number, PaginatedCollection<any>> = (
  lastPageResponse
) => {
  const { meta } = lastPageResponse;
  if (meta.page < meta.totalPages) {
    return meta.page + 1;
  }
};
