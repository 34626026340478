import isFunction from "lodash/isFunction";
import {
  Dispatch,
  ForwardedRef,
  forwardRef,
  SetStateAction,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useRef
} from "react";
import { UseFormRegisterReturn } from "react-hook-form";

export type TextAreaProps = Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, "onChange"> & {
  autofocus?: boolean;
  minHeight?: number;
  maxHeight?: number;
} & (
    | {
        register?: never;
        onChange?:
          | ((value: TextareaHTMLAttributes<HTMLTextAreaElement>["value"], event?: Event) => void)
          | Dispatch<SetStateAction<TextareaHTMLAttributes<HTMLTextAreaElement>["value"]>>;
      }
    | {
        onChange?: never;
        register: UseFormRegisterReturn;
      }
  );

export const TextArea = forwardRef(
  (
    { maxHeight = 200, minHeight = 60, register, style = {}, onChange, autofocus, ...props }: TextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>
  ) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const autoSize = () => {
      if (inputRef.current) {
        const { current } = inputRef;
        const textLength = current.value.length;
        const newLinesMatches = current.value.match(/\n/g);
        const newLines = newLinesMatches ? newLinesMatches.length : 0;

        if (textLength < 50 && newLines === 0) {
          current.style.height = "0";
        } else if (textLength < 30 && newLines < 2) {
          current.style.height = "0";
        } else {
          current.style.height = `${inputRef.current.scrollHeight}px`;
        }
      }
    };

    useEffect(() => {
      autoSize();
    }, [minHeight, maxHeight, props.value, style]);

    useEffect(() => {
      if (inputRef.current && autofocus) {
        inputRef.current.focus();
      }
      if (isFunction(ref)) {
        ref(inputRef.current);
      } else if (ref) {
        ref.current = inputRef.current;
      }
    }, []);

    const handleChange = useCallback(
      (event) => {
        const { value } = event.target;

        onChange?.(value, event);
      },
      [onChange]
    );

    return (
      <textarea
        ref={inputRef}
        style={{ minHeight, maxHeight, resize: "none", ...style }}
        onChange={handleChange}
        {...props}
        {...register}
      />
    );
  }
);

TextArea.displayName = "TextArea";
