import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { getGrowlId, useGrowl } from "PFApp/use_growl";
import { useShortlistCreate } from "PFCore/hooks/queries/shortlists/use_shortlist_create";
import { useShortlistDelete } from "PFCore/hooks/queries/shortlists/use_shortlist_delete";
import { useShortlistsInvalidate } from "PFCore/hooks/queries/shortlists/use_shortlists_invalidate";
import { Shortlist } from "PFTypes";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

const CLEAR_SHORTLIST_SUCCESS_ACTION_ID = getGrowlId("clear-shortlist");

export const useClearShortlist = () => {
  const { t } = useTranslation(["activities", "translation"]);
  const { task: activity, clearComparisonToast, allShortlists } = useActivityPageContext();
  const { mutateAsync: deleteShortlist, isPending: isDeletingShortlist } = useShortlistDelete({
    withInvalidate: false,
    activityId: activity!.id
  });
  const { invalidateShortlists } = useShortlistsInvalidate();
  const { mutateAsync: createShortlist, isPending: isCreatingShortlist } = useShortlistCreate({}, false);
  const growl = useGrowl();

  const unRemoveShortlistItems = useCallback(
    (shortlistsToUndo: Shortlist[]) => {
      const promises = shortlistsToUndo.map((shortlist) => {
        const {
          activity_id: activityId,
          profile: { id: profileId }
        } = shortlist;

        return createShortlist({ activityId, profileId });
      });

      Promise.all(promises)
        .then(() => {
          invalidateShortlists();
        })
        .catch(() => {
          invalidateShortlists();
        });
    },
    [createShortlist, invalidateShortlists]
  );

  const handleClearShortlist = useCallback(() => {
    clearComparisonToast();
    const pendingShortlists = allShortlists.filter(({ state }) => state === "pending");
    const promises = pendingShortlists.map(({ id }) => deleteShortlist(id));

    Promise.all(promises)
      .then(() => {
        invalidateShortlists();
        growl({
          id: CLEAR_SHORTLIST_SUCCESS_ACTION_ID,
          kind: "success",
          message: t("activities:show.shortlist.clearedShortlist"),
          onButtonClick: () => unRemoveShortlistItems(pendingShortlists),
          buttonText: t("translation:undo"),
          hidePreviousByTypeAndId: true,
          ttl: 30000
        });
      })
      .catch(() => {
        invalidateShortlists();
      });
  }, [
    allShortlists,
    clearComparisonToast,
    deleteShortlist,
    growl,
    invalidateShortlists,
    t,
    unRemoveShortlistItems
  ]);

  return {
    handleClearShortlist,
    isLoading: isDeletingShortlist || isCreatingShortlist
  };
};
