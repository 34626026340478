import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useTranslation } from "react-i18next";

export const useTextWithExpiryDateFormatter = () => {
  const { t } = useTranslation("core");
  const { formatDate } = useDateFormatter();

  const formatTextWithExpiryDate = (text: string, expiryDate: string) =>
    `${text} (${t("time.inWords.expiresShort")}: ${formatDate(expiryDate)})`;

  return {
    formatTextWithExpiryDate
  };
};
