import { useMutation } from "@tanstack/react-query";
import { reopenActivity, ReopenActivityPayload } from "PFCore/services/activities";
import { MutationOptions } from "PFCore/types";

import { useRolesInvalidate } from "../roles/use_roles_invalidate";

export const useReopenActivity = (options: MutationOptions<ReopenActivityPayload> = {}) => {
  const { invalidateRoles, invalidateAuditRoles } = useRolesInvalidate();

  return useMutation<void, unknown, ReopenActivityPayload>({
    mutationFn: async (payload: ReopenActivityPayload) => reopenActivity(payload),
    onSuccess: (...args) => {
      invalidateRoles();
      invalidateAuditRoles();

      options.onSuccess?.(...args);
    },
    ...options
  });
};
