import filter from "lodash/filter";
import uniqBy from "lodash/uniqBy";
import { useAppContext } from "PFApp/app_context";
import { useTranslation } from "react-i18next";

import SmartSuggestionsShell from "./smart_suggestions_shell";
import SuggestedFilters from "./suggested_filters";
import SuggestedKeywords from "./suggested_keywords";

const SmartSuggestions = () => {
  const {
    store: {
      search: { suggestedKeywords, choosenKeywords }
    }
  } = useAppContext();
  const { t } = useTranslation("search");
  const suggestibles = getSuggestibles(suggestedKeywords, choosenKeywords);
  const filterSuggestions = getFilterSuggestions(suggestedKeywords, choosenKeywords);

  return (
    <SmartSuggestionsShell empty={suggestibles.length === 0 && filterSuggestions.length === 0}>
      <p>{t("smartSuggestions.description")}</p>

      <SuggestedKeywords items={suggestibles || []} />
      <SuggestedFilters items={filterSuggestions || []} />
    </SmartSuggestionsShell>
  );
};

const getSuggestibles = (suggestedKeywords, choosenKeywords) => {
  const isNotChoosen = (keyword) => !choosenKeywords.find((other) => other.id === keyword.id);
  const notChoosenKeywords = suggestedKeywords.filter(isNotChoosen);

  return filter(notChoosenKeywords, "match");
};

const getFilterSuggestions = (suggestedKeywords, choosenKeywords) => {
  // detectables are also suggested but are to be treated as filters
  const detectables = filter(suggestedKeywords, "filterKey");
  const chosen = filter(choosenKeywords, "filterKey");
  return uniqBy(detectables.concat(chosen), "id");
};

export default SmartSuggestions;
