import isNumber from "lodash/isNumber";
import { ThresholdSlider } from "PFComponents/availability/threshold_slider";
import { AvailabilityRule, Template } from "PFTypes";

type AvailabilityThresholdSliderProps = {
  availability: Partial<AvailabilityRule>;
  template: Template;
  disabled?: boolean;
  tooltip?: string;
  onChange: (updatedAvailability: Partial<AvailabilityRule>) => void;
};

export const AvailabilityThresholdSlider = ({
  availability,
  template,
  disabled,
  tooltip,
  onChange
}: AvailabilityThresholdSliderProps) => {
  const defaultAvailabilityThreshold = template?.schema.properties?.availability?.default_threshold;

  const threshold = isNumber(availability.availability_threshold)
    ? availability.availability_threshold
    : defaultAvailabilityThreshold || 70;

  const handleThresholdChange = (value: number) => {
    onChange({ ...availability, availability_threshold: value });
  };

  return (
    <ThresholdSlider
      threshold={threshold}
      disabled={disabled}
      tooltip={tooltip}
      onChange={handleThresholdChange}
    />
  );
};
