import { useQuery } from "@tanstack/react-query";
import { fetchSubtemplates, FetchSubtemplatesParams } from "PFCore/services/subtemplates";
import { Subtemplate } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

export const useSubtemplates = (params: FetchSubtemplatesParams, options: QueryOptions<Subtemplate[]> = {}) =>
  useQuery<Subtemplate[]>({
    queryKey: ["subtemplates", params],
    queryFn: () => fetchSubtemplates(params),
    retry: false,
    ...options
  });
