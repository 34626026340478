import capitalize from "lodash/capitalize";
import { Button } from "PFComponents/button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const MatchesTimeout = ({ pending, template }) => {
  const templateName = capitalize(template.name);
  const { t } = useTranslation("activities");
  const message = pending
    ? t("show.matches.requestTimedOutWait", { templateName: templateName })
    : t("show.matches.requestTimedOut");

  return (
    <div style={{ fontSize: 20, display: "flex" }}>
      {message}
      <Button
        text={t("show.matches.clickToReload")}
        kind="text"
        style={{ fontSize: 20, marginLeft: 5 }}
        onClick={() => window.location.reload()}
      />
      .
    </div>
  );
};

MatchesTimeout.propTypes = {
  template: PropTypes.object.isRequired,
  pending: PropTypes.bool.isRequired
};
