import { SubsidiaryModeAlert } from "PFApp/components/subsidiary_mode_alert";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { useActivityPageContext } from "../../activity_page_context";
import css from "../activity_page.module.scss";

export const ComparisonToast = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation("activities");

  const { task, compareIds, clearComparisonToast } = useActivityPageContext();

  const keys = Object.keys(compareIds);
  const comparisonListLength = keys.length;

  const comparisonLocation = {
    pathname: `/activities/${task.id}/compare/details/${keys.join(",")}`,
    state: { prevPath: `${location.pathname}${location.search}` }
  };

  if (!comparisonListLength) {
    return null;
  }

  return (
    <div className={css.compareAlert}>
      <SubsidiaryModeAlert
        onClose={clearComparisonToast}
        title={
          comparisonListLength > 1
            ? t("show.countSelected", { count: comparisonListLength })
            : t("show.selectOneProfile")
        }
        actions={[
          {
            icon: "compare",
            onClick: () => history.push(comparisonLocation),
            disabled: comparisonListLength < 2
          }
        ]}
      />
    </div>
  );
};
