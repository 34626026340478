import classNames from "classnames";
import compact from "lodash/compact";
import { ActivityCustomValuesList } from "PFApp/components/custom_values_list";
import { useTemplate } from "PFApp/hooks";
import Hr from "PFComponents/hr";
import { Typography } from "PFComponents/typography";
import { useMatchableTypes } from "PFCore/helpers/activities";
import { propertyToTitle } from "PFCore/helpers/templates";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { AccessLevel, Activity } from "PFTypes";

import css from "./activity_details_common.module.scss";

type ActivityDetailsSkillsProps = {
  task: Activity;
};

export const ActivityDetailsSkills = ({ task }: ActivityDetailsSkillsProps): JSX.Element | null => {
  const matchableTypes = useMatchableTypes(task.template_id, [AccessLevel.ReadWrite, AccessLevel.ReadOnly]);
  const template = useTemplate(task.template_id);
  const { customTypes } = useCustomTypes();

  const customFields = compact(
    // We iterate matchableTypes to keep the order specified in the template, as they are already sorted
    matchableTypes.map(({ name: matchableTypeName }) => {
      const roleCustomField = task.custom_fields.find(({ type }) => type.name === matchableTypeName);
      const isPresentInRole = !!roleCustomField;
      const hasValues = (roleCustomField?.values || []).length > 0;

      if (isPresentInRole && hasValues) {
        return roleCustomField;
      }

      return null;
    })
  );

  return customFields.length > 0 ? (
    <>
      <div className={classNames(css.bigItem)} data-qa-id="ActivityDetailsSkills">
        {customFields.map((customField) => (
          <div key={customField.type.id} className={css.itemContainer}>
            <Typography variant="h5" noMargin>
              {propertyToTitle(template, customField.type.name, customTypes)}
            </Typography>
            <ActivityCustomValuesList
              customFieldName={customField.type.name}
              customValues={customField.values}
              activityId={task.id}
              limit={Infinity}
            />
          </div>
        ))}
      </div>
      <Hr />
    </>
  ) : null;
};
