import { useMutation } from "@tanstack/react-query";
import { requestAccess, RequestAccessPayload } from "PFCore/services/signup/request_access";

import { authKeys } from "./query_keys";

export const useRequestAccess = () => {
  const mutationKey = authKeys.requestAccess();

  return useMutation({ mutationFn: async (data: RequestAccessPayload) => requestAccess(data), mutationKey });
};
