import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { Id } from "PFTypes";

import { Option, SelectV2Props } from "../select_v2.types";
import { flattenOptions } from "../select_v2.utils";
import { UseOptions, useOptions } from "./use_options";

type UseSelectedOptions<T> = { selectedOptionIds: Id[] } & Pick<
  SelectV2Props<T>,
  "options" | "fetchOptions"
> &
  Pick<UseOptions<T>, "queryOptions" | "dropdownId">;

type UseSelectedOptionsReturn<T> = { data: Option<T>[]; isFetching: boolean };

export const useSelectedOptions = <T>({
  selectedOptionIds,
  options,
  fetchOptions,
  queryOptions,
  dropdownId
}: UseSelectedOptions<T>): UseSelectedOptionsReturn<T> => {
  const enabled = !isEmpty(selectedOptionIds) && (!!queryOptions?.enabled || isNil(queryOptions?.enabled));
  const { data: availableOptions, isFetching } = useOptions({
    dropdownId,
    ids: selectedOptionIds,
    options,
    fetchOptions,
    queryOptions: {
      ...queryOptions,
      enabled
    }
  });
  const selectedOptions = flattenOptions(availableOptions).filter(({ id }) => selectedOptionIds.includes(id));

  return { data: selectedOptions, isFetching: isEmpty(selectedOptionIds) ? false : isFetching };
};
