import debounce from "lodash/debounce";
import isNull from "lodash/isNull";
import moment from "moment";
import { Select } from "PFComponents/select/select";
import useAvailabilityLimits from "PFCore/hooks/use_availability_limits";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { AccountAvailability } from "PFTypes";
import { AvailabilityDuration, AvailabilityMode, StandardRange } from "PFTypes/booking";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import roundToDecimals from "../../../../helpers/round_to_decimals";
import { convertToPercentage } from "../../../../utilities/math";
import { DEFAULT_MONTHS_FORWARD_LIMIT, DURATION_VALUE_ERROR_KEY } from "../../constants";
import { getFieldError } from "../../errors_helper";
import { LoadEstimationData } from "../../hooks/use_load_estimation";
import { AvailabilityRangeCalendar } from "../availability_range_calendar";
import { NumberInput } from "../number_input";
import { SideInfo } from "../parts/side_info";
import commonCss from "../rules_carousel.modules.scss";
import css from "./standard_rule.module.scss";
import { useDurationModeOptions } from "./use_duration_mode_options";
import { useModeOptions } from "./use_mode_options";

const sanitizeDurationValue = (
  value: number | string,
  mode: AvailabilityDuration["mode"],
  toHours?: boolean
) => {
  const durationValueInt = Number(value);

  if (isNaN(durationValueInt) || value === "" || isNull(value)) {
    return null;
  }

  if (mode === "minutes") {
    return toHours ? roundToDecimals(durationValueInt / 60) : Math.round(durationValueInt * 60);
  }

  return durationValueInt;
};

type StandardRuleProps = {
  accountAvailability: AccountAvailability;
  errors?: Record<string, string>;
  mode: Omit<AvailabilityMode, AvailabilityMode.TimeRule>;
  allowedModes: string[];
  qaId?: string;
  range: StandardRange;
  minDate?: { start: string; end?: string };
  onChange: (value: any) => void;
  onModeChange?: (mode: Omit<AvailabilityMode, AvailabilityMode.TimeRule>) => void;
  portalRef?: React.RefObject<HTMLDivElement>;
  isFilter?: boolean;
  loadData: LoadEstimationData;
};

export const StandardRule = ({
  accountAvailability,
  errors = {},
  mode,
  qaId,
  allowedModes,
  range: { start, end, duration },
  minDate,
  onChange,
  onModeChange,
  portalRef,
  isFilter,
  loadData
}: StandardRuleProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "availabilityRequirement" });
  const { formatISODate } = useDateFormatter();
  const { maxDate } = useAvailabilityLimits();

  const { currentMode, availabilityModes } = useModeOptions(mode, allowedModes);
  const { currentDurationMode, durationModeOptions } = useDurationModeOptions(duration.mode);

  const canEdit = start && end;
  const canSelectHours = (accountAvailability || {}).view_granularity === "hours";
  const matchMonthsForwardLimit = accountAvailability?.match_months_limit || DEFAULT_MONTHS_FORWARD_LIMIT;

  const durationValue = useMemo(() => sanitizeDurationValue(duration.value, duration.mode, true), [duration]);
  const matchMaxDate = useMemo(
    () => formatISODate(moment().add(matchMonthsForwardLimit, "months").endOf("month")),
    [formatISODate, matchMonthsForwardLimit]
  );

  const handleDatesChange = (start: string, end: string) => onChange({ start, end });

  const handleDurationChange = debounce(
    (value: string) => {
      onChange({ duration: { ...duration, value: sanitizeDurationValue(value, duration.mode) } });
    },
    isFilter ? 1000 : 1
  );

  const handleDurationModeChange = (mode: AvailabilityDuration["mode"]) => {
    onChange({ duration: { ...duration, mode, value: mode === "minutes" ? 60 : 1 } });
  };

  const handleModeChange = (mode: AvailabilityMode) => onModeChange?.(mode);

  const { totalAvailableHours, percentageLoad } = loadData;

  return (
    <div className={css.calendars} data-qa-id={qaId}>
      <span className={commonCss.row}>
        <AvailabilityRangeCalendar
          key={`${start}+${end}+${mode}`}
          // @ts-ignore
          start={start}
          end={end}
          mode={mode}
          maxDate={maxDate}
          matchMaxDate={matchMaxDate}
          onChange={handleDatesChange}
          errors={errors}
          minDate={minDate}
          portalRef={portalRef}
        />
        <SideInfo
          label={t("totalAvailable")}
          value={totalAvailableHours !== null ? t("nHours", { count: Math.round(totalAvailableHours) }) : "-"}
          tooltipContent={t("tooltips.totalAvailable")}
        />
      </span>

      <span className={commonCss.row}>
        <div className={css.durationWrap}>
          <NumberInput
            placeholder={t("setAmount")}
            label={t("timeRequired")}
            className={css.numberInput}
            onChange={(value) => handleDurationChange(value ? String(value) : "")}
            error={getFieldError(errors, DURATION_VALUE_ERROR_KEY)}
            disabled={!canEdit}
            controlledValue={durationValue ?? undefined}
          />
          {canSelectHours ? (
            <Select
              label={t("durationMode")}
              labelHidden
              value={currentDurationMode.displayElement}
              style={{ maxWidth: 100 }}
              controlledValue
              options={durationModeOptions}
              onChange={handleDurationModeChange}
              disabled={!canEdit}
              portalRef={portalRef}
            />
          ) : (
            <span>{currentDurationMode.displayElement}</span>
          )}
          <Select
            label={t("availabilityMode")}
            labelHidden
            value={currentMode?.displayElement}
            style={{ maxWidth: 140 }}
            controlledValue
            options={availabilityModes}
            onChange={handleModeChange}
            disabled={!canEdit}
            lockedTip={t("modeLockedTip")}
            locked={!onModeChange}
            portalRef={portalRef}
          />
        </div>
        <SideInfo
          label={t("requiredPercent")}
          value={convertToPercentage(percentageLoad, { isNormalized: true })}
          tooltipContent={t("tooltips.requiredPercent")}
        />
      </span>
    </div>
  );
};
