import find from "lodash/find";
import { BookingDetailItem } from "PFApp/booking/components/booking_detail_item";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { usePhases } from "PFCore/hooks/queries/phases/use_phases";
import { FeatureFlag } from "PFTypes";
import { Phase as PhaseType } from "PFTypes/phase";
import { useTranslation } from "react-i18next";

type PhaseProps = {
  phaseSourceId?: string;
};

export const Phase = ({ phaseSourceId }: PhaseProps) => {
  const { t } = useTranslation("bookingModule");

  const hasActivityPhases = useIsFeatureEnabled()(FeatureFlag.ActivityPhases);
  const { data: phases } = usePhases(null, {
    enabled: hasActivityPhases,
    placeholderData: { entries: [], meta: { total: 0 } }
  });

  const phase = find(phases!.entries as PhaseType[], ["sourceId", phaseSourceId]);

  if (!phase) {
    return null;
  }

  return <BookingDetailItem label={t("details.phase")} value={phase?.name} />;
};
