/* eslint-disable no-unused-vars */
import i18n from "i18next";
import capitalize from "lodash/capitalize";
import { initReactI18next } from "react-i18next";

import { enGB } from "./en-GB";
import { frCA } from "./fr-CA";

const NAMESPACES = [
  "activities",
  "admin",
  "auditPlanner",
  "availability",
  "bookingModule",
  "branding",
  "changeRequests",
  "collaboratorsConfigs",
  "core",
  "feed",
  "insights",
  "interestQuestions",
  "marketplace",
  "msTeams",
  "onboarding",
  "profiles",
  "saasAdmin",
  "samlConfigs",
  "search",
  "skillsFrameworks",
  "customValuesManagement",
  "translation",
  "workflow",
  "locales"
] as const;

export type I18nNamespace = (typeof NAMESPACES)[number];

i18n
  // uncomment the line below to detect user language
  // .use(LanguageDetector)

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: "en-GB",
    fallbackLng: "en-GB",

    // eslint-disable-next-line no-undef
    debug: ["puppetmaster", "development", "staging", "multistaging"].includes(process.env.environment!),

    ns: NAMESPACES,
    fallbackNS: "translation",
    resources: {
      "en-GB": enGB,
      "fr-CA": frCA
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  })
  .then((tFunction) => {
    i18n.services?.formatter?.add("lowercase", (value) => value?.toLowerCase());
    i18n.services?.formatter?.add("capitalize", (value) => capitalize(value));
    i18n.services?.formatter?.add("uppercase", (value) => value?.toUpperCase());
    return tFunction;
  });
export default i18n;
