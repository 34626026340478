import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import { uploadAttachment } from "PFCore/services/attachments/upload_attachment";
import AttachmentIcon from "PFIcons/attachment.svg";
import PropTypes from "prop-types";
import { Component, createRef } from "react";

import css from "./chat_attachment_input.module.scss";
const ATTACHMENTS_LIMIT = 5; // api enforced

export default class ChatAttachmentInput extends Component {
  constructor(props) {
    super(props);

    this.formRef = createRef();
    this.state = { loading: false };
  }

  handleChange = (e) => {
    const { handleAttachmentSaved, handleAttachmentSaveFailed, removeAttachment } = this.props;

    this.setState({ loading: true });

    const file = e.target.files[0];

    const uploadPromise = uploadAttachment(file);

    const fakeAttachment = {
      id: uniqueId("fake"),
      fake: true,
      uploadPromise,
      fileObjectUrl: URL.createObjectURL(file)
    };

    handleAttachmentSaved(fakeAttachment);

    uploadPromise
      .always((resp) => {
        this.setState({ loading: false });
        return resp;
      })
      .then(
        (resp) => {
          resp.fileObjectUrl = fakeAttachment.fileObjectUrl;
          removeAttachment(fakeAttachment);
          handleAttachmentSaved(resp);
        },
        (resp) => {
          removeAttachment(fakeAttachment);
          handleAttachmentSaveFailed(resp);
        }
      );

    this.formRef.current.reset(); // so you can add the same file again
  };

  render() {
    const { loading } = this.state;
    const { attachments } = this.props;

    const disabled = loading || attachments.length >= ATTACHMENTS_LIMIT;

    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className={classNames(css.label, { [css.disabled]: disabled })}>
        <AttachmentIcon width={20} height={20} />
        <form ref={this.formRef}>
          <input disabled={disabled} className={css.input} type="file" onChange={this.handleChange} />
        </form>
      </label>
    );
  }
}

ChatAttachmentInput.propTypes = {
  attachments: PropTypes.array.isRequired,

  removeAttachment: PropTypes.func.isRequired,
  handleAttachmentSaved: PropTypes.func.isRequired,
  handleAttachmentSaveFailed: PropTypes.func.isRequired
};
