import { isArray } from "lodash";
import { Option, OptionsProps } from "PFComponents/select_v2";
import { Filter, Id, OptionsFilter } from "PFTypes";
import { ReactNode, useCallback } from "react";

import { ProfileItem } from "../profile_item";

export type OptionOriginal = { id: Id; text: string; displayElement?: ReactNode };
export type FilterOption = Option<OptionOriginal>;

type UseFiltersOptions = {
  filter: Filter;
};
type UseFiltersOptionsReturn = OptionsProps<OptionOriginal>;

export const useFiltersOptions = ({ filter }: UseFiltersOptions): UseFiltersOptionsReturn => {
  const formatOptions = useCallback(
    (originalOptions) =>
      originalOptions.map((option) => {
        const displayElement =
          option.displayElement ||
          (filter.name === "workforce_member_id" ? <ProfileItem profile={option} /> : undefined);

        return {
          id: option.id,
          value: option.text,
          original: option,
          displayElement
        };
      }),
    [filter.name]
  );

  const fetchOptions = useCallback(
    async ({ ids, searchTerm }) => {
      if (!filter.query) {
        return { entries: [], meta: { total: 0 } };
      }

      const options = await filter.query({ value: ids, term: searchTerm });
      if (isArray(options)) {
        return { entries: formatOptions(options), meta: {} };
      }
      return { entries: formatOptions(options.entries), meta: options.meta };
    },
    [filter, formatOptions]
  );

  return (filter as OptionsFilter).options
    ? { options: formatOptions((filter as OptionsFilter).options) }
    : { fetchOptions };
};
