import flatten from "lodash/flatten";
import map from "lodash/map";

const __injectTypesToValues = (custom_fields) =>
  map(custom_fields, (field) => {
    field.values = map(field.values, (value) => {
      value.type = field.type;
      return value;
    });
    return field;
  });

export const extractValuesWithTypes = (customFields) => {
  const values = map(__injectTypesToValues(customFields), "values");
  return flatten(values);
};
