import useRequestAttempts from "PFCore/hooks/use_request_attempts";
import { fetchShortlistsRanked, RankedShortlistCollection } from "PFCore/services/shortlist";
import { Id, QueryParams } from "PFTypes";

import { QueryOptionsWithCallbacks, useQueryWithCallbacks } from "../use_query_with_callbacks";
import { rankedShortlistsKeys } from "./query_keys";

type UseActivityRankedShortlists = {
  activityId: Id;
  profileIds?: Id[];
  params?: QueryParams;
  options?: QueryOptionsWithCallbacks<RankedShortlistCollection>;
};

export const useActivityRankedShortlists = ({
  activityId,
  profileIds = [],
  params = {},
  options = {}
}: UseActivityRankedShortlists) => {
  const queryKey = rankedShortlistsKeys.activityRankedShortlists(activityId, profileIds);
  const { handleSuccessfulAttempt } = useRequestAttempts({
    queryKey,
    revertOnNotReady: true
  });

  const query = useQueryWithCallbacks<RankedShortlistCollection>({
    queryKey,
    queryFn: () => fetchShortlistsRanked(activityId, profileIds, params.page, params.perPage),
    retry: false,
    staleTime: 0,
    ...options,
    onSuccess: (response) => {
      handleSuccessfulAttempt(response, query.refetch, options?.onSuccess);
    }
  });

  return query;
};
