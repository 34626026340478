import filter from "lodash/filter";
import { Button } from "PFComponents/button";
import { Inline } from "PFComponents/containers";
import { ReadOnlyField } from "PFComponents/read_only_field";
import { Typography } from "PFComponents/typography";
import { isRankable } from "PFCore/helpers/custom_type";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { Experience, ProfileCustomValue, PureCustomValueCamelCased } from "PFTypes";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { CORE_SKILLS_LIMIT, DEVELOPMENTAL_SKILLS_LIMIT } from "../../../../constants/skills_limits";
import { useCustomValuePreviewContext } from "../../context/custom_value_preview_context";
import css from "../../custom_value_preview.module.scss";
import { AttributeCheckbox } from "../../parts/attribute_checkbox";
import { CertificatesList } from "../../parts/certificates_list";
import { Details } from "../../parts/details";
import { ExperienceToggle } from "../../parts/experience_toggle";
import { LearningPlatformLink } from "../../parts/learning_platform_link";
import { SourceData } from "../../parts/source_data";
import { VerifiedIcons } from "../../parts/verified_icons";

type ProfileCustomValueContentProps = {
  experience: Experience | null;
  isCore: boolean;
  isDevelopmental: boolean;
  customValue: PureCustomValueCamelCased;
  profileConnection?: ProfileCustomValue;
  allowEdit: boolean;
  onExperienceChange: (experience: Experience) => void;
  onCoreChange: (isCore: boolean) => void;
  onDevelopmentalChange: (isDevelopmental: boolean) => void;
  onResetForm: VoidFunction;
};

export const ProfileCustomValueContent = ({
  experience,
  isCore,
  isDevelopmental,
  allowEdit,
  customValue,
  profileConnection,
  onExperienceChange,
  onCoreChange,
  onDevelopmentalChange,
  onResetForm
}: ProfileCustomValueContentProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "customValueSidepanel" });
  const { t: tTranslation } = useTranslation();
  const { data: currentProfile } = useCurrentProfile();
  const { formatDate } = useDateFormatter();
  const { isNew, customType, allowSearch, profileId } = useCustomValuePreviewContext();

  const rankable = isRankable(customType);
  const isSkills = customType.name === "skills";
  const isMe = profileId === currentProfile.id;
  const coreSkillsCount = filter(currentProfile.skills, "top").length;
  const developmentalSkillsCount = filter(currentProfile.skills, "developmental").length;
  const showLearningPlatformLink = isSkills && isNew && isMe;
  const infoMessage = isNew
    ? t("youDontHaveValueAddIt")
    : allowEdit
    ? t("youAlreadyHaveValueUpdateIt")
    : null;
  const CoreDevelopmentalWrapper = allowEdit ? Fragment : Inline;
  const showCoreDevelopmental = isSkills && (allowEdit || isCore || isDevelopmental);

  return (
    <>
      {isNew && allowSearch && (
        <Button
          kind="text"
          text={t("searchForAnotherCustomValue")}
          icon="arrow-left"
          onClick={onResetForm}
          className={css.fitContent}
        />
      )}
      {infoMessage && (
        <Typography variant="bodyRegular" noMargin>
          {infoMessage}
        </Typography>
      )}
      {rankable && (
        <ExperienceToggle
          experience={experience}
          onChange={onExperienceChange}
          canEdit={allowEdit}
          isRequirement={false}
        />
      )}
      {showCoreDevelopmental && (
        <CoreDevelopmentalWrapper>
          <AttributeCheckbox
            checked={isCore}
            onChange={onCoreChange}
            label={t("core")}
            iconName="core"
            disabled={!profileConnection?.top && coreSkillsCount >= CORE_SKILLS_LIMIT}
            disabledTooltip={tTranslation("coreSkillsLimit", {
              limit: CORE_SKILLS_LIMIT
            })}
            canEdit={allowEdit}
            classes={{ readonly: css.halfWidth }}
          />
          <AttributeCheckbox
            checked={isDevelopmental}
            onChange={onDevelopmentalChange}
            label={t("development")}
            iconName="development"
            disabled={
              !profileConnection?.developmental && developmentalSkillsCount >= DEVELOPMENTAL_SKILLS_LIMIT
            }
            disabledTooltip={tTranslation("developmentSkillsLimit", {
              limit: DEVELOPMENTAL_SKILLS_LIMIT
            })}
            canEdit={allowEdit}
            classes={{ readonly: css.halfWidth }}
          />
        </CoreDevelopmentalWrapper>
      )}
      {profileConnection && (
        <>
          <Inline spread>
            <VerifiedIcons profileConnection={profileConnection} />
            <SourceData source={profileConnection?.source ?? null} />
          </Inline>
          <Inline spread>
            <ReadOnlyField
              className={css.halfWidth}
              label={t("addedToProfile")}
              value={formatDate(profileConnection?.createdAt)}
            />
            <ReadOnlyField
              className={css.halfWidth}
              label={t("lastEditedInProfile")}
              value={formatDate(profileConnection?.updatedAt)}
            />
          </Inline>
        </>
      )}
      {showLearningPlatformLink && <LearningPlatformLink skillName={customValue.text} />}
      <Details customValue={customValue} />
      <CertificatesList />
    </>
  );
};
