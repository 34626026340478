/* eslint-disable camelcase */
import { ApiRoute } from "PFCore/utilities/routes";

import { BookingNote } from "../parts/overview/detail_view/booking_detail/booking_notes/types";
import jqXHR = JQuery.jqXHR;
import { camelizeKeys } from "humps";
import api from "PFCore/api";
import { fetchPaginatedCollection } from "PFCore/helpers/collection";

type FetchBookingNotesRequest = {
  bookingId: number;
  perPage?: number;
  page?: number;
};
type AddBookingNoteRequest = { bookingId: number; body: string };
type DeleteBookingNoteRequest = { bookingId: number; id: number };
type EditBookingNoteRequest = {
  bookingId: number;
  id: number;
  body: string;
};
export type BookingNotesResponse = {
  entries: BookingNote[];
  meta: {
    total: number;
    totalPages: number;
    page: number;
  };
};

export const fetchBookingNotes = ({
  bookingId: booking_id,
  perPage = 15,
  page = 1
}: FetchBookingNotesRequest): jqXHR<BookingNotesResponse> =>
  /* @ts-ignore */
  fetchPaginatedCollection(ApiRoute(`/api/booking_module/bookings/${booking_id}/booking_notes`), {
    perPage,
    page
  }).then((response) => camelizeKeys(response) as BookingNotesResponse);

export const addBookingNote = ({ bookingId, body }: AddBookingNoteRequest) =>
  api<void>({
    url: `booking_module/bookings/${bookingId}/booking_notes`,
    method: "POST",
    data: { body }
  });

export const editBookingNote = ({ bookingId, id, body }: EditBookingNoteRequest) =>
  api<BookingNote>({
    url: `booking_module/bookings/${bookingId}/booking_notes/${id}`,
    method: "PUT",
    data: { body }
  });

export const deleteBookingNote = ({ bookingId, id }: DeleteBookingNoteRequest) =>
  api<void>({
    url: `booking_module/bookings/${bookingId}/booking_notes/${id}`,
    method: "DELETE"
  });
