import toString from "lodash/toString";
import { useQueryParams } from "PFCore/helpers/use_query_params";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

type UseParamValueReturn<T> = {
  value: T;
  setValue: (value: T) => void;
  serialize: (value: T) => string;
};

type ParamValueMethods<T> = {
  parse: (rawValue: string | null) => T;
  format: (value: T) => string;
  isEmpty: (value: T) => boolean;
};

export const useParamValue = <T>(
  param: string,
  methods: Partial<ParamValueMethods<T>> & Pick<ParamValueMethods<T>, "parse">
): UseParamValueReturn<T> => {
  const history = useHistory();
  const queryParams = useQueryParams();

  const { parse, format, isEmpty } = useMemo<ParamValueMethods<T>>(
    () => ({
      format: toString,
      isEmpty: (value) => !value,
      ...methods
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(methods)]
  );

  const rawParamValue = queryParams.get(param);
  const value = useMemo(() => parse(rawParamValue), [parse, rawParamValue]);

  const setValue = useCallback(
    (value: T) => {
      if (isEmpty(value)) {
        queryParams.delete(param);
      } else {
        queryParams.set(param, format(value));
      }
      history.replace({ search: queryParams.toString() });
    },
    [isEmpty, history, queryParams, param, format]
  );

  const serialize = useCallback((value: T) => `${param}=${format(value)}`, [format, param]);

  return {
    value,
    setValue,
    serialize
  };
};
