import { useProfilePreview } from "PFApp/use_profile_preview";
import { Card } from "PFComponents/card";
import { Animate } from "PFComponents/containers";
import { getProfileName } from "PFCore/helpers/profile";
import { Profile, ProfileSearchHighlights } from "PFTypes";
import { useCallback } from "react";
import { useToggle } from "react-use";

import { ProfileCardContent } from "./parts";

export type ProfileCardProps = {
  profile: Profile;
  highlights?: ProfileSearchHighlights;
};

export const ProfileCard = ({ profile, ...props }: ProfileCardProps) => {
  const [animationTrigger, animate] = useToggle(false);
  const { showPreview } = useProfilePreview();

  const profileName = getProfileName(profile);

  const onCardClick = useCallback(() => {
    showPreview({ id: profile.id, profile });
  }, [profile, showPreview]);

  return (
    <Card onClick={onCardClick} ariaLabel={profileName}>
      <Animate trigger={animationTrigger}>
        <ProfileCardContent profile={profile} {...props} onSizeChange={animate} />
      </Animate>
    </Card>
  );
};
