import noop from "lodash/noop";
import moment from "moment/moment";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { CalendarRange } from "PFTypes";
import { createContext, PropsWithChildren, SetStateAction, useContext, useState } from "react";

const INITIAL_MAX_CALENDAR_RANGE_MONTHS = 3;

type BookingModuleContextType = {
  calendarRange: CalendarRange;
  setCalendarRange: React.Dispatch<SetStateAction<CalendarRange>>;
  headerDisabled: boolean;
  setHeaderDisabled: React.Dispatch<SetStateAction<boolean>>;
};

const BookingModuleContext = createContext<BookingModuleContextType>({
  calendarRange: { min: "", max: "" },
  setCalendarRange: noop,
  headerDisabled: false,
  setHeaderDisabled: noop
});

export const BookingModuleContextProvider = ({ children }: PropsWithChildren) => {
  const { formatISODate } = useDateFormatter();
  const [calendarRange, setCalendarRange] = useState({
    min: formatISODate(),
    max: formatISODate(moment().add(INITIAL_MAX_CALENDAR_RANGE_MONTHS, "M"))
  });
  const [headerDisabled, setHeaderDisabled] = useState<boolean>(false);

  return (
    <BookingModuleContext.Provider
      value={{ calendarRange, setCalendarRange, headerDisabled, setHeaderDisabled }}
    >
      {children}
    </BookingModuleContext.Provider>
  );
};

export const useBookingModuleContext = () => useContext(BookingModuleContext);
