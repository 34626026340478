import isEmpty from "lodash/isEmpty";
import { useTemplate } from "PFApp/hooks";
import { Activity, Profile } from "PFTypes";

type AssigneeResult = {
  assignee?: Profile;
  canView: boolean;
  canWrite: boolean;
};

export const useAssignee = (activity?: Activity): AssigneeResult => {
  const activityType = useTemplate(activity?.template_id);
  const isEngagementOrRole = ["role", "engagement"].includes(activityType?.key || "");
  const canWrite = isEngagementOrRole && !!activity?.coowned;
  const assignee = activity?.assignee?.profile;

  return { assignee, canView: !isEmpty(assignee), canWrite };
};
