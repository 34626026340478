import get from "lodash/get";
import { TableColumn } from "PFApp/components/table/table_types";
import { getMatchDisplayValue } from "PFCore/helpers/match_value";
import { getProfileName } from "PFCore/helpers/profile";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { IRoleWatcher, IWatcherProfile, IWatcherQuestion } from "../../../../../../types/watchers";

export const useFormatColumnValue = () => {
  const { t } = useTranslation("activities");
  return useCallback((watcher: IRoleWatcher, column: TableColumn) => {
    const attributeOrValue = get(watcher, [...(column.attribute || [column.name])]);

    if (column.type === "score") {
      return getMatchDisplayValue(attributeOrValue?.[column.name], {
        showPercentage: true,
        defaultValue: "-"
      });
    }

    if (column.name === "name") {
      return getProfileName(attributeOrValue as IWatcherProfile);
    }

    if (column.name === "questions") {
      return ((attributeOrValue as IWatcherQuestion[]) || [])
        .map(([question, answer]) => `${question} ${t(`show.interested.table.answers.${answer}`)}`)
        .join(" ");
    }

    return attributeOrValue;
  }, []);
};

export default useFormatColumnValue;
