import { useIsMutating, useMutation } from "@tanstack/react-query";
import { createBookingTemplate, CreateBookingTemplatePayload } from "PFApp/booking/services/api";

import { bookingTemplateKeys } from "../query_keys";

const bookingMutationsKeys = {
  create: () => [...bookingTemplateKeys.all(), "create"]
};

export const useBookingTemplateCreate = () => {
  const mutationKey = bookingMutationsKeys.create();

  const createMutation = useMutation({
    mutationFn: async (payload: CreateBookingTemplatePayload) => {
      if (!payload) {
        return;
      }

      return createBookingTemplate(payload);
    },

    mutationKey
  });

  const isCreating = useIsMutating({
    mutationKey
  });

  return {
    mutation: createMutation,
    create: createMutation.mutateAsync,
    isCreating
  };
};
