import compact from "lodash/compact";
import NotFoundComponent from "PFComponents/not_found/not_found";
import { isSubtemplate } from "PFCore/helpers/templates";
import usePageWrapper from "PFCore/helpers/use_page_wrapper";
import { Activity, Id, Template } from "PFTypes";
import { useTranslation } from "react-i18next";

import { ActivityEditForm } from "./activity_edit_form";
import { ActivityEditPageProvider, useActivityEditPageContext } from "./activity_edit_page_context";

type ActivityEditPageProps = {
  id?: Id;
  type?: Template["key"];
  attributes?: Partial<Activity>;
  isClone?: boolean | undefined;
};

const ActivityEditPageContent = () => {
  const { t } = useTranslation("activities");

  const WrappedActivityEditPage = usePageWrapper(ActivityEditForm);

  const { id, loaded, activity, template, errors } = useActivityEditPageContext();

  const isSubtemplateMissing =
    !!activity && !!activity.subtemplate_key && !isSubtemplate(template, activity.subtemplate_key);

  const isNew = !id;
  const templateError = !template && (isNew || !!activity) && t("edit.missingTemplate");
  const subtemplateError = isSubtemplateMissing && t("edit.templateNotSupported");

  return (
    <WrappedActivityEditPage
      loading={!loaded}
      errors={[...(errors || []), ...compact([templateError, subtemplateError])]}
      errorComponent={
        <NotFoundComponent
          message={templateError || subtemplateError || t("edit.activityCouldntLoad")}
          buttonMessage={t("edit.pleaseTryLater")}
          buttonUrl="/"
        />
      }
    />
  );
};

export const ActivityEditPage = ({ id, type, attributes, isClone }: ActivityEditPageProps) => (
  <ActivityEditPageProvider id={id} type={type} attributes={attributes} isClone={isClone}>
    <ActivityEditPageContent />
  </ActivityEditPageProvider>
);
