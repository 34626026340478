import { useQueryClient } from "@tanstack/react-query";

import { skillsKeys } from "./query_keys";

export const useSkillsInvalidate = () => {
  const cache = useQueryClient();
  return {
    invalidate: (ids?: number[]) => {
      if (!ids) {
        return cache.invalidateQueries({ queryKey: skillsKeys.all() });
      }

      return Promise.all([
        ...ids.map((id) =>
          cache.invalidateQueries({
            queryKey: skillsKeys.single(id)
          })
        )
      ]);
    },
    invalidateDiscoveredSkills: () => cache.invalidateQueries({ queryKey: skillsKeys.discovered() })
  };
};
