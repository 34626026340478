import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import { Checkbox } from "PFComponents/checkbox/checkbox";
import { Stack } from "PFComponents/containers/flex/stack";

import { letFilterRestore } from "../../use_filtered_collection";
import css from "./checkboxes_item.module.scss";
import { ExpandedFilterParams } from "./components/expanded_filter_wrapper";
import { FilterHeader } from "./components/filter_header";
import { numberizeValue } from "./filters.utils";

export const CheckboxesItem = ({ filter, onChange, disabled, options }: ExpandedFilterParams) => {
  const filterValue = ((filter.value || []) as number[]).map(numberizeValue);
  const canRestore = letFilterRestore(filter);
  const canClear = !isEmpty(filterValue);

  return (
    <Stack gap="spacingMd">
      <FilterHeader
        filter={filter}
        disabled={disabled}
        canClear={canClear}
        canRestore={canRestore}
        onChange={onChange}
      />
      {options.length > 0 && (
        <Stack gap="spacingSm" className={css.options}>
          {options.map((option) => {
            const checked = !!filterValue.find((value) => value === option.id);

            return (
              <Checkbox
                className={css.checkbox}
                disabled={disabled}
                key={option.id}
                label={option.value}
                checked={checked}
                onChange={(checked) => {
                  const newValues = checked
                    ? uniq([...filterValue, option.id])
                    : filterValue.filter((value) => value !== option.id);
                  onChange(newValues);
                }}
              />
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};
