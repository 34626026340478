import { EventKey } from "PFTypes/event_key";
import { Dispatch, KeyboardEvent, SetStateAction } from "react";

type GetKeyDownHandler = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

type GetKeyDownHandlerReturn = {
  onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void;
};

export const getKeyDownHandler = ({ setIsOpen }: GetKeyDownHandler): GetKeyDownHandlerReturn => {
  const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    switch (event.key) {
      case EventKey.Space:
      case EventKey.Enter:
        event.preventDefault();
        setIsOpen((prev) => !prev);
        return;
      case EventKey.ArrowDown: {
        event.preventDefault();
        setIsOpen(true);
        return;
      }
      case EventKey.ArrowUp:
      case EventKey.Escape: {
        event.preventDefault();
        setIsOpen(false);
        return;
      }
    }
  };

  return {
    onKeyDown
  };
};
