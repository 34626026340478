import { keepPreviousData } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query";
import isEmpty from "lodash/isEmpty";
import { fetchProjectsEngagements, fetchProjectsRoles } from "PFApp/booking/services/api";
import { CalendarView } from "PFApp/booking/types";
import { PER_PAGE_UNPAGINATED } from "PFApp/constants/unpaginated";
import { QueryOptions } from "PFTypes";

import { projectsKeys } from "./query_keys";
import { ProjectsResponse } from "./use_projects_entries";

const EMPTY_RESPONSE = { entries: [], meta: { total: 0, totalPages: 0, perPage: 0, page: 0 } };
const DEFAULT_ORDER = { name: ["activity.saas_created_at"], text: "Latest first", direction: "desc" };
const getActivityFilters = (pinnedActivityIds: number[]) => ({
  fields: { id: pinnedActivityIds }
});

export type UsePinnedProjects = {
  pinnedIds: number[];
  view: CalendarView;
  options: QueryOptions<ProjectsResponse, { status: number }>;
};

export const usePinnedProjects = ({
  pinnedIds,
  view,
  options = {}
}: UsePinnedProjects): UseQueryResult<ProjectsResponse, { status: number }> => {
  const filters = getActivityFilters(pinnedIds);

  return useQuery<ProjectsResponse, { status: number }>({
    queryKey: projectsKeys.pinned(view, filters, DEFAULT_ORDER, 1, PER_PAGE_UNPAGINATED),
    queryFn: () => {
      // In case activity id filter is empty it returns all entries, but we want otherwise.
      // Setting enable=false doesn't solve the issue because then keepPreviousData remembers wrong prev data.
      if (isEmpty(pinnedIds)) {
        return Promise.resolve(EMPTY_RESPONSE);
      }
      const params = { page: 1, perPage: PER_PAGE_UNPAGINATED, order: DEFAULT_ORDER, filters };
      return (view === CalendarView.RolesAndAuditRoles
        ? fetchProjectsRoles(params)
        : fetchProjectsEngagements(params)) as unknown as Promise<ProjectsResponse>;
    },
    retry: false,
    placeholderData: keepPreviousData,
    ...options
  });
};
