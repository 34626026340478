import chunk from "lodash/chunk";
import LazyLocalExportTrigger from "PFApp/components/document_buttons/lazy_local_export_trigger";
import { Icon } from "PFComponents/icon";
import { Tabs } from "PFComponents/tabs";
import { useRoleName } from "PFCore/helpers/activities";
import { getExportProfiles } from "PFCore/services/export_profiles";
import PropTypes from "prop-types";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import css from "./compare_header.module.scss";

const CompareHeader = ({ task, tabActiveId, changeTab, comparableProfiles, isBlindModeOn }) => {
  const { t } = useTranslation("activities");
  const getRoleName = useRoleName();
  const location = useLocation();
  const profilesLength = comparableProfiles?.length || 0;

  const TABS = [
    { id: "details", title: t("compare.compareDetails") },
    { id: "availability", title: t("compare.compareAvailabity") }
  ];

  const prevPath = useRef(location.state?.prevPath);
  return (
    <div className={css.root}>
      <div className={css.header}>
        <Link className={css.back} to={prevPath.current || `/activities/${task.id}/shortlist`}>
          <Icon name="chevron-left" size="xs" />
          {t("compare.exitCompare")}
        </Link>
        <LazyLocalExportTrigger
          quantity={profilesLength}
          getProfilesCollection={(paginationPage = 1, paginationSize = 50) => {
            const items = comparableProfiles.map(({ profile }) => profile);

            const paginatedArray = chunk(items, paginationSize);
            return getExportProfiles({
              profileIds: paginatedArray[paginationPage - 1].map(({ id }) => id),
              activityId: task.id
            }).then((resp) => ({
              entries: resp.map((profile) => ({
                profile
              })),
              meta: { total: items.length, page: paginationPage }
            }));
          }}
          isBlindModeOn={isBlindModeOn}
        />
      </div>

      <div className={css.row}>
        <div className={css.tabs}>
          <Tabs onChange={changeTab} tabs={TABS} activeTab={tabActiveId} />
        </div>
        {task && task.name && <h1 className={css.name}>{getRoleName(task)}</h1>}
      </div>
    </div>
  );
};

CompareHeader.propTypes = {
  task: PropTypes.object.isRequired,
  tabActiveId: PropTypes.oneOf(["details", "availability"]).isRequired,
  changeTab: PropTypes.func.isRequired,
  comparableProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      profile: PropTypes.shape({
        id: PropTypes.number
      }),
      applicable_custom_values: PropTypes.arrayOf(
        PropTypes.shape({
          required_experience: PropTypes.number,
          experience: PropTypes.number,
          text: PropTypes.string,
          importance: PropTypes.number
        })
      )
    })
  ).isRequired,
  isBlindModeOn: PropTypes.bool
};

export default CompareHeader;
