import { fetchActivity } from "PFCore/services/activities";
import { Activity } from "PFTypes/activity";
import { Id } from "PFTypes/id";

import { QueryOptionsWithCallbacks, useQueryWithCallbacks } from "../use_query_with_callbacks";
import activityKeys from "./query_keys";

export const useActivity = (activityId: Id, options: QueryOptionsWithCallbacks<Activity> = {}) =>
  useQueryWithCallbacks<Activity>({
    queryKey: activityKeys.activity(activityId),
    queryFn: () => fetchActivity(activityId),
    retry: false,
    staleTime: 0,
    ...options
  });
