/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import find from "lodash/find";
import map from "lodash/map";
import reduce from "lodash/reduce";
import moment from "moment";
import ChatAttachmentInput from "PFApp/components/chat/chat_attachment_input";
import ChatAttachments from "PFApp/components/chat/chat_attachments";
import ChatBody from "PFApp/components/chat/chat_body";
import ChatLoading from "PFApp/components/chat/chat_loading";
import ChatMessage from "PFApp/components/chat/chat_message";
import ChatSubheader from "PFApp/components/chat/chat_subheader";
import { Button } from "PFComponents/button";
import { ChatInputFieldSet } from "PFComponents/text/chat_input_field_set";
import DateValue from "PFCore/helpers/date_value";
import useDebounce from "PFCore/helpers/use_debounce";
import { useSendChatMessage } from "PFCore/hooks/queries/chat/use_send_chat_message";
import AddEmojiIcon from "PFIcons/add_emoji.svg";
import Send from "PFIcons/paper_plane.svg";
import { EventKey } from "PFTypes/event_key";
import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { isBlank } from "underscore.string";

import css from "./chat_conversation.module.scss";
import { getChatSendOnEnter } from "./send_on_enter";

type ChatConversationProps = {
  attachments: any;
  clearAttachments: () => void;
  conversationId: number;
  currentProfile: any;
  emptyMessage: string;
  isFetchingPreviousMessages: boolean;
  isPrivate: boolean;
  loading: boolean;
  messages: any;
  onAttachmentRemove: (attachment) => void;
  onAttachmentSaved: (attachment) => void;
  onAttachmentSaveFailed: (resp) => void;
  onLoadPreviousMessages: () => void;
  onMinimize: () => void;
  onOpenParticipants: () => void;
  showMentionables: boolean;
  subtitle: string;
};

type AutocompleteRef = { toggleEmojIsOpen: () => void };

export const ChatConversation = ({
  attachments,
  clearAttachments,
  conversationId,
  currentProfile,
  emptyMessage,
  isFetchingPreviousMessages,
  isPrivate,
  loading,
  messages = {},
  onAttachmentRemove,
  onAttachmentSaved,
  onAttachmentSaveFailed,
  onLoadPreviousMessages,
  onMinimize,
  onOpenParticipants,
  showMentionables,
  subtitle
}: ChatConversationProps) => {
  const { t } = useTranslation(["core", "translation"]);

  const [state, setState] = useState({
    messagesCount: 0,
    messageText: "",
    preparedMessageText: "", // have mentions in api-readable form
    showNewMessageTooltip: false
  });

  const inputRef = useRef<HTMLDivElement>(null);
  const chatScrollRef = useRef<any>(null);
  const autocompleteRef = useRef<AutocompleteRef>(null);
  const messageEndRef = useRef<HTMLDivElement>(null);
  const initialLoading = messages.pages?.length === 0 && loading;

  const { mutate: sendMessage } = useSendChatMessage(conversationId);

  useEffect(() => {
    if (chatScrollRef.current.hasSavedScrollPosition()) {
      chatScrollRef.current.restorePosition();

      setState((prevState) => ({ ...prevState, showNewMessageTooltip: true }));
    } else {
      scrollBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (!isFetchingPreviousMessages) {
      chatScrollRef.current.restorePosition();
    }
  }, [isFetchingPreviousMessages]);

  const isSendDisabled = () => {
    const { messageText } = state;

    const emptyMessage = !!isBlank(messageText);
    const noAttachments = attachments.length === 0;
    const anyFakeAttachment = !!find(attachments, { fake: true });

    return anyFakeAttachment || (noAttachments && emptyMessage);
  };

  const scrollBottom = (smooth?: boolean) => {
    messageEndRef.current?.scrollIntoView({ behavior: smooth ? "smooth" : "auto" });
  };

  const handleInputChange = (messageText, preparedMessageText) => {
    setState((prevState) => ({ ...prevState, messageText, preparedMessageText }));
  };

  const handleInputKeyDown = (event) => {
    const sendOnEnter = getChatSendOnEnter();
    const otherMod = event.metaKey || event.ctrlKey;

    if (event.key === EventKey.Enter && ((sendOnEnter && !event.shiftKey) || (!sendOnEnter && otherMod))) {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const { preparedMessageText } = state;

    if (isSendDisabled()) {
      return;
    }

    chatScrollRef.current.cleanScrollPosition();

    const attachmentIds = map(attachments, "id");

    sendMessage({
      attachmentIds,
      plain: preparedMessageText,
      conversationId,
      profileId: currentProfile.id,
      timeAgo: new DateValue(moment().toISOString()).smartAgoFormat(),
      localTimestamp: moment(Date.now()).valueOf()
    });

    setState((prevState) => ({ ...prevState, messageText: "", preparedMessageText: "" }));

    inputRef.current?.querySelector("textarea")?.focus();

    clearAttachments();
  };

  const handleChatBodyScroll = useDebounce(() => {
    chatScrollRef.current.savePosition();

    if (chatScrollRef.current.isBottom() && state.showNewMessageTooltip) {
      setState((prevState) => ({ ...prevState, showNewMessageTooltip: false }));
    }
  }, 200);

  const handleLoadPreviousMessages = () => {
    chatScrollRef.current.savePosition();
    onLoadPreviousMessages();
  };

  const handleReadNewMessages = () => {
    scrollBottom(true);
    setState((prevState) => ({ ...prevState, showNewMessageTooltip: false }));
  };

  const hasMoreMessages = useMemo(() => {
    const { pages = [] } = messages;
    const total = pages[0]?.meta.total || 0;
    const loaded = reduce(pages, (sum, page) => sum + page.entries.length, 0);
    return loaded < total;
  }, [messages]);

  const { messageText, showNewMessageTooltip } = state;

  const actions = (
    <>
      <a
        onClick={() => {
          autocompleteRef.current?.toggleEmojIsOpen();
        }}
      >
        <AddEmojiIcon width={20} height={20} />
      </a>
      <ChatAttachmentInput
        attachments={attachments}
        removeAttachment={(attachment) => {
          onAttachmentRemove(attachment);
        }}
        handleAttachmentSaved={(resp) => {
          onAttachmentSaved(resp);
        }}
        handleAttachmentSaveFailed={(resp) => {
          onAttachmentSaveFailed(resp);
        }}
      />
    </>
  );

  const title = (
    <a
      title={isPrivate ? t("core:chat.type.private") : t("core:chat.type.public")}
      onClick={onOpenParticipants}
      className={css.privateIconLink}
    >
      <b>{isPrivate ? t("translation:private") : t("translation:public")}</b>
    </a>
  );

  return (
    <>
      <ChatSubheader title={initialLoading ? "..." : title}>
        {!loading && <span style={{ float: "right" }}>{subtitle}</span>}
      </ChatSubheader>
      <ChatBody
        ref={chatScrollRef}
        opacity={isFetchingPreviousMessages ? 0.5 : 1}
        onScroll={handleChatBodyScroll}
      >
        {initialLoading ? (
          <ChatLoading />
        ) : (
          <ul className={css.normalBody}>
            {hasMoreMessages && (
              <li className={css.loadMoreLink}>
                <Button small kind="secondary" onClick={handleLoadPreviousMessages}>
                  load previous messages
                </Button>
              </li>
            )}
            {messages.pages?.map(({ entries }) =>
              entries.map((message, i) => (
                <ChatMessage
                  key={message.cid}
                  owned={currentProfile.id === message.profile_id}
                  message={message}
                  prevMessage={entries.at(i - 1)}
                  nextMessage={entries.at(i + 1)}
                  handleMinimize={onMinimize}
                />
              ))
            )}
            <div ref={messageEndRef} />
          </ul>
        )}
        {!loading && messages.pages?.length === 0 && (
          <div className={css.noMessages}>
            {emptyMessage ? (
              <span>{emptyMessage}</span>
            ) : (
              <React.Fragment>
                <span>No one has replied to this activity yet.</span>
                <span>Start a conversation here.</span>
              </React.Fragment>
            )}
          </div>
        )}
      </ChatBody>

      <div ref={inputRef} className={css.inputWrap} data-qa-id="chat-conversation-input">
        {showNewMessageTooltip && (
          <a className={css.newMsgTooltip} onClick={handleReadNewMessages}>
            {t("core:chat.newMessage")}
          </a>
        )}
        <div className={css.inputText}>
          {attachments && attachments.length > 0 && (
            <ChatAttachments
              attachments={attachments}
              removeAttachment={(attachment) => {
                onAttachmentRemove(attachment);
              }}
            />
          )}
          <ChatInputFieldSet
            ref={autocompleteRef}
            value={messageText}
            controlledValue
            placeholder={t("core:chat.typeMessage")}
            minHeight={40}
            maxHeight={200}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            profileId={currentProfile.id}
            conversationId={conversationId}
            displayMentions={showMentionables}
            displayAbove={true}
            maxLength={3000}
            minVisibleMaxLengthFactor={0.95}
            maxLengthLabelClassName={css.maxLengthLabel}
            actions={actions}
          />
        </div>
        <div className={css.sendWrap}>
          <Button
            key={messages.length /* avoid handing hover state on mobile */}
            className={css.button}
            onClick={handleSubmit}
            disabled={isSendDisabled()}
            qaId="chat-conversation-send"
          >
            <Send width={13} height={13} />
          </Button>
        </div>
      </div>
    </>
  );
};
