import SidePanel from "PFComponents/side_panel/side_panel";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ShortlistDetailAction } from "../../../parts/overview/detail_view/shortlist/shortlist_detail_action";
import { ShortlistItems } from "../../../parts/overview/detail_view/shortlist/shortlist_items";
import BookingActivityContextProvider, {
  useBookingActivityContext
} from "../../../parts/providers/booking_activity_context_provider";
import { DetailsPanelComponentProps, DetailsSidePanelProps } from "../details_panel";
import { RoleDetail } from "./role_detail";
import { RoleDetailHeader } from "./role_detail_header";
import sidePanelCss from "./role_detail_side_panel.module.scss";

type RoleDetailSidePanelProps = DetailsSidePanelProps & Pick<DetailsPanelComponentProps, "data" | "classes">;

const RoleDetailSidePanel = ({
  isOnTop,
  show,
  onClose,
  zIndex = 0,
  data,
  classes
}: RoleDetailSidePanelProps) => {
  const { t } = useTranslation(["bookingModule", "translation"]);
  const { id } = useBookingActivityContext();
  const [allocateManually, setAllocateManually] = useState(false);

  const handleClose = () => {
    setAllocateManually(false);
    onClose();
  };

  return (
    <>
      <SidePanel
        show={show}
        zIndex={zIndex}
        isSidePanelClosing={data.isClosing}
        onClose={handleClose}
        title={<RoleDetailHeader />}
        isOnTop={isOnTop}
        classes={{ show: classes?.show }}
      >
        <RoleDetail setAllocateManually={setAllocateManually} />
      </SidePanel>
      <SidePanel
        show={allocateManually}
        zIndex={zIndex - 1}
        classes={{ show: sidePanelCss.showSecondPanel }}
        title={t("bookingModule:details.shortlisted")}
        actions={<ShortlistDetailAction id={id} />}
        size="medium"
        onClose={() => setAllocateManually(false)}
      >
        {allocateManually && <ShortlistItems />}
      </SidePanel>
    </>
  );
};

export const RoleDetailSidePanelWrapper = React.memo((props: RoleDetailSidePanelProps) => (
  <BookingActivityContextProvider id={props.show ? props?.data?.id : undefined}>
    <RoleDetailSidePanel {...props} />
  </BookingActivityContextProvider>
));

RoleDetailSidePanelWrapper.displayName = "RoleDetailSidePanelWrapper";
