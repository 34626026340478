import { useQuery } from "@tanstack/react-query";
import {
  fetchAttachments,
  FetchAttachmentsPayload,
  FetchAttachmentsResponse
} from "PFCore/services/attachments/fetch_attachments";
import { QueryOptions } from "PFTypes/request_options";

import { attachmentsKeys } from "./query_keys";

export const useAttachments = (
  { targetType, targetId }: FetchAttachmentsPayload,
  options: QueryOptions<FetchAttachmentsResponse> = {}
) =>
  useQuery({
    queryKey: attachmentsKeys.list(targetType, targetId),
    queryFn: () => fetchAttachments({ targetType, targetId }),
    ...options
  });
