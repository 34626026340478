import classNames from "classnames";
import { decamelizeKeys } from "humps";
import cloneDeep from "lodash/cloneDeep";
import { Button } from "PFComponents/button";
import { LoadingDots } from "PFComponents/loading_dots";
import { Typography } from "PFComponents/typography";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { applyChangeRequest } from "PFCore/services/change_requests/apply_change_request";
import { fetchChangeRequest } from "PFCore/services/change_requests/fetch_change_request";
import { ChangeRequest } from "PFTypes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import { useGrowl } from "../use_growl";
import css from "./change_request_page.module.scss";
import { DisabledItems, MergeData } from "./change_request_page.types";
import { AboutSection } from "./parts/about_section";
import { AvatarSection } from "./parts/avatar_section";
import { PositionsSection } from "./parts/positions_section";

export const ChangeRequestPage = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const growl = useGrowl();

  const [changeRequest, setChangeRequest] = useState<ChangeRequest | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const { t } = useTranslation("changeRequests");
  const { formatDate } = useDateFormatter();

  const [disabledItems, setDisabledItems] = useState<DisabledItems>({
    customFields: {},
    positions: {}
  });

  useEffect(() => {
    if (id) {
      fetchChangeRequest(id)
        .then((resp) => {
          setChangeRequest(resp);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [id]);

  const mergeData: MergeData = (change) =>
    setChangeRequest({
      ...(changeRequest as ChangeRequest),
      data: { ...(changeRequest as ChangeRequest).data, ...change }
    });

  const commonProps = {
    mergeData,
    disabledItems,
    setDisabledItems
  };

  const handleConfirm = () => {
    if (!changeRequest) {
      return;
    }

    setIsSaving(true);

    const data: Partial<ChangeRequest["data"]> = cloneDeep(changeRequest.data);

    disabledItems.avatar && delete data.avatar;
    disabledItems.first_name && delete data.first_name;
    disabledItems.last_name && delete data.last_name;
    disabledItems.summary && delete data.summary;

    data.custom_fields = data.custom_fields?.filter(({ type }) => !disabledItems.customFields[type.name]);
    if (!data.custom_fields?.length) {
      delete data.custom_fields;
    }

    data.positions = data.positions?.filter((_, index) => !disabledItems.positions[index]);

    if (!data.positions?.length) {
      delete data.positions;
    }

    applyChangeRequest(id, data)
      .then(() => {
        history.push("/profiles/me");
      })
      .catch(({ response }) => {
        const decamelizedResponse = decamelizeKeys(response); // TODO: [PROF-5746] Remove decamelizeKeys when app is camelized
        growl({
          kind: "error",
          message: t("errors.changesNotSaved")
        });
        setErrors(decamelizedResponse.data.errors);
        setIsSaving(false);
      });
  };

  return (
    <div className={css.root} data-qa-id="ChangeRequestPage">
      <Typography variant="h1">{t("reviewYourCV")}</Typography>
      {changeRequest && (
        <Typography variant="labelRegular">{formatDate(changeRequest.created_at)}</Typography>
      )}

      {isLoading && <LoadingDots circlesEnabled pageViewEnabled />}
      {!isLoading && !changeRequest && (
        <Typography variant="bodyRegular" className={css.empty}>
          {t("notFound")}
        </Typography>
      )}
      {!isLoading && changeRequest && (
        <div className={classNames(css.sections, { [css.fade]: isSaving })}>
          <AvatarSection {...commonProps} data={changeRequest.data} />
          <AboutSection
            {...commonProps}
            data={changeRequest.data}
            errors={errors.profile?.[0]?.errors || {}}
          />
          <PositionsSection {...commonProps} data={changeRequest.data} errors={errors.positions || []} />

          <div className={css.buttonWrap}>
            <Button onClick={handleConfirm} qaId="ChangeRequestPage.confirm">
              {t("confirmData")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
