import { decamelizeKeys } from "humps";
import { ProfileCustomValuesList } from "PFApp/components/custom_values_list";
import { useCustomValuePreviewActions } from "PFApp/hooks/use_custom_value_preview_actions";
import { Card } from "PFComponents/card";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useSkillsType } from "PFCore/hooks/use_skills_type";
import { ActivityPureCustomValue, CustomValueScope, MatchCustomValue, Profile } from "PFTypes";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Interest } from "../../query/api.types";
import css from "./interest_item.module.scss";

const VISIBLE_SKILLS_LIMIT = 3;

type InterestItemProps = {
  interest: Interest;
  profile: Profile;
};

export const InterestItem = ({ interest, profile }: InterestItemProps): JSX.Element => {
  const { t } = useTranslation("profiles");
  const { formatDate } = useDateFormatter();
  const { showPreview } = useCustomValuePreviewActions();
  const skillsType = useSkillsType();

  const { activity } = interest;

  const activitySkills = activity.customFields.find(({ type }) => type.name === "skills")?.values || [];
  const activitySkillsWithProfileExperience = activitySkills.map((skill) => {
    const profileSkill = (profile.skills || []).find(({ id }) => id === skill.id);

    return { ...profileSkill, ...decamelizeKeys(skill) };
  }) as (MatchCustomValue | ActivityPureCustomValue)[];

  return (
    <Card className={css.root}>
      <small>
        {t("show.parts.interestRegistered")} {formatDate(interest.createdAt)}
      </small>
      <Link to={`/activities/${activity.id}`}>
        <div className={css.title}>{activity.name}</div>
      </Link>
      <ProfileCustomValuesList
        customFieldName="skills"
        limit={VISIBLE_SKILLS_LIMIT}
        customValues={activitySkillsWithProfileExperience}
        profileId={profile.id}
        canEdit
        onMissingClick={(skillId) =>
          showPreview({
            customTypeId: skillsType!.id,
            customValueId: skillId,
            scope: CustomValueScope.Profile,
            profileId: profile.id,
            canEdit: true,
            isNew: true
          })
        }
      />
    </Card>
  );
};

export default InterestItem;
