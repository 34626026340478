import { QueryKey } from "@tanstack/react-query";
import { fetchInterestQuestionnaires, InterestQuestionnairesResponse } from "PFCore/services/common";

import { QueryOptionsWithCallbacks, useQueryWithCallbacks } from "../use_query_with_callbacks";

export const INTEREST_QUESTIONNAIRES_KEY: QueryKey = ["interestQuestionnaires"];

export const useInterestQuestionnaires = (
  options: QueryOptionsWithCallbacks<InterestQuestionnairesResponse> = {}
) =>
  useQueryWithCallbacks<InterestQuestionnairesResponse>({
    queryFn: fetchInterestQuestionnaires,
    queryKey: INTEREST_QUESTIONNAIRES_KEY,
    ...options
  });
