import { useQuery } from "@tanstack/react-query";
import isEmpty from "lodash/isEmpty";
import some from "lodash/some";
import { RELEVANT_TO_ME_ID } from "PFApp/constants/relevant_to_me";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { fetchRelevantToMeFilters } from "PFCore/services/saved_filters";
import { Filters } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";
import { PageTarget, SavedFilter } from "PFTypes/saved_filters";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { savedFiltersKeys } from "./query_keys";

export const useRelevantToMe = (
  target: PageTarget,
  options: QueryOptions<Filters, unknown, SavedFilter | null> = {}
) => {
  const { t } = useTranslation();
  const { data: currentProfile } = useCurrentProfile();

  return useQuery<Filters, unknown, SavedFilter | null>({
    queryFn: () => fetchRelevantToMeFilters(target),
    queryKey: savedFiltersKeys.relevantToMe(target),
    select: useCallback(
      (data: Filters) => {
        const { availability, fields, numbers, strings } = data;
        const isConfigured = some(
          [availability, fields, numbers, strings],
          (filterFamily) => !isEmpty(filterFamily)
        );

        return isConfigured
          ? {
              id: RELEVANT_TO_ME_ID,
              profileId: currentProfile.id,
              target,
              name: t("relevantToMe"),
              ...data
            }
          : null;
      },
      [currentProfile.id, target, t]
    ),
    ...options
  });
};
