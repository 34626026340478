import orderBy from "lodash/orderBy";
import take from "lodash/take";
import { useCustomValuePreviewActions } from "PFApp/hooks/use_custom_value_preview_actions";
import { CustomValuePill } from "PFComponents/custom_value_pill";
import { isRankable } from "PFCore/helpers/custom_type";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { ActivityCustomValue, ActivityPureCustomValue, CustomValueScope, Id } from "PFTypes";

import { CustomValuesListCommonProps, DEFAULT_LIMIT } from "./custom_values_list.consts";
import css from "./custom_values_list.module.scss";
import { useCustomValuesVisibility } from "./hooks/use_custom_values_visibility";
import { CustomValuesDivider } from "./parts/custom_values_divider";
import { CustomValuesWrapper } from "./parts/custom_values_wrapper";

export type ActivityCustomValuesListProps = CustomValuesListCommonProps & {
  customValues: (ActivityCustomValue | ActivityPureCustomValue)[];
  activityId: Id;
};

export const ActivityCustomValuesList = ({
  customFieldName,
  customValues,
  activityId,
  canEdit = false,
  limit = DEFAULT_LIMIT,
  dark = false,
  vertical = false,
  maxNameLength,
  onMoreLessButtonClick
}: ActivityCustomValuesListProps) => {
  const { getCustomTypeByName } = useCustomTypes();
  const { showPreview } = useCustomValuePreviewActions();

  const sortedCustomValues = orderBy(customValues, "value");

  const { visibleAvailableCustomValuesCount, showAll, displayShowMoreButton, toggleShowAll } =
    useCustomValuesVisibility({
      availableCustomValuesCount: sortedCustomValues.length,
      missingCustomValuesCount: 0,
      limit
    });

  const handleMoreLessButtonClick = () => {
    toggleShowAll();
    onMoreLessButtonClick?.();
  };

  const customType = getCustomTypeByName(customFieldName);
  const isCustomTypeRankable = Boolean(customType && isRankable(customType));

  return (
    <CustomValuesWrapper
      displayShowMoreButton={displayShowMoreButton}
      showAll={showAll}
      dark={dark}
      limit={limit}
      vertical={vertical}
      onMoreLessButtonClick={handleMoreLessButtonClick}
    >
      {take(sortedCustomValues, visibleAvailableCustomValuesCount).map((customValue, index) => {
        const isLastCustomValue = index === visibleAvailableCustomValuesCount - 1;

        return (
          <span className={css.customValueWithDivider} key={customValue.id}>
            <CustomValuePill
              isRequirement
              experience={isCustomTypeRankable ? customValue.required_experience : undefined}
              name={String(customValue.value)}
              theme={dark ? "dark" : "light"}
              maxNameLength={maxNameLength}
              onClick={() => {
                showPreview({
                  scope: CustomValueScope.Activity,
                  customTypeId: customType!.id,
                  customValueId: customValue.id,
                  activityId,
                  canEdit
                });
              }}
            />
            {!isLastCustomValue && !vertical && <CustomValuesDivider />}
          </span>
        );
      })}
    </CustomValuesWrapper>
  );
};
