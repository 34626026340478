import find from "lodash/find";
import { AvailabilityMode } from "PFTypes/booking";
import { useTranslation } from "react-i18next";

export const useModeOptions = (
  mode: Omit<AvailabilityMode, AvailabilityMode.TimeRule>,
  allowedModes: string[]
) => {
  const { t } = useTranslation();

  const availabilityModes = [
    {
      displayElement: t("availabilityRequirement.withinPeriod"),
      id: AvailabilityMode.Within,
      value: AvailabilityMode.Within,
      item: AvailabilityMode.Within
    },
    {
      displayElement: t("availabilityRequirement.perWeek"),
      id: AvailabilityMode.PerWeek,
      value: AvailabilityMode.PerWeek,
      item: AvailabilityMode.PerWeek
    },
    {
      displayElement: t("availabilityRequirement.perMonth"),
      id: AvailabilityMode.PerMonth,
      value: AvailabilityMode.PerMonth,
      item: AvailabilityMode.PerMonth
    }
  ].filter(({ id }) => allowedModes.includes(id));

  const currentMode = find(availabilityModes, ["id", mode]) || availabilityModes[0];

  if (!currentMode) {
    throw new Error("Mode not supported");
  }

  return { currentMode, availabilityModes };
};
