import { useQueryClient } from "@tanstack/react-query";
import { Id } from "PFTypes";
import { useCallback } from "react";

import { activityHistoryKeys } from "./query_keys";

export const useActivityHistoryInvalidate = (activityIdOrIdsParam?: Id[] | Id) => {
  const cache = useQueryClient();

  return useCallback(
    (activityIdOrIds: Id[] | Id | undefined = activityIdOrIdsParam) => {
      if (!activityIdOrIds) {
        return;
      }

      const activityIds = Array.isArray(activityIdOrIds) ? activityIdOrIds : [activityIdOrIds];

      activityIds.forEach((activityId) => {
        cache.invalidateQueries({ queryKey: activityHistoryKeys.all(activityId) });
      });
    },
    [activityIdOrIdsParam, cache]
  );
};
