import { useQuery } from "@tanstack/react-query";
import { fetchInvites, FetchInvitesResponse } from "PFCore/services/invites/fetch_invites";

import { invitesKeys } from "./query_keys";

export const useInvites = (targetId: number | null = null) =>
  useQuery<FetchInvitesResponse["entries"]>({
    queryKey: invitesKeys.list("Task", targetId ? [targetId] : null),
    queryFn: () => fetchInvites({ targetType: "Task", targetId: targetId! }).then(({ entries }) => entries),
    placeholderData: [],
    enabled: targetId !== null
  });
