import findIndex from "lodash/findIndex";
import uniqueId from "lodash/uniqueId";
import { Select } from "PFComponents/select/select";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const SortBy = ({
  className,
  sort,
  onSortChange,
  options,
  noLabel,
  dropdownStyle,
  classes,
  disabled,
  title,
  dropdownListClassName
}) => {
  const { t } = useTranslation();
  const selectedIndex = findIndex(options, { id: sort });

  // Just in case there is still some places where the sort text has not been updated
  const mappedOptions = options.map((item) => {
    if (item.id === "Term similarity") {
      return {
        ...item,
        displayElement: t("mostRelevant")
      };
    } else {
      return item;
    }
  });

  const handleSortChange = (newSort) => {
    if (newSort !== sort) {
      onSortChange(newSort);
    }
  };

  const uniqId = useMemo(() => uniqueId("sortBy"), []);

  return (
    <Select
      title={title}
      className={className}
      inline
      id={uniqId}
      label={noLabel ? null : t("sortBy")}
      onChange={handleSortChange}
      value={sort ? mappedOptions.find(({ id }) => id === sort)?.displayElement : undefined}
      placeholder={t("chooseAnOption")}
      options={mappedOptions}
      selectedIndex={selectedIndex}
      controlledValue
      classes={classes}
      dropdownStyle={dropdownStyle}
      disabled={disabled}
      listClassName={dropdownListClassName}
    />
  );
};

SortBy.propTypes = {
  onSortChange: PropTypes.func.isRequired,
  sort: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      item: PropTypes.object,
      displayElement: PropTypes.node
    })
  ).isRequired,
  style: PropTypes.object,
  dropdownStyle: PropTypes.object,
  noLabel: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.shape({
    selectRoot: PropTypes.string
  }),
  disabled: PropTypes.bool,
  kind: PropTypes.oneOf(["bordered", "blank"]),
  title: PropTypes.string,
  dropdownListClassName: PropTypes.string
};

SortBy.defaultProps = {
  classes: {}
};

export default SortBy;
