import "PFCore/initializers/set_storage";

import extend from "lodash/extend";
import isNil from "lodash/isNil";

const envConfig = {
  api_endpoint: process.env.api_endpoint,
  development_domain: process.env.development_domain,
  pusher_key: process.env.pusher_key,
  client_id: process.env.client_id,
  environment: process.env.environment,
  datadog_environment: process.env.datadog_environment,
  pusher_host: process.env.pusher_host,
  pusher_cluster: process.env.pusher_cluster,
  insights_api_endpoint: process.env.insights_api_endpoint,
  force_ssl: process.env.force_ssl,
  segmentio_write_key: process.env.segmentio_write_key,
  google_analytics_key: process.env.google_analytics_key,
  datadog_application_id: process.env.datadog_application_id,
  datadog_client_token: process.env.datadog_client_token,
  datadog_site: process.env.datadog_site,
  chameleon_token: process.env.chameleon_token,
  ui_version: __UI_VERSION__,
  ui_branch: __UI_BRANCH__
};

const STORAGE_KEY = "profinda_custom_configuration";

const getDevConfig = () => {
  const customConfig = storage.getItem(STORAGE_KEY);
  if (customConfig) {
    return extend(envConfig, customConfig);
  }

  return envConfig;
};

const getProdConfig = () => {
  const { locale } = storage.getItem(STORAGE_KEY) || {};

  if (isNil(locale)) {
    return envConfig;
  }

  return extend(envConfig, { locale });
};

export const getConfig = () => {
  if (["development", "staging", "multistaging"].includes(envConfig.environment)) {
    return getDevConfig();
  }

  return getProdConfig();
};

export default envConfig;
