import { useQueryClient } from "@tanstack/react-query";
import { Id } from "PFTypes/id";

import { rankedShortlistsKeys, shortlistsKeys } from "./query_keys";

export const useShortlistsInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidateShortlists: (id?: Id) => {
      if (id === undefined) {
        queryClient.invalidateQueries({ queryKey: shortlistsKeys.all() });
        return;
      }

      queryClient.invalidateQueries({ queryKey: shortlistsKeys.activityShortlists(id) });
      queryClient.invalidateQueries({ queryKey: shortlistsKeys.activitySuspendedShortlists(id) });
    },
    invalidateRankedShortlists: (id?: Id) => {
      if (id === undefined) {
        queryClient.invalidateQueries({ queryKey: rankedShortlistsKeys.all() });
        return;
      }

      queryClient.invalidateQueries({ queryKey: rankedShortlistsKeys.activityRankedShortlists(id) });
    }
  };
};
