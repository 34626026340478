import { SortDirection } from "@tanstack/react-table";
import orderBy from "lodash/orderBy";
import { ProfileMisalignments } from "PFApp/booking/parts/hooks/use_get_misalignment_bars/get_misalignment_items_by_row";
import { setTimeToMidnight } from "PFApp/booking/parts/overview/calendar/calendar.utils";
import { useMemo } from "react";

import { WarningAccordion } from "../../warnings_detail/warning_accordion";
import { MisalignmentHeader } from "./misalignment_header";
import { MisalignmentItem } from "./misalignment_item";
import { MisalignmentSubHeader } from "./misalignment_subheader";

type MisalignmentsListProps = {
  misalignments: ProfileMisalignments[];
  selectedOrder: SortDirection;
  goToDate?: (date: Date) => void;
};

export const MisalignmentsList = ({ misalignments, selectedOrder, goToDate }: MisalignmentsListProps) => {
  const flattenMisalignments = misalignments.flatMap(({ profileId, misalignments }) =>
    misalignments.map((misalignment) => ({
      ...misalignment,
      profileId
    }))
  );
  const sortedMisalignments = orderBy(
    flattenMisalignments,
    [({ startDate }) => new Date(startDate).getTime(), ({ endDate }) => new Date(endDate).getTime()],
    [selectedOrder, selectedOrder]
  );

  const misalignmentNodes = useMemo(
    () =>
      sortedMisalignments.map(({ profileId, startDate, endDate, errors }) => {
        const misalignmentKey = `${profileId}_${startDate}`;
        return (
          <WarningAccordion
            key={misalignmentKey}
            onShowClick={goToDate ? () => goToDate(setTimeToMidnight(new Date(startDate))) : undefined}
            header={<MisalignmentHeader start={startDate} end={endDate} />}
            subHeader={<MisalignmentSubHeader errors={errors} />}
          >
            {Object.entries(errors)
              .filter(([, errorValues]) => errorValues.length === 2)
              .map(([errorType, errorValues], i) => (
                <MisalignmentItem key={i} errorType={errorType} errorValues={errorValues} />
              ))}
          </WarningAccordion>
        );
      }),
    [goToDate, sortedMisalignments]
  );

  return <>{misalignmentNodes}</>;
};
