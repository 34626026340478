import { datadogRum } from "@datadog/browser-rum";
import SortBy from "PFApp/components/sort_by";
import { Button } from "PFComponents/button";
import { Stack } from "PFComponents/containers";
import { Experience, Profile, ProfileCustomValue } from "PFTypes";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { groupSkillsByExperience } from "../charts/helpers";
import SkillsCategoriesList from "../skills_categories_list";
import { VerticalSkillsList } from "../vertical_skills_list";
import css from "./skills_power_list.modules.scss";
import { SkillsProficiencyGroup } from "./skills_proficiency_group";

type SkillsPowerListProps = {
  skills: ProfileCustomValue[];
  profile: Profile;
  searchTerm: string;
  clearSearch: VoidFunction;
};

export const SkillsPowerList = ({ skills, profile, searchTerm, clearSearch }: SkillsPowerListProps) => {
  const { t } = useTranslation("profiles");

  useEffect(() => {
    if (datadogRum) {
      const unrankedSkills = skills.filter(({ experience }) => !experience);

      if (unrankedSkills.length > 0) {
        datadogRum.addError(new Error("Unranked skills were found"), { unrankedSkills });
      }
    }
  }, []);

  const sortOptions = useMemo(
    () =>
      [
        { text: t("show.parts.proficiency"), value: "proficiency" },
        { text: t("show.parts.skillCategory"), value: "skill_category" },
        { text: t("show.parts.alphabetical"), value: "alphabetical" }
      ].map((item) => ({
        id: item.value,
        displayElement: item.text,
        item
      })),
    [t]
  );

  const [selectedSort, setSelectedSort] = useState(sortOptions[0].id);

  const { basic, intermediate, advanced } = useMemo(() => groupSkillsByExperience(skills), [skills]);

  const selectedView = {
    proficiency: (
      <Stack>
        <SkillsProficiencyGroup skills={advanced} experience={Experience.Advanced} profileId={profile.id} />
        <SkillsProficiencyGroup
          skills={intermediate}
          experience={Experience.Intermediate}
          profileId={profile.id}
        />
        <SkillsProficiencyGroup skills={basic} experience={Experience.Basic} profileId={profile.id} />
      </Stack>
    ),
    skill_category: <SkillsCategoriesList skills={skills} profile={profile} />,
    alphabetical: (
      <div className={css.skillList}>
        <VerticalSkillsList
          skills={skills.sort((skill1, skill2) => skill1.text.localeCompare(skill2.text))}
          profileId={profile.id}
        />
      </div>
    )
  }[selectedSort];

  const searchLength = advanced.length + intermediate.length + basic.length;
  const hasSearchTerm = searchTerm && searchTerm.length > 0;

  return (
    <div className={css.container}>
      <div className={css.header}>
        {hasSearchTerm && (
          <div className={css.headerSearchWrapper}>
            <div className={css.headerSearchActions}>
              <div className={css.headerSearch}>
                <span>
                  <Trans
                    i18nKey="show.parts.searchedTermFoundCountSkills"
                    t={t}
                    values={{ count: searchLength, searchTerm }}
                    components={[<strong key="0" />]}
                  />
                </span>
              </div>
              <div className={css.headerSearch}>
                <Button icon="cross" text={t("show.parts.clearSearch")} kind="text" onClick={clearSearch} />
              </div>
            </div>
          </div>
        )}
        <SortBy
          options={sortOptions}
          onSortChange={(sort) => setSelectedSort(sort.value)}
          sort={selectedSort}
        />
      </div>
      {selectedView}
    </div>
  );
};
