import { ApiRoute } from "PFCore/utilities/routes";

export type FetchParticipantsPayload = {
  conversationId: number;
  profileId?: number;
  withMessage?: boolean;
  page?: number;
  perPage?: number;
};

type FetchParticipantsResponse = {
  entries: any[];
  meta: { total: number };
};

export const fetchParticipants = ({
  conversationId,
  withMessage = false,
  page = 1,
  perPage = 50
}: FetchParticipantsPayload): Promise<FetchParticipantsResponse> => {
  const searchParams = new URLSearchParams({
    conversation_id: conversationId.toString(),
    with_message: withMessage.toString(),
    page: page.toString(),
    per_page: perPage.toString()
  });
  // @ts-ignore
  return $.ajax({
    url: ApiRoute(`/api/chat/participants?${searchParams.toString()}`),
    api_version: 2
  });
};
