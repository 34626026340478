import compact from "lodash/compact";
import flatten from "lodash/flatten";
import minBy from "lodash/minBy";
import times from "lodash/times";
import useWindowSize from "PFCore/helpers/use_window_size";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import css from "./freemasonry_layout.module.scss";

const FreemasonryItem = ({ children, onMount }) => {
  const ref = useRef();
  useEffect(() => {
    ref.current && onMount(children, ref.current.clientHeight);
  }, [ref.current]);

  return (
    <div ref={ref} className={css.item}>
      {children}
    </div>
  );
};

FreemasonryItem.propTypes = {
  onMount: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};

FreemasonryItem.defaultProps = {};

const FreemasonryLayout = ({ children, minColumnWidth, deps }) => {
  const [itemsToHeight, setItemsToHeight] = useState({});
  const [columnsCount, setColumnsCount] = useState(1);
  const [firstRender, setFirstRender] = useState(true);

  const { windowWidth } = useWindowSize();
  const rootRef = useRef();

  useEffect(() => {
    if (rootRef.current) {
      const viewportWidth = rootRef.current.clientWidth;
      setColumnsCount(Math.floor(viewportWidth / minColumnWidth) || 1);
      setFirstRender(false);
    }
  }, [windowWidth, rootRef.current, ...deps]);

  const handleItemMount = (item, height) =>
    setItemsToHeight((itemsToHeight) => ({ ...itemsToHeight, [item.key]: height }));

  const columns = times(columnsCount, () => ({ height: 0, items: [] }));
  // flatten and compact children so we are a bit more flexible when passing them
  compact(flatten(children)).forEach((child) => {
    const col = minBy(columns, (column) => column.height || 0); // get the shortest column
    col.height += itemsToHeight[child.key] || 0;
    col.items.push(child);
  });

  return (
    <div className={css.root} ref={rootRef} style={{ opacity: firstRender ? 0 : 1 }}>
      {columns.map((col, i) => (
        <div key={i} style={{ width: `${100 / columnsCount}%` }}>
          {(col.items || []).map((item) => (
            <FreemasonryItem key={item.key} onMount={handleItemMount}>
              {item}
            </FreemasonryItem>
          ))}
        </div>
      ))}
    </div>
  );
};

FreemasonryLayout.propTypes = {
  minColumnWidth: PropTypes.number,
  /*! use deps if viewport size changes for any other reason the browser window resize */
  deps: PropTypes.array,
  children: PropTypes.any
};

FreemasonryLayout.defaultProps = {
  minColumnWidth: 300,
  deps: [],
  children: null
};

export default FreemasonryLayout;
