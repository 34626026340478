import { keepPreviousData } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { fetchCurrentAccount } from "PFCore/services/accounts/account";
import { CurrentAccount } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";

import { accountKeys } from "./query_keys";

const initialData = {} as CurrentAccount;

export const useCurrentAccount = (options: QueryOptions<CurrentAccount> = {}) => {
  const currentAccountQuery = useQuery<CurrentAccount>({
    queryKey: accountKeys.current(),
    queryFn: () => fetchCurrentAccount(),
    retry: false,
    placeholderData: keepPreviousData,
    initialData,
    ...options
  });

  return {
    ...currentAccountQuery,
    data: currentAccountQuery.data!
  };
};
