import compact from "lodash/compact";
import orderBy from "lodash/orderBy";
import copyTextToClipboard from "PFCore/helpers/copyTextToClipboard";
import { isMsTeams, openChatInMsTeams } from "PFCore/helpers/ms_teams";
import { isProfileFieldPermitted } from "PFCore/helpers/profile_permissions";
import { isFeatureEnabled } from "PFCore/helpers/use_is_feature_enabled";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { IconName } from "PFTheme/graphics/icons";
import { AccessLevel, Profile } from "PFTypes";
import { useTranslation } from "react-i18next";

import { CONTACT_TYPE_TO_ICON_MAP } from "../../constants/contact_type_icon_map";

export type ContactInfo = {
  value: string;
  iconName: IconName;
  type: string;
  title: string;
  url?: string;
  target?: "_blank" | "_self";
  onClick?: VoidFunction;
};

const PHONE_NUMBER_REGEX = /\+?[\d\s-]*\d/;
const TYPES_GUARDED_BY_FEATURE_FLAG = [
  "facebook",
  "twitter",
  "skype",
  "skype_business",
  "instagram",
  "website"
];

const handleCopyContactNumber = (value: string) => {
  if (value) {
    const match = PHONE_NUMBER_REGEX.exec(value);
    const number = match ? match[0] : "";
    copyTextToClipboard(number);
  }
};

export const useContactInfoArray = (profile: Profile): ContactInfo[] => {
  const { t } = useTranslation("profiles", { keyPrefix: "contactInformation" });
  const { data: currentProfile } = useCurrentProfile();
  const { data: currentAccount } = useCurrentAccount();
  const isEnabled = isFeatureEnabled(currentAccount);

  const isContactInfoPermitted = isProfileFieldPermitted(
    currentProfile.permissions_group,
    profile?.permissions_group?.id,
    "contact_info",
    AccessLevel.ReadOnly
  );

  const typeToAriaLabelGetterMap = {
    email: (value) => t("sendEmailTo", { value }),
    phone: (value) => t("copyPhoneNumber", { value }),
    twitter: (value) => t("twitterAccount", { value }),
    instagram: (value) => t("instagramAccount", { value }),
    facebook: (value) => t("facebookAccount", { value }),
    skype: (value) => t("callOnSkype", { value }),
    skype_business: (value) => t("callOnSkypeForBusiness", { value }),
    website: (value) => t("visitWebsite", { value }),
    ms_teams: (value) => t("messageUserInMSTeams", { value })
  };

  const emailContact: ContactInfo | null = profile.email
    ? {
        type: "email",
        value: profile.email,
        url: `mailto:${profile.email}`,
        target: "_self",
        iconName: CONTACT_TYPE_TO_ICON_MAP.email,
        title: typeToAriaLabelGetterMap.email(profile.email)
      }
    : null;

  const profileLinks = (isContactInfoPermitted ? profile.contact_information || [] : [])
    .filter(({ type }) => !TYPES_GUARDED_BY_FEATURE_FLAG.includes(type) || isEnabled(type))
    .map<ContactInfo>(({ type, ...contact }) => {
      const target = ["skype", "skype_business"].includes(type) ? "_self" : "_blank";
      let onClick: VoidFunction | undefined;
      let { value, url } = contact;

      if (type === "ms_teams" && isMsTeams()) {
        value = t("messageInMSTeams");
        const userName = contact.value;
        onClick = () => openChatInMsTeams(userName);
        url = undefined;
      } else if (type === "phone") {
        onClick = () => handleCopyContactNumber(value);

        if (contact.label) {
          value = `${contact.value} ${contact.label}`;
        }
      } else if (type === "website") {
        value = contact.label || contact.value;
      }

      return {
        iconName: CONTACT_TYPE_TO_ICON_MAP[type],
        type,
        value,
        target,
        url,
        title: typeToAriaLabelGetterMap[type](contact.value),
        onClick
      };
    });

  // Order: email, phone, other
  const contacts = orderBy(
    compact([emailContact, ...profileLinks]),
    ({ type }) => ["phone", "email"].indexOf(type),
    "desc"
  );

  return contacts;
};
