import isNil from "lodash/isNil";
import some from "lodash/some";
import { useEngagementFeedbackParticipants } from "PFApp/activities/hooks";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityVacancies } from "PFCore/hooks/queries/activity";
import { useInfiniteActivityWatchers } from "PFCore/hooks/queries/activity/use_activity_watchers";
import { useEffect, useMemo, useState } from "react";

import { useActivityPageRoles } from "./use_activity_page_roles";

export const useActivityPageCounters = () => {
  const { task: activity, matchesMeta, shortlistsMeta, suspendedShortlistsMeta } = useActivityPageContext();
  const { roles } = useActivityPageRoles();

  const { data: vacancies } = useActivityVacancies({ activityId: activity.id });
  const { data: feedbackParticipants } = useEngagementFeedbackParticipants(activity);

  const pendingFeedbacks =
    feedbackParticipants?.entries?.filter((participant) =>
      some(participant.feedback, ({ status }) => status === "pending")
    ) || [];
  const feedbackCount = pendingFeedbacks?.length === 0 ? undefined : pendingFeedbacks?.length;

  const { data: interested } = useInfiniteActivityWatchers(activity, 0);

  const [interestsCount, setInterestsCount] = useState<number | undefined>(interested?.pages[0].meta.total);

  useEffect(() => {
    if (interested && isNil(interestsCount)) {
      setInterestsCount(interested.pages[0].meta.total);
    }
  }, [interested]);

  const counters = useMemo(
    () => ({
      shortlist: Math.max((shortlistsMeta?.total || 0) + (suspendedShortlistsMeta?.total || 0), 0),
      matches: matchesMeta.total,
      roles: roles.meta.total,
      vacancies: vacancies?.meta.total,
      interested: interestsCount,
      feedback: feedbackCount
    }),
    [
      shortlistsMeta.total,
      suspendedShortlistsMeta.total,
      feedbackCount,
      roles.meta.total,
      matchesMeta.total,
      vacancies?.meta.total,
      interestsCount
    ]
  );

  return {
    counters,
    setInterestsCount
  };
};
