import { InputFieldSet } from "PFComponents/text/input_field_set";
import { Control, Controller } from "react-hook-form";

import { BookingNoteInputs } from "../types";
import css from "./booking_note_input.module.scss";

type BookingNoteInputProps = {
  control: Control<BookingNoteInputs>;
  placeholder?: string;
  disabled?: boolean;
};

export const BookingNoteInput = ({ control, disabled, placeholder }: BookingNoteInputProps) => (
  <Controller
    control={control}
    name="content"
    rules={{
      required: true,
      validate: (value) => value.trim().length > 0
    }}
    render={({ field: { onChange, onBlur, value } }) => (
      <InputFieldSet
        value={value}
        className={css.inputRoot}
        onChange={(value, event) => onChange(event)}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
      />
    )}
  />
);
