import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  resetActivityEconomicsScenario,
  ResetActivityEconomicsScenarioPayload
} from "PFCore/services/activity";
import { Id, MutationOptions } from "PFTypes";

import activityKeys from "./query_keys";

export const useActivityEconomicsScenarioReset = (
  scenarioId: Id,
  options: MutationOptions<ResetActivityEconomicsScenarioPayload, void> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation<void, unknown, ResetActivityEconomicsScenarioPayload>({
    mutationFn: resetActivityEconomicsScenario,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.economicsScenario(scenarioId) });
      options.onSuccess?.(...args);
    },
    ...options
  });
};
