import { useQuery } from "@tanstack/react-query";
import { Collection } from "PFTypes";
import { Dispatch, SetStateAction, useState } from "react";

import { fetchInterests } from "./api";
import { Interest } from "./api.types";
import { queryKeys } from "./query_keys";
import { useProfileId } from "./use_profile_id";

const PAGE_SIZE = 5;
const FIRST_PAGE = 1;

export type UseInterestsReturn = {
  entries: Interest[];
  currentPage: number;
  isLoading: boolean;
  totalPages: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
};

const useInterests = (): UseInterestsReturn => {
  const currentVisitedProfileId = useProfileId();

  const [currentPage, setCurrentPage] = useState(FIRST_PAGE);
  const { data, isLoading } = useQuery<Collection<Interest[]>>({
    queryKey: queryKeys.interests(currentPage, currentVisitedProfileId),
    queryFn: async () => await fetchInterests(currentVisitedProfileId, currentPage, PAGE_SIZE)
  });

  return {
    entries: (data?.entries as Interest[]) ?? [],
    currentPage,
    isLoading,
    totalPages: data?.meta.total ? Math.ceil(data?.meta.total / PAGE_SIZE) : 0,
    setCurrentPage
  };
};

export default useInterests;
