import truncate from "lodash/truncate";
import { Button } from "PFComponents/button";
import { Markdown } from "PFComponents/markdown";
import { Profile } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { SummaryModal } from "../summary_modal";
import css from "./side_panel.module.scss";

const SUMMARY_LENGTH_LIMIT = 150;

type SummarySectionProps = {
  profile: Profile;
};

export const SummarySection = ({ profile }: SummarySectionProps): JSX.Element => {
  const { t } = useTranslation("profiles");

  const [renderSummaryModal, setRenderSummaryModal] = useState(false);

  const summary = profile.summary || "";

  return (
    <article className={css.summary} tabIndex={-1}>
      <Markdown
        raw={truncate(summary, {
          length: SUMMARY_LENGTH_LIMIT
        })}
        crop={1e10}
        emojify={false}
      />
      {summary.length > SUMMARY_LENGTH_LIMIT && (
        <Button
          style={{ fontSize: 13, transform: "translateY(-2px)" }}
          kind="text"
          onClick={() => setRenderSummaryModal(true)}
          aria-label={t("show.parts.openFullSummary")}
        >
          {t("show.parts.moreEllipsis")}
        </Button>
      )}
      {renderSummaryModal && <SummaryModal onClose={() => setRenderSummaryModal(false)} summary={summary} />}
    </article>
  );
};
