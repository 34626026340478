import find from "lodash/find";
import map from "lodash/map";
import { useProfiles } from "PFCore/hooks/queries/profiles/use_profiles";
import { Activity, VacanciesCollection, Vacancy } from "PFTypes";
import { useMemo } from "react";

export const useVacancyProfile = (activity: Activity, vacancies?: VacanciesCollection) => {
  const vacanciesFilledProfiles = useMemo(
    () => (vacancies?.entries || []).filter((item) => item.state !== "opened"),
    [vacancies?.entries]
  );

  const filledProfilesIds = useMemo(
    () => map(vacanciesFilledProfiles, "profileId"),
    [vacanciesFilledProfiles]
  );

  const { data: vacanciesProfiles } = useProfiles(
    {
      filters: {
        fields: { id: filledProfilesIds }
      },
      perPage: filledProfilesIds.length
    },
    {
      enabled: filledProfilesIds.length > 0
    }
  );

  const getVacancyProfile = (vacancy: Vacancy) =>
    find(vacanciesProfiles?.entries, ["id", vacancy.profileId])?.profile;
  const getVacancyFilledRole = (vacancy: Vacancy) => find(vacanciesFilledProfiles, ["id", vacancy.id]);

  return {
    getVacancyProfile,
    getVacancyFilledRole
  };
};
