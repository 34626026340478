import classNames from "classnames";
import compact from "lodash/compact";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import maxBy from "lodash/maxBy";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { isAuditRoleDisabled } from "PFApp/audit_planner/parts/helpers";
import { getGrowlId, useGrowl } from "PFApp/use_growl";
import MultiPage from "PFComponents/page/multi_page";
import PageCard from "PFComponents/page/page_card";
import { Tabs } from "PFComponents/tabs";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

import { ActivityHistoryProvider } from "../../parts/history/activity_history_context";
import { Economics } from "../economics";
import { useActivityPageCounters } from "../hooks/use_activity_page_counters";
import css from "./activity_page.module.scss";
import { ComparisonToast } from "./components/comparison_toast";
import {
  ActivityDetails,
  ActivityFeedback,
  ActivityInterested,
  ActivityMatches,
  ActivityRoles,
  ActivityShortlist,
  ActivityVacancies,
  Header
} from "./parts";
import { useCanEditAuditRoles } from "./parts/use_can_edit_audit_roles";

export const ActivityPage = () => {
  const { task, isRole, isAudit, tabs, showTabs, tabActiveId, handleChangeTab, allShortlists, compareIds } =
    useActivityPageContext();

  const growl = useGrowl();

  const { formatDateTime } = useDateFormatter();
  const { canEditAuditRole } = useCanEditAuditRoles();

  const { t } = useTranslation(["activities", "auditPlanner"]);

  const { counters, setInterestsCount } = useActivityPageCounters();

  const tabsWithCounters = useMemo(
    () => tabs.map((tab) => ({ ...tab, count: counters[tab.id] })),
    [tabs, counters]
  );

  const tabPages = useMemo(() => {
    const canViewTab = (tabId: string) => find(tabs, ["id", tabId]);

    return compact([
      canViewTab("details") && <ActivityDetails key="details" />,
      canViewTab("interested") && (
        <ActivityInterested
          key="interests"
          handleInterestsCountChange={(newCount) => setInterestsCount(newCount)}
        />
      ),
      canViewTab("matches") && <ActivityMatches key="matches" />,
      canViewTab("shortlist") && <ActivityShortlist key="shortlist" />,
      canViewTab("roles") && <ActivityRoles key="roles" />,
      canViewTab("vacancies") && <ActivityVacancies key="vacancies" />,
      canViewTab("feedback") && <ActivityFeedback key="feedback" />,
      canViewTab("economics") && <Economics key="economics" />
    ]);
  }, [tabs, setInterestsCount]);

  const isAuditRole = isAudit && isRole;

  const lastActionDate = maxBy(allShortlists || [], "updated_at")?.updated_at;

  const isPageCardVisible = !["feedback", "matches", "shortlist"].includes(tabActiveId || "");

  const fadeHeader = !isEmpty(compareIds);

  const showLastActionDate = tabActiveId === "shortlist" && (!isAuditRole || canEditAuditRole);

  if (isAuditRole && isAuditRoleDisabled(task)) {
    growl({
      id: getGrowlId("no-permission-or-activity-pending-alert-action", "alert"),
      kind: "alert",
      message: t("auditPlanner:parts.noPermissionOrActivityPending"),
      hidePreviousByTypeAndId: true,
      ttl: 5000
    });

    return <Redirect to="/audit_planner/control_centre" />;
  }

  return (
    <ActivityHistoryProvider>
      <div className={css.container}>
        <div className={css.page}>
          <Header className={classNames({ [css.fade]: fadeHeader })} />
          <div className={classNames(css.tabs, { [css.fade]: fadeHeader })}>
            {showTabs && <Tabs onChange={handleChangeTab} tabs={tabsWithCounters} activeTab={tabActiveId} />}
            <div className={css.actions}>
              {showLastActionDate && (
                <span className={css.updatedAt}>{`${t(
                  "activities:show.matches.lastEdited"
                )}: ${formatDateTime(lastActionDate)}`}</span>
              )}
            </div>
          </div>
          {isPageCardVisible ? (
            <PageCard classes={{ wrap: css.card }}>
              <MultiPage items={tabs} active={tabActiveId}>
                {tabPages}
              </MultiPage>
            </PageCard>
          ) : (
            <div className={css.minimalisticPageContainer}>
              <MultiPage items={tabs} active={tabActiveId}>
                {tabPages}
              </MultiPage>
            </div>
          )}
          <ComparisonToast />
        </div>
      </div>
    </ActivityHistoryProvider>
  );
};
