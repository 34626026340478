import { useQuery } from "@tanstack/react-query";
import { fetchActivityFeedback } from "PFCore/services/activities";
import { ActivityFeedback } from "PFTypes/activity_feedback";
import { QueryOptions } from "PFTypes/request_options";

import activityKeys from "./query_keys";

export const useActivityFeedback = (feedbackId?: number, options?: QueryOptions<ActivityFeedback>) =>
  useQuery({
    queryKey: activityKeys.feedback(feedbackId),
    queryFn: () => {
      if (!feedbackId) {
        return;
      }
      return fetchActivityFeedback(feedbackId);
    },
    retry: false,
    staleTime: 0,
    ...options
  });
