import { useQuery } from "@tanstack/react-query";
import { fetchProfileFeedback } from "PFCore/services/activities";
import { Collection } from "PFTypes";
import { FeedbackForProfile } from "PFTypes/activity_feedback";
import { QueryOptions } from "PFTypes/request_options";

import activityKeys from "./query_keys";

type ProfileFeedbackResponse = Collection<FeedbackForProfile[]>;

export const useProfileFeedback = (
  profileId: number,
  roleIds: number[],
  options?: QueryOptions<ProfileFeedbackResponse>
) =>
  useQuery<ProfileFeedbackResponse>({
    queryKey: activityKeys.profileFeedback(profileId),
    queryFn: () => fetchProfileFeedback(profileId, roleIds),
    retry: false,
    staleTime: 0,
    ...options
  });
