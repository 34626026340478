import noop from "lodash/noop";
import { createContext, useContext } from "react";

const INITIAL_MODAL_CONTEXT: ModalContextType = {
  closeWithAnimation: noop
};

type ModalContextValue = { closeWithAnimation: (withBeforeClose?: boolean) => void };
type ModalContextType = ModalContextValue | null;

const ModalContext = createContext<ModalContextType>(null);

type ModalContextProviderType = {
  closeWithAnimation: (withBeforeClose?: boolean) => void;
};

export const ModalContextProvider = ({
  closeWithAnimation,
  children
}: React.PropsWithChildren<ModalContextProviderType>) => (
  <ModalContext.Provider value={{ closeWithAnimation }}>{children}</ModalContext.Provider>
);

export const useModalContext = (): ModalContextValue => {
  const modalContext = useContext<ModalContextType>(ModalContext);

  if (!modalContext) {
    throw new Error("useModalContext must be used within a ModalContextProvider");
  }

  return modalContext || INITIAL_MODAL_CONTEXT;
};
