import truncate from "lodash/truncate";
import { Button } from "PFComponents/button";
import { fullMarkdown } from "PFCore/helpers/markdown";
import { CSSProperties, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./markdown.module.scss";

const MAX_CROP_MARGIN = 30;

type MarkdownProps = {
  raw?: string;
  qaId?: string;
  style?: CSSProperties;
  className?: string;
  crop?: number;
  emojify?: boolean;
  onToggle?: () => void;
  displayToggle?: boolean;
};

export const Markdown = ({
  raw = "",
  qaId = "Markdown",
  style,
  className,
  crop = 200,
  emojify = true,
  onToggle,
  displayToggle = true
}: MarkdownProps) => {
  const { t } = useTranslation();

  const [revealed, setRevealed] = useState(false);
  const cropMargin = Math.min(crop * 0.15, MAX_CROP_MARGIN);
  const isShort = (raw || "").length < crop + cropMargin;
  const html = useMemo(() => {
    const text = !crop || revealed || isShort ? raw : truncate(raw, { length: crop });
    return fullMarkdown(text, { emojify });
  }, [crop, emojify, revealed, raw, isShort]);

  return (
    <div style={style} className={className}>
      <div dangerouslySetInnerHTML={{ __html: html }} data-qa-id={qaId} style={style} className={css.md} />
      {displayToggle && crop && !isShort && (
        <Button
          kind="text"
          className={css.more}
          onClick={() => {
            setRevealed((val) => !val);
            onToggle && window.setTimeout(onToggle, 1);
          }}
        >
          {revealed ? t("less") : t("more")}
        </Button>
      )}
    </div>
  );
};
