// TODO: [SP-2809] get rid of query with callbacks
//  all on success use effects should be defined where the query is called
//  or should be removed completely
import type { DefaultError, QueryKey } from "@tanstack/query-core";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { QueryOptions } from "PFCore/types";
import { useEffect } from "react";

export type QueryCallbacks<TData, TError = DefaultError> = {
  onSuccess?: (response: TData) => void;
  onError?: (error: TError) => void;
  onSettled?: (responseOrError: TData | TError | null) => void;
};

export type QueryOptionsWithCallbacks<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = QueryOptions<TQueryFnData, TError, TData, TQueryKey> & QueryCallbacks<TData, TError>;

export const useQueryWithCallbacks = <
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  onSuccess,
  onError,
  onSettled,
  ...queryOptions
}: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & QueryCallbacks<TData, TError>) => {
  const query = useQuery(queryOptions);
  const { enabled } = queryOptions;
  const { data, isError, error } = query;

  useEffect(() => {
    if (enabled === false) {
      return;
    }

    if (data || error) {
      onSettled?.(data || error);
    }

    if (isError) {
      return onError?.(error);
    }

    if (data) {
      onSuccess?.(data);
    }
    // do not execute onSuccess and onError handlers when they change, or when query gets enabled
  }, [data, isError, error]); //eslint-disable-line react-hooks/exhaustive-deps

  return query;
};
