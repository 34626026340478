import { Id } from "PFTypes/id";

import { FetchVacanciesParams } from "../../../../services/vacancies";
import activityKeys from "../query_keys";

const normalizeParams = (params) =>
  ((params && (Array.isArray(params) ? params : [params])) as Array<unknown>) || [];

export const queryKeys = {
  vacancies: (activityId?: Id, params?: FetchVacanciesParams) => [
    ...activityKeys.activity(activityId),
    "vacancies",
    ...normalizeParams(params)
  ]
};
