import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EconomicsVacancy, Id, MutationOptions } from "PFTypes";

import { createActivityEconomicsScenarioAssignment } from "../../../services/activity";
import activityKeys from "./query_keys";

type Payload = { profileId: Id; vacancyId: Id };

export const useActivityEconomicsScenarioAssign = (
  scenarioId: Id,
  options?: MutationOptions<Payload, EconomicsVacancy["assignment"]>
) => {
  const queryClient = useQueryClient();
  const { onSuccess, ...queryOptions } = options || {};

  return useMutation<EconomicsVacancy["assignment"], unknown, Payload>({
    mutationFn: ({ profileId, vacancyId }) =>
      createActivityEconomicsScenarioAssignment(scenarioId, profileId, vacancyId),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.economicsScenario(scenarioId) });
      onSuccess?.(...args);
    },
    ...queryOptions
  });
};
