import { Button } from "PFComponents/button";
import { useTranslation } from "react-i18next";

type ShowMoreButtonProps = {
  showAll: boolean;
  dark?: boolean;
  onClick: VoidFunction;
};

export const ShowMoreButton = ({ dark, showAll, onClick }: ShowMoreButtonProps) => {
  const { t } = useTranslation("core");

  return (
    <Button
      kind="text"
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
      darkBackground={dark}
    >
      {showAll ? t("viewLess") : t("viewMore")}
    </Button>
  );
};
