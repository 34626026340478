import { useQuery } from "@tanstack/react-query";
import { FetchBookings, fetchBookings } from "PFApp/booking/services/api";
import { Booking, PaginatedCollection, QueryOptions } from "PFTypes";

import { profileKeys } from "../profile/query_keys";

export const useBookings = (
  params: FetchBookings,
  options?: QueryOptions<PaginatedCollection<Omit<Booking, "activity">[]>>
) =>
  useQuery<PaginatedCollection<Omit<Booking, "activity">[]>>({
    queryKey: profileKeys.bookings(params.profileId, params),
    queryFn: () => fetchBookings(params),
    ...options
  });
