import { AxiosError } from "axios";
import { useErrorsGrowl } from "PFCore/hooks";
import { useBookingNoteUpdate } from "PFCore/hooks/queries/bookings/notes/use_booking_note_update";
import { useTranslation } from "react-i18next";

import { BookingNoteForm } from "../booking_note_form/booking_note_form";
import css from "./booking_note_edit.module.scss";

type BookingNoteEditProps = {
  bookingId: number;
  id: number;
  content: string;
  onSuccess: () => void;
};

export const BookingNoteEdit = ({ bookingId, id, content, onSuccess }: BookingNoteEditProps) => {
  const { t } = useTranslation();
  const growlError = useErrorsGrowl();

  const { update, isMutating: loading } = useBookingNoteUpdate(bookingId, id, {
    onSuccess,
    onError: ({ response }: AxiosError) => {
      growlError(response);
    }
  });

  const onSubmit = ({ data: { content } }) => update(content.trim());

  return (
    <BookingNoteForm
      defaultValues={{ content }}
      submitLabel={t("save")}
      onSubmit={onSubmit}
      disabled={!!loading}
      classes={{
        root: css.root
      }}
    />
  );
};
