import classNames from "classnames";
import isNumber from "lodash/isNumber";
import sortBy from "lodash/sortBy";
import { BadgesList } from "PFComponents/badges_list/badges_list";
import SkillDetailsTooltipContent from "PFComponents/badges_list/skill_details_tooltip_content";
import { Button } from "PFComponents/button";
import ExpandableBadgesList from "PFComponents/expandable_badges_list/expandable_badges_list";
import { LegacyModal } from "PFComponents/modal/legacy_modal";
import ICON_SIZES from "PFCore/components/icons/icon_sizes";
import RemoveIcon from "PFCore/components/icons/remove_icon";
import { isRankable } from "PFCore/helpers/custom_type";
import { ownProfileCustomTypesAccessLevels } from "PFCore/helpers/custom_types";
import { requiresApproval } from "PFCore/helpers/custom_value";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import i18n from "PFCore/i18n";
import CheckIcon from "PFIcons/check_3.svg";
import CheckCredlyIcon from "PFIcons/check_credly.svg";
import DumbbellIcon from "PFIcons/dumbbell.svg";
import QuestionMarkIcon from "PFIcons/question_mark.svg";
import PropTypes from "prop-types";
import { PureComponent } from "react";

import Tooltip from "../tooltip/tooltip";
import css from "./custom_values_list.module.scss";
import { RatingIcon } from "./rating_icon";

const CustomValuesList = (props) => {
  const { formatDate } = useDateFormatter();
  return <CustomValuesListComponent {...props} formatDate={formatDate} />;
};

export default CustomValuesList;

class CustomValuesListComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showModal: null };
  }

  valueToString = (type, customValue) => {
    const raw_value = customValue.value;

    if (isNumber(raw_value)) {
      return raw_value;
    }

    if (!raw_value) {
      return "";
    }

    if (type === "date" || type.value_type === "date") {
      return this.props.formatDate?.(raw_value);
    }

    return raw_value.name ? raw_value.name : raw_value;
  };

  customValueText(type, customValue) {
    return this.valueToString(type, customValue);
  }

  customValueToBadgeProps(type, customValue) {
    const text = this.customValueText(type, customValue);
    const { currentProfile, profileId } = this.props;
    return {
      ...customValue,
      _key: customValue.global_id || customValue.id || text,
      id: customValue?.id,
      text,
      children: this.rightIcons(customValue, text),
      kind: this.badgeKind(customValue),
      disabled: requiresApproval(type, customValue),
      icon: this.badgeIcon(type, customValue),
      topRightIcon: this.topRightIcon(customValue),
      classes: customValue.classes,
      showTooltip: requiresApproval(type, customValue),
      experience: customValue?.experience,
      expiryDate: customValue?.expiry_date,
      rankable: isRankable(type),
      canEditRank:
        ownProfileCustomTypesAccessLevels(currentProfile)?.[type.id] === "rw" &&
        currentProfile?.id === profileId &&
        isRankable(type)
    };
  }

  topRightIcon(customValue) {
    const { isEditMode, onRemoveClick } = this.props;
    if (isEditMode && onRemoveClick) {
      return (
        <Button
          kind="blank"
          className={css.removeIcon}
          onClick={(event) => {
            event.stopPropagation();
            onRemoveClick(customValue);
          }}
        >
          <RemoveIcon />
        </Button>
      );
    }
  }

  wrapIcon(name, text) {
    const Icon = name === "credly" ? CheckCredlyIcon : CheckIcon;
    const color = { assessed: "var(--Color-success)", feedback: "var(--paletteBlue3)" }[name];

    return (
      <Tooltip
        title={text}
        content={
          <div className={css.rightIconsTooltip}>
            <div className={css.rightIconsTooltipRow}>
              <Icon className={css.checkIcon} fill={color} height={16} width={16} />
              <span>{i18n.t(`core:skillBadge.assessment.${name}`)}</span>
            </div>
          </div>
        }
        disabled={!this.props.showTooltip}
        theme="pf-dark-blue"
      >
        <span>
          <Icon className={css.checkIcon} fill={color} height={12} width={12} />
        </span>
      </Tooltip>
    );
  }

  rightIcons(customValue, text) {
    const { currentAccount } = this.props;
    const isValidatedIconVisible = !!currentAccount?.profile_view_customization?.validated_skill_text;
    const checkmarkIcons = [];
    if (isValidatedIconVisible) {
      if (customValue.assessment_source === "credly") {
        checkmarkIcons.push(this.wrapIcon("credly", text));
      } else if (customValue.assessed) {
        checkmarkIcons.push(this.wrapIcon("assessed", text));
      }
    }
    if (customValue.em_verified) {
      checkmarkIcons.push(this.wrapIcon("feedback", text));
    }
    return checkmarkIcons;
  }

  badgeIcon(type, customValue) {
    const { currentProfile, profileId, isEditMode } = this.props;
    const canEditRank =
      currentProfile?.id === profileId &&
      ownProfileCustomTypesAccessLevels(currentProfile)?.[type.id] === "rw" &&
      isRankable(type) &&
      type.name !== "skills";

    if (!isNumber(customValue.experience) && requiresApproval(type, customValue)) {
      return <QuestionMarkIcon width={ICON_SIZES.md} height={ICON_SIZES.md} data-qa-id="QuestionMarkIcon" />;
    }

    if (this.shouldShowRatingIcon(type, customValue)) {
      const { onRateChange } = this.props;
      return (
        <RatingIcon
          customValue={customValue}
          customType={type}
          isEditMode={!requiresApproval(type, customValue) && (isEditMode || canEditRank)}
          onRateChange={onRateChange}
        />
      );
    }

    if (customValue?.developmental) {
      return <DumbbellIcon height={20} width={20} style={{ fill: "var(--Palette-base-main)" }} />;
    }

    return null;
  }

  badgeKind(customValue) {
    if (customValue.top) {
      return "secondary";
    }
    return customValue.kind || "primary";
  }

  shouldShowRatingIcon(type, customValue) {
    const { currentProfile, profileId, displayAttributeLevel } = this.props;

    if (!displayAttributeLevel) {
      return false;
    }

    if (!isRankable(type)) {
      return false;
    }

    const canEditRank =
      currentProfile?.id === profileId &&
      ownProfileCustomTypesAccessLevels(currentProfile)?.[type.id] === "rw" &&
      isRankable(type) &&
      type.name !== "skills";

    if ((typeof customValue.experience === "undefined" || customValue.experience === null) && !canEditRank) {
      return false;
    }

    return !(
      typeof customValue.experience === "undefined" && typeof customValue.suggested_experience === "undefined"
    );
  }

  openSkillModal = (itemProps) => {
    const { profileId, currentProfile } = this.props;
    profileId &&
      currentProfile &&
      this.setState({
        showModal: {
          badgeProps: itemProps,
          profileId: this.props.profileId,
          currentProfile: this.props.currentProfile,
          isEditMode: itemProps.canEditRank
        }
      });
  };

  render() {
    const {
      type,
      customValues,
      customButton,
      sort,
      moreLimit,
      handleMoreClicked,
      rootClassName,
      expandable,
      isEditMode,
      openByDefault,
      profileId,
      currentProfile,
      icon,
      style,
      hasValuesWithExpiryDate,
      showTooltip,
      tooltipAppendTo,
      showModal
    } = this.props;
    let values = customValues;

    if (sort) {
      values = sortBy(values, (customValue) => `${this.customValueText(type, customValue)}`.toLowerCase());
    }
    values = values.map((cValue) => this.customValueToBadgeProps(type, cValue));

    const Tag = expandable ? ExpandableBadgesList : BadgesList;

    return (
      <>
        <Tag
          style={style}
          values={values}
          moreLimit={isEditMode ? undefined : moreLimit}
          rootClassName={rootClassName}
          handleMoreClicked={handleMoreClicked}
          isEditMode={isEditMode}
          customButton={customButton}
          openByDefault={openByDefault}
          profileId={profileId}
          currentProfile={currentProfile}
          icon={icon}
          hasValuesWithExpiryDate={hasValuesWithExpiryDate}
          handleItemClick={showModal ? this.openSkillModal : null}
          showTooltip={showTooltip}
          tooltipAppendTo={tooltipAppendTo}
        />
        {this.state.showModal && (
          <LegacyModal
            onClose={() => this.setState({ showModal: null })}
            // Redundant skillModal class is needed to make max-width be overwritten
            classes={{ modal: classNames(css.skillModal, css.modal), card: css.skillModalCard }}
            noPadding
          >
            <SkillDetailsTooltipContent
              {...this.state.showModal}
              close={() => this.setState({ showModal: null })}
            />
          </LegacyModal>
        )}
      </>
    );
  }
}

CustomValuesListComponent.propTypes = {
  type: PropTypes.any.isRequired,
  customValues: PropTypes.any.isRequired,
  displayAttributeLevel: PropTypes.bool,
  sort: PropTypes.bool,
  moreLimit: PropTypes.number,
  rootClassName: PropTypes.string,
  handleMoreClicked: PropTypes.func,
  customButton: PropTypes.node,
  expandable: PropTypes.bool,
  isEditMode: PropTypes.bool,
  onRemoveClick: PropTypes.func,
  openByDefault: PropTypes.bool,
  profileId: PropTypes.number,
  currentProfile: PropTypes.object,
  currentAccount: PropTypes.object,
  icon: PropTypes.node,
  onRateChange: PropTypes.func,
  style: PropTypes.object,
  hasValuesWithExpiryDate: PropTypes.bool,
  showTooltip: PropTypes.bool,
  tooltipAppendTo: PropTypes.any,
  showModal: PropTypes.bool,
  formatDate: PropTypes.func
};

CustomValuesListComponent.defaultProps = {
  type: {},
  displayAttributeLevel: true,
  handleMoreClicked: null,
  sort: true,
  moreLimit: 1000000,
  rootClassName: null,
  customButton: null,
  expandable: false,
  isEditMode: false,
  hasValuesWithExpiryDate: false
};
