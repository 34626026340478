import { InfiniteData } from "@tanstack/query-core";
import { keepPreviousData } from "@tanstack/react-query";
import { UseInfiniteQueryResult } from "@tanstack/react-query";
import { useNotificationsInfinite } from "PFCore/hooks/queries/notifications/use_notifications";
import { FetchNotificationsResponse } from "PFCore/services/notifications/fetch_notifications";
import { createContext, Dispatch, SetStateAction, useContext, useState } from "react";

type NotificationsPanelContextData = {
  notifications: UseInfiniteQueryResult<InfiniteData<FetchNotificationsResponse>>;
  target?: string[];
  setTarget: Dispatch<SetStateAction<string[] | undefined>>;
  showUnreadOnly: boolean;
  setShowUnreadOnly: Dispatch<SetStateAction<boolean>>;
};

const NotificationsPanelContext = createContext<NotificationsPanelContextData>(
  {} as NotificationsPanelContextData
);

export const NotificationsPanelContextProvider = ({ children }) => {
  const [target, setTarget] = useState<string[] | undefined>(undefined);
  const [showUnreadOnly, setShowUnreadOnly] = useState<boolean>(false);

  const params = {
    page: 1,
    perPage: 25,
    read: showUnreadOnly ? false : undefined,
    targetTypes: target,
    excludedTargetTypes: ["Chat::Conversation"]
  };

  const notifications = useNotificationsInfinite(params, { placeholderData: keepPreviousData });

  return (
    <NotificationsPanelContext.Provider
      value={{ notifications, target, setTarget, showUnreadOnly, setShowUnreadOnly }}
    >
      {children}
    </NotificationsPanelContext.Provider>
  );
};

export const useNotificationsPanelContext = () => {
  const context = useContext(NotificationsPanelContext);

  if (!context) {
    throw new Error("useNotificationsPanelContext must be used within a NotificationsPanelContextProvider");
  }

  return context;
};
