/* eslint-disable react/prop-types */
import uniqueId from "lodash/uniqueId";
import moment from "moment";
import { Select } from "PFComponents/select/select";
import { getMonths } from "PFCore/helpers/date";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { CalendarSelectProps } from "./month_selector.types";

export const Months = ({
  minDate,
  maxDate,
  displayMonth,
  setDisplayMonth
}: CalendarSelectProps): JSX.Element => {
  const {
    t,
    i18n: { language }
  } = useTranslation("core", { keyPrefix: "components.calendar" });

  const monthsOptions = useMemo(
    () =>
      getMonths()
        .map((monthName, index) => {
          //@ts-ignore-line
          const month = displayMonth.clone().set("month", monthName);
          const isBefore = month.format("YYYY-MM") < moment(minDate).format("YYYY-MM");
          const isAfter = month.format("YYYY-MM") > moment(maxDate).format("YYYY-MM");
          const disabled = isBefore || isAfter;

          return {
            id: index,
            displayElement: <span key={monthName}>{monthName}</span>,
            item: monthName,
            disabled
          };
        })
        .filter(({ disabled }) => !disabled),
    [minDate, maxDate, displayMonth, language]
  );

  const selectedIndex = monthsOptions.findIndex(({ id }) => id === moment(displayMonth).month());

  const handleChange = useCallback(
    (value: number) => setDisplayMonth(moment.utc(displayMonth).clone().set({ month: value })),
    [setDisplayMonth, displayMonth]
  );

  return (
    <Select
      inputFieldSetProps={{
        "aria-label": t("selectMonth")
      }}
      data-qa-id="calendar-select-month"
      value={displayMonth.format("MMMM")}
      readOnly
      controlledValue
      selectedIndex={selectedIndex}
      options={monthsOptions}
      key={uniqueId("select")}
      onChange={handleChange}
    />
  );
};
