import { useRef } from "react";

import css from "../../../activity_page.module.scss";
import { ShortlistMainPanel } from "./parts/shortlist_main_panel";
import { ShortlistSidePanel } from "./parts/shortlist_side_panel";

export const ActivityShortlist = () => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={scrollableContainerRef} className={css.pageCardContainer}>
      <ShortlistSidePanel scrollableContainerRef={scrollableContainerRef} />
      <ShortlistMainPanel />
    </div>
  );
};
