import map from "lodash/map";
import { ProfileItem } from "PFApp/components/profile_item";
import { useGrowl } from "PFApp/use_growl";
import { Modal } from "PFComponents/modal";
import AutoSelect from "PFComponents/select/autoselect";
import { Typography } from "PFComponents/typography";
import { useActivityEconomicsScenarioShare } from "PFCore/hooks/queries/activity/use_activity_economics_scenario_share";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { fetchProfilesOptions } from "PFCore/services/profile";
import { Id } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import css from "./share_scenario_modal.module.scss";

type ShareScenarioModalProps = {
  scenarioId: Id;
  onClose: () => void;
};

export const ShareScenarioModal = ({ scenarioId, onClose }: ShareScenarioModalProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.economics.shareScenarioModal" });
  const growl = useGrowl();

  const { data: currentProfile } = useCurrentProfile();
  const { mutateAsync: shareScenario } = useActivityEconomicsScenarioShare(scenarioId);

  const [profiles, setProfiles] = useState([]);

  const handleShare = async () => {
    await shareScenario(map(profiles, "id"), {
      onSuccess: () => {
        growl({ message: t("shareSuccess"), kind: "success" });
        onClose?.();
      },
      onError: () => {
        growl({ message: t("shareError"), kind: "error" });
      }
    });
  };

  return (
    <Modal
      title={t("title")}
      onOK={handleShare}
      onClose={onClose}
      labelOK={t("okLabel")}
      disableOKButton={!profiles.length}
    >
      <div className={css.shareScenarioBody}>
        <AutoSelect
          key={profiles.length}
          label={t("label")}
          rootClassName={css.shareProfilesSelect}
          query={(term) => fetchProfilesOptions({ term })}
          formatOption={(item) => ({
            id: item.id,
            displayElement: <ProfileItem profile={item} />,
            item: item
          })}
          filterOptions={(options) =>
            options.filter(
              (option) => currentProfile.id !== option.id && !profiles.find(({ id }) => id === option.id)
            )
          }
          values={profiles}
          icon="search"
          controlledValue
          closeOnChange
          handleChange={setProfiles}
          multi
          cache={false}
        />
        <Typography variant="bodyBold" noMargin>
          {t("disclaimerTitle")}
        </Typography>
        <Typography variant="bodyRegular" noMargin>
          {t("disclaimer")}
        </Typography>
      </div>
    </Modal>
  );
};
