import classNames from "classnames";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { FillAndBookButton } from "PFApp/components/fill_and_book_button";
import { useRoleProfileActionsPermissions } from "PFApp/hooks/use_role_profile_actions_permissions";
import { Button } from "PFComponents/button";
import CheckIcon from "PFIcons/check.svg";
import FilledIcon from "PFIcons/filled.svg";
import { Shortlist } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useBookingActivityContext } from "../../../providers/booking_activity_context_provider";
import UndoBookingModal from "../undo_booking_modal";
import { BookButton } from "./buttons/book_button";
import { FillButton } from "./buttons/fill_button";
import css from "./shortlist_actions.modules.scss";

const LABELLED_STATES: Shortlist["state"][] = ["filled", "rejected", "booked"];

type ShortlistActionsProps = {
  item: Shortlist;
};

export const ShortlistActions = ({ item }: ShortlistActionsProps): JSX.Element => {
  const { activity, parentActivity } = useBookingActivityContext();
  const { task, isFillDisabled } = useActivityPageContext();
  const parentActivityId = activity?.parent_activity_id;
  const { checkCanFill, checkCanBook, checkCanFillAndBook } = useRoleProfileActionsPermissions();

  const { t } = useTranslation("translation");
  const [renderUndoModal, setRenderUndoModal] = useState(false);

  const isCompleted = activity?.state === "complete" || parentActivity?.state === "complete";
  const isFilled = item.state === "filled";
  const canFill = activity && checkCanFill(activity, item.profile, item);
  const canBook = activity && checkCanBook(activity, item.profile, item);
  const canFillAndBook = activity && checkCanFillAndBook(activity, item.profile, item);
  const isBookedOrConfirmed = ["booked", "confirmed"].includes(item.state);

  const renderFillAndBookActions = () =>
    canFillAndBook ? (
      <FillAndBookButton
        profileId={item.profile.id}
        shortlistId={item.id}
        activity={task!}
        isFillButtonDisabled={isFillDisabled}
      />
    ) : (
      <div className={css.buttonGroup}>
        {canBook && <BookButton shortListItem={item} disabled={isCompleted} />}
        {canFill && <FillButton shortlistId={item.id} activity={task!} />}
      </div>
    );

  return (
    <>
      <div className={css.actionsButtons}>
        {renderFillAndBookActions()}
        <div className={classNames(css.sideActions, { [css.booked]: isBookedOrConfirmed })}>
          {isBookedOrConfirmed && (
            <Button kind="blank" className={css.button} onClick={() => setRenderUndoModal(true)}>
              {t("undo")}
            </Button>
          )}
          {LABELLED_STATES.includes(item.state) && (
            <Button kind="secondary" className={classNames(css.button, css.filled)}>
              {isFilled && <FilledIcon height={20} width={20} />}
              {isBookedOrConfirmed && <CheckIcon width={12} height={12} />}
              {t(item.state as unknown as TemplateStringsArray)}
            </Button>
          )}
        </div>
      </div>
      {renderUndoModal && (
        <UndoBookingModal
          item={item}
          onClose={() => setRenderUndoModal(false)}
          activityName={activity?.name}
          parentActivityId={parentActivityId}
        />
      )}
    </>
  );
};
