import every from "lodash/every";
import omit from "lodash/omit";
import some from "lodash/some";
import uniq from "lodash/uniq";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { SideDetails } from "PFApp/activities/show/parts/side_details";
import { getGrowlId, useGrowl } from "PFApp/use_growl";
import { Button } from "PFComponents/button";
import { DropdownOption } from "PFComponents/dropdown/dropdown";
import Hr from "PFComponents/hr";
import { LoadingDots } from "PFComponents/loading_dots";
import { Modal } from "PFComponents/modal";
import PageLayout from "PFComponents/page/page_layout";
import PageTitle from "PFComponents/page/page_title";
import { SimplePagination } from "PFComponents/pagination/simple_pagination";
import { useIsCurrentUserPermittedTo } from "PFCore/helpers/use_is_permitted_to";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useCloseVacancies, useReopenVacancy } from "PFCore/hooks/queries/activity";
import { MetaWithPagination, Vacancy as VacancyType } from "PFTypes";
import { Id } from "PFTypes/id";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import css from "./activity_vacancies.module.scss";
import { ClosureReason, VacanciesMetaCustomFields } from "./activity_vacancies.types";
import { requiresProfileId } from "./activity_vacancies.utils";
import { Vacancy } from "./components/vacancy";
import { useActivityPageVacancies } from "./hooks/use_activity_page_vacancies";
import { useVacancyProfile } from "./hooks/use_vacancy_profile";

const CLOSE_VACANCIES_SUCCESS_ACTION_ID = getGrowlId("manager-feedback-form");

const getVacancyNumber = (meta: MetaWithPagination<VacanciesMetaCustomFields>, index: number): number =>
  (meta.perPage || 5) * ((meta.page || 1) - 1) + index + 1;

export const ActivityVacancies = (): JSX.Element => {
  const growl = useGrowl();
  const { t } = useTranslation("activities", { keyPrefix: "show.vacancies" });
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { task, isRole, isEngagement, handleChangeTab } = useActivityPageContext();
  const { vacancies, changePage } = useActivityPageVacancies(task);
  const { getVacancyProfile, getVacancyFilledRole } = useVacancyProfile(task, vacancies);

  const { mutate: closeVacancies } = useCloseVacancies();
  const { mutate: reopenVacancy } = useReopenVacancy();

  const isPermittedTo = useIsCurrentUserPermittedTo();
  const showLoadingDots = !vacancies;
  const vacanciesMeta = vacancies?.meta as MetaWithPagination<VacanciesMetaCustomFields> | undefined;
  const isDraft = task?.state === "draft";

  const [selectedReasons, setSelectedReasons] = useState<Record<Id, string>>({});
  const [selectedCandidates, setSelectedCandidates] = useState<Record<Id, string>>({});
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [selectedVacancyId, setSelectedVacancyId] = useState<Id | null>(null);
  const { getFirstError } = useResponseErrors();

  const selectedCount = Object.keys(selectedReasons).length;
  const handleTitleClick = () => handleChangeTab("details");

  const closureReasons: ClosureReason[] = useMemo(
    () => vacanciesMeta?.availableClosureReasons || [],
    [vacanciesMeta]
  );

  const closeOptions: DropdownOption[] = useMemo(
    () =>
      closureReasons
        .filter(({ hidden }) => !hidden)
        .filter(({ restrictedPolicies: restrictedPolicies }) =>
          every(restrictedPolicies, (policy) => isPermittedTo(policy))
        )
        .map((option) => ({
          id: option.key,
          displayElement: <span>{option.label}</span>,
          item: option.key,
          global_id: option.key // eslint-disable-line camelcase
        })),
    [closureReasons, isPermittedTo]
  );

  const handleCloseVacancies = () => {
    closeVacancies(
      { selectedReasons, closureDetails: selectedCandidates, activityId: task.id },
      {
        onSuccess: (response: PromiseSettledResult<VacancyType>[]) => {
          setSelectedReasons({});
          setSelectedCandidates({});
          const fulfilledPromises = response.filter(
            ({ status }) => status === "fulfilled"
          ) as PromiseFulfilledResult<VacancyType>[];
          const rejectedPromises = response.filter(
            ({ status }) => status === "rejected"
          ) as PromiseRejectedResult[];

          if (fulfilledPromises.length > 0) {
            growl({
              id: CLOSE_VACANCIES_SUCCESS_ACTION_ID,
              kind: "success",
              message: t("vacancyHasBeenClosed", { count: fulfilledPromises.length }),
              hidePreviousByTypeAndId: true,
              ttl: 5000
            });
          }

          if (rejectedPromises.length > 0) {
            const errorMessages = uniq(rejectedPromises.map(({ reason }) => getFirstError(reason.response)));

            errorMessages.forEach((message: string) => {
              growl({
                message,
                kind: "error"
              });
            });
          }
        }
      }
    );
  };

  const handleReopenVacancies = (vacancyId: Id) => {
    reopenVacancy(
      {
        activityId: task.id,
        vacancyId
      },
      {
        onSuccess: () => {
          setSelectedVacancyId(null);
          history.push(`/activities/${id}/edit`);
        }
      }
    );
  };

  const isAutoCompleteReasonNotFilled = useMemo(
    () =>
      some(selectedReasons, (reasonValue, vacancyId) => {
        const reason = closureReasons.find(({ key }) => key === reasonValue);
        return !!reason && requiresProfileId(reason) && !selectedCandidates[vacancyId];
      }),
    [closureReasons, selectedCandidates, selectedReasons]
  );

  const selectedProfileIds = useMemo(
    () =>
      Object.entries(selectedReasons)
        .filter(
          ([vacancyId, reasonValue]) =>
            reasonValue === "filled_with_named_resource" && !!selectedCandidates[vacancyId]
        )
        .map(([vacancyId]) => selectedCandidates[vacancyId]) as Id[],
    [selectedCandidates, selectedReasons]
  );

  return (
    <PageLayout mode="withSidebar">
      <div>
        <PageTitle>{t("vacancies")}</PageTitle>

        {showLoadingDots && <LoadingDots circlesEnabled pageViewEnabled />}
        <div className={css.wrapper} data-qa-id="ActivityVacanciesTabVacanciesContainer">
          {!showLoadingDots && (
            <>
              {vacancies.entries.map((vacancy, index) => (
                <Vacancy
                  key={vacancy.id}
                  vacancyNumber={getVacancyNumber(vacanciesMeta!, index)}
                  closeOptions={closeOptions}
                  closureReasons={closureReasons}
                  reasonValue={selectedReasons[vacancy.id]}
                  candidateValue={selectedCandidates[vacancy.id]}
                  vacancy={vacancy}
                  selectedProfileIds={selectedProfileIds}
                  profile={getVacancyProfile(vacancy)}
                  filledRole={getVacancyFilledRole(vacancy)}
                  onReasonChange={(value: string) => {
                    const oldReason = closureReasons.find(({ key }) => key === selectedReasons[vacancy.id]);
                    const newReason = closureReasons.find(({ key }) => key === value);
                    if (
                      oldReason &&
                      newReason &&
                      requiresProfileId(oldReason) !== requiresProfileId(newReason)
                    ) {
                      setSelectedCandidates(omit(selectedCandidates, vacancy.id));
                    }
                    setSelectedReasons({ ...selectedReasons, [vacancy.id]: value });
                  }}
                  onCandidateChange={(selected: string | number | null) => {
                    setSelectedCandidates({ ...selectedCandidates, [vacancy.id]: String(selected) });
                  }}
                  onReopenVacancy={() => {
                    setSelectedVacancyId(vacancy.id);
                    setDisplayConfirmModal(true);
                  }}
                />
              ))}
              {vacancies.entries.length > 0 && (
                <SimplePagination
                  currentPage={vacanciesMeta!.page || 1}
                  handleClick={changePage}
                  totalPages={vacanciesMeta!.totalPages}
                />
              )}
            </>
          )}
          <Button
            text={t("closeVacancy", { count: selectedCount })}
            className={css.closeButton}
            disabled={selectedCount === 0 || isAutoCompleteReasonNotFilled}
            onClick={handleCloseVacancies}
          />

          {displayConfirmModal && (
            <Modal
              title={t("reopenVacancy")}
              onOK={() => {
                handleReopenVacancies(selectedVacancyId!);
              }}
              onClose={() => {
                setDisplayConfirmModal(false);
                setSelectedVacancyId(null);
              }}
            >
              {t("wantToReopenVacancy")}
            </Modal>
          )}
        </div>

        <PageLayout.WhenOneColumn>
          <Hr />
          <SideDetails
            handleTitleClick={handleTitleClick}
            isEngagement={isEngagement}
            tab="vacancies"
            isRole={isRole}
            isDraft={isDraft}
          />
        </PageLayout.WhenOneColumn>
      </div>
      <PageLayout.WhenTwoColumns>
        <SideDetails
          handleTitleClick={handleTitleClick}
          isEngagement={isEngagement}
          isRole={isRole}
          isDraft={isDraft}
        />
      </PageLayout.WhenTwoColumns>
    </PageLayout>
  );
};
