import difference from "lodash/difference";
import forEach from "lodash/forEach";
import pick from "lodash/pick";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { useGrowl } from "PFApp/use_growl";
import { getVisibleProperties } from "PFCore/helpers/templates";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { useSkillsFrameworksOptions } from "PFCore/hooks/queries/skills_frameworks/use_skills_frameworks_options";
import {
  Activity,
  ActivityPureCustomValue,
  CustomField,
  FeatureFlag,
  Metadata,
  PureCustomValue,
  SkillsFramework,
  SkillsFrameworkCustomValue,
  Subtemplate
} from "PFTypes";
import { useCallback } from "react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormFieldData } from "../../activity_edit_form.types";
import { useActivityEditPageContext, useActivityEditPageFlags } from "../../activity_edit_page_context";
import {
  SkillsFrameworkCustomField,
  SkillsFrameworkDecorated
} from "../../modals/skills_framework_select_modal/skills_framework_select_modal.types";
import { useFieldsAutoPopulation } from "./use_fields_auto_population";
import { useVisiblePermittedProperties } from "./use_visible_permitted_properties";

const EXTRA_SKILLS_FRAMEWORK_SKILLS_LIMIT = 3;

type UseSkillsFrameworkLinkProps = {
  customFields: CustomField[];
  setCustomFields: Dispatch<SetStateAction<CustomField[]>>;
  subtemplate: Subtemplate | null;
  setMetadata: Dispatch<SetStateAction<Metadata>>;
  selectedParentActivity?: Activity;
  cachedFrameworksValues: FormFieldData[];
  cachedSubtemplateValues: FormFieldData[];
  saveFrameworksValuesInCache: (values: FormFieldData[]) => void;
};

export type UseSkillsFrameworkLinkReturn = {
  selectedSkillsFrameworks: SkillsFramework[];
  setSelectedSkillsFrameworks: (
    newSkillsFrameworks: SkillsFramework[],
    skills?: SkillsFramework["skills"],
    customFields?: SkillsFrameworkCustomField[]
  ) => void;
  showSkillsFrameworkButton: boolean;
};

export const useSkillsFrameworkLink = ({
  subtemplate,
  customFields,
  selectedParentActivity,
  cachedFrameworksValues,
  cachedSubtemplateValues,
  saveFrameworksValuesInCache,
  setCustomFields,
  setMetadata
}: UseSkillsFrameworkLinkProps): UseSkillsFrameworkLinkReturn => {
  const { activity, template, isClone } = useActivityEditPageContext();
  const { isEdit } = useActivityEditPageFlags();
  const isEnabled = useIsFeatureEnabled();
  const growl = useGrowl();
  const { t } = useTranslation("activities");

  const [selectedSkillsFrameworks, setSelectedSkillsFrameworks] = useState<SkillsFramework[]>([]);
  const isAlertShown = useRef(false);
  const rejectedCustomFields = useRef<SkillsFrameworkCustomField[]>([]);

  const skillsFrameworksEnabled = isEnabled(FeatureFlag.SkillsFramework);

  const isFrameworkConnected = !!((activity.skills_framework_ids || []).length > 0);

  const { data: skillsFrameworks, isLoading } = useSkillsFrameworksOptions(
    { ids: activity.skills_framework_ids! },
    {
      enabled: (isEdit || isClone) && isFrameworkConnected
    }
  );

  const { getVisiblePermittedProperties } = useVisiblePermittedProperties();
  const visibleProperties = getVisiblePermittedProperties({ subtemplate, template });

  const { populateFromAllSources } = useFieldsAutoPopulation({
    setCustomFields,
    setMetadata,
    subtemplate,
    selectedParentActivity,
    cachedFrameworksValues,
    cachedSubtemplateValues
  });

  const handleSkillsFrameworksChange = useCallback(
    (
      newSkillsFrameworks: SkillsFramework[],
      skills?: SkillsFrameworkDecorated["skills"],
      customFields?: SkillsFrameworkCustomField[]
    ): void => {
      setSelectedSkillsFrameworks(newSkillsFrameworks);
      isAlertShown.current = false;
      rejectedCustomFields.current = [];

      if (!skills || !customFields) {
        return;
      }

      const activitySkills: ActivityPureCustomValue[] = skills.map(
        ({ customValue, importance, requiredExperience }) => ({
          id: customValue.id,
          text: String(customValue.value),
          importance,
          type: "skills",
          required_experience: requiredExperience
        })
      );

      const activityCustomFields: {
        [name: string]: (SkillsFrameworkCustomValue | PureCustomValue)[];
      } = customFields.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.type.name]: curr.values
            .filter(({ selected }) => (curr.conflict ? selected : true))
            .map((value) => pick(value, ["id", "value"]))
        }),
        {}
      );
      activityCustomFields.skills = activitySkills;

      const dataToPopulate: FormFieldData[] = Object.entries(activityCustomFields)
        .filter(([fieldName]) => {
          const property = visibleProperties.find(({ name }) => name === fieldName);
          // SF consists of CFs only. We don't want to populate CF value into non Custom Field template input
          // that is named the same as SF custom field
          return property?.type === "custom_field";
        })
        .map(([fieldName, values]) => ({
          values: uniqBy(values, "id"),
          fieldName
        }));

      populateFromAllSources({ sourceValues: dataToPopulate, source: "skillsFrameworks" });

      rejectedCustomFields.current = customFields.reduce((acc, { conflict, type, values }) => {
        if (conflict) {
          return [...acc, { type, values: values.filter(({ selected }) => !selected) }];
        } else {
          return acc;
        }
      }, []);

      saveFrameworksValuesInCache(dataToPopulate);
    },
    [visibleProperties, populateFromAllSources, saveFrameworksValuesInCache]
  );

  useEffect(() => {
    const selectedSkillsFrameworks = ((skillsFrameworks?.entries || []) as SkillsFramework[]).filter(
      ({ id }) => (activity.skills_framework_ids || []).includes(id)
    );
    setSelectedSkillsFrameworks(selectedSkillsFrameworks);

    if (
      selectedSkillsFrameworks.length > 0 &&
      !checkFrameworkMatched(selectedSkillsFrameworks, customFields)
    ) {
      // If initially there are frameworks that are not matching the form, assume that alert was shown before
      isAlertShown.current = true;
    }
  }, [skillsFrameworks]);

  const showSkillsFrameworkButton = skillsFrameworksEnabled && (!isLoading || !isFrameworkConnected);

  const checkFrameworkMatched = (
    skillsFrameworks: SkillsFramework[],
    customFields: CustomField[]
  ): boolean => {
    const skillsFrameworkSkillsIds = uniq(
      skillsFrameworks.reduce(
        (acc, framework) => [...acc, ...framework.skills.map(({ customValue }) => customValue.id)],
        []
      )
    );
    const skillsField = customFields.find(({ type }) => type.name === "skills");
    const selectedSkillsIds = skillsField?.values.map(({ id }) => id) || [];
    const skillsMatchFramework =
      difference(skillsFrameworkSkillsIds, selectedSkillsIds).length === 0 &&
      difference(selectedSkillsIds, skillsFrameworkSkillsIds || []).length <=
        EXTRA_SKILLS_FRAMEWORK_SKILLS_LIMIT;

    const visibleProperties = getVisibleProperties(subtemplate || template);
    const visiblePropertiesNames = visibleProperties
      .filter(({ type }) => type === "custom_field")
      .map(({ name }) => name);

    const skillsFrameworkCustomFields = skillsFrameworks.reduce(
      (acc: SkillsFrameworkCustomField[], framework: SkillsFramework) => {
        forEach(framework.customFields || [], (field) => {
          const value = field.customValue;
          const existingItem = acc.find(({ type }) => type.id === field.customType.id);
          if (existingItem) {
            existingItem.values = uniqBy([...existingItem.values, value], "id");
          } else if (visiblePropertiesNames.includes(field.customType.name)) {
            acc.push({
              type: field.customType,
              values: [value]
            });
          }
        });
        return acc;
      },
      []
    );

    const customValuesMatchFramework = !skillsFrameworkCustomFields.find(({ type: { id }, values }) => {
      const customField = customFields.find(({ type }) => type.id === Number(id));
      const rejectedCustomField = rejectedCustomFields.current.find(({ type }) => type.id === Number(id));
      const customFieldValuesIds = [
        ...(customField?.values || []),
        ...(rejectedCustomField?.values || [])
      ].map(({ id }) => id);
      const skillFrameworkValuesIds = values.map(({ id }) => id);

      return (
        difference(customFieldValuesIds, skillFrameworkValuesIds).length !== 0 ||
        difference(skillFrameworkValuesIds, customFieldValuesIds).length !== 0
      );
    });

    return skillsMatchFramework && customValuesMatchFramework;
  };

  useEffect(() => {
    if (selectedSkillsFrameworks.length === 0) {
      return;
    }

    if (!isAlertShown.current! && !checkFrameworkMatched(selectedSkillsFrameworks, customFields)) {
      growl({
        message: t("edit.skillFrameworkUnlinkedWarning"),
        kind: "alert"
      });
      isAlertShown.current = true;
    }
  }, [customFields]);

  return {
    selectedSkillsFrameworks,
    setSelectedSkillsFrameworks: handleSkillsFrameworksChange,
    showSkillsFrameworkButton
  };
};
