import { useQueryClient } from "@tanstack/react-query";
import { Id } from "PFCore/types";

import { customValueKeys } from "./query_keys";

export const useCustomValueInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidateCustomValue: (id: Id) => {
      queryClient.invalidateQueries({ queryKey: customValueKeys.singleUnscoped(id) });
    },
    invalidateCustomValueProfileScope: (customValueId: Id, profileId: Id) => {
      queryClient.invalidateQueries({
        queryKey: customValueKeys.singleInProfileScope(customValueId, profileId)
      });
    },
    removeCustomValueProfileScope: (customValueId: Id, profileId: Id) => {
      queryClient.removeQueries({ queryKey: customValueKeys.singleInProfileScope(customValueId, profileId) });
    },
    invalidateCustomValueActivityConnection: (customValueId: Id, activityId: Id) => {
      queryClient.invalidateQueries({
        queryKey: customValueKeys.customValueActivityConnection(customValueId, activityId)
      });
    },
    removeCustomValueActivityConnection: (customValueId: Id, activityId: Id) => {
      queryClient.removeQueries({
        queryKey: customValueKeys.customValueActivityConnection(customValueId, activityId)
      });
    },
    invalidateCustomValueInsights: (id: Id) => {
      queryClient.invalidateQueries({ queryKey: customValueKeys.customValueInsights(id) });
    }
  };
};
