import classNames from "classnames";
import extend from "lodash/extend";
import { Children, cloneElement, CSSProperties, isValidElement, ReactElement } from "react";

import css from "./button_group.module.scss";

type ButtonGroupProps = {
  autoWidth?: boolean;
  children: React.ReactNode;
  compact?: boolean;
  mobileBreak?: boolean;
  style?: React.CSSProperties;
  className?: string;
};

const ButtonGroup = ({
  autoWidth = false,
  children,
  compact = false,
  mobileBreak = false,
  style,
  className
}: ButtonGroupProps) => {
  const childrenCount = Children.toArray(children).length;
  const btnWidth = autoWidth ? "auto" : `${100 / childrenCount}%`;

  return (
    <div
      className={classNames(css.root, className, { [css.mobileBreak]: mobileBreak, [css.compact]: compact })}
      style={style}
    >
      {Children.map(children, (child: ReactElement<{ style: CSSProperties }>, index) => {
        if (!child || !isValidElement(child)) {
          return null;
        }

        const isLast = index === childrenCount - 1;
        const btnStyle = extend(
          {},
          child.props.style,
          mobileBreak ? null : { width: btnWidth },
          isLast ? { marginRight: 0 } : null
        );
        return cloneElement(child, { key: index, style: btnStyle });
      })}
    </div>
  );
};

export default ButtonGroup;
