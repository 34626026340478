import { useQuery } from "@tanstack/react-query";
import { getNextPageParam } from "PFCore/helpers/get_next_page_param";
import {
  fetchNotifications,
  FetchNotificationsPayload,
  FetchNotificationsResponse
} from "PFCore/services/notifications/fetch_notifications";
import { InfiniteQueryOptions, QueryOptions } from "PFTypes/request_options";

import { useInfiniteQuery } from "../use_infinite_query";
import { notificationsKeys } from "./query_keys";

export const useNotifications = (
  params: FetchNotificationsPayload,
  options: QueryOptions<FetchNotificationsResponse> = {}
) =>
  useQuery<FetchNotificationsResponse>({
    queryKey: notificationsKeys.list(params),
    queryFn: () => fetchNotifications(params),
    ...options
  });

export const useNotificationsInfinite = (
  params: FetchNotificationsPayload,
  options: InfiniteQueryOptions<FetchNotificationsResponse> = {}
) =>
  useInfiniteQuery({
    queryKey: notificationsKeys.list(params),
    queryFn: ({ pageParam }) => fetchNotifications({ ...params, page: pageParam }),
    getNextPageParam,
    initialPageParam: 1,
    ...options
  });
