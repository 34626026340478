import pickBy from "lodash/pickBy";
import startsWith from "lodash/startsWith";
import moment from "moment";
import { usePastDaysLimit } from "PFApp/hooks";
import useIsAvailabilityPermitted from "PFApp/use_is_availability_permitted";
import { AVAILABILITY_MODES, AvailabilityInput, ROOT_ERROR_KEY } from "PFComponents/availability";
import Panel from "PFComponents/panel/panel";
import { getVisibleProperties, propertyToTitle } from "PFCore/helpers/templates";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useDateFormatter } from "PFCore/hooks/use_date_formatter";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { useActivityEditFormContext } from "../activity_edit_form_context";
import { useActivityEditPageContext } from "../activity_edit_page_context";
import css from "./sections.module.scss";

const AvailabilitySection = ({ qaIdPrefix }) => {
  const { data: currentAccount } = useCurrentAccount();

  const { template, activity } = useActivityEditPageContext();
  const { resetErrors, metadata, setMetadata: onMetadataChange, errors } = useActivityEditFormContext();
  const pastDaysLimit = usePastDaysLimit();

  const { utc, formatISODate } = useDateFormatter();
  const isAvailabilityPermitted = useIsAvailabilityPermitted();
  const tomorrowISODate = formatISODate(moment().add(1, "days"));

  const templateWithAvailability = useMemo(() => {
    const properties = getVisibleProperties(template);
    return properties.filter((item) => item.name === "availability").length > 0;
  }, [template]);

  const availabilityTemplateTitle = useMemo(
    () => templateWithAvailability && propertyToTitle(template, "availability"),
    [template]
  );

  const availabilityErrors = useMemo(
    () => pickBy(errors, (_, key) => startsWith(key, ROOT_ERROR_KEY)),
    [errors]
  );

  const required = template.schema.required.includes("availability");

  if (!isAvailabilityPermitted || !templateWithAvailability) {
    return null;
  }

  const defaultAvailabilityThreshold = template.schema.properties?.availability?.default_threshold;
  const defaultAvailabilityMode = template.schema.properties?.availability?.default_mode;
  const allowedModes = template.schema.properties?.availability?.allowed_modes || AVAILABILITY_MODES;

  const { availability = {} } = metadata;

  const handleAvailabilityChange = (availability, timeRuleModeChecked) => {
    onMetadataChange((prev) => {
      if (availability) {
        return { ...prev, availability };
      } else {
        const newMeta = { ...prev, availability: {} };
        // clear sorting, so order by "availability match" is not left after availability is cleared
        if (newMeta.filters) {
          delete newMeta.filters._sort;
        }

        return newMeta;
      }
    });
    timeRuleModeChecked !== undefined && resetErrors();
  };

  const minDate = pastDaysLimit
    ? { start: formatISODate(utc().subtract(pastDaysLimit, "days")), end: tomorrowISODate }
    : { start: tomorrowISODate, end: tomorrowISODate };

  return (
    <Panel className={css.wrapper} qaId={`${qaIdPrefix}-availability`}>
      <div className={css.header}>
        {availabilityTemplateTitle}
        {required && "*"}
      </div>
      <AvailabilityInput
        availability={availability}
        accountAvailability={currentAccount.availability}
        onChange={handleAvailabilityChange}
        errors={availabilityErrors}
        minDate={minDate}
        allowedModes={allowedModes}
        defaultAvailabilityMode={defaultAvailabilityMode}
        defaultAvailabilityThreshold={defaultAvailabilityThreshold}
        activityId={activity.id}
      />
    </Panel>
  );
};

AvailabilitySection.propTypes = {
  qaIdPrefix: PropTypes.string
};

export default AvailabilitySection;
