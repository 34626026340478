import css from "./border_shadow.module.scss";

type BorderShadowProps = {
  enabled: boolean;
};

export const BorderShadow = ({ enabled }: BorderShadowProps) => (
  <div
    style={{
      opacity: enabled ? 1 : 0
    }}
    className={css.borderShadow}
  />
);
