import isEmpty from "lodash/isEmpty";
import { letFilterRestore } from "PFApp/use_filtered_collection";
import { Button } from "PFComponents/button";
import { Typography } from "PFComponents/typography";
import { FilterWithDefaults } from "PFTypes";
import { SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";

import css from "./availability_side_panel.module.scss";

type AvailabilitySidePanelHeaderProps = {
  availabilityFilterWithDefault: FilterWithDefaults;
  onRestore: (event: SyntheticEvent) => void;
  onClear: (event: SyntheticEvent) => void;
};

export const AvailabilitySidePanelHeader = ({
  availabilityFilterWithDefault,
  onClear,
  onRestore
}: AvailabilitySidePanelHeaderProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.sidePanel.filters" });

  const canRestoreAvailability = letFilterRestore(availabilityFilterWithDefault);
  const availabilityIsClear = isEmpty(availabilityFilterWithDefault.value);

  return (
    <div className={css.title}>
      <Typography variant="h4" noMargin>
        {t("availability")}
      </Typography>
      {!availabilityIsClear && <Button text={t("clear")} icon="filter-clean" kind="text" onClick={onClear} />}
      {canRestoreAvailability && (
        <Button text={t("restore")} icon="history" kind="text" onClick={onRestore} />
      )}
    </div>
  );
};
