import isArray from "lodash/isArray";
import some from "lodash/some";
import { FeedbackQuestion } from "PFTypes/activity_feedback";
import { FeedbackSkill } from "PFTypes/activity_participant";
import { useTranslation } from "react-i18next";

import { FeedbackFormData, useFeedbackFormContext } from "../feedback_form_context_provider";

export const useFeedbackFormMutations = () => {
  const { setFormData, formData, setErrors, errors } = useFeedbackFormContext();
  const { t } = useTranslation("activities");
  const formSkills = formData?.skills || [];

  const addSkill = (skill: FeedbackSkill) => {
    if (isSkillInForm(skill) || !formData) {
      return;
    }

    setFormData({
      ...formData,
      skills: [
        ...formSkills,
        {
          ...skill,
          name: skill.text || "",
          isActive: true
        }
      ]
    });
  };

  const handleSkillActivation = (changedSkill: FeedbackSkill, isActive: boolean) => {
    if (!formData) {
      return;
    }

    setFormData({
      ...formData,
      skills: formSkills.map((skill) =>
        (skill.id || skill.globalId) === (changedSkill.id || changedSkill.globalId)
          ? { ...skill, isActive }
          : skill
      )
    });
  };

  const handleRankChange = (experience: number, skillId?: number | string | null) => {
    if (!formData) {
      return;
    }

    const skills = formSkills.map((skill) =>
      skill.id === skillId || skill.globalId === skillId ? { ...skill, experience } : skill
    );

    setFormData({ ...formData, skills });
  };

  const handleAnswerChange = (value: boolean | string | null, answeredQuestion: FeedbackQuestion) => {
    if (!formData) {
      return;
    }
    const modifiedAnswer = formData.answers?.find(({ questionId }) => questionId === answeredQuestion.id);
    if (modifiedAnswer) {
      const answers = formData.answers?.map((answer) =>
        answer.questionId === answeredQuestion.id ? { ...answer, value } : answer
      );
      setFormData({ ...formData, answers });
    } else {
      setFormData({
        ...formData,
        answers: [...(formData.answers || []), { questionId: answeredQuestion.id, value }]
      });
    }
  };

  const filteredFormData: FeedbackFormData | undefined = formData
    ? {
        ...formData,
        skills: formSkills.filter((skill) => skill.isActive && !!skill.experience)
      }
    : undefined;

  const validateQuestions = () => {
    const newErrors = {};
    (formData?.questions || []).forEach((question) => {
      const answer = formData?.answers?.find((answer) => answer.questionId === question.id);
      if (answer === undefined || answer.value === null || answer.value === "") {
        newErrors[question.id] = t("edit.cantBeBlank");
      }
    });
    setErrors(newErrors);
    return newErrors;
  };

  const updateErrorsOnChange = (val: string | boolean, questionId: number) => {
    const errorField = errors[questionId];
    if (val === "" || errorField === undefined) {
      return;
    }

    const newErrors = { ...errors };
    if (isArray(errorField) && errorField.length > 2) {
      newErrors[questionId] = errorField.filter((error) => error !== t("edit.cantBeBlank"));
    } else if (errorField === t("edit.cantBeBlank")) {
      delete newErrors[questionId];
    }
    setErrors(newErrors);
  };

  const isSkillInForm = (skill: Pick<FeedbackSkill, "id" | "globalId">) =>
    some(formSkills, (selectedSkill) =>
      skill.id ? selectedSkill.id === skill.id : selectedSkill.globalId === skill.globalId
    );

  return {
    addSkill,
    handleActivation: handleSkillActivation,
    handleRankChange,
    handleAnswerChange,
    filteredFormData,
    validateQuestions,
    updateErrorsOnChange,
    isSkillInForm
  };
};
