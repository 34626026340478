import { Button } from "PFComponents/button";
import { Vacancy } from "PFTypes";
import { useTranslation } from "react-i18next";

import { ClosureReason } from "../../../activity_vacancies.types";
import css from "./closed_vacancy.module.scss";
import { ClosureInformation } from "./closure_information";

export type ClosedVacancyProps = {
  vacancy: Vacancy;
  closureReasons: ClosureReason[];
  onReopenVacancy: VoidFunction;
  profile;
  filledRole;
};

export const ClosedVacancy = ({
  vacancy,
  closureReasons,
  onReopenVacancy,
  profile,
  filledRole
}: ClosedVacancyProps): JSX.Element => {
  const { t } = useTranslation("activities", { keyPrefix: "show.vacancies" });

  return (
    <div className={css.filled}>
      <ClosureInformation
        closureDetails={vacancy.closureDetails}
        profile={profile}
        filledRole={filledRole}
        closureReasons={closureReasons}
      />

      <Button text={t("reopen")} onClick={onReopenVacancy} />
    </div>
  );
};
