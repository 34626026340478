import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const useActivityPageCompare = (taskId, tabActiveId, isAudit) => {
  const [compareIds, setCompareIds] = useState({});
  const { tab: auditPlannerRoleTab } = useParams();

  const clearComparisonToast = useCallback(() => setCompareIds({}), []);

  useEffect(() => {
    if (tabActiveId !== "shortlist" && Object.keys(compareIds).length > 0) {
      setCompareIds({});
    }
  }, [compareIds, tabActiveId, auditPlannerRoleTab, isAudit]);

  const isProfileInComparison = useCallback((profileId) => !!compareIds[profileId], [compareIds]);

  const addProfileToComparison = useCallback(
    (profileId) => setCompareIds({ ...compareIds, [profileId]: true }),
    [compareIds]
  );

  const removeProfileFromComparison = useCallback(
    (profileId) => {
      delete compareIds[profileId];
      setCompareIds({ ...compareIds });
    },
    [compareIds]
  );

  return {
    compareIds,
    setCompareIds,
    isProfileInComparison,
    addProfileToComparison,
    removeProfileFromComparison,
    clearComparisonToast
  };
};

export default useActivityPageCompare;
