import isEqual from "lodash/isEqual";
import {
  fetchAutoAllocationSettings as fetchAutoAllocate,
  setAutoAllocationSettings as setAutoAllocate
} from "PFCore/services/audit_planner";
import { useEffect, useState } from "react";

import { useIsCurrentUserPermittedTo } from "../helpers/use_is_permitted_to";
import { AutoAllocationSettings, PermissionRule } from "../types";

const useAutoAllocationSettings = () => {
  const isPermittedTo = useIsCurrentUserPermittedTo();

  const isAuditAdmin = isPermittedTo(PermissionRule.AuditAdmin);

  const [autoAllocationSettings, setAutoAllocationSettings] = useState<AutoAllocationSettings>();

  const fetchAutoAllocationSettings = () =>
    fetchAutoAllocate().then((resp) => {
      if (!isEqual(resp, autoAllocationSettings)) {
        setAutoAllocationSettings(resp);
      }
    });

  const setAlwaysAllocate = (value: boolean) =>
    setAutoAllocate(value).then((resp) =>
      setAutoAllocationSettings((prev) => ({ ...prev, alwaysOn: resp.alwaysOn } as AutoAllocationSettings))
    );

  useEffect(() => {
    if (!isAuditAdmin) {
      return; //Avoid calling if 422 is guaranteed
    }

    if (!autoAllocationSettings) {
      fetchAutoAllocationSettings();
    }
    const interval = setInterval(fetchAutoAllocationSettings, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [autoAllocationSettings]);

  return { ...autoAllocationSettings, setAlwaysAllocate };
};

export default useAutoAllocationSettings;
