import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import mapValues from "lodash/mapValues";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useActivitiesInvalidate } from "PFCore/hooks/queries/activities/use_activities_invalidate";
import { useActivityUpdate } from "PFCore/hooks/queries/activity";
import { useAttachmentsInvalidate } from "PFCore/hooks/queries/attachments/use_attachments_invalidate";
import { useBookingsFromAvailabilityInvalidate } from "PFCore/hooks/queries/bookings/bookings_from_availability/use_bookings_from_availability_invalidate";
import { useCustomValuesInvalidate } from "PFCore/hooks/queries/custom_fields/admin";
import { usePhasesInvalidate } from "PFCore/hooks/queries/phases/use_phases";
import { useRolesInvalidate } from "PFCore/hooks/queries/roles/use_roles_invalidate";
import { createActivity } from "PFCore/services/activities";
import { deleteAssignee, updateAssignee } from "PFCore/services/assignees";
import { Activity, GIdTargetType } from "PFTypes";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { ModalKey, useActivityEditFormContext } from "../activity_edit_form_context";
import { useActivityEditPageContext, useActivityEditPageFlags } from "../activity_edit_page_context";
import {
  isSubtemplatesLogicActive,
  WORKFLOW_STATUSES_WITH_BOOKINGS
} from "../utils/activity_edit_form.utils";
import { useEngagementWizard } from "./use_engagement_wizard";
import { useGenerateActivityEditFormData } from "./use_generate_activity_edit_form_data";
import { useGrowlFormError } from "./use_growl_form_error";

export const useActivityEditFormSubmit = () => {
  const { isClone, template, activity } = useActivityEditPageContext();

  const { isEdit, isNew, isAudit, isDraft, isEngagement, isRole, isAssigneeRequired } =
    useActivityEditPageFlags();

  const generateFormData = useGenerateActivityEditFormData();
  const renderError = useGrowlFormError();

  const { t } = useTranslation("activities");
  const history = useHistory();
  const location = useLocation<{ utmSource?: string }>();
  const { update: updateActivity } = useActivityUpdate(activity.id);
  const { invalidate: invalidateBookingsFromAvailability } = useBookingsFromAvailabilityInvalidate();
  const { invalidate: invlidateAcitivityPhases } = usePhasesInvalidate();
  const { invalidate: invalidateAttachments } = useAttachmentsInvalidate();
  const { invalidateAuditRoles, invalidateRoles, invalidateFilteredRoles } = useRolesInvalidate();
  const { invalidate: invalidateCustomValues } = useCustomValuesInvalidate();
  const { invalidateByCollection: invalidateActivities } = useActivitiesInvalidate();
  const { getFormattedErrors } = useResponseErrors();
  const { handleEngagementWizardNavigation } = useEngagementWizard();

  const {
    postUntil,
    setErrors,
    assignee,
    selectedParentActivity,
    metadata,
    setSubmitOptions,
    setLocked,
    isModalConfirmed,
    setModalConfirmed,
    setModalDisplayed,
    hasSubtemplates,
    subtemplateKey,
    questionsToggleValue,
    questions,
    subtemplate,
    areQuestionsModified
  } = useActivityEditFormContext();

  return (options: { draft?: boolean } = {}) => {
    let hasErrors = false;
    if (isAssigneeRequired && !assignee) {
      setErrors((prev) => ({ ...prev, assignee: t("edit.cantBeBlank") }));
      hasErrors = true;
    }

    const isExpired = isDraft && postUntil && new Date(postUntil) < new Date();
    if (isExpired) {
      setErrors((prev) => ({ ...prev, post_until: t("edit.shouldNotBeExpired") }));
      hasErrors = true;
    }

    if (!subtemplate && isSubtemplatesLogicActive(hasSubtemplates, !!subtemplateKey, isEdit, isClone)) {
      setErrors((prev) => ({ ...prev, roleForm: t("edit.cantBeBlank") }));
      hasErrors = true;
    }

    if (isNew && questionsToggleValue && questions.every(({ value }) => !value.trim())) {
      setErrors((prev) => ({ ...prev, interestQuestions: t("edit.interestQuestionsError") }));
      hasErrors = true;
    }

    if (hasErrors) {
      renderError(isNew ? t("edit.errorActivityNotCreated") : t("edit.errorActivityNotUpdated"));
      return null;
    }

    setLocked(true);
    const getStatePayload = (): { state?: Activity["state"] } => {
      if (activity.state === "complete") {
        return {};
      }
      return { state: options.draft ? "draft" : "new" };
    };

    const data = generateFormData();

    if (
      !isEngagement &&
      !options.draft &&
      (isEmpty(metadata) ||
        (template.key === "role" && isEmpty(metadata.filters) && isEmpty(metadata.availability))) &&
      data.custom_fields.every((field) => isEmpty(field.values)) &&
      !isModalConfirmed[ModalKey.NoRequirements]
    ) {
      setModalDisplayed(ModalKey.NoRequirements, true);
      setSubmitOptions(options);
      setLocked(false);
      return;
    }

    if (isNew && areQuestionsModified && !isModalConfirmed[ModalKey.QuestionsChanged]) {
      setModalDisplayed(ModalKey.QuestionsChanged, true);
      setSubmitOptions(options);
      setLocked(false);
      return;
    }

    const isAvailabilityChanged = () => {
      const dataAvailability = {
        ...(data.metadata.availability || {}),
        availability_threshold: undefined
      };
      const activityAvailability = {
        ...(activity.metadata.availability || {}),
        availability_threshold: undefined
      };
      return !isEqual(dataAvailability, activityAvailability);
    };

    if (
      isEdit &&
      isAvailabilityChanged() &&
      WORKFLOW_STATUSES_WITH_BOOKINGS.includes(activity.workflow_state) &&
      !isModalConfirmed[ModalKey.Misalignment]
    ) {
      setModalDisplayed(ModalKey.Misalignment, true);
      setSubmitOptions(options);
      setLocked(false);
      return;
    }

    const apiCallFunction = isNew ? createActivity : updateActivity;
    apiCallFunction({
      ...data,
      ...getStatePayload()
    }).then(
      (activity) => {
        if (!activity) {
          return;
        }

        const getAssigneePromise = () => {
          const shouldDelete = activity.assignee && !assignee;

          const catchFunction = () => {
            renderError(activity.id ? t("edit.errorActivityNotCreated") : t("edit.errorActivityNotUpdated"));
            setLocked(false);
          };

          if (assignee?.id === activity.assignee?.id) {
            return Promise.resolve();
          }

          if (shouldDelete && activity.assignee?.id) {
            return deleteAssignee(activity.assignee.id).catch(catchFunction);
          }

          if (assignee) {
            return updateAssignee({
              profileId: assignee?.id,
              activityId: activity.id
            }).catch(catchFunction);
          }

          return Promise.resolve();
        };

        const assigneePromise = getAssigneePromise();

        assigneePromise.then(() => {
          const utmSource = location?.state?.utmSource;
          if (handleEngagementWizardNavigation) {
            handleEngagementWizardNavigation(activity, options);
          } else if (isAudit) {
            const engagmentId = isEngagement ? activity.id : selectedParentActivity?.id;
            history.push(`/audit_planner/control_centre/${engagmentId}`);
          } else if (template.need_matches) {
            history.push(`/activities/${activity.id}/matches`, { utmSource });
          } else {
            history.push(`/activities/${activity.id}`, { utmSource });
          }
          setLocked(false);
        });
        invalidateBookingsFromAvailability(activity.id);
        invlidateAcitivityPhases(activity.id);
        invalidateAttachments(GIdTargetType.Task, activity.id);
        invalidateCustomValues();
        invalidateActivities();
        if (isRole) {
          (isAudit ? invalidateAuditRoles : invalidateRoles)();
        }
        invalidateFilteredRoles({ filters: { fields: { parentActivityId: activity.id } } });
      },
      ({ response }) => {
        setLocked(false);
        renderError(isNew ? t("edit.errorActivityNotCreated") : t("edit.errorActivityNotUpdated"));
        const errors = mapValues(getFormattedErrors(response), (val) =>
          isArray(val) ? val.join(", ") : val
        );
        setErrors(errors);
      }
    );
    setModalConfirmed(ModalKey.NoRequirements, false);
    setModalConfirmed(ModalKey.QuestionsChanged, false);
    setModalConfirmed(ModalKey.Misalignment, false);
  };
};
