// Scrolls to en element or position in the page, allows animation.
//
// @param selector [number|jQuery|string] (0) - position (scrollTop), jquery object or selector to scroll to
// @param options [Object]
//   .offset [number] (0) - offsets the target position
//   .durstion [number] (200) - duration of scroll animation in ms
import $ from "jquery";
import isString from "lodash/isString";

const jQuery = $;
const defaultOptions = { duration: 100, offset: 0, transform: "swing" };

export default function scroll(selector = 0, optionsProvided = {}) {
  const options = { ...defaultOptions, ...optionsProvided };

  // Early return if the selector is not part of the DOM
  if ((isString(selector) || selector instanceof jQuery) && !$(selector).offset()) {
    return;
  }

  let scroll_top = isString(selector) || selector instanceof jQuery ? $(selector).offset().top : selector;

  scroll_top = scroll_top + options.offset;

  return setTimeout(function () {
    $("html, body").animate({ scrollTop: scroll_top + 1 }, options.duration, options.transform);
    return $("html, body").animate({ scrollTop: scroll_top }, options.duration, options.transform);
  }, 0);
}
