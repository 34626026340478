import classNames from "classnames";
import extend from "lodash/extend";
import forEach from "lodash/forEach";
import uniqueId from "lodash/uniqueId";
import { useGrowl } from "PFApp/use_growl";
import { Modal } from "PFComponents/modal";
import { useModalContext } from "PFComponents/modal/modal_context";
import { isProfileFieldPermitted } from "PFCore/helpers/profile_permissions";
import { useResponseErrors } from "PFCore/helpers/use_response_errors";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { AccessLevel, CurrentProfile } from "PFTypes";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { OnProfileUpdateHandler } from "../../profile_show_page.types";
import css from "./my_information_edit_modal.module.scss";
import { MyInformationFormData } from "./my_information_edit_modal.types";
import { AvatarSection } from "./parts/avatar_section";
import { ContactSection } from "./parts/contact_section";
import { DetailsSection } from "./parts/details_section";
import { SkillInsightsSection } from "./parts/skill_insights_section";

type MyInformationEditModalProps = {
  profile: CurrentProfile;
  onClose: VoidFunction;
  onProfileUpdate: OnProfileUpdateHandler;
};

export const MyInformationEditModal = ({
  profile,
  onProfileUpdate,
  onClose
}: MyInformationEditModalProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.myInformationModal" });
  const { t: tTranslation } = useTranslation();
  const { data: currentProfile } = useCurrentProfile();

  const formMethods = useForm<MyInformationFormData>({
    defaultValues: {
      firstName: profile.first_name,
      lastName: profile.last_name || "",
      summary: profile.summary || "",
      contactInformation:
        profile.contact_information.map((contact) => ({
          inputId: uniqueId(),
          ...contact
        })) || [],
      skillsInsights: profile.preferences.display?.skills_insights || "bubble"
    }
  });

  const {
    formState: { isDirty },
    handleSubmit,
    setError
  } = formMethods;

  const growl = useGrowl();
  const { getFormattedErrors } = useResponseErrors();

  const [isLoading, setLoading] = useState(false);
  const [isConfirmModalDisplayed, setIsConfirmModalDisplayed] = useState(false);

  const isFieldPermitted = (field: string, level: AccessLevel) =>
    isProfileFieldPermitted(currentProfile?.permissions_group, profile.permissions_group.id, field, level);

  const handleUpdate = ({
    firstName,
    lastName,
    summary,
    skillsInsights,
    contactInformation
  }: MyInformationFormData) => {
    setLoading(true);

    const data = extend(
      { preferences: { display: { skills_insights: skillsInsights } } },
      isFieldPermitted("summary", AccessLevel.ReadWrite) && { summary },
      isFieldPermitted("first_and_last_name", AccessLevel.ReadWrite) && {
        first_name: firstName,
        last_name: lastName
      },
      isFieldPermitted("contact_info", AccessLevel.ReadWrite) && {
        contact_information: contactInformation.map(({ inputId, ...contact }) => contact)
      }
    );

    return onProfileUpdate(data)
      .then(() => {
        setLoading(false);
        growl({
          message: t("yourInformationUpdated"),
          kind: "success"
        });
      })
      .catch((errors) => {
        const formattedErrors = getFormattedErrors(errors);

        forEach(formattedErrors, (message, fieldName) => {
          // Parse from "contact_information[0][value]" to "contactInformation.0.value"
          const parsedFieldName = fieldName
            .replace("contact_information", "contactInformation")
            .replace("first_name", "firstName")
            .replace("last_name", "lastName")
            .replaceAll("[", ".")
            .replaceAll("]", "") as keyof MyInformationFormData;

          setError(parsedFieldName, { message });
        });

        setLoading(false);

        return errors;
      });
  };

  return (
    <Modal
      title={t("myInformation")}
      onClose={onClose}
      onBeforeClose={
        isDirty
          ? () => {
              setIsConfirmModalDisplayed(true);
              return false;
            }
          : undefined
      }
      onOK={handleSubmit(handleUpdate)}
      disableOKButton={!isDirty || isLoading}
      labelOK={tTranslation("save")}
      ariaLabelOK={t("saveMyInformationForm")}
      ariaLabelCancel={t("cancelEditingMyInformation")}
      classes={{
        modal: classNames(css.myInformationModal, css.modal)
      }}
    >
      <FormProvider {...formMethods}>
        <form>
          <DetailsSection isFieldPermitted={isFieldPermitted} />
          <AvatarSection profile={profile} />
          <SkillInsightsSection />
          <ContactSection isFieldPermitted={isFieldPermitted} isLoading={isLoading} />
        </form>
      </FormProvider>
      {isConfirmModalDisplayed && (
        <ConfirmCloseModal
          onConfirmClose={() => {
            setIsConfirmModalDisplayed(false);
          }}
        />
      )}
    </Modal>
  );
};

type ConfirmCloseModalProps = {
  onConfirmClose: VoidFunction;
};

const ConfirmCloseModal = ({ onConfirmClose }: ConfirmCloseModalProps) => {
  const { t } = useTranslation("profiles", { keyPrefix: "show.parts.myInformationModal" });
  const { t: tTranslation } = useTranslation();
  const { closeWithAnimation } = useModalContext();

  return (
    <Modal
      title={t("unsavedChanges")}
      onOK={() => {
        // Close the form modal
        closeWithAnimation(false);
      }}
      onClose={onConfirmClose}
      labelOK={tTranslation("ok")}
    >
      {t("changesNotSaved")}
    </Modal>
  );
};
