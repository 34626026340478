import { useQueryClient } from "@tanstack/react-query";

import { bookingsFromAvailabilityKeys } from "./query_keys";

interface InvalidateReturn {
  invalidate: (activityId?: number) => Promise<void>;
}

export const useBookingsFromAvailabilityInvalidate = (): InvalidateReturn => {
  const queryClient = useQueryClient();

  return {
    invalidate: (activityId?: number) => {
      if (!!activityId || activityId === 0) {
        return queryClient.invalidateQueries({
          queryKey: bookingsFromAvailabilityKeys.byActivity(activityId)
        });
      }
      return queryClient.invalidateQueries({ queryKey: bookingsFromAvailabilityKeys.all() });
    }
  };
};
