import { keepPreviousData } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { fetchCurrentProfile } from "PFCore/services/current_profile";
import { CurrentProfile } from "PFTypes";
import { QueryOptions } from "PFTypes/request_options";
import { useMemo } from "react";

import { profileKeys } from "./query_keys";

const initialData = {} as CurrentProfile;

export const useCurrentProfile = (options: QueryOptions<CurrentProfile> = {}) => {
  const currentProfileQuery = useQuery<CurrentProfile>({
    queryKey: profileKeys.me(),
    queryFn: () => fetchCurrentProfile(),
    retry: false,
    placeholderData: keepPreviousData,
    initialData,
    ...options
  });

  const isSignedIn = useMemo(
    () => currentProfileQuery.isFetched && !!currentProfileQuery.data?.id,
    [currentProfileQuery.isFetched, currentProfileQuery.data]
  );

  return {
    ...currentProfileQuery,
    data: currentProfileQuery.data!,
    isSignedIn
  };
};
