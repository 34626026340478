import omitBy from "lodash/omitBy";
import { AriaAttributes } from "react";

export const getAriaProps = <T extends object>(
  props: React.PropsWithChildren<T>
): { ariaProps: AriaAttributes; rest: T } => {
  const ariaProps = omitBy(props, (_, key) => !key.startsWith("aria-")) as AriaAttributes;
  const rest = omitBy(props, (_, key) => key.startsWith("aria-")) as T;

  return { ariaProps, rest };
};
