import map from "lodash/map";
import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { AddActivityProfilesModalContent } from "PFApp/activities/show/parts/activity_profiles/add_activity_profiles_modal_content";
import { getGrowlId, useGrowl } from "PFApp/use_growl";
import { Modal } from "PFComponents/modal";
import { useShortlistCreate } from "PFCore/hooks/queries/shortlists/use_shortlist_create";
import { Profile } from "PFTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ADD_SHORTLIST_SUCCESS_ACTION_ID = getGrowlId("add-shortlist");

type AddShortlistModalProps = {
  onClose: VoidFunction;
};

export const AddShortlistModal = ({ onClose }: AddShortlistModalProps) => {
  const { t } = useTranslation("activities", { keyPrefix: "show.shortlist.addShortlistModal" });
  const growl = useGrowl();

  const { task: activity, allShortlists } = useActivityPageContext();
  const { mutateAsync: createShortlist } = useShortlistCreate();

  const [profiles, setProfiles] = useState<Profile[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = async () => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const shortlistCreators = map(profiles, ({ id: profileId }) =>
      createShortlist({ activityId: activity.id, profileId, named: true })
    );

    await Promise.all(shortlistCreators)
      .then(() => {
        growl({
          id: ADD_SHORTLIST_SUCCESS_ACTION_ID,
          kind: "success",
          message: t("successMessage"),
          hidePreviousByTypeAndId: true,
          ttl: 10000
        });
      })
      .catch(() => {
        growl({ message: t("failedMessage"), kind: "error" });
      });

    setIsSaving(false);
  };

  return (
    <Modal
      disableOKButton={profiles.length === 0}
      title={t("title")}
      labelOK={t("addToShortlist")}
      onOK={handleSubmit}
      onClose={onClose}
    >
      <AddActivityProfilesModalContent
        newProfiles={profiles}
        onNewProfilesChange={setProfiles}
        profiles={[]}
        disabledProfileIds={allShortlists.map(({ profile }) => profile.id)}
        activity={activity}
      />
    </Modal>
  );
};
