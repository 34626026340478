import range from "lodash/range";
import { Moment, utc } from "moment";
import { ISO_DATE_FORMAT } from "PFCore/helpers/date";
import { BookingCategory } from "PFTypes";
import React from "react";

import { CalendarPeriod } from "../../bookings_calendar.types";
import { MorePeriods } from "./more_periods";
import { WeekPeriod } from "./week_period";
import css from "./week_periods.module.scss";

const filterEmptyFromEnd = (periodLevels: CalendarPeriod[][]): CalendarPeriod[][] => {
  const tempPeriodLevels = [...periodLevels];
  while (tempPeriodLevels.length > 0 && tempPeriodLevels[tempPeriodLevels.length - 1].length === 0) {
    tempPeriodLevels.pop();
  }
  return tempPeriodLevels;
};

type LevelsCountPerDayReturn = {
  levelsPerDay: number;
  bookingsCount: number;
};

const levelsCountPerDay = (dayDate: string, periodsByLevels: CalendarPeriod[][]): LevelsCountPerDayReturn => {
  const dayDateMoment = utc(dayDate);
  const levelsPerDay = periodsByLevels.map((bookings) =>
    bookings.filter((booking) => {
      const { start_date, end_date } = booking;
      return dayDateMoment.isBetween(start_date, end_date, undefined, "[]");
    })
  );
  const filteredLevels = filterEmptyFromEnd(levelsPerDay);

  return {
    levelsPerDay: filteredLevels.length,
    bookingsCount: filteredLevels.filter((level) => level.length).length
  };
};

type WeekPeriodsProps = {
  startWeekDate: Moment;
  endWeekDate: Moment;
  isExpanded: boolean | undefined;
  periodsByLevels: CalendarPeriod[][];
  bookingCategories: BookingCategory[];
  jobCodeDisplayAs: string;
  onClick?: (period: CalendarPeriod) => void;
};

export const WeekPeriods = ({
  isExpanded,
  periodsByLevels,
  startWeekDate,
  endWeekDate,
  bookingCategories,
  jobCodeDisplayAs,
  onClick
}: WeekPeriodsProps): JSX.Element => {
  const maxLevels = isExpanded ? 5 : 2;
  const allLevels = periodsByLevels.filter((level) => level.length).length;
  const tooMuchBookings = allLevels > maxLevels;
  const periodLevelsToDisplay = tooMuchBookings ? periodsByLevels.slice(0, maxLevels - 1) : periodsByLevels;
  const visibleLevels = maxLevels - 1;
  return (
    <div className={css.periodsContainer}>
      {periodLevelsToDisplay.map((bookings, level) =>
        bookings.map((booking) => (
          <WeekPeriod
            key={booking.id}
            period={booking}
            level={level}
            startWeekDate={startWeekDate}
            endWeekDate={endWeekDate}
            bookingCategories={bookingCategories}
            jobCodeDisplayAs={jobCodeDisplayAs}
            onClick={onClick}
          />
        ))
      )}
      {range(0, 7).map((day) => {
        const currentDay = startWeekDate.clone().add(day, "days");
        const formattedDay = currentDay.format(ISO_DATE_FORMAT);
        const { levelsPerDay, bookingsCount } = levelsCountPerDay(formattedDay, periodsByLevels);
        const tooMuchBookingsPerDay =
          levelsPerDay - visibleLevels > 0 && (tooMuchBookings || levelsPerDay > maxLevels);
        return (
          <React.Fragment key={`morePeriods-${formattedDay}`}>
            {tooMuchBookingsPerDay && (
              <MorePeriods
                visibleLevels={visibleLevels}
                count={bookingsCount - visibleLevels}
                dayNumber={day}
              />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
