import { useActivityPageContext } from "PFApp/activities/show/activity_page_context";
import { useActivityPageConversations } from "PFApp/activities/show/hooks/use_activity_page_conversations";
import { useActivityPageRoles } from "PFApp/activities/show/hooks/use_activity_page_roles";
import { useActivityParent } from "PFApp/activities/show/hooks/use_activity_parent";
import { NoLinkedRoles } from "PFApp/activities/show/parts/no_linked_roles";
import ActivityAudience from "PFApp/components/activity_commons/activity_audience";
import ActivityDetailsMoreInfo from "PFApp/components/activity_commons/activity_details_more_info";
import { ActivityDetailsNotes } from "PFApp/components/activity_commons/activity_details_notes";
import ActivityDetailsQuestions from "PFApp/components/activity_commons/activity_details_questions";
import ActivityDetailsTimeAndDate from "PFApp/components/activity_commons/activity_details_time_and_date";
import { ActivityDetailsVacancies } from "PFApp/components/activity_commons/activity_details_vacancies";
import useIsChatEnabled from "PFApp/use_is_chat_enabled";
import Hr from "PFComponents/hr";
import PageLayout from "PFComponents/page/page_layout";
import { useInvites } from "PFCore/hooks/queries/invites/use_invites";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { useLocation } from "react-router-dom";

import { ActivityDetailsDesc } from "../../details/activity_details_desc";
import { ActivityDetailsSkills } from "../../details/activity_details_skills";
import ActivityDetailsTags from "../../details/activity_details_tags";
import ActivityDetailsWatcherCheckbox from "../../details/activity_details_watcher_checkbox";
import ActivityDetailsWatchers from "../../details/activity_details_watchers";
import { useNotesPermissions } from "../../notes/use_notes_permissions";
import { ActivityProfilesSection } from "../../parts/activity_profiles_section";
import ChatsSection from "../../parts/chats_section";
import Creator from "../../parts/creator";
import { ActivityDetailsActions } from "../details/activity_details_actions";

const metadataToOmit = ["interest_questions", "interest_statement_enabled"];

export const ActivityDetails = () => {
  const location = useLocation();
  const { task, isEngagement, isRole, canCreateRoles, roleKey } = useActivityPageContext();

  const { roles } = useActivityPageRoles();
  const { shouldDisplayNotes } = useNotesPermissions({ activity: task });
  const parentActivity = useActivityParent(task);
  const isChatEnabled = useIsChatEnabled();
  const { primaryConversation, otherConversations, participantsOfConversations } =
    useActivityPageConversations(task);

  const { data: invites } = useInvites(task.id);
  const { data: currentProfile } = useCurrentProfile();

  const { id } = currentProfile;
  const enteredViaWorkflow = location?.state?.utmSource === "workflow";
  const allParticipants = (primaryConversation && participantsOfConversations[primaryConversation.id]) || [];
  const hasInvites = invites?.length > 0;
  const hasOwners = task.owners && task.owners.length > 0;
  const nonOwnerParticipants = allParticipants && allParticipants.filter(({ kind }) => kind !== "owner");
  const hasParticipants = nonOwnerParticipants && nonOwnerParticipants.length > 0;
  const showGroupChat = task.private ? hasOwners || hasInvites || hasParticipants : true;
  const isDraft = task.state === "draft";
  const isCompleted = task.state === "complete";

  const noLinkedRoles = roles.entries.length === 0;

  const showMainChat =
    isChatEnabled &&
    !isDraft &&
    ((!task.private && task.communication_type === "group") || (task.private && primaryConversation)) &&
    showGroupChat;

  const showOtherChats =
    isChatEnabled &&
    !isDraft &&
    !isEngagement &&
    (task.coowned /* always show for owner because there is an action button */ ||
      (otherConversations && otherConversations.length > 0));

  const showWatcherCheckbox =
    !task.coowned &&
    !task.shortlist_assignment &&
    !isEngagement &&
    !enteredViaWorkflow &&
    !isCompleted &&
    !isDraft;

  return (
    <PageLayout mode="withSidebar">
      <div>
        <PageLayout.WhenOneColumn>
          <ActivityDetailsTags />
        </PageLayout.WhenOneColumn>

        <ActivityDetailsActions />

        <PageLayout.WhenOneColumn>
          {showWatcherCheckbox && (
            <>
              <Hr />
              <ActivityDetailsWatcherCheckbox currentProfileId={id} />
              <Hr />
            </>
          )}
        </PageLayout.WhenOneColumn>

        <ActivityProfilesSection />

        {isChatEnabled && (
          <ChatsSection
            showMainChat={showMainChat}
            showOtherChats={showOtherChats}
            activity={task}
            allParticipants={allParticipants}
            primaryConversation={primaryConversation}
            nonOwnerParticipants={nonOwnerParticipants}
            otherConversations={otherConversations}
            participantsOfConversations={participantsOfConversations}
            isEngagement={isEngagement}
          />
        )}

        {isEngagement && noLinkedRoles && task.coowned && (
          <>
            <Hr />
            <NoLinkedRoles
              activity={task}
              canCreateRoles={task.coowned && isEngagement && canCreateRoles}
              roleKey={roleKey}
            />
          </>
        )}

        {/* ActivityDetailsWatchers component include <Hr /> */ task.coowned && <ActivityDetailsWatchers />}
        <PageLayout.WhenTwoColumns>
          <Hr />
        </PageLayout.WhenTwoColumns>

        <PageLayout.WhenOneColumn>
          <Hr />
          <ActivityDetailsMoreInfo
            activity={task}
            currentProfile={currentProfile}
            metadataToOmit={metadataToOmit}
          />
          <Hr />
        </PageLayout.WhenOneColumn>

        <ActivityDetailsSkills task={task} />

        <ActivityDetailsDesc description={task.description} />
        <Hr />
        {isRole && shouldDisplayNotes && <ActivityDetailsNotes />}

        <PageLayout.WhenOneColumn>
          <ActivityDetailsTimeAndDate activity={task} parentActivity={parentActivity} />
        </PageLayout.WhenOneColumn>

        {isRole && task.coowned && (
          <PageLayout.WhenOneColumn>
            <Hr />
            <ActivityDetailsVacancies task={task} />
          </PageLayout.WhenOneColumn>
        )}

        <PageLayout.WhenOneColumn>
          <Hr />
          <ActivityAudience activity={task} />
          <Hr />
          {task.metadata.interest_questions && (
            <>
              <ActivityDetailsQuestions questions={task.metadata.interest_questions} />
              <Hr />
            </>
          )}
          <Creator />
        </PageLayout.WhenOneColumn>
      </div>

      <div>
        <PageLayout.WhenTwoColumns>
          {showWatcherCheckbox && <ActivityDetailsWatcherCheckbox currentProfileId={id} />}
          <ActivityDetailsTags />
          <ActivityDetailsTimeAndDate activity={task} parentActivity={parentActivity} />
          <Hr />
          {isRole && task.coowned && (
            <>
              <ActivityDetailsVacancies task={task} />
              <Hr />
            </>
          )}
          <ActivityAudience activity={task} />
          <Hr />
          <ActivityDetailsMoreInfo
            activity={task}
            currentProfile={currentProfile}
            metadataToOmit={metadataToOmit}
          />
          <Hr />
          {task.metadata.interest_questions && (
            <>
              <ActivityDetailsQuestions questions={task.metadata.interest_questions} />
              <Hr />
            </>
          )}
          <Creator />
        </PageLayout.WhenTwoColumns>
      </div>
    </PageLayout>
  );
};
