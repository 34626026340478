import { useMutation } from "@tanstack/react-query";
import { closeVacancy } from "PFCore/services/vacancies";
import { Id } from "PFTypes";
import { MutationOptions } from "PFTypes/request_options";

import { useShortlistsInvalidate } from "../../shortlists/use_shortlists_invalidate";
import { useActivityInvalidate } from "../use_activity_invalidate";

type CloseVacanciesPayload = {
  selectedReasons: Record<Id, string>;
  closureDetails: Record<Id, string>;
  activityId: Id;
};

export const useCloseVacancies = (options?: MutationOptions<CloseVacanciesPayload>) => {
  const { invalidateShortlists, invalidateRankedShortlists } = useShortlistsInvalidate();
  const { invalidate: invalidateActivities, invalidateVacancies } = useActivityInvalidate();

  const { onSuccess, ...rest } = options || {};

  return useMutation({
    mutationFn: ({ selectedReasons, closureDetails, activityId }: CloseVacanciesPayload) =>
      Promise.allSettled(
        Object.keys(selectedReasons).map((vacancyId) => {
          const reason = selectedReasons[vacancyId];
          if (reason) {
            const isProfileId = reason === "filled_with_named_resource";
            const details = closureDetails[vacancyId];
            const detailsKey = isProfileId ? "profileId" : "closureDetails";

            return closeVacancy({
              activityId,
              vacancyId,
              closureReason: reason,
              [detailsKey]: details
            });
          }
        })
      ).then((response) => {
        invalidateActivities([activityId]);
        invalidateShortlists(activityId);
        invalidateRankedShortlists(activityId);
        setTimeout(() => invalidateVacancies(activityId), 500); // closing vacancies needs a moment to process

        return response;
      }),
    ...rest
  });
};
