import { useActivityCurrentUserRole } from "PFApp/activities/hooks";
import { ProfileCustomValuesList } from "PFApp/components/custom_values_list";
import { useCustomValuePreviewActions } from "PFApp/hooks/use_custom_value_preview_actions";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Typography } from "PFComponents/typography";
import { generateMatchCustomValues } from "PFCore/helpers/custom_value";
import { useSkillsType } from "PFCore/hooks/use_skills_type";
import { ActivityPureCustomValue, CustomValueScope, Id, MatchCustomValue } from "PFTypes";

import { useActivityPageContext } from "../../../../../activity_page_context";
import css from "./table_cells.module.scss";

type SkillsCellProps = {
  profileId: Id;
  matchSkills: MatchCustomValue[];
  roleSkills: ActivityPureCustomValue[];
};

export const SkillsCell = ({ profileId, matchSkills, roleSkills }: SkillsCellProps) => {
  const { task: activity } = useActivityPageContext();
  const { isReviewerUnbiased } = useActivityCurrentUserRole(activity);

  const { showPreview } = useCustomValuePreviewActions();
  const skillsType = useSkillsType();
  const matchAndActivityCustomValues = generateMatchCustomValues(matchSkills, roleSkills);

  const label = roleSkills.length > 0 ? `${matchSkills.length}/${roleSkills.length}` : matchSkills.length;

  return (
    <Tooltip
      content={
        <div className={css.skillsTooltip}>
          <ProfileCustomValuesList
            customFieldName="skills"
            customValues={matchAndActivityCustomValues}
            profileId={profileId}
            onMissingClick={(customValueId) => {
              showPreview({
                scope: CustomValueScope.Activity,
                customTypeId: skillsType!.id,
                customValueId,
                activityId: activity.id
              });
            }}
            experienceTooltipDisabled
            dark
            isBlindModeOn={isReviewerUnbiased}
          />
        </div>
      }
      disabled={matchAndActivityCustomValues.length === 0}
      interactive
    >
      <span>
        <Typography variant="bodyBold" tag="span" className={css.skillsCount} noMargin>
          {label}
        </Typography>
      </span>
    </Tooltip>
  );
};
