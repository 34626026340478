import noop from "lodash/noop";
import { CalendarMode, CalendarModeInitialData, WorkforceMetricsMode } from "PFApp/booking/types";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import { ProjectsResponse } from "PFCore/hooks/queries/bookings/projects/use_projects_entries";
import { WorkforceResponse } from "PFCore/hooks/queries/bookings/workforce/use_workforce_entries";
import { FeatureFlag } from "PFTypes";
import { createContext, useCallback, useContext, useMemo } from "react";

import { useProjects } from "../use_projects";
import { useWorkforce } from "../use_workforce";
import {
  BookingCalendarData,
  BookingsOverviewContextType,
  ProjectsCalendarData,
  WorkforceCalendarData
} from "./booking_overview_context.types";
import { INITIAL_CALENDAR_DATA_PROJECTS } from "./initial_calendar_data_projects";
import { useCalendarMode } from "./use_calendar_mode";
import { useWorkforceMetricsMode } from "./use_workforce_metrics_mode";

const BookingsOverviewContext = createContext<
  BookingsOverviewContextType<WorkforceCalendarData | ProjectsCalendarData>
>({
  calendarMode: CalendarMode.Projects,
  setCalendarMode: noop,
  calendarData: INITIAL_CALENDAR_DATA_PROJECTS,
  showUtilizationMetrics: false,
  workforceMetricsMode: WorkforceMetricsMode.BillableUtilization,
  setWorkforceMetricsMode: noop
});

export const BookingsOverviewContextProvider = ({ children }: React.PropsWithChildren): JSX.Element => {
  const showUtilizationMetrics = useIsFeatureEnabled()(FeatureFlag.ShowUtilization);

  const [calendarMode, setCalendarMode] = useCalendarMode();
  const [workforceMetricsMode, setWorkforceMetricsMode] = useWorkforceMetricsMode({ showUtilizationMetrics });

  const projects = useProjects(calendarMode === CalendarMode.Projects);
  const workforce = useWorkforce({
    enabled: calendarMode === CalendarMode.Workforce,
    showUtilizationMetrics
  });

  const handleSetCalendarMode = useCallback(
    (mode: CalendarMode, initialData?: CalendarModeInitialData) => {
      setCalendarMode(mode);
      if (!initialData) {
        return;
      }

      const calendarData = mode === CalendarMode.Projects ? projects : workforce;
      const { setView } = calendarData;
      const { view, ...rest } = initialData;
      setView(view, rest);
    },
    [projects, workforce, setCalendarMode]
  );

  const calendarData: BookingCalendarData<any> =
    calendarMode === CalendarMode.Projects ? projects : workforce;

  const contextValue = useMemo(
    () => ({
      calendarMode,
      setCalendarMode: handleSetCalendarMode,
      calendarData,
      workforceMetricsMode,
      setWorkforceMetricsMode,
      showUtilizationMetrics
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      calendarMode,
      handleSetCalendarMode,
      calendarData.loaded,
      calendarData.noFiltersSelected,
      calendarData.noOrderSelected,
      calendarData.data,
      calendarData.view,
      calendarData.setView,
      calendarData.onFiltersChange,
      calendarData.onFilterChange,
      calendarData.onFiltersReset,
      showUtilizationMetrics,
      workforceMetricsMode
    ]
  );

  return <BookingsOverviewContext.Provider value={contextValue}>{children}</BookingsOverviewContext.Provider>;
};

export const useBookingsOverviewContext = <
  T extends WorkforceCalendarData | ProjectsCalendarData,
  Response extends ProjectsResponse | WorkforceResponse = ProjectsResponse | WorkforceResponse
>() =>
  useContext<BookingsOverviewContextType<WorkforceCalendarData | ProjectsCalendarData>>(
    BookingsOverviewContext
  ) as BookingsOverviewContextType<T, Response>;
