import uniq from "lodash/uniq";
import canonicalId from "PFCore/helpers/canonicalId";
import { usePreviousValue } from "PFCore/hooks/use_previous_value";
import { Id, ProfileCustomValue, PureCustomValueCamelCased, SuggestionSkill } from "PFTypes";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import { ChangesLogItem } from "../skills_modal";

type UseChangesLogProps = {
  skills: ProfileCustomValue[];
  setSkills: Dispatch<SetStateAction<ProfileCustomValue[]>>;
  discoveredSkills: PureCustomValueCamelCased[];
  setDiscoveredSkills: Dispatch<SetStateAction<PureCustomValueCamelCased[]>>;
  suggestedSkills: SuggestionSkill[];
  setSuggestedSkills: Dispatch<SetStateAction<SuggestionSkill[]>>;
  suggestedFrameworksSkills: Pick<ProfileCustomValue, "id" | "value">[];
  setHiddenFrameworksSkills: Dispatch<SetStateAction<Id[]>>;
};

export const useChangesLog = ({
  skills,
  setSkills,
  discoveredSkills,
  setDiscoveredSkills,
  suggestedSkills,
  setSuggestedSkills,
  suggestedFrameworksSkills,
  setHiddenFrameworksSkills
}: UseChangesLogProps) => {
  const [changesLog, setChangesLog] = useState<ChangesLogItem[]>([]);

  const previousChangesLog = usePreviousValue<ChangesLogItem[]>(changesLog) || [];

  const addToLog = useCallback(
    (...changes) => {
      setChangesLog((changesLog) => [
        ...changesLog,
        ...changes.map((change) => ({
          ...change,
          oldData: [...skills, ...discoveredSkills, ...suggestedSkills].find(({ id }) => id === change.id)
        }))
      ]);
    },
    [skills, discoveredSkills, suggestedSkills]
  );

  const removeFromLog = useCallback((removePredicate: (log: ChangesLogItem) => boolean) => {
    setChangesLog((changesLog) => changesLog.filter((log) => !removePredicate(log)));
  }, []);

  const [addedValues, deletedValues] = useMemo(() => {
    const deleted = changesLog
      .filter(({ type }) => type === "delete")
      .map((entry) => canonicalId(String(entry.oldData?.value || "")));

    const added = changesLog
      .filter(({ type }) => ["new", "suggested", "discovered"].includes(type))
      .map((entry) => canonicalId(String(entry.data.value ?? entry.oldData?.value)));

    return [added, deleted];
  }, [changesLog]);

  const changeSkill = (change: ChangesLogItem) => {
    const { id, type, data } = change;

    if (type === "new") {
      setSkills((skills) => [data, ...skills]);
    }

    if (type === "delete") {
      const isNew = changesLog.find((skill) => skill.id === id)?.type === "new";
      if (isNew) {
        setChangesLog(changesLog.filter((skill) => skill.id !== id));
      }
      setSuggestedSkills(suggestedSkills.filter((skill) => skill.id !== id));
      setDiscoveredSkills(discoveredSkills.filter((skill) => skill.id !== id));
      setHiddenFrameworksSkills((prevSkills) => uniq([...prevSkills, id]));
      setSkills(skills.filter((skill) => skill.id !== id));
      return;
    }

    if (type === "ranked") {
      setSkills(
        skills.map((skill) => (skill.id !== id ? skill : ({ ...skill, ...data } as ProfileCustomValue)))
      );
    }

    if (type === "discovered") {
      const skill = discoveredSkills.find((skill) => skill.id === id);
      setDiscoveredSkills(discoveredSkills.filter((skill) => skill.id !== id));
      setSkills([{ ...skill, ...data } as ProfileCustomValue, ...skills]);
    }

    if (type === "suggested") {
      const skill = suggestedSkills.find((skill) => skill.id === id);
      setSuggestedSkills(suggestedSkills.filter((skill) => skill.id !== id));
      setSkills([{ ...skill, ...data } as ProfileCustomValue, ...skills]);
    }

    if (type === "framework") {
      const skill = suggestedFrameworksSkills.find((skill) => skill.id === id);
      setHiddenFrameworksSkills((prevSkills) => uniq([...prevSkills, id]));
      setSkills([{ ...skill, ...data } as ProfileCustomValue, ...skills]);
    }
  };

  useEffect(() => {
    if (!changesLog.length) {
      return;
    }

    const itemsAddedCount = changesLog.length - previousChangesLog.length;
    if (itemsAddedCount <= 0) {
      return;
    }

    const changesToApply = changesLog.slice(changesLog.length - itemsAddedCount);
    changesToApply.forEach((change) => changeSkill(change));
    // eslint-disable-next-line
  }, [changesLog]);

  return {
    addToLog,
    removeFromLog,
    changesLog,
    setChangesLog,
    addedValues,
    deletedValues
  };
};
