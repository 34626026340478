import classNames from "classnames";
import range from "lodash/range";

import { IconSizeTable } from "../icon";
import css from "./loading_dots.module.scss";

type CircleSize = "xs" | "sm" | "md" | "lg" | "xl";

type SingleDotProps = {
  circlesEnabled: boolean;
  circleSize: CircleSize;
};

const SingleDot = ({ circlesEnabled, circleSize }: SingleDotProps): JSX.Element => {
  const sizeValue = IconSizeTable[circleSize];
  return (
    <span
      style={circlesEnabled ? { width: sizeValue, height: sizeValue } : {}}
      className={classNames(css.loadingDotsElement, {
        [css.loadingDotsCircle]: circlesEnabled
      })}
    >
      {!circlesEnabled && "."}
    </span>
  );
};

type CommonProps = {
  pageViewEnabled?: boolean;
  centered?: boolean;
  className?: string;
};

type ConditionalProps =
  | {
      circlesEnabled: true;
      circleSize?: CircleSize;
    }
  | {
      circlesEnabled?: false;
      circleSize?: never;
    };

type LoadingDotsProps = CommonProps & ConditionalProps;

export const LoadingDots = ({
  circlesEnabled,
  pageViewEnabled,
  circleSize = "md",
  centered = false,
  className
}: LoadingDotsProps): JSX.Element => (
  <div
    role="progressbar"
    aria-busy="true"
    className={classNames(css.loadingDots, className, {
      [css.centered]: centered || pageViewEnabled,
      [css.pageView]: pageViewEnabled
    })}
  >
    {range(0, 3).map((index) => (
      <SingleDot key={index} circlesEnabled={circlesEnabled ?? false} circleSize={circleSize} />
    ))}
  </div>
);
