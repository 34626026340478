import compact from "lodash/compact";
import { ActivityEvent, ActivityMemberEvent, Id } from "PFTypes";
import { useMemo } from "react";

import { useEventsProfiles } from "./use_events_profiles";

export const useEventProfiles = (event: ActivityEvent) => {
  const { findProfiles, isLoading } = useEventsProfiles();

  const targetProfiles = useMemo(() => {
    const { diff, metadata } = event as ActivityMemberEvent;
    const profileIds = diff.profileId?.[1] || metadata.profileIds;
    const targetIds = Array.isArray(profileIds) ? profileIds : [profileIds];

    return findProfiles(compact([diff.profileId?.[0], ...targetIds]) as Id[]);
  }, [findProfiles, event]);

  const targetProfile = useMemo(() => targetProfiles?.[0], [targetProfiles]);

  return { targetProfile, targetProfiles, isLoading };
};
