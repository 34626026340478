import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { fetchCustomValues, FetchCustomValuesParams } from "PFCore/services/admin/custom_fields";
import {
  FetchCtCustomValuesParams,
  fetchCustomValue,
  fetchCustomValuesByType
} from "PFCore/services/admin/custom_values";
import {
  _CustomValue,
  CustomValue,
  CustomValueState,
  Id,
  MetaWithPagination,
  OrderOption,
  QueryOptions
} from "PFTypes";

import { QueryOptionsWithCallbacks, useQueryWithCallbacks } from "../../use_query_with_callbacks";
import { customValueKeys } from "./query_keys";

export interface CustomValuesByFieldResponse {
  entries: CustomValue[];
  meta: {
    total: number;
    counters: {
      state_tally: Record<CustomValueState, number>;
    };
    order: {
      options: OrderOption[];
    };
  };
}

export interface CustomValuesByTypeResponse {
  entries: CustomValue[];
  meta: MetaWithPagination & {
    counters: {
      state_tally: Record<CustomValueState, number>;
    };
  };
}

export const useCustomValuesByField = (
  customTypeId: number,
  page: number,
  perPage: number,
  params: FetchCustomValuesParams,
  options?: UseQueryOptions<CustomValuesByFieldResponse>
) =>
  useQuery<CustomValuesByFieldResponse>({
    queryKey: customValueKeys.list(customTypeId, page, perPage, params),
    queryFn: () => fetchCustomValues(customTypeId, page, perPage, params),
    retry: false,
    ...options
  });

export const useCustomValuesByType = (
  customTypeId: Id,
  page: number,
  perPage: number,
  params: FetchCtCustomValuesParams,
  options?: QueryOptions<CustomValuesByTypeResponse>
) =>
  useQuery<CustomValuesByTypeResponse>({
    queryKey: customValueKeys.list(customTypeId, page, perPage, params),
    queryFn: () => fetchCustomValuesByType(customTypeId, page, perPage, params),
    retry: false,
    ...options
  });

export const useCustomValue = (
  customTypeId: Id,
  customValueId: Id,
  options?: QueryOptionsWithCallbacks<_CustomValue>
) =>
  useQueryWithCallbacks({
    queryKey: customValueKeys.single(customTypeId, customValueId),
    queryFn: () => fetchCustomValue(customTypeId, customValueId),
    ...options
  });
