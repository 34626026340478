import { useQueryClient } from "@tanstack/react-query";
import { FetchProfileSkillsFrameworksParams } from "PFCore/services/skills_frameworks/fetch_profile_skills_frameworks";
import { Id } from "PFTypes";

import { skillsFrameworksKeys } from "./query_keys";

export const useSkillsFrameworksInvalidate = () => {
  const cache = useQueryClient();

  return {
    invalidateAll: () => cache.invalidateQueries({ queryKey: skillsFrameworksKeys.all() }),
    invalidateOptions: () => cache.invalidateQueries({ queryKey: skillsFrameworksKeys.options() }),
    invalidateProfileSkillsFrameworks: (profileId: Id, params?: FetchProfileSkillsFrameworksParams) =>
      cache.invalidateQueries({ queryKey: skillsFrameworksKeys.profileSkillsFrameworks(profileId, params) })
  };
};
