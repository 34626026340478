import { useQueryClient } from "@tanstack/react-query";
import { GId } from "PFTypes";

import { chatKeys } from "./query_keys";

export const useChatInvalidate = () => {
  const queryClient = useQueryClient();

  return {
    invalidateConversationsByTarget: (target: GId) => {
      queryClient.invalidateQueries({ queryKey: chatKeys.conversationsWithTarget(target) });
    },
    invalidateAllConversations: () => {
      queryClient.invalidateQueries({ queryKey: chatKeys.conversations() });
    }
  };
};
