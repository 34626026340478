import isNil from "lodash/isNil";
import { useActivity } from "PFCore/hooks/queries/activity";
import { Activity } from "PFTypes";

export const useActivityParent = (activity?: Activity): Activity | null => {
  const { parent_activity_id: parentActivityId } = activity || {};

  const { data: parentActivity } = useActivity(parentActivityId as number, { enabled: !!parentActivityId });

  return (!isNil(parentActivityId) && parentActivity) || null;
};
